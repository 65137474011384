import { useCallback, useEffect, useState } from "react";
import { registersApi } from "../api";
import { useLocationsContext } from "../providers/LocationsProvider/LocationsProvider";

export class RegisterItem {
  /**
   *
   * @param {number} id Register id
   * @param {number} serialNumber The serial number of register
   * @param {string} registerNumber The tracking code of the register
   * @param {string} status The register status
   * @param {string} pickupCity The departure city
   * @param {string} pickupAddress The departure address
   * @param {number} ordersCount The orders count
   * @param {string} creationDate The creation date
   */
  constructor({
    id,
    serialNumber,
    registerNumber,
    status,
    pickupCity,
    pickupAddress,
    ordersCount,
    creationDate,
  }) {
    /**
     * The unique ID of the register.
     *
     * This value is unique across organizations.
     * @type {number}
     */
    this.id = id;
    /**
     * The serial always-increasing number of the register in a particular organization.
     *
     * This value is not unique across organizations.
     * @type {number}
     */
    this.serialNumber = serialNumber;
    this.registerNumber = registerNumber;
    this.status = status;
    this.pickupCity = pickupCity;
    this.pickupAddress = pickupAddress;
    this.ordersCount = ordersCount;
    this.creationDate = creationDate;
  }
}

/**
 *
 * @returns {{deleteRegister: ((function(*): Promise<boolean|undefined>)|*), registers: RegisterItem[], loading: boolean}}
 */
export function useRegisters() {
  const [registers, setRegisters] = useState([]);
  const [loading, setLoading] = useState(true);

  const { getCityById } = useLocationsContext();

  const fetchRegisters = useCallback(async () => {
    try {
      setLoading(true);
      const fetchedRegisters = await registersApi.getRegisters();
      const preparedRegisters = fetchedRegisters.map((register) => {
        const pickupCity = getCityById(register.departureCityId);
        return new RegisterItem({
          id: register.id,
          serialNumber: register.serialNumber,
          registerNumber: register.trackingCode,
          status: register.status,
          pickupAddress:
            register.departureAddress === ""
              ? register.postOffice.name
              : register.departureAddress,
          pickupCity: pickupCity?.city,
          ordersCount: Number(register.ordersCount),
          creationDate: register.creationDate,
        });
      });
      setRegisters(preparedRegisters);
    } catch (error) {
      console.error("Failed to fetch the registers:", error);
    } finally {
      setLoading(false);
    }
  }, [getCityById]);

  useEffect(() => {
    fetchRegisters();
  }, [fetchRegisters, getCityById]);

  const deleteRegister = useCallback(async (id) => {
    try {
      const registerDeleted = await registersApi.deleteRegister(id);
      setRegisters((prevRegister) => prevRegister.filter((register) => register.id !== id));
      return registerDeleted;
    } catch (e) {
      console.error("Failed to delete the register:", e);
      return false;
    }
  }, []);

  return { registers, loading, deleteRegister, fetchRegisters };
}
