import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import logoYellowSmall from "../../images/logoBlackSmall.svg";
import { LanguageSwitch } from "../../components/languageSwitch/LanguageSwitch";
import React from "react";

export function ChangelogHeader() {
  const { t } = useTranslation();

  return (
    <Box className={"flex justify-between items-center py-3 px-5 shadow-md mb-2"}>
      <Link to="/" className={"w-4/5"}>
        <img src={logoYellowSmall} alt={t("alts.logoYellow")} />
      </Link>
      <Box className={"flex items-start justify-end w-1/5"}>
        <LanguageSwitch />
      </Box>
    </Box>
  );
}
