import React, { useCallback, useState } from "react";
import { IconButton, Box, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SideBarMain from "../sideBarMain/SideBarMain";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import logoYellowSmall from "../../images/logoBlackSmall.svg";

const ANCHOR_TAG = "a";

const MobileSidebar = ({ isOpen, toggleSidebar }) => {
  const handleClose = useCallback(
    (event) => {
      if (event.target.closest(ANCHOR_TAG)) {
        toggleSidebar(false);
      }
    },
    [toggleSidebar],
  );

  return (
    <>
      {isOpen && (
        <Box
          className="fixed inset-0 z-30 bg-black bg-opacity-50"
          onClick={() => toggleSidebar(false)}
        />
      )}
      <Box
        className={`fixed inset-y-0 left-0 z-40 bg-white transform overflow-auto transition-transform duration-300 ease-in-out shadow-md ${isOpen ? "translate-x-0" : "-translate-x-full"}`}
        style={{ minWidth: "300px" }}
        onClick={handleClose}
      >
        <Box className="flex items-center justify-between p-4 border-b border-gray-300">
          <Link to="/" className="block" onClick={() => toggleSidebar(false)}>
            <img src={logoYellowSmall} alt="Logo" className="h-7" />
          </Link>
          <IconButton
            onClick={() => toggleSidebar(false)}
            color="inherit"
            aria-label="close sidebar"
            edge="end"
            sx={{
              marginRight: "-12px",
              color: "text.primary",
              "&:hover": { bgcolor: "action.hover" },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <SideBarMain />
      </Box>
    </>
  );
};
const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = (open) => {
    setIsOpen(open);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open sidebar"
        edge="start"
        onClick={() => toggleSidebar(true)}
        sx={{ mr: 1, display: { xs: "block", md: "none" } }}
      >
        <MenuIcon />
      </IconButton>
      <MobileSidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
    </>
  );
};
export default BurgerMenu;
