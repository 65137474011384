import React from "react";
import { Box, Divider } from "@mui/material";
import { AddressItem } from "./AddressItem";

export function AddressList({ addresses, updateAddress, deleteAddress }) {
  return (
    <>
      {addresses.map((address) => (
        <Box key={address.id}>
          <AddressItem
            address={address}
            handleEditAddress={updateAddress}
            deleteAddress={deleteAddress}
          />
          <Divider orientation={"horizontal"} flexItem />
        </Box>
      ))}
    </>
  );
}
