import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { delivoApi } from "../api";
import showApiErrorNotification from "../components/showApiErrorNotification";
import { localizeNewsItem } from "./localizeNewsItem";
import { useItemsPerScreenSize } from "./useItemsPerScreenSize";

/**
 * News items per screen mapping.
 * @type {Object}
 * @property {number} sm - Number of items for small screens(599px and less)).
 * @property {number} lg - Number of items for large screens (600px - 1535px).
 * @property {number} xl - Number of items for extra-large screens (1536px- 2559px).
 * @property {number} xxl - Number of items for extra-extra-large screens (2560px and bigger).
 * @property {number} default - Default number of items .
 */
const newsItemsPerScreen = {
  sm: 3,
  lg: 4,
  xl: 6,
  xxl: 10,
  default: 10,
};

/**
 * Custom hook for fetching and managing news list data.
 *
 * This hook provides a way to fetch news list data using the delivoApi service,
 * manage loading state, and access localized news item text based on the current language setting.
 * It also tracks pagination information for fetching news items in batches.
 *
 * @param {number} initialPage - The initial page number for pagination.
 *
 * @returns {{
 *   news: Array<{NewsItem}>,
 *   loading: boolean,
 *   page: number,
 *   totalPages: number,
 *   setPage: (function(number): void)
 * }}
 * - news: An array of news item objects, each extended with localized text based on the current language setting.
 * - loading: A boolean flag indicating whether news list data is currently being fetched.
 * - page: The current page number for pagination.
 * - totalPages: The total number of pages available for pagination.
 * - setPage: Function to set the current page number for pagination.
 */
export function useNewsList(initialPage = 1) {
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(initialPage);
  const itemsPerAPICall = useItemsPerScreenSize({ items: newsItemsPerScreen });

  const fetchNews = useCallback(async () => {
    setLoading(true);
    try {
      const response = await delivoApi.getNewsList({ page, pageSize: itemsPerAPICall });
      setNewsList(response.results || []);
      setTotalPages(Math.ceil(response.count / itemsPerAPICall));
    } catch (error) {
      console.error("Error fetching news:", error);
      showApiErrorNotification(t("alertsMessages.failedGetNews"), error, enqueueSnackbar);
    } finally {
      setLoading(false);
    }
  }, [page, enqueueSnackbar, t, itemsPerAPICall]);

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  const localizedNews = useMemo(() => {
    return newsList.map((newsItem) => localizeNewsItem(newsItem, i18n.language));
  }, [newsList, i18n.language]);

  return { news: localizedNews, loading, page, totalPages, setPage };
}
