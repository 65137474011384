import React, { useCallback, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  MenuItem,
  Menu,
  Button,
} from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useLocationsContext } from "../../providers/LocationsProvider/LocationsProvider";
import { handleConfirmation } from "../handleConfirmation";
import { useConfirm } from "material-ui-confirm";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export function AddressItem({ address, handleEditAddress, deleteAddress }) {
  const { t } = useTranslation();
  const { getCityById } = useLocationsContext();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const city = getCityById(address.cityId);
  const isCity = !!city;
  const isOpacity = isCity ? "" : "opacity-50";

  const handleDeleteAddress = useCallback(async () => {
    try {
      const addressDeleted = await deleteAddress(address.id);
      if (addressDeleted) {
        enqueueSnackbar(t("alertsMessages.addressDeleted"), { variant: "success" });
        console.info(`Address ${address.id} deleted`);
      } else {
        enqueueSnackbar(t("alertsMessages.cantAddressDelete"), { variant: "error" });
        console.error(`Failed to delete the address, ${address.id}`);
      }
    } catch (e) {
      enqueueSnackbar(t("alertsMessages.cantAddressDelete"), { variant: "error" });
      console.error(`Failed to delete the address, ${address.id}`, e);
    }
  }, [address.id, deleteAddress, enqueueSnackbar, t]);

  const handleDeleteConfirmation = useCallback(async () => {
    const confirmationDetails = {
      description: t("confirmation.confirmDeleteAddress", { address: address.name }),
    };
    await handleConfirmation(confirm, confirmationDetails, handleDeleteAddress, t);
  }, [address.name, confirm, handleDeleteAddress, t]);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    <Button
      onClick={() => handleEditAddress(address)}
      disabled={!isCity}
      className={isOpacity}
      sx={{ fontSize: "12px", textAlign: "left", pb: 0 }}
    >
      {t("accountPage.editAddressBtn")}
    </Button>,
    <Button
      onClick={handleDeleteConfirmation}
      sx={{ opacity: 1, fontSize: "12px", color: "black", textAlign: "left", pt: 0 }}
    >
      {t("accountPage.deleteAddressBtn")}
    </Button>,
  ];

  const renderButtons = () => (
    <Box className={"smMax:w-1/6 md:w-1/5 flex items-center justify-end mr-5"}>
      <IconButton
        aria-label="Edit"
        onClick={() => handleEditAddress(address)}
        disabled={!isCity}
        className={`${isOpacity} text-sm`}
      >
        <Edit />
      </IconButton>
      <IconButton aria-label="Delete" onClick={handleDeleteConfirmation} sx={{ opacity: 1 }}>
        <Delete />
      </IconButton>
    </Box>
  );

  return (
    <Box key={address.id} className={"w-full m-3"}>
      <Box className={"w-full flex items-start flex-col"}>
        <Typography variant="caption" className={`text-gray-500 w-full ${isOpacity}`}>
          {address.name}
        </Typography>
        <Box className={"w-full flex items-center"}>
          <Box className={`smMax:w-5/6 md:w-4/5 flex items-center justify-between ${isOpacity}`}>
            <Typography className={"smMax:w-1/6 md:w-1/5"}>
              {city?.city || t("tooltips.noCity")}
            </Typography>
            <Typography
              className={
                "smMax:w-4/6 md:w-4/5 flex justify-self-center self-start whitespace-normal truncate"
              }
            >
              {address.address}
            </Typography>
          </Box>
          {!isMobile ? (
            renderButtons()
          ) : (
            <>
              <IconButton
                style={{ borderRadius: 5 }}
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                {options.map((option, index) => (
                  <MenuItem
                    key={index}
                    onClick={handleClose}
                    sx={{
                      px: 0,
                      my: 0,
                    }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
