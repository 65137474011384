import React, { useCallback, useMemo, useState } from "react";
import { Box, Divider, MenuItem, TextField, Typography } from "@mui/material";
import ButtonComponent from "../buttonComponent/ButtonComponent";
import RadioGroupField from "../radioGroupField/RadioGroupField";
import { DELIVERY_METHOD } from "../constants";
import { useTranslation } from "react-i18next";
import { usePostOffices } from "../../hooks/usePostOffices";
import DataGridTable from "../dataGrid/DataGridTable";
import { registerCreationColumns } from "./registerColumns";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { registersApi } from "../../api";
import { useNavigate } from "react-router-dom";
import { useGetAvailableOrders } from "../../hooks/useRegister";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useSnackbar } from "notistack";
import showApiErrorNotification from "../showApiErrorNotification";
import { CitySelectField } from "../selectFields/CitySelectField";
import { AddressSelectField } from "../selectFields/AddressSelectField";
import { getFormFieldHelperText } from "../getFormFieldHelperText";
import { RegionSelectField } from "../selectFields/RegionSelectField";
import { TooltipWrapper } from "../tooltipWrapper/TooltipWrapper";

const initialValues = {
  pickupMethod: DELIVERY_METHOD.COURIER,
  pickupCity: "",
  pickupPostOffice: "",
  pickupPostOfficeName: "",
  pickupPostOfficeAddress: "",
  pickupAddress: "",
  pickupAddressId: "",
  pickupRegion: "",
};

const useValidationSchema = (t) => {
  return useMemo(() => {
    return Yup.object().shape({
      pickupCity: Yup.string().when("pickupMethod", {
        is: (pickupMethod) => pickupMethod === DELIVERY_METHOD.DEPARTMENT,
        then: (schema) =>
          schema
            .required(t("errorsMessages.actualAddressCityRequire"))
            .max(30, t("errorsMessages.maxCityLength")),
        otherwise: (schema) => schema.notRequired(),
      }),
      pickupPostOffice: Yup.string().when("pickupMethod", {
        is: (pickupMethod) => pickupMethod === DELIVERY_METHOD.DEPARTMENT,
        then: (schema) => schema.required(t("errorsMessages.departmentRequire")),
        otherwise: (schema) => schema.notRequired(),
      }),
      pickupAddressId: Yup.string().when("pickupMethod", {
        is: (pickupMethod) => pickupMethod === DELIVERY_METHOD.COURIER,
        then: (schema) =>
          schema
            .required(t("errorsMessages.selectFromMyAddresses"))
            .max(255, t("errorsMessages.maxAddressLength")),
        otherwise: (schema) => schema.notRequired(),
      }),
    });
  }, [t]);
};

function RegisterCreation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [pickupCity, setPickupCity] = useState("");
  const { postOffices, isLoaded } = usePostOffices(pickupCity);
  const { getAvailableOrders } = useGetAvailableOrders();
  const columns = registerCreationColumns(t);
  const [ordersToSelect, setOrdersToSelect] = useState([]);
  const [selectedOrderTrackingCodes, setSelectedOrderTrackingCodes] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingOrders, setLoadingOrders] = useState(false);

  const validationSchema = useValidationSchema(t);

  const pickupMethodLabels = [
    {
      value: DELIVERY_METHOD.COURIER,
      label: t("orderCreation.clientPartRadioGroupDeliveryMethodCourier"),
    },
    {
      value: DELIVERY_METHOD.DEPARTMENT,
      label: t("orderCreation.clientPartRadioGroupDeliveryMethodPostOffice"),
    },
  ];
  const handleRegionChange = (cityField, addressField, setFieldValue) => {
    setFieldValue(cityField, "");
    setFieldValue(addressField, "");
  };
  const handleCityChange = (selectedCity, setFieldValue) => {
    selectedCity?.id && setPickupCity(selectedCity.id);
    setFieldValue("pickupPostOffice", "");
  };
  const handlePostOfficeName = (selectedPostOfficeId, setFieldValue) => {
    const selectedPostOffice = postOffices.find((office) => office.guid === selectedPostOfficeId);
    if (selectedPostOffice) {
      const { name, guid, address } = selectedPostOffice;
      setFieldValue("pickupPostOfficeName", name);
      setFieldValue("pickupPostOfficeAddress", address);
      setFieldValue("pickupPostOffice", guid);
    }
  };

  const handleMethodChange = (value, setFieldValue) => {
    setFieldValue("pickupMethod", value);
    if (value === DELIVERY_METHOD.DEPARTMENT) {
      setFieldValue("pickupCity", "");
      setFieldValue("pickupAddress", "");
      setFieldValue("pickupAddressId", "");
    } else if (value === DELIVERY_METHOD.COURIER) {
      setFieldValue("pickupCity", "");
      setFieldValue("pickupPostOffice", "");
      setFieldValue("pickupPostOfficeName", "");
      setFieldValue("pickupPostOfficeAddress", "");
    }
  };

  const handleGetAvailableOrders = async (filters) => {
    setLoadingOrders(true);
    const availableOrders = await getAvailableOrders(filters);
    if (availableOrders.length === 0) {
      enqueueSnackbar(t("alertsMessages.noOrdersFind"), {
        variant: "warning",
      });
      setOrdersToSelect([]);
      setLoadingOrders(false);
    } else {
      setOrdersToSelect(availableOrders);
      setLoadingOrders(false);
    }
  };

  const createNewRegister = useCallback(
    async (values, formikBag) => {
      try {
        const dataForRegister = {
          orders_tracking_codes: selectedOrderTrackingCodes,
          departure_address: values.pickupAddress,
          departure_city_id: values.pickupCity,
          departure_post_office_guid: values.pickupPostOffice || null,
          departure_post_office_name: values.pickupPostOfficeName,
          departure_post_office_address: values.pickupPostOfficeAddress,
        };
        const createRegister = await registersApi.createRegister(dataForRegister);
        console.info("A new register successfully created.", createRegister);
        enqueueSnackbar(`${t("alertsMessages.registerCreated")} ${createRegister.serialNumber}`, {
          variant: "success",
        });
        navigate(`/registers/${createRegister.id}/`);
        formikBag.resetForm();
      } catch (e) {
        console.error("Error creating register:", e);
        showApiErrorNotification(t("alertsMessages.registerCreationFailed"), e, enqueueSnackbar);
      }
    },
    [selectedOrderTrackingCodes, navigate, enqueueSnackbar, t],
  );

  const getPickupCityHelperText = (pickupPostOffices, touched, errors) => {
    return getFormFieldHelperText({
      requestMade: isLoaded,
      identifier: pickupCity,
      items: pickupPostOffices,
      field: "pickupPostOffice",
      touched: touched,
      errors: errors,
      noItemsText: t("tooltips.noPostOffices"),
    });
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <Box className={"shadow-lg rounded-md"}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createNewRegister}
      >
        {({
          values: {
            pickupMethod,
            pickupCity,
            pickupPostOffice,
            pickupAddress,
            pickupAddressId,
            pickupRegion,
          },
          touched,
          errors,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form className={"h-fit"}>
            <Box className={"flex items-center justify-start"}>
              <ButtonComponent startIcon={<ArrowBackIosNewIcon />} onClick={handleGoBack} />
              <Typography variant={"h5"} className={"m-5"}>
                {t("register.registerCreationTitle")}
              </Typography>
            </Box>
            <Divider orientation={"horizontal"} flexItem />
            <Box className={"flex flex-col"}>
              <Box className={"flex justify-between mx-5"}>
                <RadioGroupField
                  name="pickupMethod"
                  value={pickupMethod}
                  onChange={(event) => handleMethodChange(event.target.value, setFieldValue)}
                  labels={pickupMethodLabels}
                  labelClassName={"w-fit"}
                  className={"flex items-start px-.5 py-2 w-full"}
                />
                <Box className={"flex justify-center"}>
                  <TooltipWrapper title={t("tooltips.findOrders")}>
                    <span>
                      <ButtonComponent
                        text={t("register.findBtn")}
                        type="button"
                        onClick={() =>
                          handleGetAvailableOrders({
                            pickupCity,
                            pickupAddress,
                            pickupPostOffice,
                          })
                        }
                        className={"content-block m-4 p-3 "}
                        disabled={!pickupCity || (!pickupAddress && !pickupPostOffice)}
                      />
                    </span>
                  </TooltipWrapper>
                </Box>
              </Box>
              <Box className={"mx-5 mb-7"}>
                <Box
                  className={`content-block ${
                    pickupMethod === DELIVERY_METHOD.DEPARTMENT ? "flex" : "hidden"
                  }`}
                >
                  <RegionSelectField
                    fieldName="pickupRegion"
                    onCustomChange={() =>
                      handleRegionChange("pickupCity", "pickupPostOffice", setFieldValue)
                    }
                    className="w-1/3 mr-5"
                  />
                  <CitySelectField
                    fieldName="pickupCity"
                    label={t("orderCreation.clientPartSelectCity")}
                    tooltipMessage={t("tooltips.noPostOffices")}
                    customOnChange={(value) => handleCityChange(value, setFieldValue)}
                    checkedField="postOfficeDeliveryEnabled"
                    className="w-1/3 mr-5"
                    region={pickupRegion}
                  />
                  <Field
                    as={TextField}
                    name="pickupPostOffice"
                    label={t("orderCreation.clientPartSelectDepartment")}
                    value={pickupPostOffice}
                    variant="standard"
                    className={"w-1/3"}
                    disabled={!pickupCity || !postOffices.length}
                    onChange={(event) => handlePostOfficeName(event.target.value, setFieldValue)}
                    error={touched.pickupPostOffice && Boolean(errors.pickupPostOffice)}
                    helperText={getPickupCityHelperText(postOffices, touched, errors)}
                    select
                  >
                    {postOffices.map((office) => (
                      <MenuItem key={office.guid} value={office.guid}>
                        {office.name}, {office.address}
                      </MenuItem>
                    ))}
                  </Field>
                </Box>
                <Box
                  className={`content-block ${
                    pickupMethod === DELIVERY_METHOD.COURIER ? "flex" : "hidden"
                  }`}
                >
                  <AddressSelectField
                    fieldName={"pickupAddressId"}
                    className={"w-1/3 mr-2"}
                    value={pickupAddressId}
                  />
                </Box>
              </Box>
            </Box>
            <DataGridTable
              rows={ordersToSelect}
              columns={columns}
              onRowSelectionModelChange={(selectedCodes) => {
                setSelectedOrderTrackingCodes(selectedCodes);
              }}
              loading={loadingOrders}
              isRefreshBtnVisible={false}
              emptyTableText={
                <Typography className={"text-center pt-12"}>
                  {t("ordersTableHeaders.noOrdersInRegister")}
                </Typography>
              }
            />
            <Box className={"flex justify-end"}>
              <TooltipWrapper title={t("tooltips.saveBtnCreateRegister")}>
                <span>
                  <ButtonComponent
                    text={t("register.saveBtn")}
                    type="submit"
                    disabled={!selectedOrderTrackingCodes.length || isSubmitting}
                  />
                </span>
              </TooltipWrapper>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default RegisterCreation;
