import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import logoYellowSmall from "../../../images/logoBlackSmall.svg";
import { LanguageSwitch } from "../../languageSwitch/LanguageSwitch";
import React from "react";

/**
 * Component representing the header for unauthorized users.
 *
 * This component displays a header with a logo and a language switcher. The logo is conditionally rendered
 * based on the screen size. It uses the `useTranslation` hook for handling translations.
 *
 * @component
 * @returns {JSX.Element} The rendered header component.
 */
export function GuestHeader() {
  const { t } = useTranslation();
  return (
    <Box className={"flex justify-between items-center px-5 py-3 bg-color-main md:bg-white"}>
      <Link to="/">
        <img className={"md:hidden"} src={logoYellowSmall} alt={t("alts.logoYellow")} />
      </Link>
      <LanguageSwitch />
    </Box>
  );
}
