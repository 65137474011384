import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  auth,
  configurePersistence,
  signInUser,
  signOutUser,
  userStateListener,
} from "../../firebase";
import { User } from "firebase/auth";
import { Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";

/**
 * @typedef {object} AuthContextMapping
 * @property {User|null} user the currently authenticated user
 * @property {function(string,string,boolean,string): Promise<boolean>} login logs the user into the system
 * @property {function(string=):Promise<boolean>} logout logs the user out of the system
 * @property {boolean} noUserExpected no user expect state
 */

/**
 *
 * @type {AuthContextMapping}
 */
const authContextValues = {
  user: auth.currentUser,
  login: (email, password, rememberMeCheckbox, redirectRoute) => {},
  logout: (redirectRoute) => {},
  noUserExpected: false,
};

const AuthContext = createContext(authContextValues);

/**
 * Sets Sentry user out of the Firebase user details
 * @param {User} user
 */
const setSentryUser = (user) => {
  console.debug("Enriching Sentry with user details.");
  Sentry.setUser({
    email: user.email,
    id: user.uid,
    displayName: user.displayName,
  });
};

const clearSentryUser = () => {
  console.debug("Clearing Sentry user.");
  Sentry.setUser(null);
};

const AuthProvider = ({ children }) => {
  const { t, i18n } = useTranslation();

  /**
   * @returns {User | null} "user" A Firebase user object representing the currently authenticated user or 'null' if no user is authenticated.
   */
  const [user, setUser] = useState(auth.currentUser);
  const navigate = useNavigate();
  const [sessionEnded, setSessionEnded] = useState(false);
  const [noUserExpected, setNoUserExpected] = useState(false);
  const [rememberMeCheckbox, setRememberMeCheckbox] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [manuallyLoggedOut, setManuallyLoggedOut] = useState(false);

  /**
   * Logs in a user with the provided email and password.
   *
   * @param {string} email - The user's email address for authentication.
   * @param {string} password - The user's password for authentication.
   * @param {boolean} rememberMeCheckbox - A boolean indicating whether to remember the user's login.
   * @param {string} redirectRoute - A string representing the route or URL to which the application will navigate after a successful login.
   */
  const login = useCallback(
    async (email, password, rememberMeCheckbox, redirectRoute = "/account") => {
      console.debug("Logging in user.");
      await configurePersistence(rememberMeCheckbox);
      try {
        const userCredential = await signInUser(email, password);
        if (userCredential) {
          const user = userCredential.user;
          setSentryUser(user);
          console.info(`User successfully signed in. Redirecting to ${redirectRoute}`);
          setRememberMeCheckbox(rememberMeCheckbox);
          navigate(redirectRoute);
          return true;
        }
        console.error("Failed to sign in the user. User credentials are not available.");
      } catch (error) {
        console.error("Login error:", error);
      }
      return false;
    },
    [navigate],
  );

  /**
   * Logs out the currently authenticated user.
   *
   * @param {string} redirectRoute - A string representing the URL to which the application will navigate after a successful logout.
   */
  const logout = useCallback(
    async (redirectRoute = "/login") => {
      try {
        console.debug("Logging out the user.");
        setUser(null);
        await signOutUser();
        clearSentryUser();
        navigate(redirectRoute);
        setManuallyLoggedOut(true);
        return true;
      } catch (error) {
        console.error("Logout error:", error);
      }
      return false;
    },
    [navigate],
  );

  useEffect(() => {
    console.debug("Registering user state listener.");
    const unsubscribe = userStateListener((updatedUser) => {
      if (updatedUser) {
        console.debug("User state updated.", updatedUser);
        setUser(updatedUser);
        setSessionEnded(false);
        setSentryUser(updatedUser);
      } else {
        setUser(null);
        setSessionEnded(true);
        setManuallyLoggedOut(false);
        console.debug("No user available.");
        clearSentryUser();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [setUser, setSessionEnded]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!user && !manuallyLoggedOut) {
        setNoUserExpected(true);
        enqueueSnackbar(t("alertsMessages.sessionEnded"), {
          variant: "error",
        });
      }
    }, 2500);
    if (!rememberMeCheckbox) {
      setNoUserExpected(true);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [user, rememberMeCheckbox, setNoUserExpected, manuallyLoggedOut, enqueueSnackbar, t]);

  const value = {
    user,
    login,
    logout,
    noUserExpected,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}{" "}
      {sessionEnded && (
        <Snackbar>
          <Alert severity="error">{t("alertsMessages.sessionEnded")}</Alert>
        </Snackbar>
      )}
    </AuthContext.Provider>
  );
};

/**
 *
 * @returns {AuthContextMapping}
 */
const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
