import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { ChangelogItems } from "./ChangelogItems";
import Footer from "../../components/footer/Footer";
import { ChangelogHeader } from "./ChangelogHeader";

function Changelog() {
  return (
    <>
      <ChangelogHeader />
      <Box className={"flex flex-col items-center"}>
        <ChangelogItems />
        <Footer />
      </Box>
    </>
  );
}

export default Changelog;
