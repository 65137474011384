import { Box, useTheme } from "@mui/material";

/**
 * DataGridWrapper component to wrap DataGridTable with specific styles and theme.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components to be wrapped.
 * @returns {JSX.Element} The rendered component.
 */
export const DataGridWrapper = ({ children }) => {
  const theme = useTheme();

  /**
   * Object defining the height of the table for various breakpoints and orientations.
   *
   * @type {Object}
   * @property {string} xl - The height for extra-large screens.
   * @property {string} lg - The height for large screens.
   * @property {string} md - The height for medium screens.
   * @property {string} xs - The height for small screens.
   * @property {Object} [theme.breakpoints.between("sm", "lg")] - The height configurations for small to large screens.
   * @property {string} [theme.breakpoints.between("sm", "lg")]["@media (orientation: portrait)"].height - The height for portrait orientation.
   * @property {string} [theme.breakpoints.between("sm", "lg")]["@media (max-width: 1024px) and (orientation: landscape)"].height - The height for landscape orientation.
   */
  const tableHeight = {
    xl: "86vh",
    lg: "82vh",
    md: "75vh",
    xs: "78dvh",
    [theme.breakpoints.between("sm", "lg")]: {
      "@media (orientation: portrait)": {
        height: "85vh",
      },
      "@media (max-width: 1024px) and (orientation: landscape)": {
        height: "100%",
      },
    },
  };

  return (
    <Box className={"w-full shadow-lg rounded-md "}>
      <Box
        sx={{
          display: "flex",
          height: tableHeight,
          width: "100%",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
