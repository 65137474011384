import React, { useCallback, useState } from "react";
import { Box, IconButton } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NewsPopover from "./NewsPopover";

function NewsMenuButton() {
  const [newsMenuAnchorEl, setNewsMenuAnchorEl] = useState(null);

  const isNewsMenuOpen = Boolean(newsMenuAnchorEl);
  const handleNewsMenuOpen = useCallback((event) => {
    setNewsMenuAnchorEl(event.currentTarget);
  }, []);

  const handleNewsMenuClose = useCallback(() => {
    setNewsMenuAnchorEl(null);
  }, []);

  return (
    <Box>
      <IconButton
        size="medium"
        aria-label="show new notifications"
        onClick={handleNewsMenuOpen}
        aria-controls={isNewsMenuOpen ? "news-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isNewsMenuOpen ? "true" : undefined}
        id="news_button"
        className={"md:mx-4 mx-0"}
      >
        <NotificationsIcon className={"text-black"} />
      </IconButton>
      <NewsPopover
        open={isNewsMenuOpen}
        anchorEl={newsMenuAnchorEl}
        onClose={handleNewsMenuClose}
        onClick={handleNewsMenuClose}
      />
    </Box>
  );
}

export default NewsMenuButton;
