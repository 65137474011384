import React from "react";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

function SideBarListItem({ to, icon, text }) {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <ListItem
      className={"w-full sm:m-0 sm:p-0 flex items-start justify-start"}
      sx={{ padding: "0px" }}
    >
      <Link to={to} className={"text-black no-underline w-full"}>
        <ListItemButton
          className={`p-1 w-full ${isActive ? "bg-amber-50" : "hover:bg-gray-100"}`}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{text}</ListItemText>
        </ListItemButton>
      </Link>
    </ListItem>
  );
}

export default SideBarListItem;
