import { initializeApp } from "firebase/app";
import { fetchAndActivate, getBoolean, getRemoteConfig } from "firebase/remote-config";
import { getAnalytics } from "firebase/analytics";
import {
  indexedDBLocalPersistence,
  confirmPasswordReset,
  getAuth,
  inMemoryPersistence,
  NextOrObserver,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  Unsubscribe,
  User,
  UserCredential,
} from "firebase/auth";
import { getFirebaseConfig } from "./firebase-config";

const app = initializeApp(getFirebaseConfig());
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const remoteConfig = getRemoteConfig(app);
export const fetchAndActivateValue = fetchAndActivate(remoteConfig);
export const getBooleanConfig = (key) => {
  return getBoolean(remoteConfig, key);
};

/**
 * Returns currently signed in user authentication token or null.
 * @returns {Promise<null|string>}
 */
export async function authToken() {
  const currentUser = auth.currentUser;

  if (currentUser) {
    try {
      return await currentUser.getIdToken();
    } catch (error) {
      console.error("Error getting authentication token:", error);
      return null;
    }
  } else {
    console.error("No current user found");
    return null;
  }
}

/**
 * Signs in a user using email and password.
 * @param {string} email
 * @param {string} password
 * @returns {Promise<UserCredential>}
 */
export const signInUser = async (email, password) => {
  if (!email && !password) {
    console.error("Trying to sign in with no email and password.");
    return;
  }
  console.debug("Signing in user using email and password.");
  return await signInWithEmailAndPassword(auth, email, password);
};

/**
 * Adds a callback that's executed whenever the authentication state changes.
 *
 * The callback is NOT triggered when the token expires.
 *
 * @param {NextOrObserver<User>} callback
 * @returns {Unsubscribe}
 */
export const userStateListener = (callback) => {
  return onAuthStateChanged(auth, callback);
};

/**
 * Signs the user out.
 * @returns {Promise<void>}
 */
export const signOutUser = async () => await signOut(auth);

/**
 * Configures persistence based on the given remember me property.
 * @param {boolean} rememberMe
 * @returns {Promise<void>}
 */
export const configurePersistence = async (rememberMe) => {
  if (rememberMe) {
    console.debug("Setting browser local persistence.");
    await setPersistence(auth, indexedDBLocalPersistence);
  } else {
    console.debug("Setting in-memory persistence.");
    await setPersistence(auth, inMemoryPersistence);
  }
};

/**
 * Resets the user's password to a new one.
 *
 * This function is async and returns a Promise. It uses Firebase's `confirmPasswordReset`
 * to reset the password for a user identified by an out-of-band code (`oobCode`).
 *
 * @param {string} oobCode - The out-of-band code provided to the user for password reset.
 * @param {string} password - The new password to set for the user.
 * @returns {Promise<void>} A promise that resolves when the password has been successfully reset.
 *
 */
export const setNewPassword = async (oobCode, password) =>
  await confirmPasswordReset(auth, oobCode, password);
