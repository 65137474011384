import React, { useCallback, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DataGridTable, { withDefaults } from "../dataGrid/DataGridTable";
import { OrderStatus, useOrders } from "../../hooks/useOrders";
import {
  cod,
  codByRecipient,
  customTag,
  deliveryAddress,
  deliveryCity,
  deliveryCost,
  departureAddress,
  departureCity,
  description,
  estimatedCost,
  internationalTrackingNumber,
  orderNumber,
  otherCosts,
  printIcon,
  recipient,
  recipientPhone,
  weight,
} from "../reusableOrdersColumns";
import { bulkPrintStickers, handlePrintOrderSticker } from "../handlePrint";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import { handleConfirmation } from "../handleConfirmation";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { defaultFiveDaysAgo, today } from "../dates";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import showApiErrorNotification from "../showApiErrorNotification";
import { validateOrders } from "../validateOrders";
import { PageTitle } from "../pageTitle/PageTitle";
import { DataGridWrapper } from "../dataGrid/DataGridWrapper";

function NewOrders() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedDates, setSelectedDates] = useState([defaultFiveDaysAgo, today]);

  const {
    orders,
    loading: loadingOrders,
    deleteOrder,
    bulkDeleteOrders,
    archiveOrders,
    fetchOrders,
  } = useOrders(OrderStatus.NEW, {
    startDate: selectedDates[0],
    endDate: selectedDates[1],
  });
  const [selectedOrderTrackingCodes, setSelectedOrderTrackingCodes] = useState([]);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const validatedOrders = useMemo(() => validateOrders(orders, t), [orders, t]);

  const handleDeleteOrder = useCallback(
    async (id) => {
      try {
        const orderDeleted = await deleteOrder(id);
        if (orderDeleted) {
          enqueueSnackbar(t("alertsMessages.orderDeleted"), {
            variant: "success",
          });
          console.info("Order deleted");
        } else {
          enqueueSnackbar(t("alertsMessages.failedOrderDelete"), {
            variant: "error",
          });
          console.error(`Failed to delete the order ${id}`);
        }
      } catch (e) {
        showApiErrorNotification(t("alertsMessages.failedOrderDelete"), e, enqueueSnackbar);
        console.error(`Failed to delete the order: ${id}`, e);
      }
    },
    [deleteOrder, enqueueSnackbar, t],
  );
  const handleDeleteConfirmation = useCallback(
    async (id) => {
      const confirmationDetails = {
        description: t("confirmation.confirmDeleteOrder", { id }),
      };
      await handleConfirmation(confirm, confirmationDetails, () => handleDeleteOrder(id), t);
    },
    [confirm, handleDeleteOrder, t],
  );

  const navigateToEditOrder = useCallback(
    (trackingCode) => {
      navigate(`/orders/${trackingCode}/edit`);
    },
    [navigate],
  );

  const goToArchive = useCallback(() => {
    navigate("/orders/archive");
  }, [navigate]);

  const handlerArchiveOrders = useCallback(
    async (selectedTrackingCodes) => {
      try {
        const archivedOrders = await archiveOrders(selectedTrackingCodes);
        if (archivedOrders) {
          enqueueSnackbar(
            selectedTrackingCodes.length === 1
              ? t("alertsMessages.orderArchived")
              : t("alertsMessages.ordersArchived"),
            {
              variant: "success",
              action: <Button onClick={goToArchive}>{t("alertsMessages.goToArchive")}</Button>,
            },
          );
          fetchOrders({ startDate: selectedDates[0], endDate: selectedDates[1] });
        } else {
          enqueueSnackbar(t("alertsMessages.failedArchiveOrder"), {
            variant: "error",
          });
          console.error(`Error archive orders: ${selectedTrackingCodes}`);
        }
      } catch (error) {
        showApiErrorNotification(t("alertsMessages.failedArchiveOrder"), error, enqueueSnackbar);
        console.error(`Error archive orders: ${selectedTrackingCodes}`, error);
      }
    },
    [archiveOrders, enqueueSnackbar, t, goToArchive, fetchOrders, selectedDates],
  );

  const columns = useMemo(
    () => [
      orderNumber(t),
      printIcon(t, 80, (trackingCode) => handlePrintOrderSticker(trackingCode)),
      recipient(t),
      recipientPhone(t),
      deliveryCity(t),
      deliveryAddress(t),
      customTag(t),
      internationalTrackingNumber(t),
      description(t),
      weight(t),
      estimatedCost(t),
      departureCity(t),
      departureAddress(t),
      deliveryCost(t),
      cod(t),
      codByRecipient(t),
      otherCosts(t),
      {
        field: "paymentByRecipient",
        headerName: `${t("ordersTableHeaders.paymentByRecipient")}`,
        width: 120,
        align: "right",
        headerAlign: "right",
        type: "boolean",
        renderCell: ({ value }) => {
          const isPaymentByRecipient = value;
          return isPaymentByRecipient ? <CheckIcon /> : <RemoveIcon />;
        },
      },
      {
        field: "actions",
        type: "actions",
        width: 40,
        getActions: (params) => {
          const trackingCode = params.id;
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              onClick={() => navigateToEditOrder(trackingCode)}
              label={t("dataGridTable.editIconLabel")}
              className={"no-underline"}
              showInMenu={true}
            />,
            <GridActionsCellItem
              icon={<ArchiveIcon />}
              label={t("dataGridTable.archiveOrder")}
              onClick={() => {
                handlerArchiveOrders([params.id]);
              }}
              showInMenu={true}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label={t("dataGridTable.deleteIconLabel")}
              onClick={() => handleDeleteConfirmation(params.id)}
              showInMenu={true}
            />,
          ];
        },
      },
    ],
    [t, handleDeleteConfirmation, handlerArchiveOrders, navigateToEditOrder],
  );
  const handlerBulkPrintStickersHandler = async () => {
    try {
      await bulkPrintStickers(selectedOrderTrackingCodes);
    } catch (error) {
      showApiErrorNotification(t("alertsMessages.failedPrintStickers"), error, enqueueSnackbar);
      console.error(`Error printing stickers: ${selectedOrderTrackingCodes}`, error);
    }
  };

  const handleBulkDeleteOrders = async (selectedOrderTrackingCodes) => {
    try {
      const bulkDeletion = await bulkDeleteOrders(selectedOrderTrackingCodes);
      if (bulkDeletion) {
        selectedOrderTrackingCodes.length === 1
          ? enqueueSnackbar(t("alertsMessages.orderDeleted"), {
              variant: "success",
            })
          : enqueueSnackbar(t("alertsMessages.bulkOrderDeleted"), {
              variant: "success",
            });
        console.info("Bulk orders deleted successfully", selectedOrderTrackingCodes);
      } else {
        enqueueSnackbar(t("alertsMessages.failedOrderDelete"), {
          variant: "error",
        });
        console.error(`Error deleting orders: ${selectedOrderTrackingCodes}`, bulkDeletion);
      }
    } catch (error) {
      showApiErrorNotification(t("alertsMessages.failedOrderDelete"), error, enqueueSnackbar);
      console.error(`Error deleting orders: ${selectedOrderTrackingCodes}`, error);
    }
  };

  const handleBulkDeleteConfirmation = async (selectedOrderTrackingCodes) => {
    const numberOfOrdersToDelete = selectedOrderTrackingCodes.length;
    const confirmationDetails = {
      description:
        numberOfOrdersToDelete === 1
          ? t("confirmation.confirmBulkDeleteOneOrders", { numberOfOrdersToDelete })
          : t("confirmation.confirmBulkDeleteOrders", { numberOfOrdersToDelete }),
    };
    await handleConfirmation(
      confirm,
      confirmationDetails,
      () => handleBulkDeleteOrders(selectedOrderTrackingCodes),
      t,
    );
  };

  const handleDateChange = useCallback(
    (dates) => {
      setSelectedDates(dates);
    },
    [setSelectedDates],
  );

  return (
    <DataGridWrapper>
      <DataGridTable
        title={<PageTitle title={t("pagesTitles.newOrdersTitle")} />}
        initialState={withDefaults({ pinnedColumns: { right: ["actions"] } })}
        selectedDates={selectedDates}
        onDateChange={handleDateChange}
        showCalendar={true}
        rows={validatedOrders}
        autoHeight={false}
        showPrintIcon={true}
        showDeleteIcon={true}
        showArchiveIcon={true}
        columns={columns}
        rowSelectionModel={selectedOrderTrackingCodes}
        onRowSelectionModelChange={setSelectedOrderTrackingCodes}
        onPrint={handlerBulkPrintStickersHandler}
        onArchive={() => handlerArchiveOrders(selectedOrderTrackingCodes)}
        onDelete={() => handleBulkDeleteConfirmation(selectedOrderTrackingCodes)}
        onRefresh={() => fetchOrders({ startDate: selectedDates[0], endDate: selectedDates[1] })}
        disableIcons={selectedOrderTrackingCodes.length === 0}
        loading={loadingOrders}
        emptyTableText={
          <Typography className={"text-center pt-8"}>
            {t("ordersTableHeaders.noOrders")}
          </Typography>
        }
      />
    </DataGridWrapper>
  );
}

export default NewOrders;
