import React from "react";
import { Divider } from "@mui/material";
import * as Yup from "yup";
import "yup-phone-lite";
import { useTranslation } from "react-i18next";
import { useFormikContext, Field } from "formik";
import { DELIVERY_METHOD, RECIPIENT_TYPE } from "../../constants";
import { PickupOrderInfo, NonEditableOrderDetails } from "./PickupOrderInfo";
import { RecipientInfo } from "./RecipientInfo";
import { DeliveryOrderInfo } from "./DeliveryOrderInfo";
import { infoSectionStyle } from "../tailwindClasses";

const ClientInformation = ({ isUpdating, order }) => {
  const { t } = useTranslation();
  const {
    values: {
      pickupMethod,
      recipientType,
      deliveryMethod,
      pickupRegion,
      pickupCity,
      pickupAddressId,
      recipientPostOffice,
      recipientRegion,
      recipientCity,
      recipientDeliveryAddress,
      recipientGovNumber,
      recipientCompanyName,
      recipientName,
      recipientSurname,
      recipientPhone,
      pickupPostOffice,
      recipientNameSurname,
    },
    errors,
    touched,
    handleChange,
    setFieldValue,
  } = useFormikContext();

  const handlePickupCityChange = () => {
    setFieldValue("pickupPostOffice", "");
  };

  const handleRecipientCityChange = () => {
    setFieldValue("recipientPostOffice", "");
  };

  const handleRegionChange = (cityField, addressField) => {
    setFieldValue(cityField, "");
    setFieldValue(addressField, "");
  };

  return (
    <section className={infoSectionStyle}>
      {isUpdating ? (
        <NonEditableOrderDetails order={order} />
      ) : (
        <PickupOrderInfo
          pickupCity={pickupCity}
          pickupMethod={pickupMethod}
          handleChange={handleChange}
          pickupPostOffice={pickupPostOffice}
          pickupRegion={pickupRegion}
          handleRegionChange={handleRegionChange}
          handlePickupCityChange={handlePickupCityChange}
          pickupAddressId={pickupAddressId}
          touched={touched}
          errors={errors}
        />
      )}
      <Divider orientation={"horizontal"} flexItem className="hidden md:block" />
      <RecipientInfo
        recipientType={recipientType}
        recipientGovNumber={recipientGovNumber}
        recipientCompanyName={recipientCompanyName}
        recipientName={recipientName}
        recipientSurname={recipientSurname}
        recipientPhone={recipientPhone}
        recipientNameSurname={recipientNameSurname}
        isUpdating={isUpdating}
        handleChange={handleChange}
        touched={touched}
        errors={errors}
      />
      <DeliveryOrderInfo
        recipientCity={recipientCity}
        deliveryMethod={deliveryMethod}
        recipientRegion={recipientRegion}
        recipientDeliveryAddress={recipientDeliveryAddress}
        recipientPostOffice={recipientPostOffice}
        handleRegionChange={handleRegionChange}
        handleChange={handleChange}
        handleRecipientCityChange={handleRecipientCityChange}
        touched={touched}
        errors={errors}
      />
    </section>
  );
};

ClientInformation.label = "orderCreation.stepInfoClient";

ClientInformation.validationSchema = (t) =>
  Yup.lazy(() => {
    /**
     * Matches a string with at least two words where words can include
     * letters from any languages as well as `-`, `_` and `'` characters.
     * @type {RegExp}
     */
    const nameSurnameRegexp = /^[\p{L}\-_']+\s+[\p{L}\-_']+/u;

    return Yup.object().shape({
      pickupCity: Yup.string().when("pickupMethod", {
        is: (pickupMethod) => pickupMethod === DELIVERY_METHOD.DEPARTMENT,
        then: (schema) =>
          schema
            .required(t("errorsMessages.actualAddressCityRequire"))
            .max(30, t("errorsMessages.maxCityLength")),
        otherwise: (schema) => schema.notRequired(),
      }),
      pickupPostOffice: Yup.string().when("pickupMethod", {
        is: (pickupMethod) => pickupMethod === DELIVERY_METHOD.DEPARTMENT,
        then: (schema) => schema.required(t("errorsMessages.departmentRequire")),
        otherwise: (schema) => schema.notRequired(),
      }),
      pickupAddress: Yup.string().when("pickupMethod", {
        is: (pickupMethod) => pickupMethod === DELIVERY_METHOD.COURIER,
        then: (schema) =>
          schema
            .required(t("errorsMessages.selectFromMyAddresses"))
            .max(255, "errorsMessages.maxAddressLength"),
        otherwise: (schema) => schema.notRequired(),
      }),
      recipientGovNumber: Yup.string().when("recipientType", {
        is: (recipientType) => recipientType === RECIPIENT_TYPE.COMPANY,
        then: (schema) =>
          schema
            .required(t("errorsMessages.govNumberRequire"))
            .matches(/^\d+$/, t("errorsMessages.companyIDLength"))
            .min(9, t("errorsMessages.minGovNumberLength"))
            .max(11, t("errorsMessages.maxGovNumberLength")),
        otherwise: (schema) => schema.notRequired(),
      }),
      recipientCompanyName: Yup.string().when("recipientType", {
        is: (recipientType) => recipientType === RECIPIENT_TYPE.COMPANY,
        then: (schema) =>
          schema
            .required(t("errorsMessages.companyNameRequire"))
            .max(150, t("errorsMessages.maxCompanyNameLength")),
        otherwise: (schema) => schema.notRequired(),
      }),
      recipientPhone: Yup.string()
        .matches(/^\d{9}$/, t("errorsMessages.onlyDigitsError"))
        .phone("GE", t("errorsMessages.phoneNumberValidationOrder"))
        .required(t("errorsMessages.phoneNumberRequire")),
      recipientNameSurname: Yup.string().when("recipientType", {
        is: (recipientType) => recipientType === RECIPIENT_TYPE.INDIVIDUAL,
        then: (schema) =>
          schema
            .transform((value) => value.trim())
            .required(t("errorsMessages.nameSurnameRequire"))
            .matches(nameSurnameRegexp, t("errorsMessages.nameSurnameMinLength")),
        otherwise: (schema) => schema.notRequired(),
      }),
      recipientCity: Yup.string()
        .required(t("errorsMessages.actualAddressCityRequire"))
        .max(30, t("errorsMessages.maxCityLength")),
      recipientPostOffice: Yup.string().when("deliveryMethod", {
        is: (deliveryMethod) => deliveryMethod === DELIVERY_METHOD.DEPARTMENT,
        then: (schema) => schema.required(t("errorsMessages.departmentRequire")),
        otherwise: (schema) => schema.notRequired(),
      }),
      recipientDeliveryAddress: Yup.string().when("deliveryMethod", {
        is: (deliveryMethod) => deliveryMethod === DELIVERY_METHOD.COURIER,
        then: (schema) =>
          schema
            .required(t("orderCreation.clientPartWriteAddress"))
            .max(255, t("errorsMessages.maxAddressLength")),
        otherwise: (schema) => schema.notRequired(),
      }),
    });
  });

export default ClientInformation;
