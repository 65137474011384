import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { delivoApi } from "../api";
import { City } from "../api/types";
import { useSnackbar } from "notistack";
import showApiErrorNotification from "../components/showApiErrorNotification";

/**
 * Custom hook for fetching and working with city data.
 *
 * This hook provides a way to fetch city information using the delivoApi service,
 * manage loading state, and access localized city names based on the current language setting.
 * It also offers a method to retrieve detailed information about a specific city by its ID.
 *
 * @returns {{cities: City[], getCityById: (function(string): City ), loading: boolean, regionCitiesMap: Map<string, City[]> }}
 * - cities: An array of city objects, each extended with a 'city' property containing the localized city name.
 * - loading: A boolean flag indicating whether the city data is currently being fetched.
 * - getCityById: A function to retrieve a specific city's detailed information by its ID, including the localized name and loading state.  Throws an error if the city is not found.
 * - regionCitiesMap: Map of cities organized by region ID
 */
export function useCities() {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const fetchCities = useCallback(async () => {
    setLoading(true);
    try {
      const fetchedCities = await delivoApi.getCities();
      setCities(fetchedCities);
    } catch (error) {
      console.error("Error fetching cities:", error);
      showApiErrorNotification(t("alertsMessages.failedGetCities"), error, enqueueSnackbar);
    } finally {
      setLoading(false);
    }
  }, [t, enqueueSnackbar]);

  useEffect(() => {
    fetchCities();
  }, [fetchCities]);

  const localizedCities = useMemo(() => {
    return cities.map((city) => ({
      ...city,
      city: city.getName(i18n.language),
    }));
  }, [cities, i18n.language]);

  const localizedCitiesMap = useMemo(() => {
    const result = new Map();
    for (const city of localizedCities) {
      result.set(city.id.toString(), city);
    }
    return result;
  }, [localizedCities]);

  const regionCitiesMap = useMemo(() => {
    const map = new Map();
    for (const city of localizedCities) {
      if (!city.region || !city.region.code) {
        throw new Error(
          `City "${city.name}" with ID ${city.id} is missing a required region attribute.`,
        );
      }
      const regionCode = city.region.code;
      if (!map.has(regionCode)) {
        map.set(regionCode, []);
      }
      map.get(regionCode).push(city);
    }
    return map;
  }, [localizedCities]);

  const getCityById = useCallback(
    (id) => {
      const foundCity = localizedCitiesMap.get(id?.toString());
      if (!foundCity) {
        console.error(`City with ID ${id} not found.`);
      }
      return foundCity;
    },
    [localizedCitiesMap],
  );

  return { cities: localizedCities, loading, getCityById, regionCitiesMap };
}
