import dayjs from "dayjs";

const GE_LOCALE = "ka-GE";
const GE_DATE_FORMAT = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};
const GE_DATE_TIME_FORMAT = {
  ...GE_DATE_FORMAT,
  hour: "2-digit",
  minute: "2-digit",
  hourCycle: "h23",
};

const GE_DATE_TIME_INTL = new Intl.DateTimeFormat(GE_LOCALE, GE_DATE_TIME_FORMAT);
const GE_DATE_INTL = new Intl.DateTimeFormat(GE_LOCALE, GE_DATE_FORMAT);

/**
 * Formats given Date object into a local date-only string.
 * @param {Date} date the date to be formatted.
 * @returns {string} the formatted date string
 */
export const formatGeDate = (date) => GE_DATE_INTL.format(date);

/**
 * Formats given Date object into a local date-time string.
 * @param {Date} dateTime the date-time to be formatted.
 * @returns {string} the formatted date-time string
 */
export const formatGeDateTime = (dateTime) => GE_DATE_TIME_INTL.format(dateTime);

/**
 * Represents the current date with the time set to almost midnight (23:59:59).
 * @returns {dayjs.Dayjs}  - A Day.js object representing today's date at 23:59:59.
 */
export const today = dayjs().hour(23).minute(59).second(59);

/**
 * Represents the date 5 days ago from the current date.
 * @returns {dayjs.Dayjs} The date-time object representing 5 days ago.
 */
export const defaultFiveDaysAgo = today.subtract(5, "days").startOf("day");

/**
 * Represents the format for date display: DD/MM/YYYY.
 * This format represents day/month/year.
 * Used as the default date format in the calendar.
 */
export const format = "DD/MM/YYYY";
