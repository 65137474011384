import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { delivoApi } from "../api";
import showApiErrorNotification from "../components/showApiErrorNotification";
import { localizeNewsItem } from "./localizeNewsItem";
import { NewsItem } from "../api/types";

/**
 * Custom hook for fetching and working with news item data.
 *
 * This hook provides a way to fetch news item information using the delivoApi service,
 * manage loading state, and access localized news item text based on the current language setting.
 *
 * @param {Object} options - Options for fetching news item.
 * @param {string} options.slug - The slug of the news item to fetch.
 * @param {Object} options.initialNews - Initial news item data if available.
 * @returns {{newsItem: {NewsItem}, loading: boolean}} - An object containing the localized news item data and loading state.
 */
export function useNewsItem({ slug, initialNews }) {
  const [newsItem, setNewsItem] = useState(initialNews);
  const [loading, setLoading] = useState(!initialNews);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const fetchNewsItem = useCallback(async () => {
    if (!initialNews) {
      setLoading(true);
      try {
        const data = await delivoApi.getNewsItem({ slug });
        const newsInstance = new NewsItem(data);
        setNewsItem(newsInstance);
      } catch (error) {
        console.error("Error fetching news:", error);
        showApiErrorNotification(t("alertsMessages.failedGetNews"), error, enqueueSnackbar);
      } finally {
        setLoading(false);
      }
    }
  }, [slug, t, initialNews, enqueueSnackbar]);

  useEffect(() => {
    fetchNewsItem();
  }, [fetchNewsItem]);

  const localizedNewsItem = useMemo(() => {
    if (initialNews) {
      return localizeNewsItem(initialNews, i18n.language);
    }
    if (newsItem) {
      return localizeNewsItem(newsItem, i18n.language);
    }
  }, [initialNews, newsItem, i18n.language]);

  return { newsItem: localizedNewsItem, loading };
}
