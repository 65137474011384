/**
 * Opens the provided file content in a new window or downloads one if preview is not available.
 *
 * @param {function(): Promise<Blob>} fileContentFetcher A callback that fetches file content.
 * @param {string} contentType The content type of the downloaded file content
 * (e.g. `application/octet-stream`)
 */
export const downloadFile = async (fileContentFetcher, contentType) => {
  const link = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
  link.target = "_blank";
  link.referrerPolicy = "noreferrer";
  link.rel = "noopener";
  link.style = "display: none";
  const blob = new Blob([await fileContentFetcher()], { type: contentType });
  link.href = URL.createObjectURL(blob);
  document.body.appendChild(link);
  setTimeout(() => link.click(), 0);
  setTimeout(() => {
    URL.revokeObjectURL(link.href);
    link.remove();
  }, 60_000);
};

/**
 * Opens the provided file content in a new window or downloads one if preview is not available.
 *
 * @param {function(): Promise<Blob>} fileContentFetcher A callback that fetches file content.
 * @param {string} contentType The content type of the downloaded file content
 * (e.g. `application/pdf`)
 * @param {string} [windowTarget="_blank"] The window target prop
 * @param {string} [windowFeatures="noopener,noreferrer,popup"] The window features
 */
export const openFileInNewWindow = async (
  fileContentFetcher,
  contentType,
  windowTarget = "_blank",
  windowFeatures = "noopener,noreferrer,popup",
) => {
  const link = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
  link.target = "_blank";
  link.referrerPolicy = "noreferrer";
  link.rel = "noopener";
  link.style = "display: none";
  document.body.appendChild(link);
  link.onclick = async (e) => {
    e.preventDefault();
    const blob = new Blob([await fileContentFetcher()], { type: contentType });
    const fileURL = window.URL.createObjectURL(blob);
    window.open(fileURL, windowTarget, windowFeatures);
    setTimeout(() => {
      window.URL.revokeObjectURL(fileURL);
    }, 60_000);
  };
  setTimeout(() => link.click(), 0);
  setTimeout(() => {
    link.remove();
  }, 2000);
};
