import React from "react";
import { Box } from "@mui/material";
import Footer from "../../footer/Footer";
import { Outlet } from "react-router-dom";
import { GuestHeader } from "./GuestHeader";

function PasswordLayout() {
  return (
    <>
      <Box className="flex-grow">
        <GuestHeader />
        <Box className={"min-h-max flex-grow justify-self-center"}>
          <Outlet />
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default PasswordLayout;
