import React from "react";
import { Box, Chip, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import RadioGroupField from "../radioGroupField/RadioGroupField";
import { useFormikContext } from "formik";
import { PAYMENT_TYPE } from "../constants";
import { toFixedAmount } from "../toFixedAmount";
import { headerSectionStyle, infoSectionStyle } from "./tailwindClasses";

/**
 * PriceItem component displays a title and price in a flexible box layout.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.title - The title of the price item
 * @param {number} props.price - The price value
 * @param {string} [props.boxStyle] - Additional styles for the Box component
 * @returns {JSX.Element} The rendered component
 */
const PriceItem = ({ title, price, boxStyle = "" }) => {
  return (
    <Box
      className={`flex items-start sm:items-center justify-between smMax:py-2  w-full ${boxStyle}`}
    >
      <Typography variant={"subtitle1"} className={"text-gray-500 smMax:font-bold"}>
        {title}:
      </Typography>
      <Typography variant={"subtitle1"} className={"sm:p-2 whitespace-nowrap"}>
        {price}
      </Typography>
    </Box>
  );
};

/**
 * Component for displaying payment information on small screens.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child elements to be rendered within the component.
 * @returns {JSX.Element} The rendered component.
 */
const SmallScreenPaymentByItem = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Box className={"flex xl:hidden justify-between sm:items-center"}>
      <Typography variant={"subtitle1"} className={"text-gray-500 py-2"}>
        {t("orderCreation.paymentBy")}
      </Typography>
      {children}
    </Box>
  );
};

const PricesInfo = () => {
  const {
    values: {
      paymentType,
      insuranceCost,
      codCommissionAmount,
      deliveryCost,
      estimatedCost,
      cod,
      codCommissionByRecipient,
    },
    handleChange,
  } = useFormikContext();

  const { t } = useTranslation();
  const codSum = cod ? estimatedCost : 0;
  const deliveryCostFieldValue = `${toFixedAmount(deliveryCost)} ${t("orderTracking.gel")}`;
  const codFieldValue = `${toFixedAmount(codSum)} ${t("orderTracking.gel")}`;
  const codCommissionFieldValue = `${toFixedAmount(codCommissionAmount)} ${t("orderTracking.gel")}`;
  const insuranceFieldValue = `${toFixedAmount(insuranceCost)} ${t("orderTracking.gel")}`;

  const isCodPaymentTypeRecipient = codCommissionByRecipient === PAYMENT_TYPE.RECIPIENT;
  const isPaymentTypeSender = paymentType === PAYMENT_TYPE.SENDER;
  const calculateSumPaidBySender = () => {
    if (isPaymentTypeSender || !isCodPaymentTypeRecipient) {
      let total = 0;
      if (isPaymentTypeSender) {
        total += deliveryCost + insuranceCost;
      }
      if (!isCodPaymentTypeRecipient) {
        total += codCommissionAmount;
      }
      return toFixedAmount(total);
    }
    return "0.00";
  };

  const codAmountResult = isCodPaymentTypeRecipient ? codCommissionAmount : 0;

  const calculateSumPaidByRecipient = () => {
    if (!isPaymentTypeSender) {
      return toFixedAmount(codSum + codAmountResult + deliveryCost + insuranceCost);
    } else if (isCodPaymentTypeRecipient) {
      return toFixedAmount(codSum + codCommissionAmount);
    }
    return toFixedAmount(codSum);
  };

  const sumPaidBySender = calculateSumPaidBySender();
  const sumPaidByRecipient = calculateSumPaidByRecipient();

  const paymentTypeLabels = [
    { value: PAYMENT_TYPE.SENDER, label: t("orderCreation.paymentSender") },
    { value: PAYMENT_TYPE.RECIPIENT, label: t("orderCreation.paymentRecipient") },
  ];

  const paymentCodCommissionLabels = [
    { value: PAYMENT_TYPE.SENDER, label: t("orderCreation.paymentSender") },
    { value: PAYMENT_TYPE.RECIPIENT, label: t("orderCreation.paymentRecipient") },
  ];

  return (
    <section className={infoSectionStyle}>
      <Typography variant={"h6"} className={headerSectionStyle}>
        {t("orderCreation.stepPayment")}
      </Typography>
      <Divider orientation={"horizontal"} flexItem />
      <Box className={"flex flex-col items-start w-11/12 sm:w-5/6 p-1.5 my-2 mx-2 sm:mx-6"}>
        <PriceItem
          title={t("orderTracking.paidBySender")}
          price={`${sumPaidBySender} ${t("orderTracking.gel")}`}
          boxStyle={"xl:w-1/2 xxl:w-1/3"}
        />
        <PriceItem
          title={t("orderTracking.paidByRecipient")}
          price={`${sumPaidByRecipient} ${t("orderTracking.gel")}`}
          boxStyle={"xl:w-1/2 xxl:w-1/3"}
        />
      </Box>
      <Divider orientation={"horizontal"} className="hidden md:block" flexItem />
      <Box
        className={
          "flex flex-col md:flex-row items-start w-11/12 sm:w-5/6 p-1.5 my-2 mx-2 sm:mx-6"
        }
      >
        <Box className={"flex flex-col justify-between w-full xl:w-1/2 xxl:w-1/3"}>
          <Typography variant={"h6"}>{t("orderCreation.detailsCosts")}</Typography>
          <PriceItem title={t("orderTracking.deliveryCost")} price={deliveryCostFieldValue} />
          <SmallScreenPaymentByItem>
            <RadioGroupField
              name="paymentType"
              value={paymentType}
              onChange={handleChange}
              labels={paymentTypeLabels}
              className={
                "flex flex-col sm:flex-row xl:items-center sm:flex-nowrap w-full sm:py-1"
              }
            />
          </SmallScreenPaymentByItem>
          <PriceItem title={t("ordersTableHeaders.cod")} price={codFieldValue} />
          <SmallScreenPaymentByItem>
            <Chip className={"my-1 md:my-0 py-2"} label={t("ordersTableHeaders.recipient")} />
          </SmallScreenPaymentByItem>
          <PriceItem title={t("orderTracking.codComition")} price={codCommissionFieldValue} />
          <SmallScreenPaymentByItem>
            <RadioGroupField
              name="codCommissionByRecipient"
              value={codCommissionByRecipient}
              onChange={handleChange}
              labels={paymentCodCommissionLabels}
              className={
                "flex flex-col sm:flex-row sm:items-center sm:flex-nowrap w-full sm:py-2"
              }
            />
          </SmallScreenPaymentByItem>
          <PriceItem title={t("orderTracking.insurance")} price={insuranceFieldValue} />
          <SmallScreenPaymentByItem>
            <Chip
              className={"my-1 md:my-0 py-2"}
              label={
                isPaymentTypeSender
                  ? t("ordersTableHeaders.sender")
                  : t("ordersTableHeaders.recipient")
              }
            />
          </SmallScreenPaymentByItem>
        </Box>
        <Box className={"hidden xl:flex flex-col md:ml-12"}>
          <Typography variant={"h6"}>{t("orderCreation.paymentBy")}</Typography>
          <RadioGroupField
            name="paymentType"
            value={paymentType}
            onChange={handleChange}
            labels={paymentTypeLabels}
            className={"flex flex-col md:flex-row md:items-center md:flex-nowrap w-full"}
          />
          <Chip className={"my-1 md:my-0 py-2"} label={t("orderCreation.payRecipient")} />
          <RadioGroupField
            name="codCommissionByRecipient"
            value={codCommissionByRecipient}
            onChange={handleChange}
            labels={paymentCodCommissionLabels}
            className={"flex flex-col md:flex-row md:items-center md:flex-nowrap w-full md:py-2"}
          />
          <Chip
            className={"my-1 md:my-0 py-2"}
            label={
              isPaymentTypeSender ? t("orderCreation.paySender") : t("orderCreation.payRecipient")
            }
          />
        </Box>
      </Box>
    </section>
  );
};

PricesInfo.label = "orderCreation.stepPayment";

PricesInfo.validationSchema = (t) => {};

export default PricesInfo;
