/**
 * Presents information about the user's account.
 * @typedef {Object} AccountInfo account info object
 *
 * @property {string} companyName - The company name.
 * @property {string} legalAddress - The legal address of the company.
 * @property {string} email - The user's email address.
 * @property {string} govNumber - The government number of the company.
 * @property {string} companyPhone - The phone number of the company.
 * @property {string} contactName - The name of the contact person.
 * @property {string} contactPhone - The phone number of the contact person.
 * @property {string} contactEmail - The email address of the contact person.
 * @property {string} publicName - The public name of the company.
 * @property {string} publicPhone - The public phone number of the company.
 * @property {string} publicAddress - The public address of the company.
 * @property {boolean} usePublicProfile - The flag to use public fields of the organization.
 */

/**
 * Type definition for the state and context of account information.
 * @typedef {Object} AccountState
 * @property {AccountInfo} accountInfo - The account information.
 * @property {boolean} loading - Indicates if the account information is currently loading.
 */

/**
 * The initial state of the account provider.
 * 
 * @type {AccountState}
 */
export const initialAccountValues = {
  accountInfo: {
    companyName: "",
    legalAddress: "",
    email: "",
    govNumber: "",
    companyPhone: "",
    contactName: "",
    contactPhone: "",
    contactEmail: "",
    publicPhone: "",
    publicName: "",
    publicAddress: "",
    usePublicProfile: false,
  },
  loading: true,
};
