import { createContext, useContext } from "react";
import { useAccountInfo } from "../../hooks/useAccountInfo";
import { initialAccountValues } from "./initialAccountValues";

const AccountContext = createContext(initialAccountValues);

export const AccountProvider = ({ children }) => {
  const accountValues = useAccountInfo();

  return <AccountContext.Provider value={accountValues}>{children}</AccountContext.Provider>;
};

/**
 * Custom hook to consume account information context.
 * @returns {AccountState} The current account information and loading state from context.
 * @throws Will throw an error if used outside the AccountProvider component.
 */
export const useAccountContext = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error("useAccountContext must be used within an AccountProvider");
  }
  return context;
};
