import React from "react";
import { FormControlLabel, Checkbox, Link, Box } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

export function TermsCheckboxes({ values, handleChange, errors, touched }) {
  const { t } = useTranslation();
  const delivoTermsLink = "https://delivo.ge/momsakhurebis-pirobebi/";

  return (
    <Box className={"flex flex-col"}>
      <Box className="smMax:mb-3">
        <FormControlLabel
          control={
            <Checkbox checked={values.tos_accepted} onChange={handleChange} name="tos_accepted" />
          }
          label={
            <span className="text-xs">
              <Trans
                i18nKey="registrationForm.acceptTOS"
                components={[
                  <Link href={delivoTermsLink} target="_blank">
                    {t("registrationForm.link")}
                  </Link>,
                ]}
              />
            </span>
          }
        />
        {errors.tos_accepted && touched.tos_accepted && (
          <div className="text-red-500 text-xs">{errors.tos_accepted}</div>
        )}
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={values.pd_processing_accepted}
            onChange={handleChange}
            name="pd_processing_accepted"
          />
        }
        label={
          <span className="text-xs">
            <Trans
              i18nKey="registrationForm.acceptPD"
              components={[
                <Link href={delivoTermsLink} target="_blank">
                  {t("registrationForm.link")}
                </Link>,
              ]}
            />
          </span>
        }
      />
      {errors.pd_processing_accepted && touched.pd_processing_accepted && (
        <div className="text-red-500 text-xs">{errors.pd_processing_accepted}</div>
      )}
    </Box>
  );
}
