import { formatGeDateTime } from "./dates";

export const dateTimeColumnDef = {
  type: "dateTime",
  valueGetter: (value) => value && new Date(value),
  valueFormatter: (value) => value && formatGeDateTime(value),
};
/**
 * Column definition for displaying date values with time information.
 *
 * The column type is "date", but it uses a DateTime formatter to include both date and time information for a more detailed view in the table.
 */
export const dateColumnDef = {
  type: "date",
  valueGetter: (value) => value && new Date(value),
  valueFormatter: (value) => {
    return value && formatGeDateTime(value);
  },
};
