import { useEffect, useMemo, useState } from "react";
import { delivoApi } from "../api";
import { initialAccountValues } from "../providers/AccountProvider/initialAccountValues";

/**
 * Custom hook to fetch and manage account information.
 * @returns {AccountState} The current state of the account information, including the loading status.
 */

export function useAccountInfo() {
  const [accountInfo, setAccountInfo] = useState(initialAccountValues.accountInfo);
  const [loading, setLoading] = useState(initialAccountValues.loading);

  const fetchAccountInfo = useMemo(
    () => async () => {
      try {
        const accountData = await delivoApi.getMyAccount();

        const newPreparedData = {
          companyName: accountData.organization.name,
          legalAddress: accountData.organization.legalAddress,
          email: accountData.user.email,
          govNumber: accountData.organization.govNumber,
          companyPhone: accountData.organization.phone,
          contactName: accountData.organization.contactName,
          contactPhone: accountData.organization.contactPhone,
          contactEmail: accountData.organization.email,
          publicName: accountData.organization.publicName,
          publicPhone: accountData.organization.publicPhone,
          publicAddress: accountData.organization.publicAddress,
          usePublicProfile: accountData.organization.usePublicProfile,
        };
        setLoading(false);
        setAccountInfo(newPreparedData);
      } catch (error) {
        console.error("Error fetching account info", error);
      }
    },
    [],
  );

  useEffect(() => {
    fetchAccountInfo();
  }, [fetchAccountInfo]);

  return { accountInfo, loading };
}
