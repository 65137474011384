import { useField, useFormikContext } from "formik";
import { useLocationsContext } from "../../providers/LocationsProvider/LocationsProvider";
import React, { useCallback, useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { leftFieldStyle } from "../orderCreationWizard/tailwindClasses";

/**
 * `RegionSelectField` renders an autocomplete field for selecting a region.
 *
 * @param {Object} props The component props.
 * @param {string} props.fieldName The name attribute for the select field, corresponding to the property in Formik's `values`.
 * @param {string} [props.className="w-1/3 mr-11"] The CSS class for styling the select field.
 * @param {Function} [props.onCustomChange] An optional callback function that is called when a new region is selected..
 *
 * @returns {React.Component} The `RegionSelectField` component.
 */
export const RegionSelectField = ({ fieldName, className = leftFieldStyle, onCustomChange }) => {
  const { t } = useTranslation();
  const { regions, getRegionByCode } = useLocationsContext();
  const { setFieldValue, touched, errors } = useFormikContext();
  const [field] = useField(fieldName);

  const initialValue = useMemo(
    () => (field.value ? getRegionByCode(field.value) : null),
    [field.value, getRegionByCode],
  );

  const handleChange = useCallback(
    (event, newValue) => {
      setFieldValue(fieldName, newValue ? newValue.code : "");
      if (onCustomChange) {
        onCustomChange(newValue);
      }
    },
    [setFieldValue, fieldName, onCustomChange],
  );

  return (
    <Autocomplete
      value={initialValue}
      onChange={handleChange}
      options={regions}
      getOptionLabel={(option) => option.region || ""}
      className={className}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("orderCreation.clientPartSelectRegion")}
          variant="standard"
          error={touched[fieldName] && Boolean(errors[fieldName])}
          helperText={touched[fieldName] && errors[fieldName]}
        />
      )}
    />
  );
};
