const config = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG_OBJECT);

export function getFirebaseConfig() {
  if (!config || !config.apiKey) {
    throw new Error(
      "No Firebase configuration object provided." +
        "\n" +
        "Add your web app's configuration object to firebase-config.ts",
    );
  } else {
    return config;
  }
}
