import React from "react";
import { Box, Typography } from "@mui/material";
import uploadSuccess from "../../images/uploadSuccess.svg";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import ButtonComponent from "../buttonComponent/ButtonComponent";

function ImportSuccessful() {
  const { t } = useTranslation();
  const location = useLocation();
  const { totalSuccessfullySubmittedOrders } = location.state || {};

  return (
    <Box
      className={
        "flex flex-col items-center justify-center mb-5 pb-5 shadow-md rounded-md w-full"
      }
    >
      <Box className={"m-5"}>
        <Typography className={"my-2 text-justify"} variant={"h5"}>
          {t("uploadFilePage.uploadSuccess", { totalSuccessfullySubmittedOrders })}
        </Typography>
      </Box>
      <Box className={"m-5"}>
        <img src={uploadSuccess} alt="upload success" />
      </Box>
      <Link to={"/orders/new"} className={"m-1.5"}>
        <ButtonComponent text={t("uploadFilePage.btnViewOrders")} className={"p-3"} />
      </Link>
    </Box>
  );
}

export default ImportSuccessful;
