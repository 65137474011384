import { useFAQ } from "../../hooks/useFAQ";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Pagination,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import LoadingBackdrop from "../loadingBackdrop/LoadingBackdrop";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../pageTitle/PageTitle";

/**
 * Styles for the Pagination component using the sx prop.
 * This object contains styles to ensure the pagination component is responsive and properly displayed across different screen sizes.
 *
 * The styles ensure the pagination is centered and maintains a single row layout on mobile devices.
 */
const sxPaginationStyles = {
  width: "100%",
  mt: 4,
  ".MuiPagination-root": {
    display: "flex",
    justifyContent: "center",
  },
  "@media (max-width: 600px)": {
    ".MuiPagination-ul": {
      flexWrap: "nowrap",
      overflowX: "auto",
      justifyContent: "center",
    },
  },
};

const MemoizedCard = React.memo(({ faqItem, page }) => {
  const { t } = useTranslation();

  return (
    <Card className="mb-1 shadow-md p-1 col-span-1">
      <Link
        to={`/faq/${faqItem.slug}`}
        state={{ detail: faqItem, page: page }}
        className="text-black no-underline"
      >
        <CardContent>
          <Typography className="font-bold mb-3">{faqItem.question}</Typography>
          <Typography>{faqItem.previewMessage}</Typography>
        </CardContent>
        <CardActions>
          <Typography className={"text-color-main lowercase mx-2"}>{t("more")}</Typography>
        </CardActions>
      </Link>
    </Card>
  );
});

const FAQItems = ({ faq, page }) => {
  return (
    <>
      {faq.map((faqItem) => (
        <MemoizedCard faqItem={faqItem} key={faqItem.slug} page={page} />
      ))}
    </>
  );
};

const FAQList = () => {
  const { t } = useTranslation();
  const { faq, loading, page, totalPages, setPage } = useFAQ();

  const handleChange = useCallback(
    (event, value) => {
      setPage(value);
    },
    [setPage],
  );

  return (
    <Box className={"shadow-lg rounded-md"}>
      <PageTitle title={t("pagesTitles.faq")} />
      <LoadingBackdrop isVisible={loading} />
      <Box className="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
        <FAQItems faq={faq} page={page} />
      </Box>
      <Box className="flex justify-center smMax:px-0 p-4 xxl:px-2" sx={sxPaginationStyles}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChange}
          color="primary"
          siblingCount={1}
          boundaryCount={1}
          sx={{
            ".MuiPagination-ul": {
              justifyContent: "center",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default FAQList;
