import { useCallback, useEffect, useState } from "react";
import { registersApi } from "../api";
import { toGetAvailableOrdersQuery } from "../components/registers/converters";
import { useLocationsContext } from "../providers/LocationsProvider/LocationsProvider";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { internationalTrackingNumber } from "../components/reusableOrdersColumns";

/**
 * @typedef {object} RegistryOrderMapping Order object mapping
 * @property {string} id - The id of the order.
 * @property {string} index - The index of the order in object.
 * @property {string} deliveryCity - The delivery city of the recipient.
 * @property {string} deliveryAddress - The delivery address of the recipient.
 * @property {string} trackingCode -The tracking number of the order.
 * @property {string} recipientName - The name of the recipient.
 * @property {string} customTag -The custom tag of the order.
 * @property {string} deliveryCost -The delivery cost of the order.
 * @property {string} estimatedCost -The cod of the order.
 * @property {string} weight -The weight of the order.
 * @property {string} internationalTrackingNumber - The international tracking number.
 */

export class RegistryOrder {
  /**
   * Creates a new Registry order from the given mapping
   * @param {RegistryOrderMapping} mapping
   * @param {string} mapping.id - The id of the order.
   * @param {string} mapping.index - The index of the order.
   * @param {string} mapping.deliveryCity - The delivery city of the recipient.
   * @param {string} mapping.deliveryAddress - The delivery address of the recipient.
   * @param {string} mapping.trackingCode -The tracking number of the order.
   * @param {string} mapping.recipientName - The name of the recipient.
   * @param {string} mapping.customTag -The custom tag of the order.
   * @param {string} mapping.deliveryCost -The delivery cost of the order.
   * @param {string} mapping.estimatedCost -The cod of the order.
   * @param {string} mapping.weight -The weight of the order.
   * @param {string} mapping.internationalTrackingNumber - The international tracking number.
   */
  constructor({
    index,
    id,
    deliveryCity,
    weight,
    deliveryCost,
    estimatedCost,
    deliveryAddress,
    trackingCode,
    recipientName,
    customTag,
    internationalTrackingNumber,
  }) {
    this.index = index;
    this.id = id;
    this.deliveryCity = deliveryCity;
    this.deliveryAddress = deliveryAddress;
    this.trackingCode = trackingCode;
    this.recipientName = recipientName;
    this.customTag = customTag;
    this.deliveryCost = deliveryCost;
    this.estimatedCost = estimatedCost;
    this.weight = weight;
    this.internationalTrackingNumber = internationalTrackingNumber;
  }
}

export class Register {
  /**
   * Creates a Register instance
   *
   * @param {object} register - the register data
   * @param {RegistryOrder[]} register.ordersDetails - the register data
   * @param {string} register.registryTrackingNumber -The tracking number of the register.
   */
  constructor({
    ordersDetails,
    registryTrackingNumber,
    registryStatus,
    registrySerialNumber,
    registryPickupCityId,
    registryPickupCity,
    registryPickupPostOffice,
    registryPickupPostOfficeId,
    registryPickupPostOfficeAddress,
    registryPickupAddress,
  }) {
    this.ordersDetails = ordersDetails;
    this.registryTrackingNumber = registryTrackingNumber;
    this.registryStatus = registryStatus;
    this.registrySerialNumber = registrySerialNumber;
    this.registryPickupCity = registryPickupCity;
    this.registryPickupCityId = registryPickupCityId;
    this.registryPickupAddress = registryPickupAddress;
    this.registryPickupPostOffice = registryPickupPostOffice;
    this.registryPickupPostOfficeId = registryPickupPostOfficeId;
    this.registryPickupPostOfficeAddress = registryPickupPostOfficeAddress;
  }
}

/**
 *
 * @returns {{toRegistryOrder: (function(OrderListItem): RegistryOrder)}}
 */
export function useToRegistryOrder() {
  const { t } = useTranslation();
  const { getCityById } = useLocationsContext();
  /**
   * Converts given order list item to a registry order.
   * @param {OrderListItem} order
   * @returns {RegistryOrder}
   */
  const toRegistryOrder = useCallback(
    (order, index) => {
      const city = getCityById(order.destinationCityId);
      if (!city) {
        throw new Error(t("alertsMessages.cityNotFound", { cityId: order.destinationCityId }));
      }
      return new RegistryOrder({
        index,
        id: order.trackingCode,
        trackingCode: order.trackingCode,
        deliveryCity: city.city,
        deliveryAddress: order.recipient.address || order.recipient.post_office.name,
        recipientName:
          order.recipient.alias === "" ? order.recipient.name : order.recipient.alias,
        customTag: order.customTag,
        deliveryCost: order.deliveryCost,
        estimatedCost: order.cod ? order.estimatedCost : "0.00",
        weight: order.weight,
        internationalTrackingNumber: order.internationalTrackingNumber,
      });
    },
    [t, getCityById],
  );
  return { toRegistryOrder };
}

/**
 *
 * @returns {{getAvailableOrders: ((function({pickupCity: string, pickupAddress: string, pickupPostOffice: string}): Promise<RegistryOrder[]>)|*)}}
 */
export function useGetAvailableOrders() {
  const { toRegistryOrder } = useToRegistryOrder();
  const getAvailableOrders = useCallback(
    async ({ pickupCity, pickupAddress, pickupPostOffice }) => {
      const getAvailableOrdersQueryParams = toGetAvailableOrdersQuery({
        pickupCity,
        pickupAddress,
        pickupPostOffice,
      });

      const fetchedAvailableOrders = await registersApi.getAvailableOrders(
        getAvailableOrdersQueryParams,
      );

      return fetchedAvailableOrders.map(toRegistryOrder);
    },
    [toRegistryOrder],
  );

  return { getAvailableOrders };
}

/**
 *
 * @param {string | number} registerId the ID of the register (it's not the serial number)
 * @returns {{readyForDeliveryRegister: ((function((REGISTER_STATUS_FROM_API.READY_FOR_PICKUP|REGISTER_STATUS_FROM_API.READY_FOR_SHIPMENT)): Promise<void>)|*), updateRegister: ((function(CreateOrUpdateRegistryRequest): Promise<Register>)|*), loading: boolean, register: {}}}
 */
export function useRegister(registerId) {
  const [register, setRegister] = useState({});
  const [loading, setLoading] = useState(true);
  const { getCityById } = useLocationsContext();
  const { toRegistryOrder } = useToRegistryOrder();
  const { t } = useTranslation();

  const convertRegisterDetails = useCallback(
    (registerDetails) => {
      const registryOrders = registerDetails.ordersDetails;
      const ordersDetails = registryOrders.map(toRegistryOrder);
      const registryPickupCityId = getCityById(registerDetails.departureCityId);
      if (!registryPickupCityId) {
        throw new Error(
          t("alertsMessages.cityNotFoundRegister", {
            registerId: registerId,
            cityId: registerDetails.departureCityId,
          }),
        );
      }
      const registryTrackingNumber = registerDetails.trackingCode;
      const registryStatus = registerDetails.status;
      const registrySerialNumber = registerDetails.serialNumber;
      const registryPickupCity = registryPickupCityId.city;
      const registryPickupPostOffice = registerDetails.postOffice.name;
      const registryPickupPostOfficeId = registerDetails.postOffice.guid;
      const registryPickupPostOfficeAddress = registerDetails.postOffice.address;
      const registryPickupAddress = registerDetails.departureAddress;
      return new Register({
        ordersDetails,
        registryTrackingNumber,
        registrySerialNumber,
        registryPickupCityId,
        registryPickupCity,
        registryPickupAddress,
        registryPickupPostOffice,
        registryPickupPostOfficeId,
        registryPickupPostOfficeAddress,
        registryStatus,
      });
    },
    [t, registerId, getCityById, toRegistryOrder],
  );

  const fetchRegister = useCallback(async () => {
    setLoading(true);
    try {
      const fetchedRegisterData = await registersApi.getRegister(registerId);
      const result = convertRegisterDetails(fetchedRegisterData);
      setRegister(result);
      return result;
    } catch (error) {
      console.error("Error fetching register", error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, [registerId, convertRegisterDetails]);

  useEffect(() => {
    fetchRegister();
  }, [fetchRegister]);

  const readyForDeliveryRegister = useCallback(
    /**
     *
     * @param {REGISTER_STATUS_FROM_API.READY_FOR_PICKUP | REGISTER_STATUS_FROM_API.READY_FOR_SHIPMENT} status the status of register
     * @returns {Promise<void>}
     */
    async (status) => {
      setLoading(true);
      try {
        const updatedRegister = await registersApi.readyForDeliveryRegister(registerId, {
          status,
        });
        setRegister((prevRegister) => ({
          ...prevRegister,
          registryStatus: updatedRegister?.status,
        }));
      } catch (error) {
        console.error("Failed to update register status", error);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [registerId],
  );

  const updateRegister = useCallback(
    /**
     * @param {CreateOrUpdateRegistryRequest} register the register to be updated
     * @returns {Promise<Register>}
     */
    async (register) => {
      setLoading(true);
      try {
        const updatedRegister = await registersApi.updateRegister(registerId, register);
        const result = convertRegisterDetails(updatedRegister);
        setRegister(result);
        return result;
      } catch (error) {
        console.error("Failed to update register", error);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [registerId, convertRegisterDetails],
  );

  return { register, loading, readyForDeliveryRegister, updateRegister, fetchRegister };
}
