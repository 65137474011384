import React, { useCallback } from "react";
import { Box, Typography } from "@mui/material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../providers/AuthProvider/AuthProvider";
import ButtonComponent from "../buttonComponent/ButtonComponent";
import { PageTitle } from "../pageTitle/PageTitle";

function HeaderWithLogOutButton() {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const handleLogout = useCallback(async () => {
    await logout();
  }, [logout]);
  return (
    <Box className={"flex justify-between items-center"}>
      <PageTitle title={t("accountPage.title")} />
      <ButtonComponent
        className="p-3 m-4 smMax:px-6 smMax:m-2"
        text={t("logoutButton")}
        startIcon={<LogoutOutlinedIcon className="hidden sm:inline-block" />}
        onClick={handleLogout}
      />
    </Box>
  );
}

export default HeaderWithLogOutButton;
