import { Typography } from "@mui/material";
import React from "react";

/**
 * PageTitle component.
 *
 * Displays a title with specific styling.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.title - The title text to display.
 *
 * @returns {JSX.Element} The PageTitle component.
 */
export const PageTitle = ({ title }) => {
  return (
    <Typography variant={"h4"} className={"my-2 mx-5 md:m-5"}>
      {title}
    </Typography>
  );
};
