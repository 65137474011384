import { Box, Button, TextField, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../buttonComponent/ButtonComponent";
import { ordersApi } from "../../api/DelivoOrdersApi";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import excelLogo from "../../images/excelLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useSnackbar } from "notistack";
import { downloadFile } from "../handleDownload";
import uploadImage from "../../images/uploadImage.svg";
import LoadingBackdrop from "../loadingBackdrop/LoadingBackdrop";
import { ERROR_TYPES } from "../constants";
import { TooltipWrapper } from "../tooltipWrapper/TooltipWrapper";

function OrdersImport() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const initialValues = { selectedFile: null };

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      selectedFile: Yup.mixed()
        .required(t("errorsMessages.selectFile"))
        .test("fileType", t("errorsMessages.invalidFileFormat"), (value) => {
          if (!value) return true;
          return ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(
            value.type,
          );
        }),
    });
  }, [t]);

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("file", values.selectedFile);
    try {
      const bulkImportResponse = await ordersApi.uploadOrderExcel(formData);
      console.info("Excel file uploaded successfully.", bulkImportResponse);
      navigate("/orders/import/success", {
        state: {
          totalSuccessfullySubmittedOrders:
            bulkImportResponse.successfully_submitted_orders.length,
        },
      });
      enqueueSnackbar(t("alertsMessages.ordersImportedSuccess"), {
        variant: "success",
      });
      resetForm();
    } catch (error) {
      console.error("Failed to upload excel file.", error);
      const { response } = error;
      const { data } = response;

      if (response.status === 500) {
        enqueueSnackbar(t("alertsMessages.smthGoesWrong"), {
          variant: "error",
        });
      } else if (data.status === ERROR_TYPES.FAILED_ON_PARSING_OR_VALIDATION) {
        enqueueSnackbar(t("alertsMessages.failedOrdersImported"), {
          variant: "error",
        });
        const errorData = data;
        navigate("/orders/import/errors", { state: { errorData } });
      } else if (data.errors[0].code === ERROR_TYPES.SENDER_DATA_MISSMATCH) {
        enqueueSnackbar(t("alertsMessages.clientDoesNotMatch"), {
          variant: "error",
        });
      } else if (data.errors[0].code === ERROR_TYPES.TOO_MUCH_ORDERS_IN_FILE) {
        enqueueSnackbar(t("alertsMessages.tooManyOrders"), {
          variant: "error",
        });
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, isSubmitting }) => (
        <Form
          className={"w-full shadow-md rounded-md mb-5 flex flex-col items-center justify-center"}
        >
          <LoadingBackdrop isVisible={isSubmitting} />
          <Box className={"flex flex-col items-center justify-center m-5"}>
            <Box className={"m-5"}>
              <img src={uploadImage} alt="upload excel file" />
            </Box>
            <Box className={"m-5 w-1/2"}>
              <Typography className={"text-justify"}>
                {t("uploadFilePage.uploadDescription")}
              </Typography>
            </Box>
            <Box className={"my-2 w-1/2"}>
              <Link
                className={"text-black flex items-center"}
                onClick={async () => {
                  await downloadFile(
                    ordersApi.downloadOrderExcelTemplate,
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  );
                }}
                to={""}
              >
                <FileDownloadOutlinedIcon className={"mr-2"} />
                {t("uploadFilePage.downloadText")}
              </Link>
            </Box>
            <Box className={"m-5 w-1/2"}>
              <Field
                as={TextField}
                name="selectedFile"
                variant="standard"
                className={"w-3/4 mb-3"}
                value={values.selectedFile ? values.selectedFile.name : ""}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                  inputProps: {
                    style: { textAlign: "left" },
                  },
                }}
              />
              <Box className={"flex justify-center"}>
                <Button
                  variant="contained"
                  component="label"
                  className={"p-4 my-2 mr-4 text-black"}
                  disabled={isSubmitting}
                >
                  <img alt={"excel"} src={excelLogo} className={"mx-2"} />
                  {t("uploadFilePage.uploadBtn")}
                  <input
                    name="selectedFile"
                    type="file"
                    hidden
                    onChange={(event) => {
                      setFieldValue("selectedFile", event.currentTarget.files[0]);
                    }}
                    accept=".xlsx"
                  />
                </Button>
                <TooltipWrapper title={t("uploadFilePage.uploadTooltip")}>
                  <span>
                    <ButtonComponent
                      className={"text-black p-4 m-2"}
                      text={t("uploadFilePage.uploadBtnSubmit")}
                      disabled={!values.selectedFile || isSubmitting}
                      type="submit"
                    />
                  </span>
                </TooltipWrapper>
              </Box>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

export default OrdersImport;
