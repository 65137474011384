import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SnackbarContent } from "notistack";
import { SnackbarCloseButton } from "./SnackbarCloseButton";

const ErrorDetailsSnack = ({ customText, errorMessage }) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <SnackbarContent className={"flex flex-col"}>
      <Box className={"flex justify-between w-full"}>
        <Box className={"flex flex-wrap  items-center"}>
          <Typography className={"pr-3"}>{customText}</Typography>
          <Button onClick={handleToggle} sx={{ paddingLeft: "0" }}>
            {expanded ? t("alertsMessages.hideDetails") : t("alertsMessages.showDetails")}
          </Button>
        </Box>
        <Box className="flex items-start">
          <SnackbarCloseButton />
        </Box>
      </Box>
      {expanded && <Box>{errorMessage}</Box>}
    </SnackbarContent>
  );
};

export default ErrorDetailsSnack;
