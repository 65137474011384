import {
  cod,
  deliveryAddress,
  deliveryCity,
  deliveryCost,
  internationalTrackingNumber,
  orderNumber,
  recipient,
  weight,
} from "../reusableOrdersColumns";
import RemoveIcon from "@mui/icons-material/Remove";
import React from "react";

export const registerCreationColumns = (t, width) => [
  orderNumber(t, (width = 140)),
  deliveryCity(t, (width = 150)),
  deliveryAddress(t, (width = 250)),
  recipient(t, (width = 150)),
  deliveryCost(t),
  cod(t),
  weight(t),
  internationalTrackingNumber(t),
];

export const registerDetailsColumns = (t, width) => [
  {
    field: "sequenceNumber",
    headerName: `№`,
    width: 50,
    align: "right",
    headerAlign: "right",
    type: "number",
    renderCell: ({ row }) => {
      return <p className={"mt-0.5"}>{row.index + 1}</p>;
    },
  },
  ...registerCreationColumns(t, width),
];
