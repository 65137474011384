/**
 * Retrieves the localized text based on the specified language.
 *
 * @param {string} textEn The text in English.
 * @param {string} textKa The text in Georgian.
 * @param {string} lang The language code (default is English).
 * @returns {string} The localized text.
 */
export function getLocalizedText(textEn, textKa, lang = "en") {
  return lang.toLowerCase() === "ka" ? textKa : textEn;
}
