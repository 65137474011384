/**
 * @module tailwindClasses
 * This module contains Tailwind CSS class names used throughout the createOrder components.
 */

/**
 * Info section styles.
 * @type {string}
 */
export const infoSectionStyle = "w-full flex flex-col md:my-5";
export const headerSectionStyle = "px-1.5 my-1 md:my-3 mx-6 font-bold lg:font-normal";

/**
 * Box styles.
 * @type {string}
 */
export const boxWrapper = "flex items-center justify-start w-5/6 p-1.5 mb-5 mx-6";
export const displayAdaptiveBoxStyles = "flex-col md:flex-row";
export const adaptiveBoxWrapper = `${boxWrapper} ${displayAdaptiveBoxStyles}`;
export const boxClassName = "flex flex-col md:flex-row items-center justify-between w-full";

/**
 * Field styles.
 * @type {string}
 */
export const fieldBaseStyle = "w-full md:w-1/2 xl:w-1/3 mb-2 md:mb-0";
export const leftFieldStyle = `${fieldBaseStyle} md:mr-11`;
export const rightFieldStyle = `${fieldBaseStyle} md:mr-10`;

export const radioGroupBtnCustomStyle =
  "flex flex-col justify-start xl:flex-row xl:items-center p-1.5 mx-6";

/**
 * Get content block class based on display type.
 * @param {boolean} isDisplayedType - Determines if the block should be displayed.
 * @returns {string} The combined class name.
 */
export const getContentBlockClass = (isDisplayedType) => {
  const baseClass = "content-block";
  const displayClass = isDisplayedType ? "flex" : "hidden";
  return `${baseClass} ${displayClass}`;
};
