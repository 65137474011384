import { useMemo } from "react";
import { useMediaQuery, useTheme } from "@mui/material";

/**
 * Custom hook to determine the number of items per API call based on screen size.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Object} params.items - Mapping of items per screen size.
 * @param {number} items.sm - Number of items for small screens (599px and less).
 * @param {number} items.lg - Number of items for large screens (600px - 1535px).
 * @param {number} items.xl - Number of items for extra-large screens (1536px - 2559px).
 * @param {number} items.xxl - Number of items for extra-extra-large screens (2560px and bigger).
 * @param {number} items.default - Default number of items.
 * @returns {number} - Number of items per API call based on current screen size.
 */
export function useItemsPerScreenSize({ items }) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isLg = useMediaQuery(theme.breakpoints.between("sm", "xl"));
  const isXl = useMediaQuery(theme.breakpoints.between("xl", "xxxl"));
  const isXXl = useMediaQuery(theme.breakpoints.up("xxxl"));

  const itemsPerAPICall = useMemo(() => {
    if (isXXl) return items.xxl || 10;
    if (isXl) return items.xl || 6;
    if (isLg) return items.lg || 4;
    if (isSm) return items.sm || 3;
    return items.default || 10;
  }, [isSm, isLg, isXl, isXXl, items]);

  return itemsPerAPICall;
}
