import React from "react";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useNewsList } from "../../hooks/useNewsList";
import NewsItemPreview from "./NewsItemPreview";
import { formatGeDateTime } from "../dates";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LoadingBackdrop from "../loadingBackdrop/LoadingBackdrop";

/**
 * Generates a list of news items as menu items for the NewsPopover component.
 * Each news item is represented as a MenuItem component with a link to the corresponding news page.
 * Only a limited number of news items are displayed based on the specified limit.
 *
 * @param {Array} newsList - An array of news items with a limited length.
 * @param {function} onClose - The function to call when a menu item is clicked and the menu is closed.
 */
const NewsMenuItems = (newsList, onClose, page) => {
  return newsList.map((newsItem) => (
    <MenuItem
      key={newsItem.slug}
      component={Link}
      to={`/news/${newsItem.slug}`}
      state={{ detail: newsItem, page }}
      onClick={onClose}
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
        }}
      >
        <NewsItemPreview
          titleStyle={{ fontSize: "15px", fontWeight: 700 }}
          textStyle={{
            fontSize: "14px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          dateStyle={{ fontSize: "10px", color: "gray" }}
          slug={newsItem.slug}
          title={newsItem.title}
          publishingTime={formatGeDateTime(newsItem.publishingTime)}
          previewMessage={newsItem.previewMessage}
        />
      </Box>
      <ChevronRightIcon />
    </MenuItem>
  ));
};

function NewsPopover({ open, anchorEl, onClose }) {
  const { t } = useTranslation();
  const { news, loading, page } = useNewsList();

  return (
    <Menu
      id="news_menu"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": "news_button",
      }}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      sx={{ minWidth: { md: "350px", xs: "85%" }, maxWidth: "35%" }}
    >
      <Box sx={{ marginLeft: "1rem" }}>
        <Typography variant="h6" className={"text-lg m-2"}>
          {t("pagesTitles.newsTitle")}
        </Typography>
      </Box>
      <LoadingBackdrop isVisible={loading} />
      {NewsMenuItems(news, onClose, page)}
      <MenuItem
        to={"/news"}
        component={Link}
        onClick={onClose}
        sx={{
          textDecoration: "underline",
          textTransform: "lowercase",
          color: "gray",
          fontSize: "14px",
        }}
      >
        {t("newsMenu.seeMoreNews")}
      </MenuItem>
    </Menu>
  );
}

export default NewsPopover;
