import React, { useCallback, useMemo, useState } from "react";
import {
  DateRangePicker,
  LocalizationProvider,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import "dayjs/locale/ka";
import "dayjs/locale/en";
import { useTranslation } from "react-i18next";
import { format, today } from "./dates";
import Calendar from "@mui/icons-material/Event";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";

/**
 * Styles for mobile calendar view.
 * These styles apply only for screens with a width of 600px or less (xs).
 * Shortcuts are displayed in a mobile on the top.
 */
const mobileCalendarStyles = {
  sx: {
    "@media (max-width:600px)": {
      display: "block",
      width: "100%",
      maxWidth: "100%",
      "& .MuiDateRangePickerToolbar-root": {
        paddingBottom: "0",
      },
      "& .MuiDayCalendar-slideTransition": {
        minHeight: "215px",
      },
      "& .MuiDialogActions-root": {
        paddingTop: "0",
        paddingBottom: "0",
      },
      "& .MuiList-root": {
        maxWidth: "100%",
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        paddingBottom: "0",
        "& .MuiListItem-root": {
          width: "fit-content",
        },
      },
    },
  },
};

function CalendarPicker({ onDateChange, selectedDates }) {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Handles the acceptance of the new date range selection within the date picker.
   * This callback function adjusts the end date of the selected range to the end of the day (23:59:59)
   * to ensure the entire day is included. If the end date is not selected (`null`), it remains `null`.
   *
   * @param {Date[]} newValue - An array containing the start and end dates selected by the user.
   * The end date is adjusted to the end of the day if it is not `null`.
   *
   * @callback onDateChange - The callback function to be called with the new date range. This function
   * should accept an array with two elements: the start date and the adjusted end date.
   *
   * @returns {void} Nothing is returned from this function.
   */

  const maxPeriod = 6;
  const [maxDate, setMaxDate] = useState(null);

  const adjustMaxDate = useCallback(
    ([dateFrom, dateTo]) => {
      const sixMonthsAfterStartDate = dayjs(dateFrom)
        .add(maxPeriod, "month")
        .hour(23)
        .minute(59)
        .second(59);
      setMaxDate(sixMonthsAfterStartDate);
    },
    [setMaxDate],
  );

  const onDatePicked = useCallback(
    ([dateFrom, dateTo]) => {
      if (!dateFrom) {
        enqueueSnackbar(t("calendar.noDateFrom"), {
          variant: "warning",
        });
        return;
      } else if (!dateTo) {
        enqueueSnackbar(t("calendar.noDateTo"), {
          variant: "warning",
        });
        return;
      } else if (dateTo.isAfter(maxDate)) {
        enqueueSnackbar(t("calendar.maxPeriod"), {
          variant: "warning",
        });
        return;
      }
      onDateChange([dateFrom, dateTo]);
    },
    [onDateChange, enqueueSnackbar, t, maxDate],
  );

  const shortcutsItems = useMemo(
    () => [
      {
        label: t("calendar.shortcutToday"),
        getValue: () => [today.startOf("day"), today.endOf("day")],
      },
      {
        label: t("calendar.shortcutThisWeek"),
        getValue: () => [today.startOf("week"), today.endOf("day")],
      },
      {
        label: t("calendar.shortcutLastWeek"),
        getValue: () => {
          const prevWeek = today.subtract(1, "week");
          return [prevWeek.startOf("week"), prevWeek.endOf("week")];
        },
      },
      {
        label: t("calendar.shortcutCurrentMonth"),
        getValue: () => {
          return [today.startOf("month"), today.endOf("day")];
        },
      },
      {
        label: t("calendar.shortcutLastMonthFromToday"),
        getValue: () => {
          return [today.startOf("day").subtract(30, "day"), today];
        },
      },
      {
        label: t("calendar.shortcutLastMonth"),
        getValue: () => {
          const prevMonthStart = today.subtract(1, "month").startOf("month");
          const prevMonthEnd = prevMonthStart.endOf("month");
          return [prevMonthStart, prevMonthEnd];
        },
      },
      {
        label: t("calendar.shortcutLast3Months"),
        getValue: () => {
          const prevThreeMonthsStart = today.subtract(3, "month").startOf("month");
          const prevThreeMonthsEnd = today.startOf("month").subtract(1, "day");
          return [prevThreeMonthsStart, prevThreeMonthsEnd];
        },
      },
      {
        label: t("calendar.shortcutLast3MonthsFromToday"),
        getValue: () => {
          const prevThreeMonthsStart = today.subtract(3, "month").startOf("day");
          const prevThreeMonthsEnd = today.endOf("day");
          return [prevThreeMonthsStart, prevThreeMonthsEnd];
        },
      },
    ],
    [t],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
      <DateRangePicker
        className="w-64"
        slots={{ field: SingleInputDateRangeField }}
        slotProps={{
          textField: {
            format,
            InputProps: {
              endAdornment: <Calendar />,
            },
          },
          layout: mobileCalendarStyles,
          shortcuts: {
            items: shortcutsItems,
            changeImportance: "set",
          },
          actionBar: {
            actions: ["accept"],
          },
        }}
        closeOnSelect={false}
        value={selectedDates}
        onAccept={onDatePicked}
        onChange={adjustMaxDate}
        maxDate={maxDate}
      />
    </LocalizationProvider>
  );
}

export default CalendarPicker;
