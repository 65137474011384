import React, { useMemo, useState } from "react";
import { setNewPassword } from "../../firebase";
import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import LoadingBackdrop from "../../components/loadingBackdrop/LoadingBackdrop";
import ButtonComponent from "../../components/buttonComponent/ButtonComponent";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import logoBlack from "../../images/logoBlack.svg";
import { SetNewPasswordPageGuide } from "./ResetPasswordInstructions";

const NewPasswordPage = () => {
  const [typePass, setTypePass] = useState("password");
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get("oobCode");
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  /**
   *
   * The validation criteria applied are:
   * - `password`: A string that must be at least 6 characters long (validation for a password in Firebase). The field is also marked as required.
   *
   * @type {Yup.ObjectSchema} The `validationSchema` is an instance of `Yup.ObjectSchema`
   */
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string()
          .min(6, t("errorsMessages.passwordLength"))
          .required(t("errorsMessages.requiredField")),
      }),
    [t],
  );

  const handleFormSubmit = async (values) => {
    try {
      await setNewPassword(oobCode, values.password);
      enqueueSnackbar(t("alertsMessages.setNewPassword"), { variant: "success" });
      navigate("/login");
    } catch (err) {
      enqueueSnackbar(t("alertsMessages.setPasswordError"), { variant: "error" });
    }
  };

  return (
    <Box
      className={
        "flex justify-center md:justify-start shadow-md rounded-md md:m-5 flex-col md:flex-row"
      }
    >
      <Box
        className={
          "flex bg-color-main flex-col items-center md:w-1/4 md:min-w-[300px] xl:min-w-[350px] 2xl:min-w-[400px]"
        }
      >
        <Link to={"/"}>
          <img
            src={logoBlack}
            alt={t("alts.logoBlack")}
            className={"w-32 md:w-36 md:mt-14 md:mb-8 smMax:hidden"}
          />
        </Link>
        <SetNewPasswordPageGuide />
      </Box>
      <Box className={"min-h-max flex-grow m-3 justify-self-center"}>
        <Formik
          initialValues={{ password: "" }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className="flex flex-col items-center justify-around p-2 sm:mt-5">
              <Typography variant="h4">{t("setPassword")}</Typography>
              <Field
                as={TextField}
                label={t("loginForm.passwordFieldLabel")}
                type={typePass}
                placeholder={t("placeholders.password")}
                className="w-full sm:w-2/3 md:w-1/2 m-4"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setTypePass(typePass === "password" ? "text" : "password")}
                      >
                        <VisibilityOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                name="password"
                error={Boolean(errors.password) && Boolean(touched.password)}
                helperText={Boolean(touched.password) && errors.password}
              />
              <Box className="flex flex-col items-center">
                <LoadingBackdrop isVisible={isSubmitting} />
                <ButtonComponent text={t("setPassword")} disabled={isSubmitting} type="submit" />
              </Box>
              <Link to={"/login"} className="text-gray-400 text-s mt-3 mb-5">
                {t("goToLogin")}
              </Link>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default NewPasswordPage;
