import { Tooltip } from "@mui/material";

/**
 * TooltipWrapper component wraps its children with a Tooltip that displays the given title.
 * It is optimized to react on touch events on mobile devices.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The elements that the tooltip will wrap.
 * @param {React.ReactNode | string} props.title - The content of the tooltip.
 * @param {string} [props.placement="bottom"] - The placement of the tooltip.
 * @param {...Object} [props] - Any additional props to be passed to the Tooltip component.
 */
export const TooltipWrapper = ({ children, title, placement = "bottom", ...props }) => {
  return (
    <Tooltip
      {...props}
      title={title}
      placement={placement}
      enterTouchDelay={0}
      leaveTouchDelay={3000}
    >
      {children}
    </Tooltip>
  );
};
