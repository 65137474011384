import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from "react";
import { LargeScreenButtons } from "./LargeScreenButtons";
import { SmallScreenMenu } from "./SmallScreenMenu";
import { Box } from "@mui/material";

/**
 * LanguageSwitch is a React component that renders language switch buttons and a dropdown menu for changing the application's language.
 * This component utilizes the `useTranslation` hook from 'react-i18next' to handle language changes and ensures that the entire
 * application re-renders by reloading the window after a language change.
 *
 * @returns {JSX.Element} A Box component containing both large and small screen
 * components for language switching. LargeScreenButtons are shown on larger screens and SmallScreenMenu is used for smaller screens.
 *
 * - LargeScreenButtons: A component for rendering language switch buttons on larger screens.
 * - SmallScreenMenu: A component that provides a dropdown menu for language switching on smaller screens.
 */
export function LanguageSwitch() {
  const { i18n } = useTranslation();

  const handleChangeLanguage = useCallback(
    (lang) => {
      i18n.changeLanguage(lang);
      window.location.reload();
    },
    [i18n],
  );

  return (
    <Box className="flex items-center md:ml-4">
      <LargeScreenButtons handleChangeLanguage={handleChangeLanguage} />
      <SmallScreenMenu
        selectedLanguage={i18n.language}
        handleChangeLanguage={handleChangeLanguage}
      />
    </Box>
  );
}
