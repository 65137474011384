import { DELIVERY_METHOD, PARCEL_TYPE, PAYMENT_TYPE, RECIPIENT_TYPE } from "../constants";
import { getRecipientNameAndSurname } from "../getRecipientNameAndSurname";
import { EMPTY_ORDER } from "../../hooks/useOrder";

/**
 * @typedef {Object} OrderCreationInitialValues - OrderCreationWizard initial values
 * @property {string} pickupMethod - The method for picking up the parcel (courier or department).
 * @property {string} recipientType - The type of recipient (individual or company).
 * @property {string} deliveryMethod - The method for delivering the parcel (courier or department).
 * @property {string} pickupRegion - The region for parcel pickup. Optional field
 * @property {string} pickupCity - The city for parcel pickup.
 * @property {string} pickupPostOffice - The post office for parcel pickup.
 * @property {string} pickupAddress - The address for parcel pickup.
 * @property {string} pickupAddressId - The ID of the pickup address.
 * @property {string} recipientGovNumber - The government number of the recipient.
 * @property {string} recipientCompanyName - The name of the recipient's company.
 * @property {string} recipientName - The first name of the recipient.
 * @property {string} recipientSurname - The last name of the recipient.
 * @property {string} recipientNameSurname - The name and surname of the recipient.
 * @property {string} recipientPhone - The phone number of the recipient.
 * @property {string} recipientRegion - The region of the recipient. Optional field
 * @property {string} recipientCity - The city of the recipient.
 * @property {string} recipientPostOffice - The recipient's post office.
 * @property {string} recipientDeliveryAddress - The recipient's delivery address.
 * @property {string} recipientOrganizationsRepresentative - The representative of the recipient's company.
 * @property {string} parcelType - The type of the parcel (document or parcel).
 * @property {number} parcelWidth - The width of the parcel.
 * @property {number} parcelHeight - The height of the parcel.
 * @property {string} parcelWeight - The weight of the parcel.
 * @property {number} parcelLength - The length of the parcel.
 * @property {string} estimatedValue - The estimated value of the parcel.
 * @property {boolean} cod - Indicates whether cash on delivery is enabled.
 * @property {boolean} fragile - Indicates whether the parcel is fragile.
 * @property {boolean} insured - Indicates whether the parcel is insured.
 * @property {string} parcelDescription - The description of the parcel.
 * @property {string} parcelClientCustomAttribute - Custom attribute for the parcel.
 * @property {string} paymentType - The type of payment (sender or recipient).
 * @property {number} insuranceCost - The cost of insurance for the parcel.
 * @property {number} codCommissionAmount - The commission amount for cash on delivery.
 * @property {number} deliveryCost - The cost of parcel delivery.
 * @property {number} estimatedCost - The estimated overall cost of the parcel.
 * @property {boolean} codCommissionByRecipient - The cod commission flag of payment (sender or recipient).
 * @property {string} internationalTrackingNumber - The international tracking number.
 */

/**
 * Initial empty values.
 * @type {OrderCreationInitialValues}
 */
export const emptyInitialValues = {
  pickupMethod: DELIVERY_METHOD.COURIER,
  recipientType: RECIPIENT_TYPE.INDIVIDUAL,
  deliveryMethod: DELIVERY_METHOD.DEPARTMENT,
  pickupRegion: "",
  pickupCity: "",
  pickupPostOffice: "",
  pickupAddress: "",
  pickupAddressId: "",
  recipientGovNumber: "",
  recipientCompanyName: "",
  recipientName: "",
  recipientSurname: "",
  recipientNameSurname: "",
  recipientPhone: "",
  recipientRegion: "",
  recipientCity: "",
  recipientPostOffice: "",
  recipientDeliveryAddress: "",
  recipientOrganizationsRepresentative: "",
  parcelType: PARCEL_TYPE.PARCEL,
  parcelWidth: null,
  parcelHeight: null,
  parcelWeight: "",
  parcelLength: null,
  estimatedValue: "",
  cod: false,
  fragile: false,
  insured: false,
  parcelDescription: "",
  parcelClientCustomAttribute: "",
  paymentType: PAYMENT_TYPE.SENDER,
  insuranceCost: 0,
  codCommissionAmount: 0,
  deliveryCost: 0,
  estimatedCost: 0,
  codCommissionByRecipient: PAYMENT_TYPE.RECIPIENT,
  internationalTrackingNumber: "",
};

/**
 * Transforms order data from the API into initial values for form fields.
 * Used in the OrderCreationWizard component when updating an existing order.
 *
 * @param {OrderInfo} order - The order data retrieved from the API.
 * @returns {OrderCreationInitialValues} Initial values for form fields based on the order data.
 */
export const initialValuesFromAPI = (order) => {
  if (order === EMPTY_ORDER) {
    return emptyInitialValues;
  }
  const recipientType =
    order.recipientGovNumber === "" ? RECIPIENT_TYPE.INDIVIDUAL : RECIPIENT_TYPE.COMPANY;

  const { name: recipientName, surname: recipientSurname } = getRecipientNameAndSurname(
    recipientType === RECIPIENT_TYPE.INDIVIDUAL ? order.recipientName : "",
    recipientType === RECIPIENT_TYPE.COMPANY ? order.recipientCompanyRepresentative : "",
  );
  const recipientPhone = order.recipientPhone.slice(4);

  return {
    pickupMethod:
      order.pickupPostOffice === "" ? DELIVERY_METHOD.COURIER : DELIVERY_METHOD.DEPARTMENT,
    recipientType: recipientType,
    deliveryMethod:
      order.deliveryPostOffice === "" ? DELIVERY_METHOD.COURIER : DELIVERY_METHOD.DEPARTMENT,
    pickupCity: order.pickupCityId,
    pickupPostOffice: order.pickupPostOffice,
    pickupAddress: order.pickupPostOffice === "" ? order.pickupAddress : "",
    pickupAddressId: "",
    recipientGovNumber: order.recipientGovNumber,
    recipientCompanyName: RECIPIENT_TYPE.COMPANY ? order.recipientName : "",
    recipientName: recipientName,
    recipientSurname: recipientSurname,
    recipientNameSurname: order.recipientName,
    recipientPhone: recipientPhone,
    recipientCity: order.deliveryCityId,
    recipientPostOffice: order.deliveryPostOffice,
    recipientDeliveryAddress: order.deliveryPostOffice === "" ? order.deliveryAddress : "",
    recipientOrganizationsRepresentative: order.recipientCompanyRepresentative,
    parcelType: order.isParcel ? PARCEL_TYPE.DOCUMENTS : PARCEL_TYPE.PARCEL,
    parcelWidth: order.width,
    parcelHeight: order.height,
    parcelWeight: order.weight,
    parcelLength: order.length,
    estimatedValue: order.estimatedCost,
    cod: order.cod,
    fragile: order.fragile,
    insured: order.insured,
    parcelDescription: order.description,
    parcelClientCustomAttribute: order.customTag,
    paymentType: order.paymentByRecipient ? PAYMENT_TYPE.RECIPIENT : PAYMENT_TYPE.SENDER,
    insuranceCost: parseFloat(order.insurance),
    codCommissionAmount: parseFloat(order.codCommission),
    deliveryCost: parseFloat(order.deliveryCost),
    estimatedCost: order.cod ? parseFloat(order.estimatedCost) : 0,
    codCommissionByRecipient: order.codCommissionByRecipient
      ? PAYMENT_TYPE.RECIPIENT
      : PAYMENT_TYPE.SENDER,
    internationalTrackingNumber: order.internationalTrackingNumber,
  };
};
