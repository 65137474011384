import React from "react";
import { Box, Grid } from "@mui/material";
import SideBarMain from "../../sideBarMain/SideBarMain";
import NavigatorMenu from "../../navigatorMenu/NavigatorMenu";
import { Outlet } from "react-router-dom";
import Footer from "../../footer/Footer";
import { AccountProvider } from "../../../providers/AccountProvider/AccountProvider";

/**
 * Configuration for grid layout columns across different screen sizes.
 * @type {Object}
 * @property {number} columns - Total number of columns in the grid container.
 * @property {number} xs - Number of columns for extra-small screens. Up to 600px (Mobile devices)
 * @property {number} sm - Number of columns for small screens.  600px and above (Small tablets, mobile phones in landscape)
 * @property {number} md - Number of columns for medium screens. 900px and above (Medium-sized devices)
 * @property {number} lg - Number of columns for large screens. 1200px and above (Large devices, desktops)
 * @property {number} xl - Number of columns for large screens. 1536px and above (Extra-large desktops, high-res monitors)
 * @property {number} xxl - Number of columns for extra-extra-large screens.  1920px and above (Full HD monitors)
 * @property {number} xxxl - Number of columns for extra-extra-extra-large screens. 2560px and above (4K monitors)
 */
const columnConfig = {
  columns: 20,
  xs: 18,
  sm: 19,
  md: 13,
  lg: 15,
  xl: 15,
  xxl: 16,
  xxxl: 17,
};

function PrivateLayout() {
  return (
    <AccountProvider>
      <Box className="flex-grow max-w-full">
        <NavigatorMenu />
        <Grid
          container
          columns={columnConfig.columns}
          className="flex-grow justify-center md:justify-start"
        >
          <Grid
            item
            md={1}
            sx={{
              minWidth: { xs: 250, xl: 280, xxl: 330 },
              display: { xs: "none", md: "block" },
              margin: 0,
              marginRight: { md: "16px" },
            }}
          >
            <SideBarMain />
          </Grid>
          <Grid
            item
            xs={columnConfig.xs}
            sm={columnConfig.sm}
            md={columnConfig.md}
            lg={columnConfig.lg}
            xxl={columnConfig.xxl}
            xxxl={columnConfig.xxxl}
            className={"flex flex-col justify-start m-3 w-full rounded-md"}
          >
            <Outlet />
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </AccountProvider>
  );
}

export default PrivateLayout;
