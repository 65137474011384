import React from "react";
import { Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ResetPasswordPageGuide = () => {
  const { t } = useTranslation();

  return (
    <Box className="flex flex-col items-center h-full m-5">
      <Typography variant="h6" gutterBottom className="text-center font-semibold">
        {t("resetPasswordGuide.title")}
      </Typography>
      <ol className="flex flex-col items-start m-0 2xl:m-5">
        <li>
          <Typography variant="body1" className={"md:my-2 smMax:text-sm"}>
            {t("resetPasswordGuide.email")}
          </Typography>
        </li>
        <li>
          <Typography variant="body1" className={"md:my-2 smMax:text-sm"}>
            {t("resetPasswordGuide.recaptcha")}
          </Typography>
        </li>
        <li>
          <Typography variant="body1" className={"md:my-2 smMax:text-sm"}>
            {t("resetPasswordGuide.link")}
          </Typography>
        </li>
        <li>
          <Typography variant="body1" className={"my-2"}>
            {t("resetPasswordGuide.securePage")}
          </Typography>
        </li>
      </ol>
    </Box>
  );
};
export const SetNewPasswordPageGuide = () => {
  const { t } = useTranslation();

  return (
    <Box className="flex flex-col items-center h-full m-5">
      <Typography variant="h6" gutterBottom className="text-center font-semibold ">
        {t("setPasswordGuide.title")}
      </Typography>
      <Box className="flex flex-col items-start m-0 2xl:m-5">
        <ul className="list-decimal pl-6">
          <li>
            <Typography variant="body1" className={"md:my-2 smMax:text-sm"}>
              {t("setPasswordGuide.length")}
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={"md:my-2 smMax:text-sm"}>
              {t("setPasswordGuide.complexity")}
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={"md:my-2 smMax:text-sm"}>
              {t("setPasswordGuide.commonPassword")}
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" className={"md:my-2 smMax:text-sm"}>
          {t("setPasswordGuide.issue")}
          <Link
            to="/resetpassword"
            className="text-yellow-800 no-underline hover:text-yellow-950"
          >
            {t("setPasswordGuide.link")}
          </Link>
          {t("setPasswordGuide.tryAgain")}
        </Typography>
        <Typography variant="body1" className={"md:my-2 smMax:text-sm"}>
          {t("setPasswordGuide.support")}
        </Typography>
      </Box>
    </Box>
  );
};
