import axios from "axios";
import { BASE_URL } from "./delivo-api-config";
import { authToken, signOutUser } from "../firebase";
import axiosRetry from "axios-retry";
import AuthenticationError from "../errors/AuthenticationError";

/** Custom Axios instance pre-configured for working with Delivo private APIs. **/
const delivoAxios = axios.create({
  baseURL: BASE_URL,
});

axiosRetry(delivoAxios, {
  retryDelay: axiosRetry.exponentialDelay,
});

delivoAxios.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    const originalRequest = error.config;
    const errorMessage = error.response?.data?.error;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newToken = await authToken();
        if (newToken !== null) {
          originalRequest.headers["Authorization"] = newToken;
          return delivoAxios(originalRequest);
        } else {
          await signOutUser();
        }
      } catch (refreshError) {
        console.error("Failed to refresh token:", refreshError);
        throw new AuthenticationError(errorMessage);
      }
    } else if (error.response && error.response.status === 500) {
      throw error;
    } else if (error instanceof AuthenticationError) {
      await signOutUser();
    }

    return Promise.reject(error);
  },
);

export default delivoAxios;
