import React from "react";
import { Box, IconButton } from "@mui/material";
import ArchiveIcon from "@mui/icons-material/Archive";
import { useTranslation } from "react-i18next";
import { TooltipWrapper } from "../tooltipWrapper/TooltipWrapper";

function ArchiveAction({ onClick, disabled }) {
  const { t } = useTranslation();

  return (
    <Box>
      <TooltipWrapper title={t("tooltips.archiveOrder")}>
        <span>
          <IconButton aria-label="archive" color="primary" onClick={onClick} disabled={disabled}>
            <ArchiveIcon />
          </IconButton>
        </span>
      </TooltipWrapper>
    </Box>
  );
}

export default ArchiveAction;
