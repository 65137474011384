/**
 * Processes an array of orders to ensure each has a valid pickup and delivery city.
 * If the city is missing in an order, a localized fallback message is used.
 *
 * @param {Array} orders - Array of order objects to be validated.
 * @param {Function} t - Translation function used for localizing the fallback city message.
 * @returns {Array} An array of orders where each order has validated city properties.
 */
export const validateOrders = (orders, t) =>
  orders.map((order) => ({
    ...order,
    pickupCity: order.departureCity || t("errorsMessages.city"),
    deliveryCity: order.deliveryCity || t("errorsMessages.city"),
  }));
