import { Dimensions } from "../../api/orderTypes";

/**
 * Prepares dimensions for a parcel based on provided data.
 *
 * @param {boolean} isDocumentParcel Indicates whether the parcel is a document.
 * @param {object} parcelData The parcel data containing dimensions.
 * @param {number} [parcelData.parcelLength] The length of the parcel.
 * @param {number} [parcelData.parcelWidth] The width of the parcel.
 * @param {number} [parcelData.parcelHeight] The height of the parcel.
 * @param {number} [parcelData.parcelWeight] The weight of the parcel.
 * @returns {{ dimensions: Dimensions, weight: number }} An object containing parcel dimensions and weight.
 *  */
export const prepareDimensions = (isDocumentParcel, parcelData) => {
  const length = isDocumentParcel ? 0 : parcelData.parcelLength || 0;
  const width = isDocumentParcel ? 0 : parcelData.parcelWidth || 0;
  const height = isDocumentParcel ? 0 : parcelData.parcelHeight || 0;
  const weight = isDocumentParcel ? 0 : parcelData.parcelWeight || 0;

  const dimensions = new Dimensions({
    length: parseInt(length),
    width: parseInt(width),
    height: parseInt(height),
  });

  return { dimensions, weight: parseFloat(weight) };
};

/**
 * Checks if the parcel is fragile based on the parcel type.
 *
 * @param {boolean} isDocumentParcel Indicates whether the parcel is a document.
 * @param {boolean} fragile Indicates whether the parcel is fragile.
 * @returns {boolean} A boolean indicating whether the parcel is fragile.
 */
export const isFragile = (isDocumentParcel, fragile) => {
  return !isDocumentParcel && fragile;
};

/**
 * Checks if the parcel is insured based on the parcel type.
 *
 * @param {boolean} isDocumentParcel Indicates whether the parcel is a document.
 * @param {boolean} insured Indicates whether the parcel is insured.
 * @returns {boolean} A boolean indicating whether the parcel is insured.
 */
export const isInsured = (isDocumentParcel, insured) => {
  return !isDocumentParcel && insured;
};
