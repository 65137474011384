import React from "react";
import { Button } from "@mui/material";

function ButtonComponent({
  text,
  onClick,
  disabled,
  type,
  startIcon,
  className = "p-3 m-4",
  variant = "contained",
  size = "large",
  ...props
}) {
  return (
    <Button
      type={type}
      variant={variant}
      size={size}
      onClick={onClick}
      disabled={disabled}
      className={className}
      startIcon={startIcon}
      {...props}
    >
      {text}
    </Button>
  );
}

export default ButtonComponent;
