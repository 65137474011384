import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useFAQItem } from "../../hooks/useFAQItem";
import { Box, Button, Typography } from "@mui/material";
import LoadingBackdrop from "../loadingBackdrop/LoadingBackdrop";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RelatedFAQ } from "./RelatedFAQ";
import { HtmlContentDisplay } from "../htmlContentDisplay/HtmlContentDisplay";
import { Breadcrumb } from "../breadcrumb/Breadcrumb";

/**
 * Represents an FAQ item display component within a React application using React Router and i18next.
 *
 * This component fetches and displays a specific FAQ item based on the URL parameter 'slug'.
 * It utilizes `useParams` to retrieve the 'slug' and `useLocation` from 'react-router-dom' to access any initial FAQ data
 * that might be passed via route state (`location.state.detail`). This initial data, if present, is passed directly to the
 * custom hook `useFAQItem`. If no initial data is provided, `useFAQItem` will fetch the FAQ data from the API endpoint `faq/{slug}`.
 * This approach minimizes unnecessary API calls when navigating from an FAQ list where initial data might already be available.
 *
 * @returns {FAQItem} A React component that displays the FAQ item in a styled box with conditional
 * rendering based on the data's loading state and availability.
 */
function FAQItem() {
  const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const page = location.state?.page || 1;
  const initialFAQ = location.state?.detail || null;
  const { faq, loading } = useFAQItem({ slug, initialFAQ });
  const { t } = useTranslation();
  const faqPageLink = `/faq?page=${page}`;

  const handleBack = useCallback(() => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(faqPageLink);
    }
  }, [navigate, faqPageLink]);

  return (
    <Box className={"shadow-lg rounded-md p-4"}>
      <LoadingBackdrop isVisible={loading} />
      <Breadcrumb href={faqPageLink} label={t("pagesTitles.faq")} text={faq?.question || ""} />
      {faq ? (
        <Box className={"flex flex-col md:flex-row justify-between"}>
          <Box className={`p-4 ${faq.related.length ? "md:max-w-[50%]" : "md:max-w-[100%]"}`}>
            <Typography variant="h6" className="text-xl font-bold mb-4">
              {faq.question}
            </Typography>
            <HtmlContentDisplay content={faq.fullMessage} />
          </Box>
          <Box className="flex flex-col">
            <RelatedFAQ relatedFAQ={faq.related} />
          </Box>
        </Box>
      ) : (
        <Typography className="p-4">{t("alertsMessages.noFAQ")}</Typography>
      )}
      <Button
        onClick={handleBack}
        className="mt-4 text-black lowercase underline hover:underline-offset-2 md:text-lg p-4"
      >
        {t("back")}
      </Button>
    </Box>
  );
}

export default FAQItem;
