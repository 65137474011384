import { useTranslation } from "react-i18next";
import { useAuth } from "../../../providers/AuthProvider/AuthProvider";
import { useAccountContext } from "../../../providers/AccountProvider/AccountProvider";
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import React, { useCallback } from "react";

/**
 * Account menu component to display user account information and actions.
 * @param {Object} props - The props object.
 * @param {HTMLElement} props.anchorEl - The anchor element for the menu.
 * @param {boolean} props.isOpen - Indicates whether the menu is open.
 * @param {Function} props.handleMenuClose - Callback function to handle menu close event.
 * @returns {JSX.Element} AccountMenu component.
 */
export function AccountMenu({ anchorEl, isOpen, handleMenuClose }) {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const { accountInfo } = useAccountContext();

  const handleLogout = useCallback(async () => {
    await logout();
    handleMenuClose();
  }, [logout, handleMenuClose]);

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleMenuClose}
      MenuListProps={{ "aria-labelledby": "basic-button" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem className="text-black">
        <Box className="w-full flex flex-col">
          <Typography variant="body1">{accountInfo.companyName}</Typography>
          <Typography className="pb-2 text-gray-500" variant="body2">
            {accountInfo.email}
          </Typography>
        </Box>
      </MenuItem>
      <Divider flexItem />
      <MenuItem
        onClick={handleMenuClose}
        component={Link}
        to="/account"
        className="text-black uppercase justify-between"
      >
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        {t("navBar.myAccountItem")}
      </MenuItem>
      <Divider flexItem />
      <MenuItem onClick={handleLogout} className="justify-between">
        <ListItemIcon>
          <LogoutOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={t("logoutButton")} />
      </MenuItem>
    </Menu>
  );
}
