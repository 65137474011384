import React from "react";
import { LanguageButton } from "./LanguageButton";
import { Box } from "@mui/material";
import { languages } from "./languages";

/**
 * `LargeScreenButtons` is a React component that renders a series of language selection buttons, specifically for larger screens.
 * This component uses the `LanguageButton` for each available language to facilitate language switching.
 *
 * @param {Object} props The properties passed to the LargeScreenButtons component.
 * @param {Function} props.handleChangeLanguage The callback function to invoke when a language button is clicked.
 * This function should handle the logic for changing the application's current language.
 *
 * @returns {JSX.Element} A Box component (from a UI library like Material-UI) styled to display only on larger screens (md and above).
 *
 */
export function LargeScreenButtons({ handleChangeLanguage }) {
  return (
    <Box className="hidden md:flex">
      {languages.map((language) => (
        <LanguageButton
          key={language}
          language={language}
          handleChangeLanguage={() => handleChangeLanguage(language)}
        />
      ))}
    </Box>
  );
}
