import {
  CITIES,
  REGIONS,
  FEEDBACK_FORM_EMAIL,
  MANAGER,
  MY_ACCOUNT,
  POST_OFFICES,
  REGISTRATION_URL,
  RESET_PASSWORD_EMAIL,
  NEWS_PAGE,
  FAQ,
} from "./delivo-api-config";
import {
  Account,
  City,
  Region,
  FeedbackFormRequest,
  Manager,
  PostOffice,
  RegisteredOrganization,
  ResetPasswordRequest,
  FAQItem,
  NewsItem,
} from "./types";

import { auth, authToken } from "../firebase";
import delivoAxios from "./delivoAxios";

const getHeaders = async (contentType, fetchUserToken, accepts) => {
  let newHeaders = {
    "Content-Type": contentType,
    Accept: accepts,
  };
  if (fetchUserToken) {
    const token = await authToken();
    newHeaders = {
      ...newHeaders,
      Authorization: token,
    };
    return newHeaders;
  }
  return newHeaders;
};

/**
 * Prepares AXIOS request params from the passed token and query params.
 * @param {object} [config={}] the params configuration object
 * @param {any} [config.queryParams=null] the query parameters to be added
 * @param {string} [config.contentType=application/json] the content type of the request
 * @param {string} [config.accept=application/json] the content type of the expected response
 * @param {boolean} [config.fetchUserToken=true] determines if we should fetch and add user token
 * to the Authorization header
 */
export const getParams = async ({
  queryParams = null,
  contentType = "application/json",
  accept = "application/json",
  fetchUserToken = true,
} = {}) => {
  const headers = await getHeaders(contentType, fetchUserToken, accept);
  if (queryParams === null || queryParams === "") {
    return { headers };
  }
  return {
    headers: headers,
    params: queryParams,
  };
};

const postOfficeInCity = (cityId) =>
  cityId !== "" ? `${POST_OFFICES}?city_id=${cityId}` : POST_OFFICES;

export class DelivoApi {
  /**
   * Returns a list of the available post offices.
   *
   * @param {string} [cityId] cityId the id of the city to find the post offices in
   * @returns {Promise<PostOffice[]>}
   */
  async getPostOffices(cityId = "") {
    try {
      const params = await getParams();
      console.debug("Retrieving post offices.", cityId);
      const postOfficesUrl = postOfficeInCity(cityId);

      const response = await delivoAxios.get(postOfficesUrl, params);
      if (response.status !== 200) {
        console.error("Failed to retrieve post offices.", cityId, response);
        throw new Error("Failed to retrieve post offices.");
      }
      const postOffices = response.data;
      const result = postOffices.map((postOffice) => new PostOffice(postOffice));
      console.debug("Post offices retrieved.", cityId, result);
      return result;
    } catch (e) {
      console.error("Post offices info retrieval failed.", e);
      throw e;
    }
  }

  /**
   * Asynchronously retrieves a list of cities based on provided search criteria.
   * Only non-empty parameters are included in the request.
   * Only cities that are enabled (`is_enabled` is `true`) are returned.
   *
   * @param {Object} params An object containing optional search parameters.
   * @param {string} [params.name_en] The city name in English.
   * @param {string} [params.name_ka] The city name in Georgian.
   * @param {string} [params.city_code] The unique city code.
   * @returns {Promise<City[]>} A promise that resolves to an array of City instances matching the search criteria.
   * @throws {Error} Throws an error if the request fails.
   */
  async getCities(params = {}) {
    try {
      const queryParams = Object.entries(params).reduce((acc, [key, value]) => {
        if (value !== "") {
          acc[key] = value;
        }
        return acc;
      }, {});
      const requestParams = await getParams({ queryParams });
      const response = await delivoAxios.get(CITIES, requestParams);
      const enabledCities = response.data.filter((city) => city.is_enabled);
      return enabledCities.map((city) => new City(city));
    } catch (e) {
      throw e;
    }
  }

  /**
   * Fetches and returns the list of regions from the server.
   * This function asynchronously retrieves regions using predefined parameters obtained from `getParams`.
   *
   * @returns {Promise<Region[]>} The data containing the list of regions.
   */
  async getRegions() {
    const params = await getParams();
    const response = await delivoAxios.get(REGIONS, params);
    return response.data.map((region) => new Region(region));
  }

  /**
   * Retrieves a list of FAQ items from the server, paginated according to the page number provided.
   * This function constructs the necessary API endpoint, fetches parameters for the request, and processes the response to return a formatted object containing FAQ items and pagination details.
   *
   * @param {object} options - Configuration options for the function.
   * @param {number} [options.page=1] - The current page number of the FAQ list to fetch. Defaults to 1 if not specified.
   *
   * @returns {Promise<{results: Array<FAQItem>, count: number, nextPage: string, previousPage: string}>}
   * A promise that resolves to an object containing:
   *  - `results`: An array of `FAQItem` instances created from the fetched data.
   *  - `count`: The total number of FAQ items available on the server.
   *  - `nextPage`: The URL to the next page of FAQ items.
   *  - `previousPage`: The URL to the previous page of FAQ items.
   */
  async getFAQList({ page = 1, pageSize = 10 }) {
    const params = await getParams();
    const urlFAQ = `${FAQ}?page=${page}&page_size=${pageSize}`;
    const response = await delivoAxios.get(urlFAQ, params);
    const data = response.data;
    return {
      results: data.results.map((faq) => new FAQItem(faq)),
      count: data.count,
      nextPage: data.next,
      previousPage: data.previous,
    };
  }

  /**
   * Retrieves a specific FAQ item from the server using the provided slug as an identifier.
   * This method constructs the full URL for the specific FAQ resource, fetches necessary request parameters, makes a GET request to retrieve the FAQ data, and returns it as an instance of `FAQItem`.
   *
   * @param {object} options - Configuration options for the function.
   * @param {string} options.slug - The unique identifier (slug) for the desired FAQ item, used to construct the request URL.
   * @returns {Promise<FAQItem>} A promise that resolves to an `FAQItem` instance representing the fetched FAQ data.
   */
  async getFAQItem({ slug }) {
    const params = await getParams();
    const url = `${FAQ}${slug}/`;
    const response = await delivoAxios.get(url, params);
    return new FAQItem(response.data);
  }

  /**
   * Returns information about the currently logged-in user account.
   *
   * @returns {Promise<Account>}
   */
  async getMyAccount() {
    try {
      console.debug("Retrieving my account details.");
      const params = await getParams();

      const response = await delivoAxios.get(MY_ACCOUNT, params);
      if (response.status !== 200) {
        console.error("Failed retrieve account info.", response);
        throw new Error("Failed retrieve account info.");
      }
      const registeredOrganization = response.data;
      const result = new Account(registeredOrganization);
      console.debug("Account details retrieved.", result);
      return result;
    } catch (e) {
      console.error("Account info retrieval failed.", e);
      throw e;
    }
  }

  /**
   * Returns information about the support manager.
   *
   * @returns {Promise<Manager>}
   */
  async getManager() {
    console.debug("Retrieving manager details.");
    const params = await getParams();
    const response = await delivoAxios.get(MANAGER, params);
    if (response.status === 200) {
      const managerInfo = response.data;
      const result = new Manager(managerInfo);
      console.debug("Manager details retrieved.", result);
      return result;
    } else if (response.status === 404) {
      console.debug("Manager not found.");
      return null;
    } else {
      console.error("Failed to retrieve manager info.", response);
      throw new Error("Failed to retrieve manager info.");
    }
  }

  /**
   * Registers a new organization.
   *
   * @param {OrganizationRegistrationRequest} registerOrganizationRequest the registration request
   * @returns {Promise<RegisteredOrganization>} registered organization data when successfully registered otherwise the whole response.
   */
  async registerOrganisation(registerOrganizationRequest) {
    const params = await getParams();

    const response = await delivoAxios.post(
      REGISTRATION_URL,
      registerOrganizationRequest,
      params,
    );
    if (response.status !== 201) {
      console.error("Failed to register an organization.", registerOrganizationRequest, response);
      throw new Error(`Failed to register an organization: ${registerOrganizationRequest}`);
    }
    const registeredOrganization = response.data;
    return new RegisteredOrganization(registeredOrganization);
  }

  /**
   * Send a feedback form to email.
   *
   * @param {FeedbackFormRequest} feedbackFormRequest the feedback request
   * @return {boolean} sent feedback form to email
   */
  async sendFeedbackEmail(feedbackFormRequest) {
    if (!auth.currentUser) {
      console.error("You need to login");
      throw new Error("Authentication required to send feedback emails");
    }
    const params = await getParams();
    const response = await delivoAxios.post(FEEDBACK_FORM_EMAIL, feedbackFormRequest, params);
    if (response.status !== 200) {
      console.error("Failed to send feedback.", feedbackFormRequest, response);
      return false;
    }
    return true;
  }

  /**
   * Request for reset password.
   *
   * @param {ResetPasswordRequest} resetPassword
   * @return {Promise<boolean>} determines if password reset email was sent
   */
  async resetPassword(resetPassword) {
    const params = await getParams({ fetchUserToken: false });
    const response = await delivoAxios.post(RESET_PASSWORD_EMAIL, resetPassword, params);
    if (response.status !== 200) {
      console.error("Failed to reset password.", resetPassword, response);
      return false;
    }
    return true;
  }

  /**
   * Retrieves a list of News items from the server, paginated according to the page number provided.
   *
   * @param {number} [page=1] - The page number of the News list to retrieve, with a default value of 1.
   * @param {number} [pageSize=10] - The number of News items to show per page, with a default value of 10.
   *
   * @returns {Promise<{results: Array<NewsItem>, count: number, nextPage: string, previousPage: string}>}
   * A promise that resolves to an object containing:
   *  - `results`: An array of `NewsItem` instances created from the fetched data.
   *  - `count`: The total number of News items available on the server.
   *  - `nextPage`: The URL to the next page of News items.
   *  - `previousPage`: The URL to the previous page of News items.
   */
  async getNewsList({ page = 1, pageSize = 10 }) {
    const params = await getParams();
    const urlNews = `${NEWS_PAGE}?page=${page}&page_size=${pageSize}`;
    const response = await delivoAxios.get(urlNews, params);
    const data = response.data;
    return {
      results: data.results.map((item) => new NewsItem(item)),
      count: data.count,
      nextPage: data.next,
      previousPage: data.previous,
    };
  }

  /**
   * Retrieves a specific News item from the server using the provided slug as an identifier.
   *
   * @param {string} slug - The unique identifier (slug) for the desired News item, used to construct the request URL.
   * @returns {Promise<NewsItem>} A promise that resolves to an `NewsItem` instance representing the fetched News data.
   */
  async getNewsItem({ slug }) {
    const params = await getParams();
    const url = `${NEWS_PAGE}${slug}/`;
    const response = await delivoAxios.get(url, params);
    return new NewsItem(response.data);
  }
}

const delivoApi = new DelivoApi();
export { delivoApi };
