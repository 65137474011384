import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { useTranslation } from "react-i18next";
import { GridActionsCellItem, GridRowEditStopReasons, GridRowModes } from "@mui/x-data-grid";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DataGridTable from "../dataGrid/DataGridTable";

const initialRows = [
  { id: 201302301, fullName: "Snow Jon", phoneNumber: 995352344567, eMail: "test@test.com" },
  {
    id: 201302302,
    fullName: "Lannister Cersei",
    phoneNumber: 995352344567,
    eMail: "test@test.com",
  },
  {
    id: 201302303,
    fullName: "Lannister Jaime",
    phoneNumber: 995352344567,
    eMail: "test@test.com",
  },
  { id: 201302304, fullName: "Stark Arya", phoneNumber: 995352344567, eMail: "test@test.com" },
  {
    id: 201302305,
    fullName: "Targaryen Daenerys",
    phoneNumber: 995352344567,
    eMail: "test@test.com",
  },
  { id: 201302306, fullName: "Melisandre", phoneNumber: 995352344567, eMail: "test@test.com" },
  {
    id: 201302307,
    fullName: "Clifford Ferrara",
    phoneNumber: 995352344567,
    eMail: "test@test.com",
  },
  {
    id: 201302308,
    fullName: "Frances Rossini",
    phoneNumber: 995352344567,
    eMail: "test@test.com",
  },
  { id: 201302309, fullName: "Roxie Harvey", phoneNumber: 995352344567, eMail: "test@test.com" },
  { id: 201302310, fullName: "Harvey", phoneNumber: 995352344567, eMail: "test@test.com" },
];
function Employees() {
  const { t, i18n } = useTranslation();
  const columns = [
    {
      field: "fullName",
      headerName: `${t("employeesTableHeaders.fullName")}`,
      flex: 1,
      align: "left",
      editable: true,
      headerAlign: "left",
    },
    {
      field: "id",
      headerName: `${t("employeesTableHeaders.employeeID")}`,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "eMail",
      headerName: `${t("employeesTableHeaders.emailEmployee")}`,
      type: "email",
      flex: 1,
      editable: true,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "phoneNumber",
      headerName: `${t("employeesTableHeaders.phoneEmployee")}`,
      flex: 1,
      editable: true,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: " ",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick(id)}
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditOutlinedIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
          />,
          <GridActionsCellItem
            icon={<DeleteOutlinedIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
          />,
        ];
      },
    },
  ];

  const [rows, setRows] = useState(initialRows);
  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  return (
    <Box className={"w-full h-3/4 m-2"}>
      <Box className={"flex justify-between items-center"}>
        <Typography variant={"h6"}>{t("accountPage.employees")}</Typography>
        <Button
          type="submit"
          variant="outlined"
          size="large"
          className={"bg-color-main text-black uppercase m-2 border-transparent"}
        >
          <PersonAddAltIcon className={"mr-2"} />
          {t("accountPage.createEmployeeButton")}
        </Button>
      </Box>
      <DataGridTable
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        checkboxSelection={false}
        emptyTableText={
          <Typography className={"text-center pt-16"}>
            {t("ordersTableHeaders.noEmployees")}
          </Typography>
        }
      />
    </Box>
  );
}

export default Employees;
