import RadioGroupField from "../../radioGroupField/RadioGroupField";
import { Box, TextField } from "@mui/material";
import { DELIVERY_METHOD } from "../../constants";
import { RegionSelectField } from "../../selectFields/RegionSelectField";
import { CitySelectField } from "../../selectFields/CitySelectField";
import { Field } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  adaptiveBoxWrapper,
  fieldBaseStyle,
  getContentBlockClass,
  radioGroupBtnCustomStyle,
} from "../tailwindClasses";
import { PostOfficeField } from "../../selectFields/PostOfficeField";

/**
 * DeliveryOrderInfo component displays the delivery information section in an order form.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.recipientCity - The selected recipient city
 * @param {string} props.deliveryMethod - The selected delivery method
 * @param {string} props.recipientRegion - The selected recipient region
 * @param {string} props.recipientDeliveryAddress - The recipient's delivery address
 * @param {string} props.recipientPostOffice - The selected recipient post office
 * @param {Function} props.handleRegionChange - Handler for region change
 * @param {Function} props.handleChange - General change handler for form fields
 * @param {Function} props.handleRecipientCityChange - Handler for recipient city change
 * @param {Object} props.touched - Formik touched object for field validation
 * @param {Object} props.errors - Formik errors object for field validation
 * @returns {JSX.Element} The rendered component
 */
export const DeliveryOrderInfo = ({
  recipientCity,
  deliveryMethod,
  recipientRegion,
  recipientDeliveryAddress,
  recipientPostOffice,
  handleRegionChange,
  handleChange,
  handleRecipientCityChange,
  touched,
  errors,
}) => {
  const { t } = useTranslation();
  const deliveryMethodLabels = [
    {
      value: DELIVERY_METHOD.DEPARTMENT,
      label: t("orderCreation.clientPartRadioGroupRecevingMethodPostOffice"),
    },
    {
      value: DELIVERY_METHOD.COURIER,
      label: t("orderCreation.clientPartRadioGroupRecevingMethodCourier"),
    },
  ];

  const departmentStyles = getContentBlockClass(deliveryMethod === DELIVERY_METHOD.DEPARTMENT);
  const courierStyles = getContentBlockClass(deliveryMethod === DELIVERY_METHOD.COURIER);
  return (
    <>
      <RadioGroupField
        name="deliveryMethod"
        labels={deliveryMethodLabels}
        value={deliveryMethod}
        onChange={handleChange}
        className={radioGroupBtnCustomStyle}
      />
      <Box className={departmentStyles}>
        <Box className={adaptiveBoxWrapper}>
          <RegionSelectField
            fieldName="recipientRegion"
            onCustomChange={() => handleRegionChange("recipientCity", "recipientPostOffice")}
          />
          <CitySelectField
            fieldName="recipientCity"
            label={t("orderCreation.clientPartSelectCity")}
            tooltipMessage={t("tooltips.noPostOffices")}
            checkedField="postOfficeDeliveryEnabled"
            region={recipientRegion}
            customOnChange={handleRecipientCityChange}
          />
          <PostOfficeField
            cityId={recipientCity}
            selectedPostOffice={recipientPostOffice}
            fieldName="recipientPostOffice"
            touched={touched}
            errors={errors}
          />
        </Box>
      </Box>
      <Box className={courierStyles}>
        <Box className={adaptiveBoxWrapper}>
          <RegionSelectField
            fieldName="recipientRegion"
            onCustomChange={() => handleRegionChange("recipientCity", "recipientDeliveryAddress")}
          />
          <CitySelectField
            fieldName="recipientCity"
            label={t("orderCreation.clientPartSelectCity")}
            tooltipMessage={t("tooltips.noCourierDelivery")}
            checkedField="courierDeliveryEnabled"
            region={recipientRegion}
          />
          <Field
            as={TextField}
            name="recipientDeliveryAddress"
            label={t("orderCreation.clientPartWriteAddress")}
            value={recipientDeliveryAddress}
            error={touched.recipientDeliveryAddress && Boolean(errors.recipientDeliveryAddress)}
            helperText={touched.recipientDeliveryAddress && errors.recipientDeliveryAddress}
            variant="standard"
            placeholder={t("placeholders.address")}
            className={fieldBaseStyle}
            inputProps={{ maxLength: 255 }}
          />
        </Box>
      </Box>
    </>
  );
};
