/**
 * Converts given filter parameters to respective registers API query params.
 * @param pickupCity
 * @param pickupAddress
 * @param pickupPostOffice
 * @returns {GetAvailableOrdersByAddress|GetAvailableOrdersByPostOffice}
 */
export const toGetAvailableOrdersQuery = ({ pickupCity, pickupAddress, pickupPostOffice }) => {
  if (pickupPostOffice) {
    return { post_office_sender_guid: pickupPostOffice };
  }
  return {
    departure_city_id: pickupCity,
    departure_address: pickupAddress,
  };
};
