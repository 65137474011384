import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../providers/AuthProvider/AuthProvider";
import { Box, ListItemText, Typography } from "@mui/material";
import { AppVersion } from "../appVersion/AppVersion";
import { FooterLink } from "./FooterLink";
import { FeedbackBtn } from "../feedbackBtn/FeedbackBtn";
import { getPublicApiUrl } from "../publicApiUrl";

const Footer = () => {
  const { t } = useTranslation();
  const publicApiLink = getPublicApiUrl();
  const { user } = useAuth();
  const homeLink = user ? "pagesTitles.myOrdersTitle" : "loginButton";

  return (
    <>
      <Box className="md:h-10 hidden md:block"> {/* This Box acts as a spacer */}</Box>
      <Box className="md:fixed md:inset-x-0 md:bottom-0 md:px-5 flex-wrap shadow-inner md:h-10 bg-color-main md:bg-white flex items-center justify-center ">
        <Box className="flex justify-center  flex-wrap md:space-x-3">
          <Link
            to="/"
            className={"text-black px-3 md:py-2 no-underline hover:underline underline-offset-8"}
          >
            <ListItemText className={"whitespace-nowrap"}>{t(homeLink)}</ListItemText>
          </Link>
          <FooterLink link={publicApiLink} text="footer.forDevelopers" />
          <FooterLink link="https://delivo.ge/" text="footer.delivoWebsite" />
          <FooterLink
            link="https://delivo.ge/momsakhurebis-pirobebi/"
            text="footer.termsConditions"
          />
          <AppVersion />
          <Box className="flex align-center md:py-2 md:absolute md:right-5 md:900:bottom-2 xl:pr-3">
            <FeedbackBtn />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
