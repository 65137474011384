import React, { useCallback } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useNewsItem } from "../../hooks/useNewsItem";
import LoadingBackdrop from "../loadingBackdrop/LoadingBackdrop";
import { useTranslation } from "react-i18next";
import { formatGeDateTime } from "../dates";
import { Breadcrumb } from "../breadcrumb/Breadcrumb";
import { HtmlContentDisplay } from "../htmlContentDisplay/HtmlContentDisplay";

/**
 * Displays detailed information about a specific news item.
 *
 * This component retrieves and presents detailed information regarding a news item identified by the 'slug' parameter in the URL.
 * It uses `useParams` from 'react-router-dom' to obtain the 'slug' parameter.
 * Additionally, it utilizes `useLocation` to access any initial news data that may have been passed via route state (`location.state.detail`).
 * If initial data is available, it's passed directly to the custom hook `useNewsItem`.
 * If no initial data is provided, the component fetches the news item data from the API endpoint `/news/{slug}` using `useNewsItem`.
 * This approach minimizes unnecessary API calls, especially when navigating from a news list where initial data might already exist.
 *
 * @returns {NewsItem} A React component that presents detailed information about a news item, offering navigation back to the news list page.
 */
function NewsItem() {
  const { t } = useTranslation();
  const { slug } = useParams();
  const location = useLocation();
  const initialNews = location.state?.detail || null;
  const { newsItem, loading } = useNewsItem({ slug, initialNews });
  const navigate = useNavigate();
  const page = location.state?.page || 1;
  const newsPageLink = `/news?page=${page}`;

  const handleGoBack = useCallback(() => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(newsPageLink);
    }
  }, [navigate, newsPageLink]);

  return (
    <Box className={"shadow-lg rounded-md p-4"}>
      <LoadingBackdrop isVisible={loading} />
      <Breadcrumb href={newsPageLink} text={newsItem.title} label={t("pagesTitles.newsTitle")} />
      <Box className="p-3 flex flex-col items-start">
        <Box className={"px-2"}>
          <Typography variant="h5" className="font-bold mb-4">
            {newsItem.title}
          </Typography>
          <HtmlContentDisplay content={newsItem.fullMessage} />
          <Typography className="text-gray-400 mb-4" variant="caption">
            {formatGeDateTime(newsItem.publishingTime)}
          </Typography>
        </Box>
        <Button
          onClick={handleGoBack}
          className="mt-4 text-black lowercase underline hover:underline-offset-2 md:text-lg py-4"
        >
          {t("back")}
        </Button>
      </Box>
    </Box>
  );
}

export default NewsItem;
