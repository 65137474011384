import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Box, Button, Divider, Step, StepLabel, Stepper, Typography } from "@mui/material";
import ClientInformation from "./clientInformation/ClientInformation";
import ParcelInformation from "./ParcelInformation";
import PricesInfo from "./PricesInfo";
import { useTranslation } from "react-i18next";
import { CalculateOrderCosts, CreateOrderRequest, OrderRequest } from "../../api/orderTypes";
import { ordersApi } from "../../api/DelivoOrdersApi";
import { DELIVERY_METHOD, PARCEL_TYPE, PAYMENT_TYPE, RECIPIENT_TYPE } from "../constants";
import ButtonComponent from "../buttonComponent/ButtonComponent";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { useOrder } from "../../hooks/useOrder";
import CloseIcon from "@mui/icons-material/Close";
import { useConfirm } from "material-ui-confirm";
import { handleConfirmation } from "../handleConfirmation";
import { emptyInitialValues, initialValuesFromAPI } from "./initialValuesOrder";
import LoadingBackdrop from "../loadingBackdrop/LoadingBackdrop";
import { isFragile, isInsured, prepareDimensions } from "./checkers";
import showApiErrorNotification from "../showApiErrorNotification";

/**
 * Initializes organizations representative based on the data.
 * @param {OrderCreationInitialValues} data - The initial values data.
 * @returns {object} Object containing recipient organizations representative property.
 */
const initializeOrganizationsRepresentative = (data) => {
  let recipientOrganizationsRepresentative = "";

  if (data.recipientType === RECIPIENT_TYPE.COMPANY) {
    recipientOrganizationsRepresentative = `${data.recipientName} ${data.recipientSurname}`;
  }

  return { recipientOrganizationsRepresentative };
};

const toCreateOrderRequest = (data) => {
  const pickupAddress =
    data.pickupMethod === DELIVERY_METHOD.DEPARTMENT ? null : data.pickupAddress;
  const recipientDeliveryAddress =
    data.deliveryMethod === DELIVERY_METHOD.DEPARTMENT ? null : data.recipientDeliveryAddress;
  const recipientPostOffice =
    data.deliveryMethod === DELIVERY_METHOD.COURIER ? null : data.recipientPostOffice;

  const { recipientOrganizationsRepresentative } = initializeOrganizationsRepresentative(data);
  const isDocumentParcel = data.parcelType === PARCEL_TYPE.DOCUMENTS;
  const [firstName, ...lastNameArray] = data.recipientNameSurname.trim().split(/\s+/);
  const lastName = lastNameArray.join(" ");

  const recipient = {
    first_name: firstName,
    last_name: lastName,
    organization_name: data.recipientCompanyName,
    gov_number: data.recipientGovNumber,
    phone: `+995${data.recipientPhone}`,
    address: recipientDeliveryAddress,
    is_individual_person: data.recipientType === RECIPIENT_TYPE.INDIVIDUAL,
    post_office_guid: recipientPostOffice,
  };
  const { dimensions } = prepareDimensions(isDocumentParcel, data);
  const postOfficeSender =
    data.pickupMethod === DELIVERY_METHOD.COURIER ? null : data.pickupPostOffice;
  const sendingDocuments = isDocumentParcel;
  const paymentRecipient = data.paymentType === PAYMENT_TYPE.RECIPIENT;
  const fragile = isFragile(isDocumentParcel, data.fragile);
  const insured = isInsured(isDocumentParcel, data.insured);
  const estimatedValue = data.estimatedValue || "0";
  const estimatedCost = parseFloat(estimatedValue);
  const cod = data.cod || false;
  const codByRecipient = data.codCommissionByRecipient === PAYMENT_TYPE.RECIPIENT;

  return new CreateOrderRequest({
    recipient: recipient,
    description: data.parcelDescription,
    client_custom_attribute: data.parcelClientCustomAttribute,
    departure_city_id: data.pickupCity,
    destination_city_id: data.recipientCity,
    post_office_sender_guid: postOfficeSender,
    sender_address: pickupAddress,
    sending_documents: sendingDocuments,
    payment_by_recipient: paymentRecipient,
    estimated_cost: estimatedCost,
    delivery_cost: parseFloat(data.deliveryCost),
    fragile: fragile,
    insured: insured,
    insurance_cost: parseFloat(data.insuranceCost),
    cod: cod,
    cod_commission_amount: parseFloat(data.codCommissionAmount),
    dimensions: dimensions,
    weight: parseFloat(data.parcelWeight) || 0,
    recipient_organizations_representative: recipientOrganizationsRepresentative,
    cod_by_recipient: codByRecipient,
    international_tracking_number: data.internationalTrackingNumber,
  });
};

const toUpdateOrderRequest = (data) => {
  const recipientDeliveryAddress =
    data.deliveryMethod === DELIVERY_METHOD.DEPARTMENT ? null : data.recipientDeliveryAddress;
  const recipientPostOffice =
    data.deliveryMethod === DELIVERY_METHOD.COURIER ? null : data.recipientPostOffice;

  const organizationName =
    data.recipientType === RECIPIENT_TYPE.COMPANY
      ? data.recipientCompanyName
      : `${data.recipientName} ${data.recipientSurname}`;

  const [firstName, ...lastNameArray] = data.recipientNameSurname.trim().split(/\s+/);
  const lastName = lastNameArray.join(" ");

  const recipient = {
    first_name: firstName,
    last_name: lastName,
    organization_name: organizationName,
    gov_number: data.recipientGovNumber,
    phone: `+995${data.recipientPhone}`,
    address: recipientDeliveryAddress,
    is_individual_person: data.recipientType === RECIPIENT_TYPE.INDIVIDUAL,
    post_office_guid: recipientPostOffice,
  };
  const isDocumentParcel = data.parcelType === PARCEL_TYPE.DOCUMENTS;
  const { dimensions } = prepareDimensions(isDocumentParcel, data);
  const sendingDocuments = isDocumentParcel;
  const paymentRecipient = data.paymentType === PAYMENT_TYPE.RECIPIENT;
  const fragile = isFragile(isDocumentParcel, data.fragile);
  const insured = isInsured(isDocumentParcel, data.insured);
  const estimatedValue = data.estimatedValue || "0";
  const estimatedCost = parseFloat(estimatedValue);
  const cod = data.cod || false;
  const { recipientOrganizationsRepresentative } = initializeOrganizationsRepresentative(data);
  const codByRecipient = data.codCommissionByRecipient === PAYMENT_TYPE.RECIPIENT;

  return new OrderRequest({
    recipient: recipient,
    description: data.parcelDescription,
    client_custom_attribute: data.parcelClientCustomAttribute,
    destination_city_id: data.recipientCity,
    sending_documents: sendingDocuments,
    payment_by_recipient: paymentRecipient,
    estimated_cost: estimatedCost,
    delivery_cost: parseFloat(data.deliveryCost),
    fragile: fragile,
    insured: insured,
    insurance_cost: parseFloat(data.insuranceCost),
    cod: cod,
    cod_commission_amount: parseFloat(data.codCommissionAmount),
    dimensions: dimensions,
    weight: parseFloat(data.parcelWeight) || 0,
    recipient_organizations_representative: recipientOrganizationsRepresentative,
    cod_by_recipient: codByRecipient,
    international_tracking_number: data.internationalTrackingNumber,
  });
};

const toCalculateOrderCosts = (data) => {
  const isDocumentParcel = data.parcelType === PARCEL_TYPE.DOCUMENTS;
  const destinationCityId = data.recipientPostOfficeCity || data.recipientDeliveryAddressCity;
  const sendingDocuments = isDocumentParcel;
  const insured = isInsured(isDocumentParcel, data.insured);
  const cod = data.cod || false;
  const estimatedValue = data.estimatedValue || "0";
  const estimatedCost = parseFloat(estimatedValue);
  const courierPickup = data.pickupMethod === DELIVERY_METHOD.COURIER;
  const courierDelivery = data.deliveryMethod === DELIVERY_METHOD.COURIER;
  const { dimensions, weight } = prepareDimensions(isDocumentParcel, data);
  return new CalculateOrderCosts({
    departure_city_id: data.pickupCity,
    destination_city_id: data.recipientCity,
    courier_pickup: courierPickup,
    courier_deliver: courierDelivery,
    sending_documents: sendingDocuments,
    insured: insured,
    cod: cod,
    estimated_cost: estimatedCost,
    ...dimensions,
    weight: weight,
  });
};

export default function OrderCreationWizard({ isUpdating }) {
  const { t } = useTranslation();

  const steps = [ClientInformation, ParcelInformation, PricesInfo];
  const { trackingCode } = useParams();
  const { order, loading } = useOrder(trackingCode);
  const [activeStep, setActiveStep] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const initialValues = isUpdating ? initialValuesFromAPI(order) : emptyInitialValues;

  const handleGoBack = () => {
    navigate("/");
  };

  const handleCloseConfirmation = async () => {
    const confirmationDetails = {
      description: t("confirmation.confirmClose"),
    };
    await handleConfirmation(confirm, confirmationDetails, handleGoBack, t);
  };

  const updateOrder = async (values, resetForm, enqueueSnackbar) => {
    const updateOrderRequest = toUpdateOrderRequest(values);

    try {
      const updatedOrder = await ordersApi.updateOrder(order.trackingCode, updateOrderRequest);
      console.info("The order successfully updated.", updatedOrder);

      enqueueSnackbar(t("orderUpdating.orderUpdated"), {
        variant: "success",
      });
      navigate(`/orders/${order.trackingCode}/`);
    } catch (e) {
      showApiErrorNotification(t("alertsMessages.failedUpdateOrder"), e, enqueueSnackbar);
      console.error("Failed to update an order.", e);
    }
  };

  const createOrder = async (values, resetForm, enqueueSnackbar) => {
    const createOrderRequest = toCreateOrderRequest(values);
    try {
      const createdOrder = await ordersApi.createOrder(createOrderRequest);
      console.info("A new order successfully created.", createdOrder);
      const navigateNewTab = () =>
        window.open(`/orders/${createdOrder.trackingCode}/`, "_blank", "noopener noreferrer");

      enqueueSnackbar(t("orderCreation.orderCreated"), {
        variant: "success",
        action: <Button onClick={navigateNewTab}>{createdOrder.trackingCode}</Button>,
      });
      setActiveStep(0);
      resetForm();
    } catch (e) {
      showApiErrorNotification(t("alertsMessages.failedCreateOrder"), e, enqueueSnackbar);
      console.error("Failed to create an order.", e);
    }
  };

  const onSubmit = async (values, formikBag) => {
    const { setSubmitting, resetForm } = formikBag;

    if (activeStep === steps.indexOf(ParcelInformation)) {
      console.debug("Retrieving order costs.");
      const calculateOrderCosts = toCalculateOrderCosts(values);

      try {
        const fetchedCostsAmount = await ordersApi.getOrdersCosts(calculateOrderCosts);

        const { insuranceCost, codCommissionAmount, deliveryCost, estimatedCost } =
          fetchedCostsAmount;

        console.debug("Costs Amount Response:", fetchedCostsAmount);
        values.insuranceCost = insuranceCost;
        values.codCommissionAmount = codCommissionAmount;
        values.deliveryCost = deliveryCost;
        values.estimatedCost = estimatedCost;
      } catch (error) {
        showApiErrorNotification(t("alertsMessages.failedGetAmounts"), error, enqueueSnackbar);
        console.error("Error fetching costs amount:", error);
      }
    }
    if (!isLastStep()) {
      console.debug("Proceeding to the next step.");
      setSubmitting(false);
      handleNext();
      return;
    }

    if (isUpdating) {
      await updateOrder(values, resetForm, enqueueSnackbar);
    } else {
      await createOrder(values, resetForm, enqueueSnackbar);
    }

    setSubmitting(false);
  };

  const ActiveStep = steps[activeStep];
  const validationSchema = ActiveStep.validationSchema(t);

  return (
    <>
      <LoadingBackdrop isVisible={isUpdating && loading} />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ isSubmitting, touched, values, errors }) => (
          <>
            <Form className={`shadow-lg`}>
              {isUpdating && (
                <>
                  <Box className={"flex items-center justify-between my-3 mx-6"}>
                    <Typography className={"p-1.5"} variant={"h5"}>
                      {t("orderUpdating.orderEditingTitle")}: {order.trackingCode}
                    </Typography>
                    <CloseIcon
                      className={"text-gray-700 cursor-pointer"}
                      onClick={handleCloseConfirmation}
                    />
                  </Box>
                  <Divider orientation={"horizontal"} flexItem />
                </>
              )}
              <Stepper alternativeLabel activeStep={activeStep} className={"m-4"}>
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepLabel>{t(step.label)}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box>
                <ActiveStep isUpdating={isUpdating} order={order} />
              </Box>
              <Box className={"flex justify-between sm:p-2"}>
                <ButtonComponent
                  variant="text"
                  text={t("orderCreation.stepButtonBack")}
                  disabled={activeStep === 0 || isSubmitting}
                  onClick={handlePrev}
                />
                <ButtonComponent
                  disabled={isSubmitting}
                  type="submit"
                  text={
                    isLastStep()
                      ? `${t("orderCreation.stepButtonSubmit")}`
                      : `${t("orderCreation.stepButtonNext")}`
                  }
                />
              </Box>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
