import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * PaymentBlockWithChip Component
 *
 * This component is used to display a payment information block with a chip.
 * It takes in a title, type, and label as props and displays them in a structured format.
 * The component uses responsive styles to adjust the layout based on screen size.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.title - The title text to display.
 * @param {string} props.type - The type or amount of the payment.
 * @param {string} props.label - The label text for the chip.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const PaymentBlockWithChip = ({ title, type, label }) => {
  const { t } = useTranslation();

  return (
    <Box
      className={
        "flex items-center xl:w-1/2 smMax:w-full md:w-full sm:flex-wrap sm:justify-stretch"
      }
    >
      <Typography className={"text-gray-500 py-2 smMax:text-xs smMax:w-auto"}>
        {title}:
      </Typography>
      <Typography className={"p-2 smMax:px-1 smMax:text-sm smMax:w-auto"}>
        {type} {t("orderTracking.gel")}
      </Typography>
      <Chip className={"smMax:text-xs smMax:p-0.5 smMax:m-1 smMax:w-auto"} label={label} />
    </Box>
  );
};
