import { useEffect, useState } from "react";
import { addressApi } from "../api";

export function useAddresses() {
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const addressesData = await addressApi.getAddresses();
        setAddresses(addressesData);
      } catch (error) {
        console.error("Error fetching addresses", error);
      }
    };
    fetchAddresses();
  }, []);

  const addAddress = async (newAddress) => {
    const addedAddress = await addressApi.addAddress(newAddress);
    setAddresses((prevAddresses) => [...prevAddresses, addedAddress]);
    return addedAddress;
  };

  const updateAddress = async (updatedAddress) => {
    const updatedData = await addressApi.updateAddress(updatedAddress);
    setAddresses((prevAddresses) =>
      prevAddresses.map((address) => (address.id === updatedData.id ? updatedData : address)),
    );
    console.info("Address updated", updatedData);
    return updatedData;
  };

  const deleteAddress = async (id) => {
    const addressDeleted = await addressApi.deleteAddress(id);
    setAddresses((prevAddresses) => prevAddresses.filter((address) => address.id !== id));
    return addressDeleted;
  };

  return { addresses, addAddress, deleteAddress, updateAddress };
}
