import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, TextField, Typography, useMediaQuery } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { boolean, object, string } from "yup";
import "yup-phone-lite";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../recaptcha-config";
import { delivoApi } from "../../api";
import {
  OrganizationRegistrationRequest,
  ReCAPTCHAVerification,
  RegisterOrganization,
} from "../../api/types";
import ButtonComponent from "../buttonComponent/ButtonComponent";
import { useSnackbar } from "notistack";
import LoadingBackdrop from "../loadingBackdrop/LoadingBackdrop";
import showApiErrorNotification from "../showApiErrorNotification";
import { TermsCheckboxes } from "./TermsCheckboxes";
import { CitySelectField } from "../selectFields/CitySelectField";
import { TooltipWrapper } from "../tooltipWrapper/TooltipWrapper";

/**
 * Converts given form data into a structured org registration info.
 * @param data
 * @returns {RegisterOrganization}
 */
const preparePostData = (data) => {
  return new RegisterOrganization({
    name: data.name,
    gov_number: data.gov_number,
    phone: data.phone ? `+${data.phone}` : "",
    email: data.email.toLowerCase(),
    legal_address: data.legal_address,
    owner_name: data.owner_name,
    address: data.address,
    contact_name: data.contact_name,
    contact_phone: `+${data.contact_phone}`,
    userEmail: data.userEmail.toLowerCase(),
    actual_address_city: data.actual_address_city,
    public_name: data.public_name,
    public_phone: data.public_phone,
    public_address: data.public_address,
    tos_accepted: data.tos_accepted,
    pd_processing_accepted: data.pd_processing_accepted,
  });
};
const initialValues = {
  name: "",
  gov_number: "",
  phone: "",
  email: "",
  legal_address: "",
  owner_name: "",
  address: "",
  contact_name: "",
  contact_phone: "",
  userEmail: "",
  actual_address_city: "",
  public_name: "",
  public_phone: "",
  public_address: "",
  tos_accepted: false,
  pd_processing_accepted: false,
};

function RegistrationForm() {
  const { t, i18n } = useTranslation();
  const captchaRef = useRef();
  const [language, setLanguage] = useState(i18n.language);
  const onLanguageChanged = useCallback(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);
  useEffect(() => {
    i18n.on("languageChanged", onLanguageChanged);
    return () => {
      i18n.off("languageChanged", onLanguageChanged);
    };
  }, [onLanguageChanged, i18n]);

  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = object({
    name: string().max(150, t("errorsMessages.maxCompanyNameLength")),
    gov_number: string()
      .required(t("errorsMessages.companyIDError"))
      .matches(/^\d{9,11}/, t("errorsMessages.companyIDLength")),
    phone: string()
      .phone("GE", t("errorsMessages.phoneNumberValidation"))
      .matches(/^\d{12}$/, t("errorsMessages.phoneNumberValidation")),
    legal_address: string().max(255, t("errorsMessages.maxAddressLength")),
    owner_name: string(),
    address: string().max(255, t("errorsMessages.maxAddressLength")),
    contact_name: string().required(t("errorsMessages.userNameRequire")),
    contact_phone: string()
      .phone("GE", t("errorsMessages.phoneNumberValidation"))
      .matches(/^\d{12}$/, t("errorsMessages.phoneNumberValidation"))
      .required(t("errorsMessages.phoneNumberRequire")),
    email: string()
      .required(t("errorsMessages.emailRequire"))
      .email(t("errorsMessages.emailValidation"))
      .max(125, t("errorsMessages.maxEmailLength")),
    userEmail: string()
      .email(t("errorsMessages.emailValidation"))
      .max(125, t("errorsMessages.maxEmailLength")),
    actual_address_city: string()
      .required(t("errorsMessages.actualAddressCityRequire"))
      .max(30, t("errorsMessages.maxCityLength")),
    public_phone: string()
      .phone("GE", t("errorsMessages.phoneNumberValidation"))
      .matches(/^\d{12}$/, t("errorsMessages.phoneNumberValidation")),
    public_address: string().max(255, t("errorsMessages.maxAddressLength")),
    public_name: string().max(50, t("errorsMessages.maxNameLength")),
    tos_accepted: boolean().oneOf([true], t("errorsMessages.acceptTerm")).required(),
    pd_processing_accepted: boolean()
      .oneOf([true], t("errorsMessages.acceptPersonalData"))
      .required(),
  });

  const fieldStyle = "w-full sm:w-[47%] md:w-5/12  md:m-1 flex flex-col";
  const isSmallScreen = useMediaQuery("(max-width:385px)");
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        const recaptchaToken = captchaRef.current.getValue();
        if (!recaptchaToken) {
          enqueueSnackbar(t("errorsMessages.recaptchaInvalid"), { variant: "error" });

          //TODO:2023-08-27:yuri-sergiichuk: add proper error handling on the UI.
          throw new Error(
            "reCAPTCHA verification token is not available. Can't proceed with the registration.",
          );
        }
        const recaptcha = new ReCAPTCHAVerification(recaptchaToken, "register_organization");
        const registerOrganization = preparePostData(values);
        const request = new OrganizationRegistrationRequest(recaptcha, registerOrganization);
        try {
          const registeredOrganization = await delivoApi.registerOrganisation(request);
          console.info("A new organization successfully registered.", registeredOrganization);
          enqueueSnackbar(t("alertsMessages.successRegistrationFormResponse"), {
            variant: "success",
          });
          actions.resetForm();
        } catch (error) {
          const errorMes = error.response.data;
          showApiErrorNotification(
            t("alertsMessages.failedFinishRegistration"),
            error,
            enqueueSnackbar,
          );
          if (errorMes && errorMes.errors) {
            errorMes.errors.forEach(({ attr, detail }) => {
              switch (attr) {
                case "organization.gov_number":
                  actions.setFieldError("gov_number", t("errorsMessages.govNumberExist"));
                  break;
                case "organization.email":
                  actions.setFieldError("email", t("errorsMessages.emailExist"));
                  break;
                case "organization.contact_phone":
                  actions.setFieldError("contact_phone", t("errorsMessages.phoneExist"));
                  break;
                default:
                  console.error(`Error: ${attr} - ${detail}`);
                  break;
              }
            });
          }
          captchaRef.current.reset();
        }
      }}
    >
      {({ errors, touched, isSubmitting, values, setFieldValue, handleChange }) => (
        <Form className={"flex flex-col items-center p-2 "}>
          <Typography variant="h4" className={"mb-4"}>
            {t("registrationForm.title")}
          </Typography>
          <Box className={"flex flex-wrap justify-between md:justify-evenly max-w-full"}>
            <Field
              as={TextField}
              error={Boolean(errors.name) && Boolean(touched.name)}
              helperText={(Boolean(touched.name) && errors.name) || " "}
              label={t("registrationForm.companyNameFieldLabel")}
              name="name"
              placeholder={t("placeholders.companyName")}
              className={fieldStyle}
              inputProps={{ maxLength: 150 }}
            />
            <Box className={fieldStyle}>
              <Field
                as={TextField}
                error={Boolean(errors.gov_number) && Boolean(touched.gov_number)}
                helperText={(Boolean(touched.gov_number) && errors.gov_number) || " "}
                label={`*${t("registrationForm.companyIDFieldLabel")}`}
                name="gov_number"
                type="text"
                placeholder="123456789"
                inputProps={{ maxLength: 11 }}
                variant="outlined"
              />
            </Box>
            <Box className={fieldStyle}>
              <Field
                as={TextField}
                error={Boolean(errors.email) && Boolean(touched.email)}
                helperText={(Boolean(touched.email) && errors.email) || " "}
                label={`*${t("registrationForm.emailFieldLabel")}`}
                name="email"
                placeholder="example@mail.com"
                type="email"
                inputProps={{ maxLength: 125 }}
              />
            </Box>
            <Field
              as={TextField}
              error={Boolean(errors.phone) && Boolean(touched.phone)}
              helperText={(Boolean(touched.phone) && errors.phone) || " "}
              label={t("registrationForm.companyPhoneNumberFieldLabel")}
              name="phone"
              placeholder="995325000010"
              className={fieldStyle}
              inputProps={{ maxLength: 12 }}
            />
            <Field
              as={TextField}
              error={Boolean(errors.legal_address) && Boolean(touched.legal_address)}
              helperText={(Boolean(touched.legal_address) && errors.legal_address) || " "}
              label={t("registrationForm.legalAddressFieldLabel")}
              name="legal_address"
              placeholder={t("placeholders.address")}
              className={fieldStyle}
              inputProps={{ maxLength: 255 }}
            />
            <Field
              as={TextField}
              error={Boolean(errors.owner_name) && Boolean(touched.owner_name)}
              helperText={(Boolean(touched.owner_name) && errors.owner_name) || " "}
              label={t("registrationForm.managerFieldLabel")}
              name="owner_name"
              placeholder={t("placeholders.contactName")}
              className={fieldStyle}
              inputProps={{ maxLength: 100 }}
            />
            <Box className={fieldStyle}>
              <CitySelectField
                fieldName={"actual_address_city"}
                label={t("registrationForm.actualAddressCity")}
                className={"w-full mb-6"}
              />
            </Box>
            <Field
              as={TextField}
              error={Boolean(errors.address) && Boolean(touched.address)}
              helperText={(Boolean(touched.address) && errors.address) || " "}
              label={t("registrationForm.factAddressFieldLabel")}
              name="address"
              placeholder={t("placeholders.address")}
              className={fieldStyle}
              inputProps={{ maxLength: 255 }}
            />
            <Field
              as={TextField}
              error={Boolean(errors.contact_name) && Boolean(touched.contact_name)}
              helperText={(Boolean(touched.contact_name) && errors.contact_name) || " "}
              label={`*${t("registrationForm.nameContactPersonFieldLabel")}`}
              name="contact_name"
              placeholder={t("placeholders.contactName")}
              className={fieldStyle}
              inputProps={{ maxLength: 100 }}
            />
            <Field
              as={TextField}
              error={Boolean(errors.contact_phone) && Boolean(touched.contact_phone)}
              helperText={(Boolean(touched.contact_phone) && errors.contact_phone) || " "}
              label={`*${t("registrationForm.phoneNumberContactPersonFieldLabel")}`}
              name="contact_phone"
              placeholder="995325000010"
              className={fieldStyle}
              inputProps={{ maxLength: 12 }}
            />
            <Field
              as={TextField}
              error={Boolean(errors.userEmail) && Boolean(touched.userEmail)}
              helperText={(Boolean(touched.userEmail) && errors.userEmail) || " "}
              label={t("registrationForm.emailContactPersonFieldLabel")}
              name="userEmail"
              placeholder="example@mail.com"
              type="email"
              className={fieldStyle}
              inputProps={{ maxLength: 125 }}
            />
            <TooltipWrapper title={t("tooltips.publicNameEnabled")} placement="top">
              <span className={fieldStyle}>
                <Field
                  as={TextField}
                  error={Boolean(errors.public_name) && Boolean(touched.public_name)}
                  helperText={(Boolean(touched.public_name) && errors.public_name) || " "}
                  label={t("registrationForm.publicName")}
                  name="public_name"
                  placeholder={t("placeholders.contactName")}
                  value={values.public_name}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </TooltipWrapper>
            <TooltipWrapper title={t("tooltips.publicPhoneEnabled")} placement="top">
              <span className={fieldStyle}>
                <Field
                  as={TextField}
                  error={Boolean(errors.public_phone) && Boolean(touched.public_phone)}
                  helperText={(Boolean(touched.public_phone) && errors.public_phone) || " "}
                  label={t("registrationForm.publicPhone")}
                  name="public_phone"
                  placeholder="995325000010"
                  value={values.public_phone}
                  inputProps={{ maxLength: 12 }}
                />
              </span>
            </TooltipWrapper>
            <TooltipWrapper title={t("tooltips.publicAddressEnabled")} placement="top">
              <span className={fieldStyle}>
                <Field
                  as={TextField}
                  error={Boolean(errors.public_address) && Boolean(touched.public_address)}
                  helperText={(Boolean(touched.public_address) && errors.public_address) || " "}
                  label={t("registrationForm.publicAddress")}
                  name="public_address"
                  placeholder={t("placeholders.address")}
                  value={values.public_address}
                  inputProps={{ maxLength: 255 }}
                />
              </span>
            </TooltipWrapper>
            <Box className={"flex-col start-0 ml-0 w-11/12 md:w-10/12 pb-2"}>
              <TermsCheckboxes
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </Box>
            <Typography variant="caption" className={"w-11/12 md:w-10/12 text-xs text-gray-400"}>
              {t("registrationForm.agreement")}
            </Typography>
            <div className={"flex flex-col items-center justify-center w-full my-4 sm:m-4"}>
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                ref={captchaRef}
                size={isSmallScreen ? "compact" : "normal"}
                //TODO:2023-08-27:yuri-sergiichuk: Language does not change dynamically.
                // See https://github.com/dfl-group/delivo-business-portal-frontend/issues/13
                hl={language}
                onChange={(values) => console.debug("reCAPTCHA completed", values)}
                onErrored={(values) => {
                  console.error("reCAPTCHA errored", values);
                  enqueueSnackbar(t("alertsMessages.recaptchaError"), { variant: "error" });
                }}
                onExpired={(values) => {
                  console.warn(
                    "reCAPTCHA challenge expired. Another verification required.",
                    values,
                  );
                  enqueueSnackbar(t("alertsMessages.recaptchaExpired"), {
                    variant: "warning",
                  });
                }}
              />
            </div>
          </Box>
          <Box className={"flex flex-col items-center justify-center sm:mx-4"}>
            <LoadingBackdrop isVisible={isSubmitting} />
            <ButtonComponent
              text={t("registrationForm.applyButton")}
              type="submit"
              disabled={isSubmitting}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
}

export default RegistrationForm;
