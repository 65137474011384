import React, { useCallback, useMemo } from "react";
import { ListItemText, ListItemIcon, TextField, Autocomplete, MenuItem } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useFormikContext, Field, useField } from "formik";
import { useLocationsContext } from "../../providers/LocationsProvider/LocationsProvider";
import { getFormFieldHelperText } from "../getFormFieldHelperText";
import { useTranslation } from "react-i18next";
import { leftFieldStyle } from "../orderCreationWizard/tailwindClasses";
import { TooltipWrapper } from "../tooltipWrapper/TooltipWrapper";

/**
 * `CitySelectField` renders an autocomplete field that allows users to select a city from a list.
 * This component is designed to be flexible, supporting custom onChange handlers, conditional
 * rendering of tooltips for city options, and customizable styling.
 *
 * @param {Object} props - The component props.
 * @param {string} props.fieldName - The name attribute for the Formik field.
 * @param {string} props.label - The label for the autocomplete input field.
 * @param {string} [props.tooltipMessage=''] - Optional message to display in a tooltip for cities that are not selectable based on `checkedField`.
 * @param {Function} [props.customOnChange] - Optional custom onChange handler that is called when the selected city changes.
 * @param {string} props.checkedField - The name of the field in city objects that determines if a city is selectable or should display a tooltip.
 * @param {string} [props.className="w-1/3 mr-11"] - Optional CSS class for the autocomplete component, allowing for custom styling.
 * @param {Object} [props.textFieldStyles] - Optional style object that can be passed to the `sx` prop of the MUI `TextField` for custom inline styling.
 *
 * @returns `CitySelectField` component renders a select field with city options based on the context.
 */
export const CitySelectField = ({
  fieldName,
  label,
  tooltipMessage = "",
  customOnChange,
  checkedField,
  className = leftFieldStyle,
  textFieldStyles,
  region,
}) => {
  const { setFieldValue, values, touched, errors } = useFormikContext();
  const [field] = useField(fieldName);
  const { t } = useTranslation();
  const { cities, getCityById, regionCitiesMap, loading } = useLocationsContext();

  const initialValue = useMemo(
    () => (field.value ? getCityById(field.value) : null),
    [field.value, getCityById],
  );
  const resultCities = region ? regionCitiesMap.get(region) || [] : cities;

  const handleChange = useCallback(
    (event, newValue) => {
      setFieldValue(fieldName, newValue ? newValue.id : "");
      if (customOnChange) {
        customOnChange(newValue);
      }
    },
    [setFieldValue, fieldName, customOnChange],
  );

  const helperText = getFormFieldHelperText({
    requestMade: !loading,
    identifier: region,
    items: resultCities,
    field: fieldName,
    touched: touched,
    errors: errors,
    noItemsText: t("tooltips.noCitiesInRegion"),
  });

  const renderOption = useCallback(
    (props, option) => {
      return option[checkedField] ? (
        <li {...props} key={option.id}>
          {option.city}
        </li>
      ) : (
        <TooltipWrapper title={tooltipMessage} key={option.id}>
          <div>
            <MenuItem value={option.id} disabled={!option[checkedField]}>
              <ListItemText>{option.city}</ListItemText>
              <ListItemIcon>
                <InfoIcon fontSize="small" />
              </ListItemIcon>
            </MenuItem>
          </div>
        </TooltipWrapper>
      );
    },
    [checkedField, tooltipMessage],
  );

  return (
    <Autocomplete
      value={initialValue}
      onChange={handleChange}
      options={resultCities}
      noOptionsText={t("tooltips.noOptions")}
      getOptionLabel={(option) => option.city || ""}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      className={className}
      renderOption={tooltipMessage ? renderOption : undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={tooltipMessage ? "standard" : "outlined"}
          sx={textFieldStyles}
          error={touched[fieldName] && Boolean(errors[fieldName])}
          helperText={helperText}
          inputProps={{
            ...params.inputProps,
            maxLength: 30,
            ...textFieldStyles,
          }}
        />
      )}
    />
  );
};
