import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { delivoApi } from "../api";
import showApiErrorNotification from "../components/showApiErrorNotification";
import { useSearchParams } from "react-router-dom";
import { getLocalizedText } from "../components/getLocalizedText";
import { useItemsPerScreenSize } from "./useItemsPerScreenSize";

/**
 * FAQ items per screen mapping.
 * @type {Object}
 * @property {number} sm - Number of items for small screens(599px and less)).
 * @property {number} lg - Number of items for large screens (600px - 1535px).
 * @property {number} xl - Number of items for extra-large screens (1536px- 2559px).
 * @property {number} xxl - Number of items for extra-extra-large screens (2560px and bigger).
 * @property {number} default - Default number of items .
 */
const faqItemsPerScreen = {
  sm: 3,
  lg: 4,
  xl: 6,
  xxl: 10,
  default: 10,
};

/**
 * Localizes the content of an FAQ item based on the specified language.
 * This function converts all text fields of an FAQ item and its related items to the target language.
 *
 * @param {object} faqItem - The FAQ item to localize. This object should contain the original
 * text fields in multiple languages and a function `getLocalizedText` to perform the localization.
 * @param {string} language - The language code to localize the FAQ item into (e.g., 'en', 'ka').
 * @returns {object} The localized FAQ item, including its related items, with all text fields
 * localized to the specified language.
 */
export function localizeFAQ(faqItem, language) {
  return {
    ...faqItem,
    question: getLocalizedText(faqItem.questionEn, faqItem.questionKa, language),
    previewMessage: getLocalizedText(
      faqItem.previewMessageEn,
      faqItem.previewMessageKa,
      language,
    ),
    fullMessage: getLocalizedText(faqItem.fullMessageEn, faqItem.fullMessageKa, language),
    related: faqItem.related?.map((relatedFaq) => {
      return {
        ...relatedFaq,
        question: getLocalizedText(relatedFaq.questionEn, relatedFaq.questionKa, language),
      };
    }),
  };
}

/**
 * A custom React hook that fetches and manages the state of FAQs from an API.
 * It handles loading states, pagination, and localizing FAQ content based on the current language setting.
 *
 * @returns {{ faq: Array<{FAQItem}>,loading: boolean, page:number, totalPages:number, setPage: function}} The FAQs data and utility states and functions:
 *   - `faq`: An array of localized FAQ objects.
 *   - `loading`: Boolean indicating whether the FAQs are currently being loaded.
 *   - `page`: The current page number of FAQs being viewed.
 *   - `totalPages`: Total number of pages available for the FAQ data.
 *   - `setPage`: Function to update the current page number.
 *
 *
 * Each FAQ item is augmented with localized fields (`question`, `previewMessage`, `fullMessage`)
 * based on the current language selected (using i18n.language).
 */
export function useFAQ() {
  const [faq, setFAQs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const itemsPerAPICall = useItemsPerScreenSize({ items: faqItemsPerScreen });

  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page") || "1", 10);

  const fetchFAQ = useCallback(async () => {
    setLoading(true);
    try {
      const response = await delivoApi.getFAQList({ page, pageSize: itemsPerAPICall });
      setFAQs(response.results || []);
      setTotalPages(Math.ceil(response.count / itemsPerAPICall));
    } catch (error) {
      console.error("Error fetching FAQ:", error);
      showApiErrorNotification(t("alertsMessages.failedGetFAQ"), error, enqueueSnackbar);
    } finally {
      setLoading(false);
    }
  }, [page, itemsPerAPICall, t, enqueueSnackbar]);

  useEffect(() => {
    fetchFAQ();
  }, [fetchFAQ]);

  const localizedFAQs = useMemo(() => {
    return faq.map((faqItem) => localizeFAQ(faqItem, i18n.language));
  }, [faq, i18n.language]);

  const setPage = useCallback(
    (newPage) => {
      setSearchParams({ page: newPage.toString() });
    },
    [setSearchParams],
  );

  return { faq: localizedFAQs, loading, page, totalPages, setPage };
}
