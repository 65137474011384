import { useMemo } from "react";

/**
 * Custom hook to determine the item size based on the number of errors.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {number} params.minSize - Minimum item size.
 * @param {number} params.maxSize - Maximum item size.
 * @returns {function} - Function to calculate item size based on the number of errors.
 */
export function useItemSizePerErrorCount({ minSize, maxSize }) {
  const calculateItemSize = useMemo(() => {
    return (errorCount) => {
      return Math.max(minSize, Math.min(maxSize, minSize + errorCount * 20));
    };
  }, [minSize, maxSize]);

  return calculateItemSize;
}
