import React from "react";
import { Box, IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { useTranslation } from "react-i18next";
import { TooltipWrapper } from "../tooltipWrapper/TooltipWrapper";

function PrintAction({ onClick, disabled }) {
  const { t } = useTranslation();

  return (
    <Box>
      <TooltipWrapper title={t("tooltips.printSticker")}>
        <span>
          <IconButton aria-label="print" color="primary" onClick={onClick} disabled={disabled}>
            <PrintIcon />
          </IconButton>
        </span>
      </TooltipWrapper>
    </Box>
  );
}

export default PrintAction;
