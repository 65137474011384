import React from "react";
import "./App.css";
import Router from "./Router";

import * as Sentry from "@sentry/react";

function App() {
  return (
    <div className={"flex flex-col min-h-dvh smMax:max-h-dvh mx-auto max-w-full"}>
      <Router />
    </div>
  );
}

export default Sentry.withProfiler(App, { name: "DelivoBusinessPortal" });
