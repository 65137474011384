import React from "react";
import { Box, Typography } from "@mui/material";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TooltipWrapper } from "../tooltipWrapper/TooltipWrapper";

function GeneralInfo({ managerInfo }) {
  const { t } = useTranslation();
  const companyPhone = managerInfo.companyPhone ? managerInfo.companyPhone : "+995322500271";

  return (
    <Box
      className={
        "flex flex-col border-solid border-0.5 border-black rounded-lg my-2 items-center w-full p-2"
      }
    >
      <Typography className={"uppercase text-xs text-center"}>
        {t("managerInfo.generalInfo")}
      </Typography>
      <Link
        to={`tel:${companyPhone}`}
        rel="noopener noreferrer"
        title={t("managerInfo.mobilePhone")}
        aria-label={t("managerInfo.mobilePhone")}
        className={"w-full flex items-center justify-center sm:flex-wrap no-underline text-black"}
      >
        <Box className={"flex items-center"}>
          <TooltipWrapper title={t("managerInfo.mobilePhone")}>
            <PhoneIphoneOutlinedIcon className={"h-7 w-5 px-2"} />
          </TooltipWrapper>
        </Box>
        <Typography variant={"body2"} className={"text-xs"}>
          {companyPhone}
        </Typography>
      </Link>
    </Box>
  );
}

export default GeneralInfo;
