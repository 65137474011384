import React from "react";
import { Box } from "@mui/material";
import StatusItem from "./StatusItem";

function StatusItems({ order }) {
  return (
    <Box>
      {order.statusChanges
        .slice()
        .reverse()
        .map((statusItem, index) => (
          <StatusItem key={index} statusItem={statusItem} />
        ))}
    </Box>
  );
}

export default StatusItems;
