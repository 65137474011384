import { useAccountContext } from "../../../providers/AccountProvider/AccountProvider";
import { Button, useMediaQuery } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useMemo } from "react";

/**
 * Account button component to display account information and toggle the account menu.
 * @param {Object} props - The props object.
 * @param {boolean} props.isOpen - Indicates whether the account menu is open.
 * @param {Function} props.handleMenuOpen - Callback function to handle opening the account menu.
 * @returns {JSX.Element} AccountButton component.
 */
export function AccountButton({ isOpen, handleMenuOpen }) {
  const { accountInfo } = useAccountContext();
  const isSmallScreen = useMediaQuery("(max-width:899px)");

  const renderButtonContent = useMemo(
    () =>
      isSmallScreen ? (
        <PersonIcon />
      ) : (
        <>
          {accountInfo.companyName}
          <ExpandMoreIcon className="pl-2" />
        </>
      ),
    [isSmallScreen, accountInfo.companyName],
  );

  return (
    <Button
      id="basic-button"
      aria-controls={isOpen ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={isOpen ? "true" : "false"}
      onClick={handleMenuOpen}
      sx={{
        "@media (max-width:360px)": {
          margin: 0,
          minWidth: "40px",
        },
        color: "black",
        fontSize: "0.875rem",
      }}
    >
      {renderButtonContent}
    </Button>
  );
}
