import React from "react";
import { Box, Typography } from "@mui/material";
import { useManagerInfo } from "../../hooks/useManager";
import { useTranslation } from "react-i18next";
import GeneralInfo from "./GeneralInfo";
import PrivateManager from "./PrivateManager";

function ManagerInfo() {
  const { managerInfo } = useManagerInfo();
  const { t } = useTranslation();

  return (
    <Box className={"md:bg-color-main p-4 rounded-lg flex flex-col items-center my-4 w-11/12"}>
      <Typography className={"pt-2 uppercase text-center text-sm font-semibold"}>
        {t("managerInfo.title")}
      </Typography>
      {managerInfo.firstName && <PrivateManager managerInfo={managerInfo} />}
      <GeneralInfo managerInfo={managerInfo} />
    </Box>
  );
}

export default ManagerInfo;
