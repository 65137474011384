import React, { useEffect } from "react";
import { Box, Card, CardActions, CardContent, Pagination, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNewsList } from "../../hooks/useNewsList";
import LoadingBackdrop from "../loadingBackdrop/LoadingBackdrop";
import NewsItemPreview from "./NewsItemPreview";
import { Link, useSearchParams } from "react-router-dom";
import { formatGeDateTime } from "../dates";
import { PageTitle } from "../pageTitle/PageTitle";

/**
 * Styles for the Pagination component using the sx prop.
 * This object contains styles to ensure the pagination component is responsive and properly displayed across different screen sizes.
 *
 * The styles ensure the pagination is centered and maintains a single row layout on mobile devices.
 */
const sxPaginationStyles = {
  width: "100%",
  mb: 2,
  ".MuiPagination-root": {
    display: "flex",
    justifyContent: "center",
  },
  "@media (max-width: 600px)": {
    ".MuiPagination-ul": {
      flexWrap: "nowrap",
      overflowX: "auto",
    },
  },
};

function NewsList() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page") || "1", 10);
  const { news, loading, totalPages, setPage } = useNewsList(page);

  const handleChange = (event, value) => {
    setPage(value);
    setSearchParams({ page: value.toString() });
  };

  useEffect(() => {
    setPage(page);
  }, [page, setPage]);

  return (
    <Box className={"shadow-lg rounded-md"}>
      <PageTitle title={t("pagesTitles.newsTitle")} />
      <LoadingBackdrop isVisible={loading} />
      <Box className="grid grid-cols-1 md:grid-cols-2 gap-4 md:px-4">
        {news.map((newsItem) => (
          <Card className={"mb-2 shadow-md p-2 col-span-1"} key={newsItem.slug}>
            <Link
              to={`/news/${newsItem.slug}`}
              state={{ detail: newsItem, page }}
              className={"text-black no-underline"}
            >
              <CardContent>
                <NewsItemPreview
                  slug={newsItem.slug}
                  title={newsItem.title}
                  publishingTime={formatGeDateTime(newsItem.publishingTime)}
                  previewMessage={newsItem.previewMessage}
                />
              </CardContent>
              <CardActions>
                <Typography className={"text-color-main lowercase mx-2"}>{t("more")}</Typography>
              </CardActions>
            </Link>
          </Card>
        ))}
      </Box>
      <Box className="flex justify-center md:p-4" sx={sxPaginationStyles}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChange}
          className="mt-4"
          color="primary"
          siblingCount={1}
          boundaryCount={1}
          sx={{
            ".MuiPagination-ul": {
              justifyContent: "center",
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default NewsList;
