import React from "react";
import { Box, Typography } from "@mui/material";

/**
 * Represents a preview of a news item.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.slug - The slug of the news item.
 * @param {string} props.title - The title of the news item.
 * @param {string} props.previewMessage - The preview message of the news item.
 * @param {string} props.publishingTime - The publishing time of the news item.
 * @param {Object} props.titleStyle - The style for the title.
 * @param {Object} props.textStyle - The style for the preview message.
 * @param {Object} props.dateStyle - The style for the publishing time.
 * @param {number} props.page - The page number.
 * @returns {NewsItem} A React component representing the preview of a news item.
 */
function NewsItemPreview({
  slug,
  title,
  previewMessage,
  publishingTime,
  titleStyle,
  textStyle,
  dateStyle,
  page,
}) {
  return (
    <Box key={slug} page={page}>
      <Typography className={"font-bold mb-3"} sx={titleStyle}>
        {title}
      </Typography>
      <Typography className="flex flex-wrap" sx={textStyle}>
        {previewMessage}
      </Typography>
      <Typography className="text-gray-400" variant="caption" sx={dateStyle}>
        {publishingTime}
      </Typography>
    </Box>
  );
}

export default NewsItemPreview;
