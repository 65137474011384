import React from "react";
import ErrorDetailsSnack from "./snackbar/ErrorDetailsSnack";
import i18n from "i18next";

/**
 * Recursively processes error details object or array and gathers error messages.
 * @param {object|array} details - The error details object or array.
 * @param {string} [parentAttr=""] - The parent attribute to prepend to each error message.
 * @returns {string[]} An array containing all error messages.
 */
const processDetails = (details, parentAttr) => {
  /**
   * If details is an array, iterates through each element and appends it to the errorMessages array.
   * If details is an object, recursively handles nested errors and appends them to the errorMessages array.
   */
  if (Array.isArray(details)) {
    return details.flatMap((error) => {
      const { attr, detail } = error;
      const newParentAttr = parentAttr ? `${parentAttr}.${attr}` : attr;
      return processDetails(detail, newParentAttr);
    });
  } else if (typeof details === "object" && Object.keys(details).length > 0) {
    return Object.entries(details).flatMap(([attr, error]) => {
      const newParentAttr = parentAttr ? `${parentAttr}.${attr}` : attr;
      return processDetails(error, newParentAttr);
    });
  } else {
    return [{ attr: parentAttr, detail: details }];
  }
};

/**
 * Displays error messages by iterating through the provided details object and showing corresponding snackbar messages.
 * @param {object|array} details - The error details object or array.
 * @param {string} [parentAttr=""] - The parent attribute to prepend to each error message.
 * @param {function} enqueueSnackbar - The enqueueSnackbar function from the notistack library.
 * @param {string} businessErrorText - The custom snackbar message to inform user using localization
 */
const displayError = (details, parentAttr = "", enqueueSnackbar, businessErrorText) => {
  const errorMessages = processDetails(details, parentAttr);

  if (errorMessages.length > 0) {
    const combinedErrorMessage = errorMessages
      .map(({ attr, detail }) => `${attr}: ${detail}`)
      .join("\n");

    enqueueSnackbar({
      variant: "error",
      autoHideDuration: 8000,
      style: { whiteSpace: "pre-line" },
      action: (
        <ErrorDetailsSnack errorMessage={combinedErrorMessage} customText={businessErrorText} />
      ),
    });
  }
};

/**
 * Handles API errors by extracting error details from the response and displaying corresponding snackbar messages.
 * @param {string} businessErrorText - The custom snackbar message to inform user using localization
 * @param {object|string} error - The error object or string as custom message received from the API request.
 * @param {function} enqueueSnackbar - The enqueueSnackbar function from the notistack library.
 */
const showApiErrorNotification = (businessErrorText, error, enqueueSnackbar) => {
  if (error.response && error.response.data) {
    const responseData = error.response.data;
    displayError(responseData.errors, "", enqueueSnackbar, businessErrorText);
  } else {
    enqueueSnackbar(i18n.t("alertsMessages.smthGoesWrong"), { variant: "error" });
  }
};

export default showApiErrorNotification;
