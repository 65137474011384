import React from "react";
import { Box, Divider, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { Field, Form, Formik } from "formik";
import "yup-phone-lite";
import ButtonComponent from "../buttonComponent/ButtonComponent";
import { Link } from "react-router-dom";
import { FeedbackFormRequest } from "../../api/types";
import { delivoApi } from "../../api";
import { useSnackbar } from "notistack";
import { CitySelectField } from "../selectFields/CitySelectField";
import { PageTitle } from "../pageTitle/PageTitle";

const initialValues = {
  name: "",
  surname: "",
  actual_address_city: "",
  address: "",
  phone: "",
  email: "",
  comment: "",
};

/**
 * Converts given form data into a structured feedback info.
 * @param data
 * @returns {FeedbackFormRequest}
 */
const preparePostData = (data) => {
  return new FeedbackFormRequest({
    name: data.name,
    surname: data.surname,
    actual_address_city: data.actual_address_city,
    address: data.address,
    phone: `+${data.phone}`,
    email: data.email.toLowerCase(),
    comment: data.comment,
  });
};

function FeedbackForm() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = object({
    name: string().required(t("errorsMessages.nameRequire")),
    surname: string().required(t("errorsMessages.surnameRequire")),
    actual_address_city: string().max(30, t("errorsMessages.maxCityLength")),
    address: string().max(255, t("errorsMessages.maxAddressLength")),
    phone: string()
      .phone("GE", t("errorsMessages.phoneNumberValidation"))
      .matches(/^\d{12}$/, t("errorsMessages.phoneNumberValidation"))
      .required(t("errorsMessages.phoneNumberRequire")),
    email: string()
      .required(t("errorsMessages.emailRequire"))
      .email(t("errorsMessages.emailValidation"))
      .max(125, t("errorsMessages.maxEmailLength")),
    comment: string().required(t("errorsMessages.commentRequire")),
  });

  return (
    <Box className={"w-full shadow-md rounded-md"}>
      <PageTitle title={t("pagesTitles.contacts")} />
      <Box className={"flex item-center w-full p-1.5 m-2"}>
        <Link
          to={`mailto:info@delivo.ge`}
          className={"flex items-center w-1/2 text-gray-500 no-underline"}
        >
          <IconButton>
            <EmailIcon className={"m-2"} />
          </IconButton>
          <Typography variant={"body2"}>info@delivo.ge</Typography>
        </Link>
        <Link
          to={`tel:0325000010`}
          className={"flex items-center w-1/2 text-gray-500 no-underline"}
        >
          <IconButton>
            <LocalPhoneIcon className={"m-2"} />
          </IconButton>
          <Typography variant={"body2"}>0 (32) 5 00 00 10</Typography>
        </Link>
      </Box>
      <Divider orientation={"horizontal"} flexItem />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, FormikHelpers) => {
          const prepareSendData = preparePostData(values);
          const sendFeedbackForm = await delivoApi.sendFeedbackEmail(prepareSendData);
          if (sendFeedbackForm) {
            enqueueSnackbar(t("alertsMessages.successFeedbackFormResponse"), {
              variant: "success",
            });
            FormikHelpers.resetForm();
          } else {
            enqueueSnackbar(t("alertsMessages.smthGoesWrong"), { variant: "error" });
          }
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form className={"flex flex-col justify-around p-2"}>
            <Typography variant={"h4"} className={"px-1 m-2"}>
              {t("feedbackForm.feedbackTitle")}
            </Typography>
            <Divider orientation={"horizontal"} flexItem />
            <Typography variant={"body1"} className={"m-4"}>
              {t("feedbackForm.clientInfo")}
            </Typography>
            <Box className={"flex item-center justify-evenly w-full"}>
              <Field
                as={TextField}
                error={Boolean(errors.name) && Boolean(touched.name)}
                helperText={(Boolean(touched.name) && errors.name) || " "}
                label={`*${t("feedbackForm.clientName")}`}
                name="name"
                placeholder={t("placeholders.name")}
                inputProps={{ maxLength: 150 }}
                className={"w-1/2 m-4"}
              />
              <Field
                as={TextField}
                error={Boolean(errors.surname) && Boolean(touched.surname)}
                helperText={(Boolean(touched.surname) && errors.surname) || " "}
                label={`*${t("feedbackForm.clientSurname")}`}
                name="surname"
                inputProps={{ maxLength: 150 }}
                placeholder={t("placeholders.surname")}
                className={"w-1/2 m-4"}
              />
            </Box>
            <Typography variant={"body1"} className={"m-4"}>
              {t("feedbackForm.clientAddressTitle")}
            </Typography>
            <Box className={"flex item-center justify-around w-full"}>
              <CitySelectField
                fieldName={"actual_address_city"}
                label={t("feedbackForm.clientCity")}
                className={"w-1/2 m-4"}
              />
              <Field
                as={TextField}
                error={Boolean(errors.address) && Boolean(touched.address)}
                helperText={(Boolean(touched.address) && errors.address) || " "}
                label={t("feedbackForm.clientAddress")}
                name="address"
                placeholder={t("placeholders.street")}
                inputProps={{ maxLength: 255 }}
                className={"w-1/2 m-4"}
              />
            </Box>
            <Typography variant={"body1"} className={"m-4"}>
              {t("feedbackForm.clientAdditionalInfo")}
            </Typography>
            <Box className={"flex item-center justify-between w-full"}>
              <Field
                as={TextField}
                error={Boolean(errors.email) && Boolean(touched.email)}
                helperText={(Boolean(touched.email) && errors.email) || " "}
                label={`*${t("feedbackForm.clientEmail")}`}
                name="email"
                placeholder="example@mail.com"
                className={"w-1/2 m-4"}
                inputProps={{ maxLength: 125 }}
              />
              <Field
                as={TextField}
                error={Boolean(errors.phone) && Boolean(touched.phone)}
                helperText={(Boolean(touched.phone) && errors.phone) || " "}
                label={`*${t("feedbackForm.clientPhone")}`}
                name="phone"
                placeholder="995325000010"
                inputProps={{ maxLength: 12 }}
                className={"w-1/2 m-4"}
              />
            </Box>
            <Typography variant={"body1"} className={"m-4"}>
              {`*${t("feedbackForm.comment")}`}
            </Typography>
            <Box className={"px-4"}>
              <Field
                as={TextField}
                error={Boolean(errors.comment) && Boolean(touched.comment)}
                helperText={(Boolean(touched.comment) && errors.comment) || " "}
                name="comment"
                multiline
                rows={8}
                placeholder={t("placeholders.comment")}
                className={"w-full"}
              />
            </Box>
            <Box className={"flex flex-col"}>
              <ButtonComponent
                type="submit"
                text={t("sendButton")}
                disabled={isSubmitting}
                className={"m-4 px-10 justify-self-end self-end"}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default FeedbackForm;
