import { useCallback, useMemo } from "react";
import {
  FIELDS_NAME_FROM_API,
  ORDER_STATUS,
  REGISTER_STATUS_FROM_API,
} from "../components/constants";
import { useTranslation } from "react-i18next";

function useStatusTranslations() {
  const { t } = useTranslation();

  const orderStatusMap = useMemo(
    () => ({
      [ORDER_STATUS.DRAFT]: t("statuses.draft"),
      [ORDER_STATUS.UNDELIVERED]: t("statuses.undelivered"),
      [ORDER_STATUS.ISSUED_TO_RECIPIENT]: t("statuses.issuedToTheRecipient"),
      [ORDER_STATUS.ISSUED_TO_COURIER]: t("statuses.issuedToTheCourier"),
      [ORDER_STATUS.ACCEPTED_FOR_SHIPMENT]: t("statuses.acceptedForShipment"),
      [ORDER_STATUS.PREPARED_FOR_SHIPMENT]: t("statuses.preparedForShipment"),
      [ORDER_STATUS.PREPARED_FOR_THE_COURIER]: t("statuses.preparedForCourier"),
      [ORDER_STATUS.DELIVERED_TO_THE_ISSUING_OFFICE]: t("statuses.deliveredToTheIssuingOffice"),
      [ORDER_STATUS.DELIVERED_TO_SORTING_WAREHOUSE]: t("statuses.deliveredToSortingWarehouse"),
      [ORDER_STATUS.READY_TO_ISSUE]: t("statuses.readyToIssue"),
      [ORDER_STATUS.COURIER_PICKED_UP_FROM_THE_SENDER]: t("statuses.courierPickedupFromSender"),
      [ORDER_STATUS.RETURNED_TO_SENDER]: t("statuses.returnedToSender"),
      [ORDER_STATUS.RETURN_TO_SENDER]: t("statuses.returnToSender"),
      [ORDER_STATUS.SENT]: t("statuses.sent"),
      [ORDER_STATUS.IMPORT_CUSTOMS_HANDOVER]: t("statuses.importCustomHandover"),
    }),
    [t],
  );
  const formatOrderStatus = useCallback(
    (statusKey) => orderStatusMap[statusKey] || statusKey,
    [orderStatusMap],
  );
  const registerStatusMap = useMemo(
    () => ({
      [REGISTER_STATUS_FROM_API.DRAFT]: t("statuses.draft"),
      [REGISTER_STATUS_FROM_API.READY_FOR_PICKUP]: t("statuses.readyForPickup"),
      [REGISTER_STATUS_FROM_API.READY_FOR_SHIPMENT]: t("statuses.readyForShipment"),
      [REGISTER_STATUS_FROM_API.ACCEPTED_IN_POST_OFFICE]: t("statuses.acceptedInPostOffice"),
      [REGISTER_STATUS_FROM_API.PICKED_UP_BY_COURIER]: t("statuses.pickedUpByCourier"),
      [REGISTER_STATUS_FROM_API.CANCELED]: t("statuses.canceled"),
    }),
    [t],
  );
  const formatRegisterStatus = useCallback(
    (statusKey) => registerStatusMap[statusKey] || statusKey,
    [registerStatusMap],
  );

  const fieldNamesMap = useMemo(
    () => ({
      [FIELDS_NAME_FROM_API.RECIPIENT_GOV_NUMBER]: t("fieldNames.recipientGovNumber"),
      [FIELDS_NAME_FROM_API.RECIPIENT_NAME]: t("fieldNames.recipientName"),
      [FIELDS_NAME_FROM_API.RECIPIENT_FIRST_NAME]: t("fieldNames.recipientFirstName"),
      [FIELDS_NAME_FROM_API.RECIPIENT_LAST_NAME]: t("fieldNames.recipientLastName"),
      [FIELDS_NAME_FROM_API.RECIPIENT_ORGANIZATION_NAME]: t(
        "fieldNames.recipientOrganizationName",
      ),
      [FIELDS_NAME_FROM_API.RECIPIENT_ADDRESS]: t("fieldNames.recipientAddress"),
      [FIELDS_NAME_FROM_API.RECIPIENT_PHONE]: t("fieldNames.recipientPhone"),
      [FIELDS_NAME_FROM_API.RECIPIENT_POST_OFFICE]: t("fieldNames.recipientPostOfficeGuid"),
      [FIELDS_NAME_FROM_API.WEIGHT]: t("fieldNames.weight"),
      [FIELDS_NAME_FROM_API.DIMENSIONS_LENGTH]: t("fieldNames.length"),
      [FIELDS_NAME_FROM_API.DIMENSIONS_WIDTH]: t("fieldNames.width"),
      [FIELDS_NAME_FROM_API.DIMENSIONS_HEIGHT]: t("fieldNames.height"),
      [FIELDS_NAME_FROM_API.DEPARTURE_CITY_ID]: t("fieldNames.departureCity"),
      [FIELDS_NAME_FROM_API.DESTINATION_CITY_ID]: t("fieldNames.destinationCity"),
      [FIELDS_NAME_FROM_API.PAYMENT_BY_RECIPIENT]: t("fieldNames.paymentByRecipient"),
      [FIELDS_NAME_FROM_API.COD_BY_RECIPIENT]: t("fieldNames.paymentByRecipient"),
      [FIELDS_NAME_FROM_API.COD]: t("fieldNames.cod"),
      [FIELDS_NAME_FROM_API.ESTIMATED_COST]: t("fieldNames.estimatedCost"),
      [FIELDS_NAME_FROM_API.DELIVERY_COST]: t("fieldNames.deliveryCost"),
      [FIELDS_NAME_FROM_API.INSURANCE_COST]: t("fieldNames.insurance_cost"),
      [FIELDS_NAME_FROM_API.INSURED]: t("fieldNames.insured"),
      [FIELDS_NAME_FROM_API.FRAGILE]: t("fieldNames.fragile"),
      [FIELDS_NAME_FROM_API.RECIPIENT_NON_FIELD_ERRORS]: t("fieldNames.recipientNonField"),
      [FIELDS_NAME_FROM_API.INTERNATIONAL_TRACKING_NUMBER]: t(
        "fieldNames.internationalTrackingNumber",
      ),
    }),
    [t],
  );
  const formatFieldName = useCallback(
    (fieldNameKey) => fieldNamesMap[fieldNameKey] || fieldNameKey,
    [fieldNamesMap],
  );

  return { formatOrderStatus, formatRegisterStatus, formatFieldName };
}

export default useStatusTranslations;
