import React from "react";
import { Box, IconButton } from "@mui/material";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { useTranslation } from "react-i18next";
import { TooltipWrapper } from "../tooltipWrapper/TooltipWrapper";

function UnarchiveAction({ onClick, disabled }) {
  const { t } = useTranslation();

  return (
    <Box>
      <TooltipWrapper title={t("tooltips.unarchiveOrder")}>
        <span>
          <IconButton aria-label="uarchive" color="primary" onClick={onClick} disabled={disabled}>
            <UnarchiveIcon />
          </IconButton>
        </span>
      </TooltipWrapper>
    </Box>
  );
}

export default UnarchiveAction;
