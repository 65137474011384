import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { delivoApi } from "../api";
import { useSnackbar } from "notistack";
import showApiErrorNotification from "../components/showApiErrorNotification";

/**
 * Custom hook for fetching and localizing region information. It initiates a loading state,
 * fetches region data using a provided API service, localizes the region names based on the current language,
 * and handles any errors that occur during the fetch operation.
 *
 * @returns {{ regions: Array<{Region}>, regionLoading: boolean}} An object containing the localized regions array and a loading state indicator.
 */
export function useRegions() {
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const fetchRegions = useCallback(async () => {
    setLoading(true);
    try {
      const fetchedRegions = await delivoApi.getRegions();
      setRegions(fetchedRegions);
    } catch (error) {
      console.error("Error fetching regions:", error);
      showApiErrorNotification(t("alertsMessages.failedGetRegions"), error, enqueueSnackbar);
    } finally {
      setLoading(false);
    }
  }, [t, enqueueSnackbar]);

  useEffect(() => {
    fetchRegions();
  }, [fetchRegions]);

  const localizedRegions = useMemo(() => {
    return regions.map((region) => ({
      ...region,
      region: region.getName(i18n.language),
    }));
  }, [regions, i18n.language]);

  const localizedRegionMap = useMemo(() => {
    const result = new Map();
    for (const region of localizedRegions) {
      result.set(region.code, region);
    }
    return result;
  }, [localizedRegions]);

  const getRegionByCode = useCallback(
    (code) => {
      const foundRegion = localizedRegionMap.get(code);
      if (!foundRegion) {
        console.error(`Region with code ${code} not found.`);
      }
      return foundRegion;
    },
    [localizedRegionMap],
  );

  return { regions: localizedRegions, regionLoading: loading, getRegionByCode };
}
