import React, { createContext, useContext, useEffect, useState } from "react";
import { ConfigFlags } from "../../api/types";
import { fetchAndActivateValue, getBooleanConfig, remoteConfig } from "../../firebase";

const flags = {
  accountPageEnable: true,
  myAddressesPageEnable: true,
  employeesPageEnable: false,
  loginPageEnable: true,
  myOrdersPageEnable: true,
  newOrdersPageEnable: true,
  registersPageEnable: true,
  registerDetailsPageEnable: true,
  registerCreationPageEnable: true,
  registerEditingPageEnable: true,
  faqPageEnable: true,
  feedbackPageEnable: true,
  registrationPageEnable: true,
  resetPasswordPageEnable: true,
  setPasswordPageEnable: true,
  ordersTrackingPageEnable: true,
  orderCreationPageEnable: true,
  importExcelFilePageEnable: true,
  orderUpdatingPageEnable: true,
  archivedOrdersPageEnable: true,
  newsPageEnable: true,
};
export const defaultFlags = new ConfigFlags(flags);
const FlagsContext = createContext({ flags: defaultFlags });
export const useFlags = () => {
  return useContext(FlagsContext);
};
const CONFIG_FETCH_INTERVAL_MILLIS = parseInt(
  process.env.REACT_APP_FIREBASE_CONFIG_FETCH_INTERVAL_MILLIS || "36000",
);

export function FlagsProvider({ defaults, children }) {
  remoteConfig.settings = {
    minimumFetchIntervalMillis: CONFIG_FETCH_INTERVAL_MILLIS,
  };

  const [flags, setFlags] = useState(defaults);
  remoteConfig.defaultConfig = defaults;

  useEffect(() => {
    async function fetchRemoteConfig() {
      try {
        await fetchAndActivateValue;
        const parsedFlags = {
          accountPageEnable: getBooleanConfig("accountPageEnable"),
          employeesPageEnable: getBooleanConfig("employeesPageEnable"),
          loginPageEnable: getBooleanConfig("loginPageEnable"),
          myOrdersPageEnable: getBooleanConfig("myOrdersPageEnable"),
          newOrdersPageEnable: getBooleanConfig("newOrdersPageEnable"),
          registersPageEnable: getBooleanConfig("registersPageEnable"),
          registerDetailsPageEnable: getBooleanConfig("registerDetailsPageEnable"),
          registerCreationPageEnable: getBooleanConfig("registerCreationPageEnable"),
          registerEditingPageEnable: getBooleanConfig("registerEditingPageEnable"),
          faqPageEnable: getBooleanConfig("faqPageEnable"),
          feedbackPageEnable: getBooleanConfig("feedbackPageEnable"),
          registrationPageEnable: getBooleanConfig("registrationPageEnable"),
          resetPasswordPageEnable: getBooleanConfig("resetPasswordPageEnable"),
          setPasswordPageEnable: getBooleanConfig("setPasswordPageEnable"),
          ordersTrackingPageEnable: getBooleanConfig("ordersTrackingPageEnable"),
          orderCreationPageEnable: getBooleanConfig("orderCreationPageEnable"),
          myAddressesPageEnable: getBooleanConfig("myAddressesPageEnable"),
          importExcelFilePageEnable: getBooleanConfig("importExcelFilePageEnable"),
          orderUpdatingPageEnable: getBooleanConfig("orderUpdatingPageEnable"),
          archivedOrdersPageEnable: getBooleanConfig("archivedOrdersPageEnable"),
          newsPageEnable: getBooleanConfig("newsPageEnable"),
        };
        const firebaseFlags = new ConfigFlags(parsedFlags);
        if (!firebaseFlags.equal(flags)) {
          console.info("Using flags from Firebase remote config.", firebaseFlags, flags);
          setFlags(firebaseFlags);
        } else {
          console.debug("Firebase remote config flags and existing flags are the same.");
        }
      } catch (error) {
        console.error("Error fetching Remote Config:", error);
      }
    }

    fetchRemoteConfig();
  }, [flags]);
  return <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>;
}
