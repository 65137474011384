import {
  Box,
  Divider,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import RadioGroupField from "../../radioGroupField/RadioGroupField";
import { RECIPIENT_TYPE } from "../../constants";
import { Field } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  adaptiveBoxWrapper,
  boxClassName,
  boxWrapper,
  displayAdaptiveBoxStyles,
  fieldBaseStyle,
  getContentBlockClass,
  leftFieldStyle,
  radioGroupBtnCustomStyle,
  rightFieldStyle,
} from "../tailwindClasses";

/**
 * Defines the width of the `recipientNameSurname` input field. This width is calculated to occupy two-thirds
 * (66.67%) of the container's width plus an additional 2.75rem to account for margins, ensuring it aligns
 * correctly with other fields in the layout.
 *
 * @type {string} The CSS value for the width of the `recipientNameSurname` input field
 */
const nameSurnameFieldWidth = "calc(66.67% + 2.75rem)";

/**
 * RecipientInfo component displays the recipient information section in an order form.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.recipientType - The selected recipient type
 * @param {string} props.recipientGovNumber - The recipient's government number
 * @param {string} props.recipientCompanyName - The recipient's company name
 * @param {string} props.recipientName - The recipient's first name
 * @param {string} props.recipientSurname - The recipient's last name
 * @param {string} props.recipientPhone - The recipient's phone number
 * @param {string} props.recipientNameSurname - The recipient's full name
 * @param {boolean} props.isUpdating - Flag indicating if the form is in updating mode
 * @param {Function} props.handleChange - General change handler for form fields
 * @param {Object} props.touched - Formik touched object for field validation
 * @param {Object} props.errors - Formik errors object for field validation
 * @returns {JSX.Element} The rendered component
 */
export const RecipientInfo = ({
  recipientType,
  recipientGovNumber,
  recipientCompanyName,
  recipientName,
  recipientSurname,
  recipientPhone,
  recipientNameSurname,
  isUpdating,
  handleChange,
  touched,
  errors,
}) => {
  const { t } = useTranslation();
  const [isFocusedPhoneField, setIsFocusedPhoneField] = useState(false);
  const recipientTypeLabels = [
    {
      value: RECIPIENT_TYPE.INDIVIDUAL,
      label: t("orderCreation.clientPartRadioGroupRecipientTypeIndividual"),
    },
    {
      value: RECIPIENT_TYPE.COMPANY,
      label: t("orderCreation.clientPartRadioGroupRecipientTypeLegalEntity"),
    },
  ];
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const individualStyles = getContentBlockClass(recipientType === RECIPIENT_TYPE.INDIVIDUAL);
  const companyStyles = getContentBlockClass(recipientType === RECIPIENT_TYPE.COMPANY);

  return (
    <>
      <Typography variant={"h6"} className={"p-1.5 my-2 mx-6"}>
        {t("orderCreation.clientPartTitleRecipientDetails")}
      </Typography>
      <Divider orientation={"horizontal"} flexItem className="hidden md:block" />
      <RadioGroupField
        name="recipientType"
        labels={recipientTypeLabels}
        value={recipientType}
        onChange={handleChange}
        className={radioGroupBtnCustomStyle}
      />
      <Box className={companyStyles}>
        <Box className={`${boxWrapper} flex-wrap`}>
          <Box className={boxClassName}>
            <Field
              as={TextField}
              label={t("registrationForm.companyIDFieldLabel")}
              name="recipientGovNumber"
              type="text"
              value={recipientGovNumber}
              error={touched.recipientGovNumber && Boolean(errors.recipientGovNumber)}
              helperText={touched.recipientGovNumber && errors.recipientGovNumber}
              variant="standard"
              inputProps={{ maxLength: 11 }}
              className={leftFieldStyle}
              placeholder="123456789"
            />
            <Field
              as={TextField}
              label={t("registrationForm.companyNameFieldLabel")}
              name="recipientCompanyName"
              variant="standard"
              value={recipientCompanyName}
              error={touched.recipientCompanyName && Boolean(errors.recipientCompanyName)}
              helperText={touched.recipientCompanyName && errors.recipientCompanyName}
              className={rightFieldStyle}
              inputProps={{ maxLength: 150 }}
              placeholder={t("placeholders.companyName")}
            />
            <Box className={"w-full md:w-1/3"} />
          </Box>
          <Box className={boxClassName}>
            <Field
              as={TextField}
              label={t("orderCreation.recipientName")}
              name="recipientName"
              variant="standard"
              value={recipientName}
              error={touched.recipientName && Boolean(errors.recipientName)}
              helperText={touched.recipientName && errors.recipientName}
              placeholder={t("placeholders.name")}
              className={leftFieldStyle}
              inputProps={{ maxLength: 50 }}
            />
            <Field
              as={TextField}
              label={t("orderCreation.recipientSurname")}
              name="recipientSurname"
              value={recipientSurname}
              error={touched.recipientSurname && Boolean(errors.recipientSurname)}
              helperText={touched.recipientSurname && errors.recipientSurname}
              variant="standard"
              placeholder={t("placeholders.surname")}
              className={rightFieldStyle}
              inputProps={{ maxLength: 30 }}
            />
            <Field
              as={TextField}
              label={t("orderCreation.recipientPhone")}
              name="recipientPhone"
              value={recipientPhone}
              error={touched.recipientPhone && Boolean(errors.recipientPhone)}
              helperText={touched.recipientPhone && errors.recipientPhone}
              className={fieldBaseStyle}
              onFocus={() => {
                setIsFocusedPhoneField(true);
              }}
              InputProps={{
                startAdornment: (isFocusedPhoneField || isUpdating) && (
                  <InputAdornment position="start">+995</InputAdornment>
                ),
              }}
              inputProps={{ maxLength: 9, minLength: 9 }}
              variant="standard"
            />
          </Box>
        </Box>
      </Box>
      <Box className={individualStyles}>
        <Box className={adaptiveBoxWrapper}>
          <Field
            as={TextField}
            label={t("orderCreation.recipientPhone")}
            name="recipientPhone"
            value={recipientPhone}
            error={touched.recipientPhone && Boolean(errors.recipientPhone)}
            helperText={touched.recipientPhone && errors.recipientPhone}
            className={leftFieldStyle}
            onFocus={() => {
              setIsFocusedPhoneField(true);
            }}
            InputProps={{
              startAdornment: (isFocusedPhoneField || isUpdating) && (
                <InputAdornment position="start">+995</InputAdornment>
              ),
            }}
            inputProps={{ maxLength: 9, minLength: 9 }}
            variant="standard"
          />
          <Field
            as={TextField}
            label={t("orderCreation.recipientNameSurname")}
            name="recipientNameSurname"
            value={recipientNameSurname}
            error={touched.recipientNameSurname && Boolean(errors.recipientNameSurname)}
            helperText={touched.recipientNameSurname && errors.recipientNameSurname}
            variant="standard"
            placeholder={`${t("placeholders.name")} ${t("placeholders.surname")}`}
            sx={{
              width: isMd ? "100%" : nameSurnameFieldWidth,
            }}
            inputProps={{ maxLength: 80 }}
          />
        </Box>
      </Box>
    </>
  );
};
