import { Link } from "react-router-dom";
import React from "react";
import { ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

export function AppVersion() {
  const version = process.env.REACT_APP_VERSION;
  const { t } = useTranslation();

  return (
    <Link
      to={"/changelog"}
      className={"text-black no-underline hover:underline underline-offset-8 px-3 md:py-2"}
    >
      <ListItemText className={"whitespace-nowrap"}>
        {t("app.version")} - {version}
      </ListItemText>
    </Link>
  );
}
