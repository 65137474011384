import React from "react";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStatusTranslations from "../../hooks/useStatusTranslations";

const FailedRowDetails = ({ error }) => {
  const { t } = useTranslation();
  const { formatFieldName } = useStatusTranslations();

  return (
    <Box className={"flex"}>
      <Typography className={"mr-3 italic w-1/12"}>{error.cell_reference}</Typography>
      <Typography className={"mr-3 w-3/12"}>{formatFieldName(error.field_name)}</Typography>
      <Typography className={"mr-3 w-10/12"}>
        {error.ERROR_TYPE === "CELL_VALUE_CASTING_ERROR"
          ? `${t("uploadFilePage.wrongFormatError")}`
          : error.message}
      </Typography>
    </Box>
  );
};

export default FailedRowDetails;
