import { Button } from "@mui/material";
import React from "react";
import { Flag } from "./Flag";

/**
 * `LanguageButton` is a React component that renders a button used to trigger a language change in an application.
 * The button displays a flag corresponding to the current language and can be used to either directly change the language
 * or to open a dropdown menu for language selection.
 *
 * @param {Object} props The properties passed to the LanguageButton component.
 * @param {string} props.language The language code or identifier for the button, used to display the corresponding flag.
 * @param {Function} props.handleChangeLanguage The callback function to invoke when the button is clicked. This function
 * should handle the logic for initiating a language change or opening a language selection menu.
 *
 * @returns {JSX.Element} A Button component styled with an outline variant and additional class properties. The button
 * includes an icon of a flag that represents the current language. The onClick event is linked to the `handleChangeLanguage`
 * function, which is intended to handle language switching or menu toggling.
 *
 */
export function LanguageButton({ language, handleChangeLanguage }) {
  return (
    <Button
      variant="outlined"
      className="focus:bg-color-primary"
      onClick={handleChangeLanguage}
      sx={{
        "@media (max-width:360px)": {
          margin: 0,
          minWidth: "40px",
          padding: "5px 0px",
        },
      }}
    >
      <Flag language={language} />
    </Button>
  );
}
