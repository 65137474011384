/**
 * The reCAPTCHA enterprise verification data.
 */
export class ReCAPTCHAVerification {
  /**
   * Creates a new reCAPTCHA verification object.
   * @param {string} token the token received from the reCAPTCHA API.
   * @param {string} action the verification action. See available actions {@link https://cloud.google.com/recaptcha-enterprise/docs/actions here}.
   */
  constructor(token, action) {
    this.token = token;
    this.action = action;
  }
}

/**
 * Represents information about the organization available during the registration process.
 */
export class RegisterOrganization {
  /**
   * Creates a new organization registration request information object.
   * @param {object} org organization registration info
   * @param {string} org.name the name of the organization
   * @param {string} org.gov_number the government-provided ID of the organization
   * @param {email} org.email the email of the organization
   * @param {string} org.legal_address the legal address of the organization
   * @param {string} org.owner_name the name of the organization owner
   * @param {string} org.contact_name the name of the contact person
   * @param {string} org.contact_phone the phone number of the contact person
   * @param {string} org.phone the phone number of the organization
   * @param {string} org.address the address in the city where the delivery happens
   * @param {string} org.actual_address_city the ID of the city where the delivery happens
   * @param {string} org.public_name the public name of the organization
   * @param {string} org.public_phone the public phone number of the organization
   * @param {string} org.public_address the public address of the organization
   * @param {boolean} org.tos_accepted  whether the Terms of Service is accepted
   * @param {boolean} org.pd_processing_accepted whether the processing of personal data is accepted
   */
  constructor({
    name,
    gov_number,
    email,
    legal_address,
    owner_name,
    contact_name,
    contact_phone,
    phone,
    address,
    actual_address_city,
    public_phone,
    public_address,
    public_name,
    tos_accepted,
    pd_processing_accepted,
  }) {
    this.name = name;
    this.gov_number = gov_number;
    this.email = email;
    this.legal_address = legal_address;
    this.owner_name = owner_name;
    this.contact_name = contact_name;
    this.contact_phone = contact_phone;
    this.phone = phone;
    this.address = address;
    this.actual_address_city = actual_address_city;
    this.public_phone = public_phone;
    this.public_name = public_name;
    this.public_address = public_address;
    this.tos_accepted = tos_accepted;
    this.pd_processing_accepted = pd_processing_accepted;
  }
}

/**
 * Represents an organization registration request which includes required reCAPTCHA and org data.
 */
export class OrganizationRegistrationRequest {
  /**
   * Creates a new organization registration request.
   * @param {ReCAPTCHAVerification} recaptcha the reCAPTCHA verification info
   * @param {RegisterOrganization} organization the org registration info
   */
  constructor(recaptcha, organization) {
    this.recaptcha = recaptcha;
    this.organization = organization;
  }
}

/**
 * Represents organization data available upon a successful registration.
 */
export class RegisteredOrganization {
  /**
   * Creates a new registered organization info object.
   * @param {object} org registered organization information
   * @param {string} org.gov_number the government-provided ID of the organization
   * @param {email} org.email the email of the organization
   * @param {string} org.contact_name the name of the contact person
   * @param {string} org.contact_phone the phone number of the contact person
   * @param {string} org.actual_address_city the ID of the city where the delivery happens
   * @param {string} org.public_name the public name of the organization
   * @param {string} org.public_phone the public phone number of the organization
   * @param {string} org.public_address the public address of the organization
   */
  constructor({
    status,
    gov_number,
    email,
    contact_name,
    contact_phone,
    actual_address_city,
    public_phone,
    public_address,
    public_name,
  }) {
    this.status = status;
    this.gov_number = gov_number;
    this.email = email;
    this.contact_name = contact_name;
    this.contact_phone = contact_phone;
    this.actual_address_city = actual_address_city;
    this.public_phone = public_phone;
    this.public_name = public_name;
    this.public_address = public_address;
  }
}

export class FeedbackFormRequest {
  /**
   * Creates a feedback request information object.
   * @param {object} feedbackForm feedback form info
   * @param {string} feedbackForm.name user name from the feedback form
   * @param {string} feedbackForm.surname user surname from the feedback form
   * @param {string} feedbackForm.actualAddressCity the ID of the city from the feedback form
   * @param {string} feedbackForm.address the address in the city from the feedback form
   * @param {email} feedbackForm.email user email from the feedback form
   * @param {string} feedbackForm.phone user phone number from the feedback form
   * @param {string} feedbackForm.comment the comment from the feedback form
   */
  constructor({ name, surname, actualAddressCity, address, phone, email, comment }) {
    this.name = name;
    this.surname = surname;
    this.actualAddressCity = actualAddressCity;
    this.address = address;
    this.email = email;
    this.phone = phone;
    this.comment = comment;
  }
}

export class ResetPasswordRequest {
  /**
   * Creates a new request to reset password.
   * @param {ReCAPTCHAVerification} recaptcha the reCAPTCHA verification info
   * @param {email} email user email for reset password
   */
  constructor(recaptcha, email) {
    this.recaptcha = recaptcha;
    this.email = email;
  }
}

export class ConfigFlags {
  /**
   * Creates an instance of EnabledFlags.
   *
   * @param {object} config - The configuration object.
   * @param {boolean} config.accountPageEnable - Flag to enable the account page.
   * @param {boolean} config.employeesPageEnable - Flag to enable the employees page.
   * @param {boolean} config.loginPageEnable - Flag to enable the login page.
   * @param {boolean} config.myAddressesPageEnable - Flag to enable my addresses page.
   * @param {boolean} config.myOrdersPageEnable - Flag to enable my orders page.
   * @param {boolean} config.newOrdersPageEnable - Flag to enable the new orders page.
   * @param {boolean} config.archivedOrdersPageEnable - Flag to enable the archived orders page.
   * @param {boolean} config.registersPageEnable - Flag to enable the registers page.
   * @param {boolean} config.registerDetailsPageEnable - Flag to enable the register details page.
   * @param {boolean} config.registerCreationPageEnable - Flag to enable the register creation page.
   * @param {boolean} config.registerEditingPageEnable - Flag to enable the register editing page.
   * @param {boolean} config.faqPageEnable - Flag to enable the FAQ page.
   * @param {boolean} config.feedbackPageEnable - Flag to enable the feedback page.
   * @param {boolean} config.registrationPageEnable - Flag to enable the registration page.
   * @param {boolean} config.resetPasswordPageEnable - Flag to enable the reset password page.
   * @param {boolean} config.setPasswordPageEnable - Flag to enable the set password page.
   * @param {boolean} config.ordersTrackingPageEnable - Flag to enable the tracking orders page.
   * @param {boolean} config.orderCreationPageEnable - Flag to enable the order creation page.
   * @param {boolean} config.orderUpdatingPageEnable - Flag to enable the order updating page.
   * @param {boolean} config.importExcelFilePageEnable - Flag to enable the import excel files page.
   * @param {boolean} config.newsPageEnable - Flag to enable the news page.
   */
  constructor({
    accountPageEnable,
    employeesPageEnable,
    loginPageEnable,
    myAddressesPageEnable,
    myOrdersPageEnable,
    newOrdersPageEnable,
    registersPageEnable,
    registerDetailsPageEnable,
    registerCreationPageEnable,
    registerEditingPageEnable,
    faqPageEnable,
    feedbackPageEnable,
    registrationPageEnable,
    resetPasswordPageEnable,
    setPasswordPageEnable,
    ordersTrackingPageEnable,
    orderCreationPageEnable,
    importExcelFilePageEnable,
    archivedOrdersPageEnable,
    orderUpdatingPageEnable,
    newsPageEnable,
  }) {
    this.accountPageEnable = accountPageEnable;
    this.employeesPageEnable = employeesPageEnable;
    this.loginPageEnable = loginPageEnable;
    this.myAddressesPageEnable = myAddressesPageEnable;
    this.myOrdersPageEnable = myOrdersPageEnable;
    this.newOrdersPageEnable = newOrdersPageEnable;
    this.registersPageEnable = registersPageEnable;
    this.registerDetailsPageEnable = registerDetailsPageEnable;
    this.registerCreationPageEnable = registerCreationPageEnable;
    this.registerEditingPageEnable = registerEditingPageEnable;
    this.feedbackPageEnable = feedbackPageEnable;
    this.faqPageEnable = faqPageEnable;
    this.registrationPageEnable = registrationPageEnable;
    this.resetPasswordPageEnable = resetPasswordPageEnable;
    this.setPasswordPageEnable = setPasswordPageEnable;
    this.ordersTrackingPageEnable = ordersTrackingPageEnable;
    this.orderCreationPageEnable = orderCreationPageEnable;
    this.orderUpdatingPageEnable = orderUpdatingPageEnable;
    this.importExcelFilePageEnable = importExcelFilePageEnable;
    this.archivedOrdersPageEnable = archivedOrdersPageEnable;
    this.newsPageEnable = newsPageEnable;
  }

  equal(other) {
    return (
      this.accountPageEnable === other.accountPageEnable &&
      this.employeesPageEnable === other.employeesPageEnable &&
      this.loginPageEnable === other.loginPageEnable &&
      this.myAddressesPageEnable === other.myAddressesPageEnable &&
      this.myOrdersPageEnable === other.myOrdersPageEnable &&
      this.newOrdersPageEnable === other.newOrdersPageEnable &&
      this.registersPageEnable === other.registersPageEnable &&
      this.registerDetailsPageEnable === other.registerDetailsPageEnable &&
      this.registerCreationPageEnable === other.registerCreationPageEnable &&
      this.registerEditingPageEnable === other.registerEditingPageEnable &&
      this.faqPageEnable === other.faqPageEnable &&
      this.feedbackPageEnable === other.feedbackPageEnable &&
      this.registrationPageEnable === other.registrationPageEnable &&
      this.resetPasswordPageEnable === other.resetPasswordPageEnable &&
      this.setPasswordPageEnable === other.setPasswordPageEnable &&
      this.ordersTrackingPageEnable === other.ordersTrackingPageEnable &&
      this.orderCreationPageEnable === other.orderCreationPageEnable &&
      this.orderUpdatingPageEnable === other.orderUpdatingPageEnable &&
      this.importExcelFilePageEnable === other.importExcelFilePageEnable &&
      this.archivedOrdersPageEnable === other.archivedOrdersPageEnable &&
      this.newsPageEnable === other.newsPageEnable
    );
  }
}

/**
 * @typedef {object} UserMapping User object mapping
 *
 * @property {string} first_name the first name of the user
 * @property {string} last_name the last name of the user
 * @property {string} email the email of the user
 */

/**
 * Represents user account information.
 */
export class User {
  /**
   * Creates a new user account info.
   *
   * @param {UserMapping} user user account info
   * @param {string} user.first_name the first name of the user
   * @param {string} user.last_name the last name of the user
   * @param {string} user.email the email of the user
   */
  constructor({ first_name, last_name, email }) {
    this.firstName = first_name;
    this.lastName = last_name;
    this.email = email;
  }
}

/**
 * @typedef {object} OrgMapping Organization object mapping
 *
 * @property {string} name the name of the organization
 * @property {string} gov_number the gov number of the organization
 * @property {string} email the email of the organization
 * @property {string} phone the phone number of the organization
 * @property {string} legal_address the registration (legal) address of the organization
 * @property {string} owner_name the name of the organization owner
 * @property {string} contact_name the name of the contact person within the organization
 * @property {string} contact_phone the phone number of the contact person within the organization
 * @property {string} public_name the public name of the organization
 * @property {string} public_phone the public phone number of the organization
 * @property {string} public_address the public address of the organization
 * @property {boolean} use_public_profile the flag to use public fields of the organization
 */

export class Organization {
  /**
   * Creates a new organization information.
   *
   * @param {OrgMapping} org organization info
   * @param {string} org.name the name of the organization
   * @param {string} org.gov_number the gov number of the organization
   * @param {string} org.email the email of the organization
   * @param {string} org.phone the phone number of the organization
   * @param {string} org.legal_address the registration (legal) address of the organization
   * @param {string} org.owner_name the name of the organization owner
   * @param {string} org.contact_name the name of the contact person within the organization
   * @param {string} org.contact_phone the phone number of the contact person within the organization
   * @param {string} org.public_name the public name of the organization
   * @param {string} org.public_phone the public phone number of the organization
   * @param {string} org.public_address the public address of the organization
   * @param {boolean} org.use_public_profile the flag to use public fields of the organization
   */
  constructor({
    name,
    gov_number,
    email,
    phone,
    legal_address,
    owner_name,
    contact_name,
    contact_phone,
    public_name,
    public_phone,
    public_address,
    use_public_profile,
  }) {
    this.name = name;
    this.govNumber = gov_number;
    this.email = email;
    this.phone = phone;
    this.legalAddress = legal_address;
    this.ownerName = owner_name;
    this.contactName = contact_name;
    this.contactPhone = contact_phone;
    this.publicName = public_name;
    this.publicPhone = public_phone;
    this.publicAddress = public_address;
    this.usePublicProfile = use_public_profile;
  }
}

/**
 * @typedef {object} AccountMapping Account object mapping
 *
 * @property {UserMapping} user the user info
 * @property {OrgMapping} organization the organization info
 * @property {string} role the account role
 */

/**
 * The user account representation.
 *
 * Account unites both user personal and organization information together.
 */
export class Account {
  /**
   * Creates a new account object from the given account information.
   *
   * @param {AccountMapping} account the account information
   * @param {UserMapping} account.user the user info
   * @param {OrgMapping} account.organization the organization info
   * @param {string} account.role the account role
   */
  constructor({ user, organization, role }) {
    this.user = new User(user);
    this.organization = new Organization(organization);
    this.role = role;
  }
}

/**
 * @typedef {object} PostOfficeMapping post office object mapping
 *
 * @property {string} guid the generated UID of the post office
 * @property {string} name the name of the post office
 * @property {string} city_id the ID of the city where the post office is located at
 * @property {boolean} warehouse a flag that determines if the post office is a warehouse
 * @property {string} address the address of the post office
 */

/**
 * Represents a Delivo post office info.
 */
export class PostOffice {
  /**
   * Creates a new Post Office object.
   * @param {PostOfficeMapping} postOffice the post office information
   * @param {string} postOffice.guid the generated UID of the post office
   * @param {string} postOffice.name the name of the post office
   * @param {string} postOffice.city_id the ID of the city where the post office is located at
   * @param {boolean} postOffice.warehouse a flag that determines if the post office is a warehouse
   * @param {string} postOffice.address the address of the post office
   */
  constructor({ guid, name, city_id, warehouse, address }) {
    this.guid = guid;
    this.name = name;
    this.cityId = city_id;
    this.warehouse = warehouse;
    this.address = address;
  }
}

/**
 * @typedef {object} AddressMapping address object mapping
 *
 * @property {number} id the generated ID of the address
 * @property {string} name the name of the address
 * @property {string} city the label of the address city
 * @property {string} address the full address string
 */

/**
 * Represents a users saved address.
 */
export class Address {
  /**
   * Creates a new address object.
   *
   * @param {AddressMapping} addr the address information
   * @param {number} addr.id the generated ID of the address
   * @param {string} addr.name the name of the address
   * @param {string} addr.city_id the ID of the address city
   * @param {string} addr.address the full address string
   */
  constructor({ id, name, city_id, address }) {
    this.id = id;
    this.name = name;
    this.cityId = city_id;
    this.address = address;
  }
}

/**
 * Represents query parameters for an orders list.
 */
export class OrdersListQueryParams {
  /**
   * Creates a new object with query parameters for an orders list.
   *
   * @param {Date} [date_from] start date of the filtering interval
   * @param {Date} [date_to] end date of the filtering interval
   */
  constructor({ date_from = null, date_to = null }) {
    this.date_from = this.toIsoStringOrNull(date_from);
    this.date_to = this.toIsoStringOrNull(date_to);
  }

  toIsoStringOrNull(date) {
    return date ? date.toISOString() : null;
  }
}

/**
 * @typedef {object} ManagerMapping manager object mapping
 *
 * @property {number} id the generated ID of the manager
 * @property {string} email the email of the manager
 * @property {string} first_name the first name of the manager
 * @property {string} last_name the last name of the manager
 * @property {string} company_phone_number the company phone number of the manager
 * @property {string} personal_phone_number the personal phone number of the manager
 * @property {string} work_time_start the work time start of the manager
 * @property {string} work_time_end the work time end of the manager
 * @property {string} extra_contact_info the extra contact info of the manager
 */

/**
 * Represents a support manager info.
 */

export class Manager {
  /**
   * Creates a manager object.
   *
   * @param {ManagerMapping} manager the manager information
   * @param {number} manager.id the generated ID of the address
   * @param {string} manager.email the email of the manager
   * @param {string} manager.first_name the first name of the manager
   * @param {string} manager.last_name the last name of the manager
   * @param {string} manager.company_phone_number the company phone number of the manager
   * @param {string} manager.personal_phone_number the personal phone number of the manager
   * @param {string} manager.work_time_start the work time start of the manager
   * @param {string} manager.work_time_end the work time end of the manager
   * @param {string} manager.extra_contact_info the extra contact info of the manager
   */
  constructor({
    id,
    email,
    first_name,
    last_name,
    company_phone_number,
    personal_phone_number,
    work_time_start,
    work_time_end,
    extra_contact_info,
  }) {
    this.id = id;
    this.email = email;
    this.firstName = first_name;
    this.lastName = last_name;
    this.companyPhoneNumber = company_phone_number;
    this.personalPhoneNumber = personal_phone_number;
    this.workTimeStart = work_time_start;
    this.workTimeEnd = work_time_end;
    this.extraContactInfo = extra_contact_info;
  }
}

class LocalizedEntity {
  /**
   * Returns the name in the specified language.
   * Defaults to English if the specified language is not supported.
   *
   * @param {string} nameEn The name of the entity in English.
   * @param {string} nameKa The name of the entity in Georgian.
   * @param {string} [lang="en"] The language code ('en' for English, 'ka' for Georgian).
   * @returns {string} The name in the specified language, with English as a fallback.
   */
  static getNameByLang(nameEn, nameKa, lang = "en") {
    switch (lang.toLowerCase()) {
      case "ka":
        return nameKa;
      case "en":
      default:
        return nameEn;
    }
  }
}

export class City extends LocalizedEntity {
  /**
   * Creates an instance of the City class.
   *
   * @param {Object} params An object containing the city's details.
   * @param {string} params.name_en The name of the city in English.
   * @param {string} params.name_ka The name of the city in Georgian.
   * @param {number} params.id A unique identifier for the city.
   * @param {string} params.code The city code.
   * @param {boolean} params.is_enabled Flag indicating if the city is enabled.
   * @param {boolean} params.courier_delivery_enabled Flag indicating if courier delivery is enabled for the city.
   * @param {boolean} params.post_office_delivery_enabled Flag indicating if post office delivery is enabled for the city.
   * @param {boolean} params.courier_pickup_enabled Flag indicating if courier pickup is enabled for the city.
   * @param {Object} params.region An object containing the region's details, including:
   * @param {number} params.region.id A unique identifier for the region.
   * @param {string} params.region.name_en The name of the region in English.
   * @param {string} params.region.name_ka The name of the region in Georgian.
   * @param {string} params.region.code The region code
   */
  constructor({
    name_en,
    name_ka,
    id,
    code,
    post_office_delivery_enabled,
    is_enabled,
    courier_delivery_enabled,
    courier_pickup_enabled,
    region,
  }) {
    super();
    this.nameEn = name_en;
    this.nameKa = name_ka;
    this.id = id;
    this.cityCode = code;
    this.isEnabled = is_enabled;
    this.courierDeliveryEnabled = courier_delivery_enabled;
    this.postOfficeDeliveryEnabled = post_office_delivery_enabled;
    this.courierPickupEnabled = courier_pickup_enabled;
    this.region = new Region(region);
  }

  getName(lang = "en") {
    return City.getNameByLang(this.nameEn, this.nameKa, lang);
  }
}

export class Region extends LocalizedEntity {
  /**
   * Creates an instance of the Region class.
   *
   * @param {Object} params An object containing the region's details.
   * @param {string} params.name_en The name of the region in English.
   * @param {string} params.name_ka The name of the region in Georgian.
   * @param {number} params.id A unique identifier for the region.
   * @param {string} params.code The region code
   */
  constructor({ name_en, name_ka, id, code }) {
    super();
    this.nameEn = name_en;
    this.nameKa = name_ka;
    this.id = id;
    this.code = code;
  }

  getName(lang = "en") {
    return Region.getNameByLang(this.nameEn, this.nameKa, lang);
  }
}

/**
 * Represents an individual Frequently Asked Question (FAQ) item.
 */
export class FAQItem {
  /**
   * Creates an instance of FAQItem.
   * @param {Object} params An object containing the faq details.
   * @param {number} id The unique identifier for the FAQ item.
   * @param {string} creation_date The ISO string of the creation date.
   * @param {string} update_date The ISO string of the last update date.
   * @param {string} question_en The question text in English.
   * @param {string} question_ka The question text in Georgian.
   * @param {boolean} is_published Indicates whether the FAQ is published.
   * @param {string} preview_message_en The preview message in English.
   * @param {string} preview_message_ka The preview message in Georgian.
   * @param {string} full_message_en The full message text in English.
   * @param {string} full_message_ka The full message text in Georgian.
   * @param {string} slug The URL slug associated with this FAQ item.
   * @param {number} priority The display priority of the FAQ item.
   * @param {Array<Object>} related An array of objects that describe related FAQs.
   */
  constructor({
    id,
    creation_date,
    update_date,
    question_en,
    question_ka,
    is_published,
    preview_message_en,
    preview_message_ka,
    full_message_en,
    full_message_ka,
    slug,
    priority,
    related = [],
  }) {
    this.id = id;
    this.creationDate = new Date(creation_date);
    this.updateDate = new Date(update_date);
    this.questionEn = question_en;
    this.questionKa = question_ka;
    this.isPublished = is_published;
    this.previewMessageEn = preview_message_en;
    this.previewMessageKa = preview_message_ka;
    this.fullMessageEn = full_message_en;
    this.fullMessageKa = full_message_ka;
    this.slug = slug;
    this.priority = priority;
    this.related = related.map((r) => ({
      questionEn: r.question_en,
      questionKa: r.question_ka,
      slug: r.slug,
      priority: r.priority,
    }));
  }
}

/**
 * @typedef {object} NewsMapping News object mapping
 *
 * @property {number} id The unique identifier of the news.
 * @property {string} creation_date The creation date of the news.
 * @property {string} update_date The update date of the news.
 * @property {string} title_en The title of the news in English.
 * @property {string} title_ka The title of the news in Georgian.
 * @property {string} publishing_time The publishing time of the news.
 * @property {string} preview_message_en The preview message of the news in English.
 * @property {string} preview_message_ka The preview message of the news in Georgian.
 * @property {string} full_message_en The full message of the news in English.
 * @property {string} full_message_ka The full message of the news in Georgian.
 * @property {string} slug The slug of the news.
 */

/**
 * Represents a news item.
 */
export class NewsItem {
  /**
   * Creates a news item object.
   *
   * @param {NewsMapping} news The news information.
   * @param {number} news.id The unique identifier of the news.
   * @param {string} news.creation_date The creation date of the news.
   * @param {string} news.update_date The update date of the news.
   * @param {string} news.title_en The title of the news in English.
   * @param {string} news.title_ka The title of the news in Georgian.
   * @param {string} news.publishing_time The publishing time of the news.
   * @param {string} news.preview_message_en The preview message of the news in English.
   * @param {string} news.preview_message_ka The preview message of the news in Georgian.
   * @param {string} news.full_message_en The full message of the news in English.
   * @param {string} news.full_message_ka The full message of the news in Georgian.
   * @param {string} news.slug The slug of the news.
   */
  constructor({
    id,
    creation_date,
    update_date,
    title_en,
    title_ka,
    publishing_time,
    preview_message_en,
    preview_message_ka,
    full_message_en,
    full_message_ka,
    slug,
  }) {
    this.id = id;
    this.creationDate = new Date(creation_date);
    this.updateDate = new Date(update_date);
    this.titleEn = title_en;
    this.titleKa = title_ka;
    this.publishingTime = new Date(publishing_time);
    this.previewMessageEn = preview_message_en;
    this.previewMessageKa = preview_message_ka;
    this.fullMessageEn = full_message_en;
    this.fullMessageKa = full_message_ka;
    this.slug = slug;
  }
}
