import { Field, useFormikContext } from "formik";
import { useLocationsContext } from "../../providers/LocationsProvider/LocationsProvider";
import React, { useCallback } from "react";
import { Box, ListItemIcon, ListItemText, MenuItem, TextField } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useAddresses } from "../../hooks/useAddresses";
import { useTranslation } from "react-i18next";
import { TooltipWrapper } from "../tooltipWrapper/TooltipWrapper";

/**
 * `AddressSelectField` is a dropdown select field component for choosing an address.
 * This component updates other related form fields (`pickupCity`, `pickupAddress`, `pickupAddressId`) based on the user's address selection.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.fieldName - The name of the field in the formik context to associate with the selected address.
 * @param {string} [props.className="w-1/3"] - The CSS class for styling the component. Defaults to "w-1/3".
 * @param {string} props.value - The current value of the `AddressSelectField`, correlating to a selected address ID.
 */
export const AddressSelectField = ({ fieldName, className = "w-full md:w-1/3", value }) => {
  const { t } = useTranslation();
  const { getCityById } = useLocationsContext();
  const { addresses } = useAddresses();
  const { setFieldValue, values, touched, errors } = useFormikContext();

  const updateCityIdAndAddress = useCallback(
    (selectedId) => {
      const selectedAddress = addresses.find((address) => address.id === selectedId);
      if (selectedAddress) {
        const { cityId, address } = selectedAddress;
        setFieldValue("pickupCity", cityId);
        setFieldValue("pickupAddress", address);
        setFieldValue("pickupAddressId", selectedId);
      } else {
        setFieldValue("pickupCity", "");
        setFieldValue("pickupAddress", "");
        setFieldValue("pickupAddressId", "");
      }
    },
    [addresses, setFieldValue],
  );

  const addressLabelFor = (address) => {
    const addressCity = getCityById(address.cityId);
    const foundCity = addressCity ? addressCity.city : t("tooltips.noCity");
    const { name, address: addressText } = address;
    const hasName = !!name;

    const label = hasName
      ? `${name}: ${foundCity}, ${addressText}`
      : `${foundCity}, ${addressText}`;

    return {
      address: label,
      isCourier: addressCity?.courierPickupEnabled,
      isCity: !!addressCity,
    };
  };

  const handleChange = useCallback(
    (event) => {
      updateCityIdAndAddress(event.target.value);
    },
    [updateCityIdAndAddress],
  );

  return (
    <Field
      as={TextField}
      select
      name={fieldName}
      label={t("orderCreation.clientPartSelectAddress")}
      className={className}
      variant="standard"
      value={value}
      inputProps={{ maxLength: 255 }}
      helperText={touched[fieldName] && errors[fieldName]}
      error={touched[fieldName] && Boolean(errors[fieldName])}
      onChange={handleChange}
      sx={{ maxWidth: "100%" }}
    >
      {addresses.map((option) => {
        const { address, isCourier, isCity } = addressLabelFor(option);
        const disabled = !isCourier || !isCity;
        const tooltipTitle = !isCity ? t("tooltips.noCity") : t("tooltips.noCourierPickup");

        return !disabled ? (
          <MenuItem key={option.id} value={option.id} className="flex items-center">
            <Box
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              }}
            >
              {address}
            </Box>
          </MenuItem>
        ) : (
          <TooltipWrapper title={tooltipTitle} key={option.id}>
            <div>
              <MenuItem value={option.id} disabled>
                <ListItemText>{address}</ListItemText>
                <ListItemIcon>
                  <InfoIcon fontSize="small" />
                </ListItemIcon>
              </MenuItem>
            </div>
          </TooltipWrapper>
        );
      })}
    </Field>
  );
};
