import React from "react";

export const FeedbackIcon = (props) => (
  <svg
    {...props}
    width="13"
    height="15"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5559 0.550552C14.3168 0.389422 14.0417 0.289694 13.7549 0.260189C13.468 0.230683 13.1784 0.27231 12.9115 0.381388L5.30026 3.48293H3.00312C2.25157 3.4837 1.53099 3.78243 0.999351 4.31364C0.467707 4.84484 0.168382 5.56518 0.166992 6.31672V8.78536C0.168241 9.44936 0.40248 10.0919 0.828878 10.6009C1.25527 11.1099 1.84678 11.4531 2.50029 11.5707V15.9999C2.50029 16.464 2.68467 16.9092 3.01285 17.2373C3.34103 17.5655 3.78615 17.7499 4.25027 17.7499C4.71439 17.7499 5.15951 17.5655 5.48769 17.2373C5.81587 16.9092 6.00025 16.464 6.00025 15.9999V11.9085L12.9115 14.7254C13.1785 14.8342 13.4681 14.8758 13.7549 14.8463C14.0417 14.8168 14.3169 14.7173 14.5561 14.5565C14.7954 14.3956 14.9915 14.1784 15.127 13.924C15.2626 13.6695 15.3335 13.3856 15.3335 13.0973V2.00945C15.3337 1.7211 15.2629 1.43714 15.1272 1.18269C14.9916 0.928229 14.7954 0.711116 14.5559 0.550552ZM1.33364 8.78769V6.31672C1.33411 5.87409 1.51015 5.44972 1.82313 5.13674C2.13612 4.82375 2.56049 4.64771 3.00312 4.64725H4.8336V10.4583H3.00312C2.56029 10.4579 2.13574 10.2817 1.82272 9.96843C1.5097 9.65519 1.3338 9.23052 1.33364 8.78769ZM4.25027 16.5833C4.09556 16.5833 3.94719 16.5218 3.8378 16.4124C3.7284 16.303 3.66694 16.1546 3.66694 15.9999V11.625H4.8336V15.9999C4.8336 16.1546 4.77214 16.303 4.66274 16.4124C4.55335 16.5218 4.40498 16.5833 4.25027 16.5833ZM14.1668 13.0973C14.167 13.1946 14.1433 13.2904 14.0976 13.3763C14.0519 13.4622 13.9858 13.5355 13.905 13.5897C13.8243 13.644 13.7314 13.6774 13.6346 13.6872C13.5378 13.6969 13.4401 13.6827 13.3501 13.6456L6.00025 10.6485V4.45825L13.3501 1.46171C13.44 1.42454 13.5377 1.41015 13.6345 1.4198C13.7313 1.42945 13.8243 1.46286 13.905 1.51705C13.9858 1.57123 14.052 1.64453 14.0977 1.73043C14.1433 1.81633 14.1671 1.91217 14.1668 2.00945V13.0973Z"
      fill="#0A0A0A"
    />
  </svg>
);
