import { createContext, useContext } from "react";
import { useCities } from "../../hooks/useCities";
import { City } from "../../api/types";
import { useRegions } from "../../hooks/useRegions";

/**
 * A placeholder function for getCityById within the default context value.
 *
 * @param {string} id - The unique identifier for the city.
 * @returns {City} - Always returns empty object in the default context.
 */
const defaultGetCityById = (id) => {};
/**
 * @typedef {Object} CityContextValue
 * @property {City[]} cities - An array intended to contain `City` instances. It is empty by default.
 * @property {boolean} loading - A boolean flag set to true by default.
 * @property {Function} getCityById - A placeholder function that mimics the signature of the actual `getCityById` function.
 * @property {Map<string, City[]>} regionCitiesMap - Map of cities organized by region ID. It is empty by default.
 * @property {Region[]} regions - An array intended to contain `Region` instances. It is empty by default.
 * @property {boolean} regionLoading - A boolean flag set to true by default.
 */
const defaultLocationsContextValue = {
  cities: [],
  loading: true,
  getCityById: defaultGetCityById,
  regionCitiesMap: {},
  regions: [],
  regionLoading: true,
};

const LocationsContext = createContext(defaultLocationsContextValue);

export const LocationsProvider = ({ children }) => {
  const citiesValues = useCities();
  const regionValues = useRegions();
  const context = { ...citiesValues, ...regionValues };

  return <LocationsContext.Provider value={context}>{children}</LocationsContext.Provider>;
};

/**
 * Custom hook to consume cities context.
 * @returns {CityContextValue} The current cities information and loading state from context.
 * @throws Will throw an error if used outside the LocationsProvider component.
 */
export const useLocationsContext = () => {
  const context = useContext(LocationsContext);
  if (!context) {
    throw new Error("useLocationsContext must be used within an LocationsProvider");
  }
  return context;
};
