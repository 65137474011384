import React from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

function RadioGroupField({
  name,
  value,
  onChange,
  labels,
  className,
  labelClassName,
  disabled,
  ...props
}) {
  return (
    <FormControl>
      <RadioGroup row={true} name={name} value={value} onChange={onChange} className={className}>
        {labels.map((label, ind) => (
          <FormControlLabel
            key={label.value}
            value={label.value}
            control={<Radio />}
            label={label.label}
            disabled={disabled}
            className={labelClassName || `${ind === 0 ? "xl:w-1/4" : "flex-grow"} pr-2 md:pr-14`}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioGroupField;
