import { ADDRESSES } from "./delivo-api-config";
import { Address } from "./types";
import { getParams } from "./DelivoApi";
import delivoAxios from "./delivoAxios";

/**
 * Delivo API for working with addresses.
 */
class DelivoAddressApi {
  /**
   * Returns a list of the available user addresses.
   *
   * @returns {Promise<Address[]>}
   */
  async getAddresses() {
    try {
      const params = await getParams();

      const response = await delivoAxios.get(ADDRESSES, params);
      if (response.status !== 200) {
        console.error("Failed retrieve user addresses.", response);
        throw Error("Failed to retrieve addresses.");
      }
      const userAddresses = response.data;
      console.debug("userAddresses", userAddresses);
      return userAddresses.map((userAddress) => new Address(userAddress));
    } catch (e) {
      console.error("User addresses retrieval failed.", e);
      throw e;
    }
  }

  /**
   * Adds a new address to the user's addresses.
   *
   * @param {object} addr the new address to be added.
   * @param {string} addr.name the name of the address
   * @param {string} addr.cityId the ID of the address city
   * @param {string} addr.address the full address string
   *
   * @returns {Promise<Address>}
   */
  async addAddress({ name, cityId, address }) {
    const params = await getParams();

    const response = await delivoAxios.post(
      ADDRESSES,
      { name, city_id: cityId, address },
      params,
    );
    if (response.status !== 201) {
      console.error(
        `Failed add a new address named "${name}" in city ${cityId}: ${address}.`,
        response,
      );
      throw Error(`Failed add a new address named "${name}" in city ${cityId}: ${address}.`);
    }
    const addedAddress = response.data;
    return new Address(addedAddress);
  }

  /**
   * Returns a list of the available user addresses.
   *
   * @param {Address} address the address to be updated.
   *
   * @returns {Promise<Address>}
   */
  async updateAddress(address) {
    const params = await getParams();
    const data = { name: address.name, city_id: address.cityId, address: address.address };
    const response = await delivoAxios.put(`${ADDRESSES}${address.id}/`, data, params);
    if (response.status !== 200) {
      console.error(`Failed to update address ${address}.`, response);
      throw new Error(`Failed to update address ${address}.`);
    }
    const updatedAddress = response.data;
    return new Address(updatedAddress);
  }

  /**
   * Updates fields of the address with the given ID.
   *
   * @param {number} id the ID of the address to be updated
   * @param {object} addr the fields of the address to be updated.
   * @param {string} [addr.name] the name of the address
   * @param {string} [addr.city] the ID of the address city
   * @param {string} [addr.address] the full address string
   *
   * @returns {Promise<Address>}
   */
  async updateAddressFields(id, { name, city, address }) {
    try {
      const params = await getParams();
      const data = {};
      if (name) {
        data.name = name;
      }
      if (city) {
        data.city = city;
      }
      if (address) {
        data.address = address;
      }
      const response = await delivoAxios.patch(`${ADDRESSES}/${id}`, data, params);
      if (response.status !== 200) {
        console.error(`Failed to partial update address ${address}.`, response);
        throw new Error(`Failed to partial update address ${address}.`);
      }
      const updatedAddress = response.data;
      return new Address(updatedAddress);
    } catch (e) {
      console.error(`Address ${address} partial update failed.`, e);
      throw e;
    }
  }

  /**
   * Deletes address with the given ID.
   *
   * @param {number} id the ID of the address to be deleted
   *
   * @returns {Promise<boolean>}
   */
  async deleteAddress(id) {
    const params = await getParams();
    const response = await delivoAxios.delete(`${ADDRESSES}/${id}`, params);
    if (response.status !== 204) {
      console.error(`Failed to delete address ${id}.`, response);
      return false;
    }
    return true;
  }
}

const addressApi = new DelivoAddressApi();
export { addressApi };
