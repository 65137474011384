import React from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logoYellowSmall from "../../images/logoBlackSmall.svg";
import { LanguageSwitch } from "../languageSwitch/LanguageSwitch";
import BurgerMenu from "./BurgerMenu";
import { AccountInfoHeader } from "../accoutnInfo/accountInfoHeader/AccountInfoHeader";
import { useAccountContext } from "../../providers/AccountProvider/AccountProvider";
import NewsMenuButton from "../news/NewsMenuButton";

function NavigatorMenu() {
  const { t } = useTranslation();

  return (
    <Box
      className={
        "flex justify-between items-center shadow-md py-3 px-5 bg-color-main md:bg-white  max-w-full"
      }
    >
      <Box className="flex items-center">
        <BurgerMenu />
        <Link to="/">
          <img src={logoYellowSmall} alt={t("alts.logoYellow")} />
        </Link>
      </Box>
      <Box className="flex items-center justify-end">
        <AccountInfoHeader />
        <NewsMenuButton />
        <LanguageSwitch />
      </Box>
    </Box>
  );
}

export default NavigatorMenu;
