import React, { useMemo, useRef } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { object, string } from "yup";
import ButtonComponent from "../../components/buttonComponent/ButtonComponent";
import { ReCAPTCHAVerification, ResetPasswordRequest } from "../../api/types";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../recaptcha-config";
import { delivoApi } from "../../api";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import LoadingBackdrop from "../../components/loadingBackdrop/LoadingBackdrop";
import showApiErrorNotification from "../../components/showApiErrorNotification";
import logoBlack from "../../images/logoBlack.svg";
import { ResetPasswordPageGuide } from "./ResetPasswordInstructions";

const preparePostData = (data) => {
  return data.email.toLowerCase();
};

function handleResetPasswordFormSubmit(captchaRef, enqueueSnackbar, t) {
  return async (values, actions) => {
    const recaptchaToken = captchaRef.current.getValue();
    if (!recaptchaToken) {
      enqueueSnackbar(t("errorsMessages.recaptchaInvalid"), { variant: "error" });
      //TODO: add error handling on the UI.
      throw new Error(
        "reCAPTCHA verification token is not available. Can't proceed with reset password.",
      );
    }
    const recaptcha = new ReCAPTCHAVerification(recaptchaToken, "resetPassword");
    const resetRequest = preparePostData(values);
    const request = new ResetPasswordRequest(recaptcha, resetRequest);
    try {
      const passwordResetLinkSent = await delivoApi.resetPassword(request);
      if (passwordResetLinkSent) {
        console.info("Password reset link successfully sent.");
        enqueueSnackbar(t("alertsMessages.resetPasswordSend"), { variant: "success" });
        actions.resetForm();
      } else {
        enqueueSnackbar(t("alertsMessages.resetPasswordUnhandledError"), {
          variant: "error",
        });
      }
    } catch (error) {
      showApiErrorNotification(
        t("alertsMessages.resetPasswordUnhandledError"),
        error,
        enqueueSnackbar,
      );
    }
  };
}

function ResetPassword() {
  const { t, i18n } = useTranslation();
  const captchaRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = useMemo(
    () =>
      object({
        email: string()
          .required(t("errorsMessages.emailRequire"))
          .email(t("errorsMessages.emailValidation"))
          .max(125, t("errorsMessages.maxEmailLength")),
      }),
    [t],
  );

  const formSubmitHandler = handleResetPasswordFormSubmit(captchaRef, enqueueSnackbar, t);

  return (
    <Box
      className={
        "flex justify-center md:justify-start shadow-md rounded-md md:m-5 flex-col md:flex-row"
      }
    >
      <Box
        className={
          "flex bg-color-main flex-col items-center md:w-1/4 md:min-w-[300px] xl:min-w-[350px] 2xl:min-w-[400px]"
        }
      >
        <Link to={"/"}>
          <img
            src={logoBlack}
            alt={t("alts.logoBlack")}
            className={"w-32 md:w-36 md:mt-12 md:mb-8 smMax:hidden"}
          />
        </Link>
        <ResetPasswordPageGuide />
      </Box>
      <Box className={"min-h-max flex-grow m-3 justify-self-center"}>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={validationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className="flex flex-col items-center justify-around p-2 sm:mt-5">
              <Typography variant="h4">{t("resetPasswordButton")}</Typography>
              <Field
                as={TextField}
                error={Boolean(errors.email) && Boolean(touched.email)}
                helperText={Boolean(touched.email) && errors.email}
                label={t("loginForm.emailFieldLabel")}
                name="email"
                placeholder="example@mail.com"
                type="email"
                className="w-full sm:w-2/3 md:w-1/2 m-4"
                inputProps={{ maxLength: 125 }}
              />
              <Box className="flex flex-col items-center justify-center w-full m-4">
                <ReCAPTCHA
                  sitekey={RECAPTCHA_SITE_KEY}
                  ref={captchaRef}
                  hl={i18n.language}
                  onChange={(values) => {
                    console.debug("reCAPTCHA completed", values);
                  }}
                  onErrored={(values) => {
                    console.error("reCAPTCHA errored", values);
                    enqueueSnackbar(t("alertsMessages.recaptchaError"), { variant: "error" });
                  }}
                  onExpired={(values) => {
                    console.warn(
                      "reCAPTCHA challenge expired. Another verification required.",
                      values,
                    );
                    enqueueSnackbar(t("alertsMessages.recaptchaExpired"), {
                      variant: "warning",
                    });
                  }}
                />
              </Box>
              <Box className="flex flex-col items-center">
                <LoadingBackdrop isVisible={isSubmitting} />
                <ButtonComponent
                  text={t("resetPasswordButton")}
                  disabled={isSubmitting}
                  type="submit"
                />
              </Box>
              <Link to={"/login"} className="text-gray-400 text-s mt-3 mb-5">
                {t("goToLogin")}
              </Link>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default ResetPassword;
