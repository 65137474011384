import { useCallback, useEffect, useState } from "react";
import { ordersApi } from "../api/DelivoOrdersApi";
import { useLocationsContext } from "../providers/LocationsProvider/LocationsProvider";
import { useTranslation } from "react-i18next";

/**
 * @typedef {Object} StatusChange
 * @property {string} StatusChange.status_date - The date of the status change.
 * @property {string} StatusChange.shipment_status - The shipment status.
 * @property {string} StatusChange.post_office_name - The post office name.
 * @property {string} StatusChange.post_office_guid - The post office GUID.
 * @property {string} StatusChange.status_comment - The status comment.
 */

/**
 * Represents information about an order.
 *
 * @typedef {Object} OrderInfo - Order information object
 * @property {string} orderDate - The date of the order.
 * @property {string} deliveryDate - The delivery date of the order.
 * @property {string} trackingCode - The tracking code of the order.
 * @property {string} senderEmail - The email address of the sender.
 * @property {string} status - The shipment status of the order.
 * @property {string} pickupCity - The city name where the order is picked up.
 * @property {string} pickupCityId - The city ID where the order is picked up.
 * @property {string} pickupAddress - The address where the order is picked up.
 * @property {string} pickupPostOffice - The post office guid where the order is picked up.
 * @property {number} deliveryCityId - The city ID where the order is delivered.
 * @property {number} deliveryCity - The city name where the order is delivered.
 * @property {string} deliveryAddress - The address where the order is delivered.
 * @property {string} deliveryPostOffice - The post office guid where the order is delivered.
 * @property {string} senderCompanyName - The name of the sender's company.
 * @property {string} senderGovNumber - The government number of the sender's company.
 * @property {string} senderPhone - The phone number of the sender.
 * @property {boolean} isParcel - The type of the parcel.
 * @property {string} weight - The weight of the parcel.
 * @property {string} estimatedCost - The estimated cost of the order.
 * @property {boolean} cod - The cash on delivery amount.
 * @property {number} codCommission - The commission amount for cash on delivery.
 * @property {string} description - The description of the order.
 * @property {number} length - The length of the parcel in cm.
 * @property {number} width - The width of the parcel in cm.
 * @property {number} height - The height of parcel in cm.
 * @property {string} recipientName - The name of the recipient.
 * @property {string} recipientType - The type of the recipient.
 * @property {string} recipientCompany - The company of the recipient.
 * @property {string} recipientGovNumber - The government number of the recipient.
 * @property {string} recipientPhone - The phone number of the recipient.
 * @property {string} deliveryCost - The delivery cost of the order.
 * @property {string} insurance - The insurance cost of the order.
 * @property {boolean} paymentByRecipient - Payment type.
 * @property {Array<StatusChange>} statusChanges - Array of status changes objects for each step.
 * @property {string} stepStatus - Status of shipment steps.
 * @property {string} stepDate - Dates of shipment steps.
 * @property {string} stepPostOffice - Post office name for each step.
 * @property {string} stepComment - Comment for status in step.
 * @property {boolean} fragile - Fragile of order.
 * @property {boolean} insured - Insures of order.
 * @property {string} customTag - The custom tag of order.
 * @property {boolean} isArchived - The flag shows if of order in archived or not.
 * @property {string} recipientCompanyRepresentative - The representative of the recipient's company.
 * @property {boolean} codCommissionByRecipient - Payment type of COD commission.
 * @property {string} internationalTrackingNumber - The international tracking number.
 */

/**
 * @type {OrderInfo}
 */
export const EMPTY_ORDER = {
  orderDate: "",
  deliveryDate: "",
  trackingCode: "",
  senderEmail: "",
  status: "",
  pickupCityId: "",
  pickupCity: "",
  pickupAddress: "",
  pickupPostOffice: "",
  deliveryCity: "",
  deliveryCityId: "",
  deliveryAddress: "",
  deliveryPostOffice: "",
  senderCompanyName: "",
  senderGovNumber: "",
  senderPhone: "",
  isParcel: false,
  weight: "",
  estimatedCost: "",
  cod: false,
  codCommission: 0,
  description: "",
  length: 0,
  width: 0,
  height: 0,
  recipientName: "",
  recipientGovNumber: "",
  recipientCompanyRepresentative: "",
  recipientType: "",
  recipientPhone: "",
  deliveryCost: "",
  insurance: "",
  paymentByRecipient: false,
  stepStatus: "",
  stepDate: "",
  stepPostOffice: "",
  statusChanges: [],
  statusComment: "",
  fragile: false,
  insured: false,
  customTag: "",
  isArchived: false,
  codCommissionByRecipient: true,
  internationalTrackingNumber: "",
};

/**
 *
 * @param orderId ID of selected order

 * @returns {{loading: boolean, order: OrderInfo}}
 */
export function useOrder(orderId) {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(EMPTY_ORDER);
  const { getCityById } = useLocationsContext();
  const { t } = useTranslation();

  const fetchOrderData = useCallback(
    async (orderId) => {
      try {
        const orderData = await ordersApi.getOrder(orderId);
        const pickupCity = getCityById(orderData.departure_city_id);
        const deliveryCity = getCityById(orderData.destination_city_id);

        if (!pickupCity) {
          throw new Error(
            t("alertsMessages.cityNotFoundOrder", {
              cityType: "pickup",
              orderId: orderId,
              cityId: orderData.departure_city_id,
            }),
          );
        }

        if (!deliveryCity) {
          throw new Error(
            t("alertsMessages.cityNotFoundOrder", {
              cityType: "delivery",
              orderId: orderId,
              cityId: orderData.destination_city_id,
            }),
          );
        }
        const statusChanges = orderData.status_changes.map((statusChange) => ({
          stepStatus: statusChange.shipment_status,
          stepDate: statusChange.status_date,
          stepPostOffice: statusChange.post_office_name,
          stepComment: statusChange.status_comment,
        }));

        const prepareOrderData = {
          orderDate: orderData.order_date,
          deliveryDate: orderData.delivery_date,
          trackingCode: orderData.tracking_code,
          senderEmail: orderData.sender.email,
          status: orderData.shipment_status,
          pickupCityId: pickupCity.id,
          pickupCity: pickupCity.city,
          pickupPostOffice: orderData.sender.post_office.guid,
          pickupAddress:
            orderData.sender.address ||
            `${orderData.sender.post_office.name}, ${orderData.sender.post_office.address}`,
          deliveryCityId: deliveryCity.id,
          deliveryCity: deliveryCity.city,
          deliveryPostOffice: orderData.recipient.post_office.guid,
          deliveryAddress:
            orderData.recipient.address ||
            `${orderData.recipient.post_office.name}, ${orderData.recipient.post_office.address}`,
          senderCompanyName: orderData.sender.name,
          senderGovNumber: orderData.sender.gov_number,
          senderPhone: orderData.sender.phone,
          isParcel: orderData.sending_documents,
          weight: orderData.weight,
          estimatedCost: orderData.estimated_cost,
          cod: orderData.cod,
          codCommission: orderData.cod_commission_amount,
          description: orderData.description,
          length: orderData.dimensions.length,
          width: orderData.dimensions.width,
          height: orderData.dimensions.height,
          recipientName:
            orderData.recipient.alias === ""
              ? orderData.recipient.name
              : orderData.recipient.alias,
          recipientGovNumber: orderData.recipient.gov_number,
          recipientPhone: orderData.recipient.phone,
          recipientCompanyRepresentative: orderData.recipient_organizations_representative,
          recipientType: orderData.recipient_type_of_client,
          deliveryCost: orderData.delivery_cost,
          insurance: orderData.insurance_cost,
          paymentByRecipient: orderData.payment_by_recipient,
          statusChanges: statusChanges,
          fragile: orderData.fragile,
          insured: orderData.insured,
          customTag: orderData.client_custom_attribute,
          isArchived: orderData.is_archived,
          codCommissionByRecipient: orderData.cod_by_recipient,
          internationalTrackingNumber: orderData.international_tracking_number,
        };
        setOrder(prepareOrderData);
      } catch (error) {
        console.error("Error fetching order", error);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [t, getCityById],
  );

  useEffect(() => {
    if (orderId) {
      fetchOrderData(orderId);
    } else {
      console.debug("No order ID provided. Skipping order fetch.");
    }
  }, [fetchOrderData, orderId]);

  const deleteOrder = async () => {
    /**
     * Deletes a single order.
     *
     * @returns {Promise<boolean>} - A promise that resolves to `true` if the deletion is successful, otherwise `false`.
     */
    return await ordersApi.deleteOrder(orderId);
  };

  const archiveOrder = async () => {
    /**
     * Archive order.
     *
     * @returns {Promise<boolean>} - A promise that resolves to `true` if the order archived,  otherwise `false`.
     */
    const archivedOrder = await ordersApi.archiveOrders([orderId]);
    if (archivedOrder) {
      setOrder({ ...order, isArchived: true });
      console.info("Order archived successfully");
    }
    return archivedOrder;
  };

  const unarchiveOrder = async () => {
    /**
     * Unarchives order, making them active again.
     *
     * @returns {Promise<boolean>} - A promise that resolves with the unarchived order if successful.
     */
    const unarchivedOrder = await ordersApi.unarchiveOrders([orderId]);
    if (unarchivedOrder) {
      setOrder({ ...order, isArchived: false });
      console.info("Order unarchived successfully");
    }
    return unarchivedOrder;
  };

  return { order, loading, archiveOrder, unarchiveOrder, deleteOrder };
}
