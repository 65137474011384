import { useEffect, useMemo, useState } from "react";
import { delivoApi } from "../api";

/**
 * @type {Manager} an object with the manager information
 */

const initialState = {
  email: "",
  firstName: "",
  lastName: "",
  companyPhone: "",
  personalPhone: "",
  workHourStart: "",
  workHourEnd: "",
  extraContactInfo: "",
};

/**
 * Custom hook to fetch a manager information.
 * @returns {Manager}  an object with the manager information
 */
export function useManagerInfo() {
  const [managerInfo, setManagerInfo] = useState(initialState);
  const [loading, setLoading] = useState(true);

  const fetchManagerInfo = useMemo(
    () => async () => {
      try {
        const managerInfoData = await delivoApi.getManager();
        if (managerInfoData === null) {
          setLoading(false);
          return;
        }
        const newPreparedData = {
          email: managerInfoData.email,
          firstName: managerInfoData.firstName,
          lastName: managerInfoData.lastName,
          companyPhone: managerInfoData.companyPhoneNumber,
          personalPhone: managerInfoData.personalPhoneNumber,
          workHourStart: managerInfoData.workTimeStart,
          workHourEnd: managerInfoData.workTimeEnd,
          extraContactInfo: managerInfoData.extraContactInfo,
        };
        setLoading(false);
        setManagerInfo(newPreparedData);
      } catch (error) {
        console.error("Error fetching manager info", error);
      }
    },

    [],
  );
  useEffect(() => {
    fetchManagerInfo();
  }, [fetchManagerInfo]);

  return { managerInfo, loading };
}
