import React from "react";
import FailedRowCard from "./FailedRowCard";
import { VariableSizeList } from "react-window";
import { useItemsPerScreenSize } from "../../hooks/useItemsPerScreenSize";
import { useItemSizePerErrorCount } from "./calculateItemSizePerErrorCount";

const FailedRowsList = ({ errorData }) => {
  const itemCount = errorData.failed_rows.length;

  const handleContainerHeight = useItemsPerScreenSize({
    items: {
      sm: 600,
      lg: 480,
      xl: 650,
      xxl: 700,
      default: 500,
    },
  });

  const calculateItemSize = useItemSizePerErrorCount({ minSize: 120, maxSize: 220 });

  return (
    <VariableSizeList
      height={handleContainerHeight}
      itemCount={itemCount}
      itemSize={(index) => calculateItemSize(errorData.failed_rows[index].errors.length)}
      overscanCount={5}
      className={"overflow-y-hidden h-fit"}
    >
      {({ index, style }) => (
        <div style={style} className={"my-2"}>
          <FailedRowCard failedRow={errorData.failed_rows[index]} index={index} />
        </div>
      )}
    </VariableSizeList>
  );
};

export default FailedRowsList;
