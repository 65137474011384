/**
 * Handles confirmation and executes an action based on user confirmation.
 * @param {function[void, Promise<void>]} confirmationFunction - A function used to trigger the confirmation window.
 * @param {object} confirmationDetails - Details for the confirmation dialog.
 * @param {string} confirmationDetails.description - Description shown in the confirmation dialog.
 * @param {(function[void, Promise<void>])} action - The action to be executed upon user confirmation.
 * @param {function} t - The translations.
 */
export const handleConfirmation = async (
  confirmationFunction,
  confirmationDetails,
  action,
  t,
) => {
  try {
    await confirmationFunction({
      title: t("confirmation.confirmationTitle"),
      description: confirmationDetails.description,
      confirmationText: t("confirmation.confirmBtnSuccess"),
      cancellationText: t("confirmation.confirmBtnCancel"),
      dialogActionsProps: {
        sx: {
          mb: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
      dialogProps: {
        sx: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
      confirmationButtonProps: {
        sx: {
          width: "50%",
        },
      },
      cancellationButtonProps: {
        sx: {
          width: "30%",
          color: "#000",
        },
      },
      titleProps: {
        sx: {
          textAlign: "center",
        },
      },
    });
    await action();
  } catch (error) {
    console.error("Action cancelled or error occurred", error);
  }
};
