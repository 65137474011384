import React from "react";
import { Box } from "@mui/material";
import ContentSideForm from "../../contentSideForm";
import { Outlet } from "react-router-dom";
import Footer from "../../footer/Footer";
import { useTranslation } from "react-i18next";
import { GuestHeader } from "./GuestHeader";

function GuestLayout() {
  const { t } = useTranslation();
  return (
    <>
      <Box className="flex flex-col flex-grow">
        <GuestHeader />
        <Box className={"flex justify-center md:justify-start shadow-md rounded-md m-5"}>
          <Box
            className={
              "hidden md:flex text-black pl-0 bg-color-main md:w-1/4 border-box md:min-w-[300px] xl:min-w-[350px] 2xl:min-w-[470px] max-w-[470px]"
            }
          >
            <ContentSideForm />
          </Box>
          <Box className={"min-h-max flex-grow m-3 justify-self-center max-w-full"}>
            <Outlet />
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default GuestLayout;
