import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import DataGridTable, { withDefaults } from "../dataGrid/DataGridTable";
import ButtonComponent from "../buttonComponent/ButtonComponent";
import { Link } from "react-router-dom";
import { useRegisters } from "../../hooks/useRegisters";
import { printIcon, registerNumber } from "../reusableOrdersColumns";
import { handlePrintRegisterSticker } from "../handlePrint";
import { dateColumnDef } from "../dateTimeColumnDef";
import useStatusTranslations from "../../hooks/useStatusTranslations";
import { PageTitle } from "../pageTitle/PageTitle";
import { DataGridWrapper } from "../dataGrid/DataGridWrapper";

function Registers() {
  const { t } = useTranslation();
  const { registers, loading: loadingRegisters, fetchRegisters } = useRegisters();
  const { formatRegisterStatus } = useStatusTranslations();
  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: `№`,
        width: 80,
        align: "left",
        headerAlign: "left",
        type: "number",
        renderCell: ({ id, value }) => {
          const registryId = id;
          const serialNumber = value;
          return (
            <Link to={`/registers/${registryId}/`} className={"text-black"}>
              {serialNumber}
            </Link>
          );
        },
      },
      registerNumber(t),
      printIcon(t, 80, (registerId) => handlePrintRegisterSticker(registerId)),
      {
        field: "status",
        headerName: `${t("ordersTableHeaders.status")}`,
        width: 150,
        align: "left",
        headerAlign: "left",
        valueFormatter: (value) => formatRegisterStatus(value),
      },
      {
        field: "pickupCity",
        headerName: `${t("register.pickupCity")}`,
        width: 150,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "pickupAddress",
        headerName: `${t("register.pickupAddress")}`,
        width: 200,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "ordersCount",
        headerName: `${t("register.totalParcels")}`,
        width: 100,
        type: "number",
        align: "center",
        headerAlign: "left",
      },
      {
        field: "creationDate",
        headerName: `${t("ordersTableHeaders.creationDate")}`,
        width: 200,
        align: "left",
        headerAlign: "left",
        ...dateColumnDef,
      },
    ],
    [t, formatRegisterStatus],
  );

  const validatedRegisters = useMemo(
    () =>
      registers.map((register) => ({
        ...register,
        pickupCity: register.pickupCity || t("errorsMessages.city"),
      })),
    [registers, t],
  );

  const headerComponent = (
    <Box className={"flex items-center justify-between"}>
      <PageTitle title={t("pagesTitles.registries")} />
      <Link to={"/registers/new"}>
        <ButtonComponent text={t("register.createBtn")} />
      </Link>
    </Box>
  );

  return (
    <DataGridWrapper>
      <DataGridTable
        rows={validatedRegisters}
        columns={columns}
        autoHeight={false}
        initialState={withDefaults({
          sorting: { sortModel: [{ field: "serialNumber", sort: "desc" }] },
        })}
        loading={loadingRegisters}
        onRefresh={fetchRegisters}
        emptyTableText={
          <Typography className={"text-center pt-3"}>
            {t("ordersTableHeaders.noRegisters")}
          </Typography>
        }
        showOtherTablesHeader={true}
        headerComponent={headerComponent}
      />
    </DataGridWrapper>
  );
}

export default Registers;
