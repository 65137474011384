/**
 *
 * @param {string} recipientName recipient name for individual recipient type
 * @param {string} recipientCompanyRepresentative recipient name for legal entity recipient type
 * @returns {{surname: (string), name: (string)}}
 */
export const getRecipientNameAndSurname = (recipientName, recipientCompanyRepresentative) => {
  const fullName =
    recipientCompanyRepresentative === "" ? recipientName : recipientCompanyRepresentative;
  const nameParts = fullName.split(" ");

  const name = nameParts[0];
  const surname = nameParts.slice(1).join(" ");

  return { name, surname };
};
