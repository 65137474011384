/**
 * @typedef {Object} AddressInitialValues - Initial values for address form
 * @property {string} name - The name of the address entry.
 * @property {string} cityId - The ID of the city associated with the address.
 * @property {string} address - The address details.
 */

/**
 * Initial empty values for the address form.
 * @type {AddressInitialValues}
 */
export const emptyAddressesInitialValues = { name: "", cityId: "", address: "" };

/**
 * Generates initial values for editing an address based on the provided address data.
 *
 * @param {Object} address - The address data to generate initial values from.
 * @param {string} address.name - The name of the address entry.
 * @param {string} address.cityId - The ID of the city associated with the address.
 * @param {string} address.address - The address details.
 * @param {string} address.id - The address ID.
 * @returns {AddressInitialValues} Initial values for editing the address.
 */
export const editAddressesInitialValues = (address) => {
  return {
    id: address.id,
    name: address.name,
    cityId: address.cityId,
    address: address.address,
  };
};
