import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { delivoApi } from "../api";
import { localizeFAQ } from "./useFAQ";

/**
 * Custom React hook for fetching and localizing an FAQ item based on a provided slug and optional initial FAQ data.
 *
 * This hook is intended for use in a component where FAQ details are displayed. It accepts a `slug` to identify
 * the FAQ item and optionally an `initialFAQ` object. If `initialFAQ` is not provided or is falsy (e.g., null or undefined),
 * the hook fetches the FAQ data from a server using the `slug`. If `initialFAQ` is provided, it bypasses the fetch and uses
 * the provided data, minimizing unnecessary network requests.
 *
 * The hook also handles localization of the FAQ item's text based on the current language settings managed by `i18n` from `useTranslation`.
 *
 * @param {object} params - The parameters object.
 * @param {string} params.slug - The unique identifier for the FAQ item, used to fetch the FAQ data if not initially provided.
 * @param {object|null} params.initialFAQ - Optional initial data for the FAQ item, used to avoid fetching data from the server if present.
 * @returns {{ faq: {FAQItem},loading: boolean}}, The hook returns an object containing:
 * `faq`: an object representing the localized FAQ item, or null if no data is available.
 * `loading`: a boolean that indicates whether the FAQ data is currently being fetched.
 */
export function useFAQItem({ slug, initialFAQ }) {
  const [faqItem, setFaqItem] = useState(initialFAQ);
  const [loading, setLoading] = useState(!initialFAQ);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const fetchFAQ = useCallback(async () => {
    if (!initialFAQ) {
      setLoading(true);
      try {
        const response = await delivoApi.getFAQItem({ slug });
        setFaqItem(response);
      } catch (error) {
        console.error("Error fetching FAQ:", error);
        enqueueSnackbar(t("alertsMessages.failedGetFAQ"), { variant: "error" });
      } finally {
        setLoading(false);
      }
    }
  }, [slug, t, initialFAQ, enqueueSnackbar]);

  useEffect(() => {
    fetchFAQ();
  }, [fetchFAQ]);

  const localizedFAQ = useMemo(() => {
    if (initialFAQ) {
      return localizeFAQ(initialFAQ, i18n.language);
    }
    if (faqItem) {
      return localizeFAQ(faqItem, i18n.language);
    }
    return null;
  }, [initialFAQ, faqItem, i18n.language]);

  return { faq: localizedFAQ, loading };
}
