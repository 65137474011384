import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ButtonComponent from "../../components/buttonComponent/ButtonComponent";

export function PageNotFound() {
  const { t } = useTranslation();
  return (
    <Box className={"flex justify-center items-center flex-col min-h-[calc(100vh-130px)]"}>
      <Typography variant="h1">404</Typography>
      <Typography variant="h4">{t("errorsMessages.pageNotFound")}</Typography>

      <Link to={"/"}>
        <ButtonComponent type="button" text={t("home")} />
      </Link>
    </Box>
  );
}
