import React from "react";
import { Link, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

export const FooterLink = ({ link, text }) => {
  const { t } = useTranslation();
  return (
    <Link
      href={link}
      className={"text-black px-3 md:py-2 no-underline hover:underline underline-offset-8"}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ListItemText className={"whitespace-nowrap"}> {t(text)}</ListItemText>
    </Link>
  );
};
