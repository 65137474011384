import React, { useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAddresses } from "../../hooks/useAddresses";
import { Add } from "@mui/icons-material";
import ButtonComponent from "../buttonComponent/ButtonComponent";
import AddressModal from "./AddressModal";
import { useSnackbar } from "notistack";
import showApiErrorNotification from "../showApiErrorNotification";
import { AddressList } from "./AddressList";

function MyAddresses() {
  const { t, i18n } = useTranslation();
  const { addresses, addAddress, updateAddress, deleteAddress } = useAddresses();
  const { enqueueSnackbar } = useSnackbar();

  const [isModalOpen, setModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState(null);

  const openModal = () => {
    setModalOpen(true);
    setIsUpdating(false);
    setAddressToEdit(null);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleAddAddress = async (newAddress) => {
    try {
      const addedAddress = await addAddress(newAddress);
      console.info("Address added", addedAddress);
      enqueueSnackbar(t("alertsMessages.addressCreated"), { variant: "success" });
      return addedAddress;
    } catch (e) {
      showApiErrorNotification(t("alertsMessages.failedCreateAddress"), e, enqueueSnackbar);
      console.error(e.message, e);
    }
  };

  const handleUpdateAddress = async (updatedAddress) => {
    try {
      const result = await updateAddress(updatedAddress);
      console.info("Address updated", result);
      enqueueSnackbar(t("alertsMessages.addressEdited"), { variant: "success" });
    } catch (e) {
      showApiErrorNotification(t("alertsMessages.cantEditAddress"), e, enqueueSnackbar);
      console.error("Failed to update address", e);
    }
  };

  const handleEditAddress = (address) => {
    setAddressToEdit(address);
    setIsUpdating(true);
    setModalOpen(true);
  };

  return (
    <Box className={"flex flex-col justify-around h-fit w-full"}>
      <Box className={"flex justify-end mx-4"}>
        <ButtonComponent
          text={t("accountPage.addAddressTitle")}
          startIcon={<Add />}
          className={"ml-0 my-4"}
          onClick={openModal}
        />
      </Box>
      <AddressList
        addresses={addresses}
        updateAddress={handleEditAddress}
        deleteAddress={deleteAddress}
      />
      <AddressModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onAddAddress={handleAddAddress}
        onUpdateAddress={handleUpdateAddress}
        initialData={addressToEdit}
        isUpdating={isUpdating}
      />
    </Box>
  );
}

export default MyAddresses;
