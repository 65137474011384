import React, { useCallback } from "react";
import { Box, Chip, Divider, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { NavLink, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { formatGeDateTime } from "../dates";
import { useOrder } from "../../hooks/useOrder";
import PrintIcon from "@mui/icons-material/Print";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import ArchiveIcon from "@mui/icons-material/Archive";
import excelLogo from "../../images/excelLogo.svg";
import { useSnackbar } from "notistack";
import { handlePrintOrderSticker } from "../handlePrint";
import useStatusTranslations from "../../hooks/useStatusTranslations";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { handleConfirmation } from "../handleConfirmation";
import { useConfirm } from "material-ui-confirm";
import { ORDER_STATUS } from "../constants";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import showApiErrorNotification from "../showApiErrorNotification";
import DescriptionIcon from "@mui/icons-material/Description";
import { TooltipWrapper } from "../tooltipWrapper/TooltipWrapper";

function OrderDetailsTabs() {
  const { t } = useTranslation();
  const { trackingCode } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const { order, loading, archiveOrder, unarchiveOrder, deleteOrder } = useOrder(trackingCode);
  const { enqueueSnackbar } = useSnackbar();
  const { formatOrderStatus } = useStatusTranslations();
  const confirm = useConfirm();

  const creationDate = order.orderDate;
  let creationDateTitle = "";
  if (creationDate) {
    creationDateTitle = `${t("ordersTableHeaders.creationDate")}: ${formatGeDateTime(
      new Date(creationDate),
    )}`;
  }
  const handleCopyOrderNumber = () => {
    navigator.clipboard
      .writeText(trackingCode)
      .then(() => {
        enqueueSnackbar(t("alertsMessages.orderTrackingNumberCopied", { trackingCode }), {
          variant: "success",
        });
      })
      .catch((err) => {
        showApiErrorNotification(
          t("alertsMessages.failedCopyTrackingCode"),
          err,
          enqueueSnackbar,
        );
        console.error("Failed to copy: ", err);
      });
  };

  const internationalTrackingCode = order.internationalTrackingNumber;
  const handleCopyInternationalNumber = () => {
    navigator.clipboard
      .writeText(internationalTrackingCode)
      .then(() => {
        enqueueSnackbar(
          t("alertsMessages.orderInternationalTrackingNumberCopied", {
            internationalTrackingCode,
          }),
          {
            variant: "success",
          },
        );
      })
      .catch((err) => {
        showApiErrorNotification(
          t("alertsMessages.failedCopyTrackingCode"),
          err,
          enqueueSnackbar,
        );
        console.error(`Failed to copy ITN ${internationalTrackingCode}: `, err);
      });
  };

  const handlePrintOrder = async () => {
    try {
      await handlePrintOrderSticker(trackingCode);
    } catch (error) {
      console.error("Error printing order sticker:", error);
      showApiErrorNotification(t("alertsMessages.failedPrintSticker"), error, enqueueSnackbar);
    }
  };

  const status = formatOrderStatus(order.status);

  const handleGoBack = useCallback(() => {
    navigate("/orders/new");
  }, [navigate]);

  const contextValue = {
    order,
    loading,
  };
  const handleDeleteOrder = useCallback(async () => {
    try {
      const orderDeleted = deleteOrder();
      if (orderDeleted) {
        enqueueSnackbar(t("alertsMessages.orderDeleted"), {
          variant: "success",
        });
        handleGoBack();
        console.info(`Order deleted ${trackingCode}`);
      } else {
        enqueueSnackbar(t("alertsMessages.failedOrderDelete"), {
          variant: "error",
        });
        console.error(`Failed to delete the order ${trackingCode}`);
      }
    } catch (e) {
      showApiErrorNotification(t("alertsMessages.failedOrderDelete"), e, enqueueSnackbar);
      console.error(`Failed to delete the order: ${trackingCode}`, e);
    }
  }, [enqueueSnackbar, t, handleGoBack, trackingCode, deleteOrder]);

  const handleDeleteConfirmation = useCallback(
    async (id) => {
      const confirmationDetails = {
        description: t("confirmation.confirmDeleteOrder", { id }),
      };
      await handleConfirmation(confirm, confirmationDetails, () => handleDeleteOrder(id), t);
    },
    [confirm, handleDeleteOrder, t],
  );

  const handlerArchiveOrders = async () => {
    try {
      const archivedOrders = await archiveOrder();
      if (archivedOrders) {
        enqueueSnackbar(t("alertsMessages.orderArchived"), {
          variant: "success",
        });
      } else {
        enqueueSnackbar(t("alertsMessages.failedArchiveOrder"), {
          variant: "error",
        });
        console.error(`Error archive order: ${trackingCode}`);
      }
    } catch (error) {
      showApiErrorNotification("alertsMessages.failedArchiveOrder", error, enqueueSnackbar);
      console.error(`Error archive order: ${trackingCode}`, error);
    }
  };

  const handlerUnarchiveOrders = async () => {
    try {
      const unarchivedOrders = await unarchiveOrder();
      if (unarchivedOrders) {
        enqueueSnackbar(t("alertsMessages.orderUnarchived"), {
          variant: "success",
        });
      } else {
        enqueueSnackbar(t("alertsMessages.failedUnarchiveOrder"), {
          variant: "error",
        });
        console.error(`Error unarchive order: ${trackingCode}`);
      }
    } catch (error) {
      showApiErrorNotification(t("alertsMessages.failedUnarchiveOrder"), error, enqueueSnackbar);
      console.error(`Error unarchive order: ${trackingCode}`, error);
    }
  };
  const TabsContainer = ({ className }) => {
    return (
      <Tabs value={path} aria-label="order details tabs" className={className}>
        <Tab
          label={t("orderTracking.orderDetails")}
          component={NavLink}
          to={`/orders/${trackingCode}/`}
          value={`/orders/${trackingCode}/`}
          className={"smMax:w-1/2"}
        />
        <Divider orientation={"vertical"} flexItem />
        <Tab
          label={t("orderTracking.deliveryStatus")}
          component={NavLink}
          to={`/orders/${trackingCode}/status`}
          value={`/orders/${trackingCode}/status`}
          className={"smMax:w-1/2"}
        />
      </Tabs>
    );
  };
  const CreateDateBox = ({ className }) => {
    return (
      <Box className={className}>
        <Typography variant={"caption"} className={"text-gray-400"}>
          {creationDateTitle}
        </Typography>
        <IconButton
          onClick={handleGoBack}
          className={"text-gray-700 cursor-pointer smMax:hidden"}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    );
  };

  return (
    <Box className={"shadow-md rounded-md mb-5"}>
      <TabsContainer className={"md:hidden w-full"} />
      <Divider orientation={"horizontal"} flexItem className={"md:hidden"} />
      <Box>
        <CreateDateBox className={"flex items-center justify-between px-5 my-4 smMax:hidden"} />
        <Box
          className={
            "flex md:items-center md:my-4 md:mx-2 md:justify-stretch md:flex-wrap xl:flex-nowrap smMax:flex-col"
          }
        >
          <Box
            className={"md:w-6/12 xl:w-4/12 flex items-center cursor-pointer smMax:pl-3"}
            onClick={handleCopyOrderNumber}
          >
            <TooltipWrapper title={t("tooltips.copyTrackingCode")}>
              <IconButton>
                <DescriptionOutlinedIcon
                  className={"smMax:w-6 smMax:h-6 w-9 h-9 text-gray-700"}
                />
              </IconButton>
            </TooltipWrapper>
            <Typography className={"text-xl md:text-3xl"}>{trackingCode}</Typography>
          </Box>
          {internationalTrackingCode ? (
            <Box
              className={
                "md:w-5/12 xl:w-3/12 flex items-center md:ml-10 cursor-pointer smMax:w-full smMax:my-2 smMax:pl-3"
              }
              onClick={handleCopyInternationalNumber}
            >
              <TooltipWrapper title={t("tooltips.copyInternationalTrackingCode")}>
                <IconButton>
                  <DescriptionIcon className={"smMax:w-6 smMax:h-6 w-9 h-9 text-gray-700"} />
                </IconButton>
              </TooltipWrapper>
              <Box className={"flex flex-col"}>
                <Typography>{internationalTrackingCode}</Typography>
                <Typography variant="caption" className={"text-gray-500"}>
                  {t("orderTracking.internationalTrackingCode")}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box className={"w-2/12"}></Box>
          )}
          {order.isArchived ? (
            <Box className={"md:w-2/12 xl:w-1/12 mx-1.5 smMax:mb-1 smMax:pl-4"}>
              <Chip label={t("tooltips.archivedFlag")} />
            </Box>
          ) : (
            ""
          )}
          <CreateDateBox
            className={"flex items-center justify-between md:px-4 md:hidden smMax:pl-7 mb-1"}
          />
          <Box
            className={
              "md:w-6/12 xl:w-4/12 sm:8/12 flex items-center justify-stretch sm:my-3 smMax:pl-5"
            }
          >
            <FiberManualRecordIcon className={"text-sm mx-1 smMax:hidden"} color="primary" />
            <Typography className={"smMax:mx-2"}>{status}</Typography>
            <FiberManualRecordIcon
              className={"text-sm mx-1 smMax:text-xs md:hidden"}
              color="primary"
            />
          </Box>
          <Box
            className={
              "flex items-center justify-end text-gray-500 md:w-5/12 xl:w-1/12 xl:mr-5 smMax:mx-4"
            }
          >
            <TooltipWrapper title={t("tooltips.printSticker")}>
              <IconButton onClick={handlePrintOrder}>
                <PrintIcon className={"m-1 cursor-pointer"} />
              </IconButton>
            </TooltipWrapper>
            {order.isArchived ? (
              <TooltipWrapper title={t("tooltips.unarchiveOrder")}>
                <IconButton
                  onClick={() => {
                    handlerUnarchiveOrders([trackingCode]);
                  }}
                >
                  <UnarchiveIcon className={"m-1 cursor-pointer"} />
                </IconButton>
              </TooltipWrapper>
            ) : (
              <TooltipWrapper title={t("tooltips.archiveOrder")}>
                <IconButton
                  onClick={() => {
                    handlerArchiveOrders([trackingCode]);
                  }}
                >
                  <ArchiveIcon className={"m-1 cursor-pointer"} />
                </IconButton>
              </TooltipWrapper>
            )}
            <TooltipWrapper title={t("tooltips.addToRegister")}>
              <IconButton className={"hidden"}>
                <FolderSpecialIcon className={"m-1 cursor-pointer"} />
              </IconButton>
            </TooltipWrapper>
            <TooltipWrapper title={t("tooltips.addToExcel")}>
              <IconButton className={"hidden"}>
                <img alt={"excel"} src={excelLogo} className={"m-1 cursor-pointer"} />
              </IconButton>
            </TooltipWrapper>
            {order.status === ORDER_STATUS.DRAFT ? (
              <>
                <TooltipWrapper title={t("tooltips.addToArchive")}>
                  <IconButton className={"hidden"}>
                    <ArchiveIcon className={"m-1 cursor-pointer"} />
                  </IconButton>
                </TooltipWrapper>
                <TooltipWrapper title={t("tooltips.editOrder")}>
                  <IconButton
                    onClick={() => {
                      navigate(`/orders/${trackingCode}/edit`);
                    }}
                  >
                    <EditIcon className={"m-1"} />
                  </IconButton>
                </TooltipWrapper>
                <TooltipWrapper title={t("tooltips.deleteOrder")}>
                  <IconButton onClick={() => handleDeleteConfirmation(trackingCode)}>
                    <DeleteIcon className={"m-1"} />
                  </IconButton>
                </TooltipWrapper>
              </>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>
      <Divider orientation={"horizontal"} flexItem className={"smMax:hidden"} />
      <TabsContainer className={"mx-2 smMax:hidden"} />
      <Divider orientation={"horizontal"} flexItem className={"smMax:hidden"} />
      <Outlet context={contextValue} />
    </Box>
  );
}

export default OrderDetailsTabs;
