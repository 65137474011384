/**
 * The base URL where the API is accessible.
 * @type {string}
 */
export const BASE_URL = process.env.REACT_APP_DELIVO_API_BASE_URL;
/**
 * The URL of the registration endpoint.
 * @type {string}
 */
export const REGISTRATION_ENDPOINT = "v1/registration/";
/**
 * The URL of the registration endpoint.
 * @type {string}
 */
export const REGISTRATION_URL = `/${REGISTRATION_ENDPOINT}`;
const EMAIL_API_PREFIX = "v1/mail";
export const FEEDBACK_FORM_EMAIL = `/${EMAIL_API_PREFIX}/feedback-form/`;
export const RESET_PASSWORD_EMAIL = `/${EMAIL_API_PREFIX}/reset-password/`;

const ORDERS_URL_PREFIX = `/v1/orders`;

/**
 * The URL of the orders.
 * @type {string}
 */
export const ORDERS_URL = `${ORDERS_URL_PREFIX}/`;
export const NEW_ORDERS_URL = `${ORDERS_URL_PREFIX}/new-orders/`;
export const ACTIVE_ORDERS_URL = `${ORDERS_URL_PREFIX}/active-orders/`;
export const ARCHIVED_ORDERS_URL = `${ORDERS_URL_PREFIX}/archived-orders/`;

export const ACCOUNT = `/v1/accounts`;
export const MY_ACCOUNT = `${ACCOUNT}/my/`;
export const POST_OFFICES = `/v1/post_offices/`;

export const ADDRESSES = `/v1/addresses/`;
export const ORDERS_GET_AMOUNT = `${ORDERS_URL_PREFIX}/get-amount/`;
export const ORDERS_STICKERS = `${ORDERS_URL_PREFIX}/stickers/`;
export const ORDERS_DELETE = `${ORDERS_URL_PREFIX}/delete-orders/`;

export const REGISTERS_URL_PREFIX = `/v1/registers`;
/**
 * The URL of the registers.
 * @type {string}
 */
export const REGISTERS_URL = `${REGISTERS_URL_PREFIX}/`;
export const AVAILABLE_ORDERS_REGISTERS_URL = `${REGISTERS_URL_PREFIX}/available-orders/`;

export const ORDERS_UPLOAD_EXCEL = `${ORDERS_URL_PREFIX}/upload-excel/`;
export const ORDERS_DOWNLOAD_EXCEL_TEMPLATE = `${ORDERS_URL_PREFIX}/bulk-create-excel-template/`;

export const ARCHIVE_ORDERS = `${ORDERS_URL_PREFIX}/archive/`;
export const UNARCHIVE_ORDERS = `${ORDERS_URL_PREFIX}/unarchive/`;

export const MANAGER = `${ACCOUNT}/manager/`;

/**
 * The URL of the cities.
 * @type {string}
 */
export const CITIES = `${BASE_URL}/v1/cities/`;

/**
 * The URL of the regions.
 * @type {string}
 */
export const REGIONS = `${BASE_URL}/v1/regions/`;

/**
 * The URL of the FAQ list.
 * @type {string}
 */
export const FAQ = `${BASE_URL}/v1/faq/`;

/**
 * The URL of the news.
 * @type {string}
 */
export const NEWS_PAGE = `${BASE_URL}/v1/news/`;
