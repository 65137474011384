import { useEffect, useState } from "react";
import { delivoApi } from "../api";
import { PostOffice } from "../api/types";

/**
 * Fetches post offices for a given city or for all cities if no city ID is specified.
 * @param {string} [cityId]
 * @returns {{postOffices: PostOffice[], isLoaded: boolean}}
 * isLoaded: A boolean indicating whether a request to fetch post offices was initiated.
 */
export function usePostOffices(cityId = "") {
  const [postOffices, setPostOffices] = useState([]);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchPostOffices = async () => {
      if (cityId) {
        try {
          const fetchedPostOffices = await delivoApi.getPostOffices(cityId);
          setPostOffices(fetchedPostOffices);
          setLoaded(true);
        } catch (e) {
          console.error("Can not get post offices", e);
        }
      } else {
        console.debug("No city ID provided. Skipping post offices fetch.");
        setLoaded(false);
      }
    };
    fetchPostOffices();
  }, [cityId]);
  return { postOffices, isLoaded };
}
