import * as Yup from "yup";

/**
 * Generates a validation schema for address forms.
 *
 * This schema validates user input for address-related forms,
 * ensuring that all necessary fields meet the application's
 * requirements for length, presence, and format.
 *
 * @param {Function} t - The translation function from i18next used for error messages.
 * @returns {Yup.ObjectSchema} A Yup validation schema object for address forms.
 */
export const getValidationSchema = (t) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t("errorsMessages.addressNameRequired"))
      .matches(/\S/, t("errorsMessages.addressNameRequired"))
      .max(80, t("errorsMessages.maxAddressNameLength")),
    cityId: Yup.string()
      .required(t("errorsMessages.actualAddressCityRequire"))
      .max(30, t("errorsMessages.maxCityLength")),
    address: Yup.string()
      .trim()
      .required(t("errorsMessages.addressRequired"))
      .matches(/\S/, t("errorsMessages.addressRequired"))
      .max(255, t("errorsMessages.maxAddressLength")),
  });
