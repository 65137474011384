import { useTranslation } from "react-i18next";
import { usePostOffices } from "../../hooks/usePostOffices";
import { getFormFieldHelperText } from "../getFormFieldHelperText";
import { Field } from "formik";
import { MenuItem, TextField } from "@mui/material";

/**
 * PostOfficeField component for rendering a dropdown of post offices based on the selected city.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.cityId - The ID of the city to fetch post offices for.
 * @param {string} props.selectedPostOffice - The currently selected post office ID.
 * @param {string} props.fieldName - The name of the form field.
 * @param {Object} props.touched - Object containing information about which fields have been touched.
 * @param {Object} props.errors - Object containing validation errors for the fields.
 * @returns {JSX.Element} The rendered component.
 */
export const PostOfficeField = ({ cityId, selectedPostOffice, fieldName, touched, errors }) => {
  const { t } = useTranslation();
  const { postOffices, isLoaded } = usePostOffices(cityId);

  const postOfficesText = getFormFieldHelperText({
    requestMade: isLoaded,
    identifier: cityId,
    items: postOffices,
    field: fieldName,
    touched: touched,
    errors: errors,
    noItemsText: t("tooltips.noPostOffices"),
  });

  return (
    <Field
      as={TextField}
      name={fieldName}
      label={t("orderCreation.clientPartSelectDepartment")}
      select
      value={selectedPostOffice}
      error={touched[fieldName] && Boolean(errors[fieldName])}
      helperText={postOfficesText}
      disabled={!cityId || !postOffices.length}
      variant="standard"
      className={"w-full md:w-1/3"}
    >
      {postOffices.map((option) => (
        <MenuItem key={option.guid} value={option.guid}>
          {option.name}, {option.address}
        </MenuItem>
      ))}
    </Field>
  );
};
