import { ordersApi } from "../api/DelivoOrdersApi";
import { registersApi } from "../api";
import { openFileInNewWindow } from "./handleDownload";

/**
 * Opens the provided PDF data in a new window.
 *
 * @param {function(): Promise<Blob>} pdfDataFetcher A callback that fetches PDF data.
 */
const openPdfInNewWindow = async (pdfDataFetcher) => {
  await openFileInNewWindow(pdfDataFetcher, "application/pdf");
};

/**
 *
 * * @param {string} trackingCode The tracking code of the order
 *
 *  * @returns {Promise<Blob>} A Promise that resolves to a PDF Blob representing the order sticker
 */
export const handlePrintOrderSticker = async (trackingCode) => {
  try {
    await openPdfInNewWindow(async () => await ordersApi.getOrderSticker(trackingCode));
  } catch (error) {
    console.error("Error fetching order sticker:", error);
    throw error;
  }
};

/**
 * Fetches the register sticker for a given register ID.
 *
 * @param {string|number} registerId The ID of the register
 * @returns {Promise<Blob>} A Promise that resolves to a PDF Blob representing the register sticker
 */
export const handlePrintRegisterSticker = async (registerId) => {
  try {
    await openPdfInNewWindow(async () => await registersApi.getRegisterSticker(registerId));
  } catch (error) {
    console.error("Error fetching register sticker:", error);
    throw error;
  }
};

export const bulkPrintStickers = async (trackingCodes) => {
  try {
    await openPdfInNewWindow(async () => await ordersApi.getOrdersSticker(trackingCodes));
    console.info("Bulk orders stickers printed successfully");
  } catch (error) {
    console.error("Error printing bulk orders stickers:", error);
    throw error;
  }
};
