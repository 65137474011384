import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { Box, Divider, Tab, Tabs } from "@mui/material";

function LoginTabs() {
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname;
  return (
    <Box className={"max-w-full"}>
      <Tabs value={path} aria-label="login tabs" centered>
        <Tab label={t("loginButton")} component={NavLink} to="/login" value="/login" />
        <Tab
          label={t("registrationButton")}
          component={NavLink}
          to="/registration"
          value="/registration"
        />
      </Tabs>
      <Divider orientation={"horizontal"} flexItem />
      <Outlet />
    </Box>
  );
}

export default LoginTabs;
