import { Box, ListItemIcon, Menu, MenuItem } from "@mui/material";
import React, { useCallback, useState } from "react";
import { LanguageButton } from "./LanguageButton";
import { Flag } from "./Flag";
import { languages } from "./languages";

/**
 * `LanguageMenuItem` is a React component that renders an individual menu item for a specific language in a dropdown menu.
 * It provides a clickable area that triggers a language change and closes the menu upon selection.
 *
 * @param {Object} props The properties passed to the LanguageMenuItem component.
 * @param {string} props.language The language code or identifier for the menu item, used to display the corresponding flag.
 * @param {Function} props.handleChangeLanguage The callback function to invoke with the selected language. This function
 * should handle the logic for changing the application's current language.
 * @param {Function} props.setAnchorEl A function to control the visibility of the menu by manipulating its anchor element's state.
 *
 * @returns {JSX.Element} A MenuItem component that includes a clickable area. When clicked, it triggers the language change
 * and closes the menu by setting the anchor element to null. The MenuItem also displays a flag icon corresponding to the
 * specified language.
 *
 */
function LanguageMenuItem({ language, handleChangeLanguage, setAnchorEl }) {
  const handleClick = () => {
    handleChangeLanguage(language);
    setAnchorEl(null);
  };

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon className="min-w-0 p-0">
        <Flag language={language} />
      </ListItemIcon>
    </MenuItem>
  );
}

/**
 * `SmallScreenMenu` is a React component that renders a dropdown menu for language switching specifically for smaller screens.
 * It uses a toggle button to open a menu that lists available languages, allowing the user to select a new language.
 *
 * @param {Object} props The properties passed to the SmallScreenMenu component.
 * @param {string} props.selectedLanguage The currently selected language, used to display the active state in the menu.
 * @param {Function} props.handleChangeLanguage The callback function to invoke when a new language is selected.
 * This function should handle the actual language change logic.
 *
 * @returns {JSX.Element} A component structure consisting of a Box that only displays on small screens. Inside the Box,
 * there is a LanguageButton that opens the menu and a Menu component that contains LanguageMenuItem components for each
 * available language.
 *
 */
export function SmallScreenMenu({ selectedLanguage, handleChangeLanguage }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Box display={{ md: "none" }}>
      <LanguageButton language={selectedLanguage} handleChangeLanguage={handleOpenMenu} />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {languages.map((language) => (
          <LanguageMenuItem
            key={language}
            language={language}
            handleChangeLanguage={handleChangeLanguage}
            setAnchorEl={setAnchorEl}
          />
        ))}
      </Menu>
    </Box>
  );
}
