import React from "react";
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FeedIcon from "@mui/icons-material/Feed";
import ArchiveIcon from "@mui/icons-material/Archive";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import HelpIcon from "@mui/icons-material/Help";
import UploadIcon from "@mui/icons-material/Upload";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SideBarListItem from "./SideBarListItem";
import ButtonComponent from "../buttonComponent/ButtonComponent";
import ManagerInfo from "../managerInfo/ManagerInfo";
import { TooltipWrapper } from "../tooltipWrapper/TooltipWrapper";

function SideBarMain() {
  const { t, i18n } = useTranslation();

  return (
    <Box className={"m-4"}>
      <Box className={"flex items-center box-border"}>
        <Link to={"/orders/create"} className={"m-1.5"}>
          <ButtonComponent text={t("pagesTitles.CreateOrder")} className={"p-3"} />
        </Link>
        <Link to={"/orders/import"} className={"w-1/5"}>
          <ButtonComponent text={<UploadIcon />} className={"px-0.5 py-3 ml-2"} />
        </Link>
      </Box>
      <List>
        <SideBarListItem
          to={"/orders/new"}
          icon={<AssignmentIcon />}
          text={t("pagesTitles.newOrdersTitle")}
        />
        <SideBarListItem
          to={"/orders/my"}
          icon={<FeedIcon />}
          text={t("pagesTitles.myOrdersTitle")}
        />
        <SideBarListItem
          to={"/orders/archive"}
          icon={<ArchiveIcon />}
          text={t("pagesTitles.archive")}
        />
        <SideBarListItem
          to={"/registers"}
          icon={<FolderSpecialIcon />}
          text={t("pagesTitles.registries")}
        />
        <TooltipWrapper title={t("hintInWork")} arrow>
          <ListItem className={"w-full p-1 flex items-start justify-start"}>
            <ListItemIcon className={"py-1"}>
              <InsertChartIcon />
            </ListItemIcon>
            <ListItemText className={"p-0"}>{t("pagesTitles.payments")}</ListItemText>
          </ListItem>
        </TooltipWrapper>
        <SideBarListItem to={"/faq"} icon={<HelpIcon />} text={t("pagesTitles.faq")} />
      </List>
      <Link to={"/feedback"} className={"text-color-main no-underline w-full"}>
        <Typography className={"text-center uppercase"}>
          {t("pagesTitles.RequestCallBack")}
        </Typography>
      </Link>
      <ManagerInfo className={"p-3 box-border"} />
    </Box>
  );
}

export default SideBarMain;
