import React from "react";
import { ChangelogItem } from "./ChangelogItem";
import { Box } from "@mui/material";

export function ChangelogItems() {
  const changeLogItems = [
    {
      version: "v0.82.0",
      gitCommit: "380aa1fbeb1efcc45b20437341dfdf501298f28f",
      whenReleased: "2024-06-27",
    },
    {
      version: "v0.76.0",
      gitCommit: "51edf4ba3592143e75fc9d0de18f3089a43d442d",
      whenReleased: "2024-06-17",
    },
    {
      version: "v0.68.1",
      gitCommit: "57937067a9761720ad0e01af772239ce017f234d",
      whenReleased: "2024-05-30",
    },
    {
      version: "v0.65.1",
      gitCommit: "8118f58de52bf9d3717a7eaf44e68ea1798504e7",
      whenReleased: "2024-05-17",
    },
    {
      version: "v0.65.0",
      gitCommit: "7b945edb781915e622b68612a97a4ec31bc6e2a1",
      whenReleased: "2024-05-16",
    },
    {
      version: "v0.62.2",
      gitCommit: "32d189143aa1a3260ed2218fbdee3fe6217cb640",
      whenReleased: "2024-05-08",
    },
    {
      version: "v0.60.2",
      gitCommit: "2c9f6414b15840ce6b59c5862e458cc8f85f90e3",
      whenReleased: "2024-04-23",
    },
    {
      version: "v0.59.0",
      gitCommit: "893b64f5e57f546ad2f68c96b44582333ac96c42",
      whenReleased: "2024-04-19",
    },
    {
      version: "v0.58.0",
      gitCommit: "19bd96502555a2d6319567a05c0a7d91e6c8178a",
      whenReleased: "2024-04-10",
    },
    {
      version: "v0.57.0",
      gitCommit: "a51062cc4987b8c5f1ccd18bee63f0a6a069428c",
      whenReleased: "2024-04-01",
    },
    {
      version: "v0.54.0",
      gitCommit: "1a1c880d1a535301e8b6d5df13b9deae2c7f597a",
      whenReleased: "2024-03-23",
    },
    {
      version: "v0.45.3",
      gitCommit: "b346f2991ffa800b463cb8434423d0700a725925",
      whenReleased: "2024-02-23",
    },
    {
      version: "v0.43.0",
      gitCommit: "495bbb3a0f0dfe2de84e96fb41b511c5f495b0ea",
      whenReleased: "2024-02-08",
    },
    {
      version: "v0.42.1",
      gitCommit: "43872ad55fb98fd28893eb63bcf611a027cf1f9a",
      whenReleased: "2024-02-02",
    },
    {
      version: "v0.40.2",
      gitCommit: "e11bc0ac67e32d04e6ffb1f3c924b9939f9a614a",
      whenReleased: "2024-01-26",
    },
    {
      version: "v0.38.1",
      gitCommit: "5a38e4db3da3bc77fdcee2035686557cea75b0d7",
      whenReleased: "2024-01-18",
    },
    {
      version: "v0.37.3",
      gitCommit: "6e12afc8614d5a5ab2b24bb9c106b890758ff527",
      whenReleased: "2024-01-12",
    },
    {
      version: "v0.36.0",
      gitCommit: "0fb10078d6985c4ceb23c521eba64f94a96b6f9d",
      whenReleased: "2024-01-04",
    },
    {
      version: "v0.34.0",
      gitCommit: "f7ab70d87d7fc1e53c6e0a385130242d67491239",
      whenReleased: "2023-12-28",
    },
    {
      version: "v0.31.0",
      gitCommit: "3c6f74d1086899144950ce90fc8b5e4954f5ae20",
      whenReleased: "2023-12-21",
    },
    {
      version: "v0.27.1",
      gitCommit: "9ba418767d79927387f17c35ea716af28820675b",
      whenReleased: "2023-12-10",
    },
    {
      version: "v0.23.0",
      gitCommit: "61d44de8442c8279d936b28d687c92f38b33a427",
      whenReleased: "2023-12-04",
    },
    {
      version: "v0.22.1",
      gitCommit: "828af21eb0304d25ae3b23e9b6e54c8aafc26f9c",
      whenReleased: "2023-11-28",
    },
    {
      version: "v0.17.6",
      gitCommit: "a5511a234ae8557466385e47dd9cf4fe7ddda68a",
      whenReleased: "2023-11-17",
    },
    {
      version: "v0.13.3",
      gitCommit: "32b58ce743ad21a075606f7303c9df41757814a8",
      whenReleased: "2023-11-04",
    },
    {
      version: "v0.3.0",
      gitCommit: "9759ed0c4b561cc35ae2b674a183f1eced2e691b",
      whenReleased: "2023-09-28",
    },
  ];
  return <Box className={"w-7/12"}>{changeLogItems.map((item) => ChangelogItem(item))}</Box>;
}
