import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import FailedRowDetails from "./FailedRowDetails";
import { useTranslation } from "react-i18next";

/**
 * Component representing a card for displaying details of a failed row.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.failedRow - Information about the failed row.
 * @param {number} props.index - Index of the failed row.
 * @returns {JSX.Element} - JSX element representing the failed row card.
 */
const FailedRowCard = ({ failedRow, index }) => {
  const { t } = useTranslation();
  const errorAmountChecking = `${failedRow.errors.length === 1 ? `1 ${t("uploadFilePage.errorOne")}` : `${failedRow.errors.length} ${t("uploadFilePage.errorAmount")}`}`;

  return (
    <Card key={index} className={`m-3 min-h-fit`}>
      <CardContent className="flex flex-col">
        <Box className={"flex"}>
          <Typography className={"font-semibold mr-5"}>
            {t("uploadFilePage.rowNumber")} {failedRow.row_number}
          </Typography>
          <Typography className={"text-red-500"}>{errorAmountChecking}</Typography>
        </Box>
        {failedRow.errors.map((error, idx) => (
          <FailedRowDetails key={idx} error={error} />
        ))}
      </CardContent>
    </Card>
  );
};

export default FailedRowCard;
