import React, { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { AccountMenu } from "./AccountMenu";
import { AccountButton } from "./AccountButton";

/**
 * Account info header component to display account button and menu.
 * @returns {JSX.Element} AccountInfoHeader component.
 */
export function AccountInfoHeader() {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Box className="flex items-start">
      <AccountButton isOpen={isOpen} handleMenuOpen={handleMenuOpen} />
      <AccountMenu anchorEl={anchorEl} isOpen={isOpen} handleMenuClose={handleMenuClose} />
    </Box>
  );
}
