import {
  ACTIVE_ORDERS_URL,
  ARCHIVE_ORDERS,
  ARCHIVED_ORDERS_URL,
  NEW_ORDERS_URL,
  ORDERS_DELETE,
  ORDERS_DOWNLOAD_EXCEL_TEMPLATE,
  ORDERS_GET_AMOUNT,
  ORDERS_STICKERS,
  ORDERS_UPLOAD_EXCEL,
  ORDERS_URL,
  UNARCHIVE_ORDERS,
} from "./delivo-api-config";

import { getParams } from "./DelivoApi";
import {
  CreateOrderRequest,
  Order,
  OrderCosts,
  OrderListItem,
  OrderRequest,
  OrdersListQueryParams,
  OrderTracking,
} from "./orderTypes";
import delivoAxios from "./delivoAxios";

/**
 * Delivo API for working with orders.
 */
class DelivoOrdersApi {
  /**
   * Get all orders.
   *
   * @param {OrdersListQueryParams} queryParams Orders list query parameters
   * @returns {Promise<OrderListItem[]>} A list of order data objects.
   */
  async getOrders(queryParams) {
    try {
      const params = await getParams({ queryParams });
      const response = await delivoAxios.get(ORDERS_URL, params);
      if (response.status !== 200) {
        console.error("Failed to get orders.", response);
        throw new Error("Failed to get orders");
      }
      const userOrders = response.data;
      console.debug("userOrders", userOrders);
      return userOrders.map((userOrder) => new OrderListItem(userOrder));
    } catch (e) {
      console.error("Failed to get orders.", e);
      throw e;
    }
  }

  /**
   * Get order detail info.
   *
   * @param {string} orderId the ID of the order
   * @returns {Promise<Order>} An object of order data.
   */
  async getOrder(orderId) {
    try {
      const params = await getParams();
      const url = `${ORDERS_URL}${orderId}/`;
      const response = await delivoAxios.get(url, params);
      if (response.status !== 200) {
        console.error(`Failed to get order with ID ${orderId}.`, response);
        throw new Error(`Failed to get order with ID ${orderId}`);
      }
      return response.data;
    } catch (e) {
      console.error("Failed to get order.", e);
      throw e;
    }
  }

  /**
   * Get new orders.
   *
   * @param {OrdersListQueryParams} queryParams Orders list query parameters
   * @returns {Promise<OrderListItem[]>} A list of order data objects
   */
  async getNewOrders(queryParams) {
    const params = await getParams({ queryParams });
    const response = await delivoAxios.get(NEW_ORDERS_URL, params);
    if (response.status !== 200) {
      console.error("Failed to get new orders.", response);
      throw new Error("Failed to get new orders.");
    }
    const userNewOrders = response.data;
    console.debug("userNewOrders", userNewOrders);
    return userNewOrders.map((userOrder) => new OrderListItem(userOrder));
  }

  /**
   * Get active orders.
   *
   * @param {OrdersListQueryParams} queryParams Orders list query parameters
   * @returns {Promise<OrderListItem[]>} A list of order data objects
   */
  async getActiveOrders(queryParams) {
    const params = await getParams({ queryParams });
    const response = await delivoAxios.get(ACTIVE_ORDERS_URL, params);
    if (response.status !== 200) {
      console.error("Failed to get active orders.", response);
      throw new Error("Failed to get active orders.");
    }
    const userActiveOrders = response.data;
    console.debug("userActiveOrders", userActiveOrders);
    return userActiveOrders.map((userOrder) => new OrderListItem(userOrder));
  }

  /**
   * Get archived orders.
   *
   * @param {OrdersListQueryParams} queryParams Orders list query parameters
   * @returns {Promise<OrderListItem[]>} A list of order data objects
   */
  async getArchivedOrders(queryParams) {
    const params = await getParams({ queryParams });
    const response = await delivoAxios.get(ARCHIVED_ORDERS_URL, params);
    if (response.status !== 200) {
      console.error("Failed to get archived orders.", response);
      throw new Error(`Failed to get archived orders: ${JSON.stringify(queryParams)}`);
    }
    const userArchivedOrders = response.data;
    console.debug("userArchivedOrders", userArchivedOrders);
    return userArchivedOrders.map((userOrder) => new OrderListItem(userOrder));
  }

  /**
   * Returns information about costs according data from order creation form
   * @param {CalculateOrderCosts} queryParams the order costs request params
   * @returns {Promise<OrderCosts>}
   */
  async getOrdersCosts(queryParams) {
    const params = await getParams({ queryParams });
    const response = await delivoAxios.get(ORDERS_GET_AMOUNT, params);
    if (response.status !== 200) {
      console.error("Failed to retrieve costs amount info.", response);
      throw new Error(`Failed to retrieve costs amount info: ${JSON.stringify(queryParams)}`);
    }
    return new OrderCosts(response.data);
  }

  /**
   * Creates a new order from the given request params.
   *
   * @param {CreateOrderRequest} orderCreationRequest the order creation request body
   * @returns {Promise<OrderTracking>}
   */
  async createOrder(orderCreationRequest) {
    const params = await getParams();

    const response = await delivoAxios.post(ORDERS_URL, orderCreationRequest, params);
    if (response.status !== 201) {
      console.error("Failed to create an order.", orderCreationRequest, response);
      throw new Error(`Failed to create an order: ${JSON.stringify(orderCreationRequest)}`);
    }
    const orderCreated = response.data;
    return new OrderTracking(orderCreated);
  }

  /**
   * Get order sticker with the given ID.
   *
   * @param {string|number} orderId the ID of the order
   *
   * @returns {Promise<Blob>} A Promise that resolves to a PDF Blob representing the order sticker
   */
  async getOrderSticker(orderId) {
    const params = await getParams();
    params.responseType = "blob";
    const url = `${ORDERS_URL}${orderId}/sticker/`;
    const response = await delivoAxios.get(url, params);
    if (response.status !== 200) {
      console.error("Failed to get order sticker.", response);
      throw new Error(`Failed to get order sticker for order ${orderId}.`);
    }
    return response.data;
  }

  /**
   * Get order sticker with the given ID.
   *
   * @param {Array<string|number>} trackingCodes the IDs of the orders to generate the sticker for
   *
   * @returns {Promise<Blob>} A Promise that resolves to a PDF Blob representing the order sticker
   */
  async getOrdersSticker(trackingCodes) {
    const params = await getParams();
    params.responseType = "blob";
    const request = {
      tracking_codes: trackingCodes,
    };
    const response = await delivoAxios.post(ORDERS_STICKERS, request, params);
    if (response.status !== 200) {
      console.error("Failed to get generate orders stickers.", trackingCodes, response);
      throw new Error("Failed to get generate orders stickers.");
    }
    return response.data;
  }

  /**
   * Uploads an Excel file for bulk import processing.
   *
   * <p>Returns back a response with success and failure (if any) details.
   * @param {FormData} uploadFile form data with the file that is being uploaded.
   * @returns {Promise<BulkCreateOrdersResponse>}
   */
  async uploadOrderExcel(uploadFile) {
    const params = await getParams({ contentType: "multipart/form-data" });
    const response = await delivoAxios.post(ORDERS_UPLOAD_EXCEL, uploadFile, params);
    if (response.status !== 201) {
      console.error("Failed to upload excel with orders.", response);
      throw new Error("Failed to upload excel with orders.");
    }
    return response.data;
  }
  /**
   * Downloads the latest Excel template.
   * @returns {Promise<Blob>}
   */
  async downloadOrderExcelTemplate() {
    const params = await getParams();
    params.responseType = "blob";
    const response = await delivoAxios.get(ORDERS_DOWNLOAD_EXCEL_TEMPLATE, params);
    if (response.status !== 200) {
      console.error("Failed to download excel template.", response);
      throw new Error("Failed to download excel template.");
    }
    return response.data;
  }

  /**
   * Deletes order with the given ID.
   *
   * @param {string|number} orderId the ID of the order to be deleted
   *
   * @returns {Promise<boolean>} true if the order was deleted, false otherwise
   */
  async deleteOrder(orderId) {
    console.debug("Deleting order", orderId);
    const params = await getParams();
    const response = await delivoAxios.delete(`${ORDERS_URL}${orderId}/`, params);
    if (response.status !== 204) {
      console.error(`Failed to delete order ${orderId}.`, response);
      return false;
    }
    console.debug("Order deleted.", orderId);
    return true;
  }

  /**
   * Deletes orders in bulk by their tracking codes.
   *
   * @param {Array<string|number>} trackingCodes the IDs of the orders to be deleted
   *
   * @returns {Promise<boolean>} true if the orders were deleted, false otherwise
   */
  async deleteOrders(trackingCodes) {
    console.debug("Deleting orders", trackingCodes);
    const params = await getParams();
    const request = {
      tracking_codes: trackingCodes,
    };
    const response = await delivoAxios.post(ORDERS_DELETE, request, params);
    if (response.status !== 204) {
      console.error(`Failed to bulk delete order.`, trackingCodes, response);
      return false;
    }
    console.debug("Orders deleted.", trackingCodes);
    return true;
  }

  /**
   * Updates order with the given ID.
   *
   * @param {string} orderId the ID of the order to be updated
   * @param {OrderRequest} order the order to be updated
   *
   * @returns {Promise<Order>} An object of order data.
   */
  async updateOrder(orderId, order) {
    const params = await getParams();
    const response = await delivoAxios.put(
      `${ORDERS_URL}${orderId}/order_draft_update/`,
      order,
      params,
    );
    if (response.status !== 200) {
      console.error(`Failed to update order with ID ${orderId}.`, order, response);
      throw Error(`Failed to update order with ID ${orderId}.`);
    }
    const updatedOrder = new Order(response.data);
    console.debug("Order updated.", orderId, updatedOrder);
    return updatedOrder;
  }

  /**
   * Archive orders by their tracking codes.
   *
   * @param {Array<string|number>} trackingCodes the IDs of the orders to be archived
   *
   * @returns {Promise<boolean>} true if the orders were archived, false otherwise
   */
  async archiveOrders(trackingCodes) {
    const params = await getParams();
    const request = {
      tracking_codes: trackingCodes,
    };
    const response = await delivoAxios.post(ARCHIVE_ORDERS, request, params);
    if (response.status !== 200) {
      console.error(`Failed to archive order.`, trackingCodes, response);
      return false;
    }
    console.debug("Orders archived.", trackingCodes);
    return true;
  }

  /**
   * Unarchives orders by their tracking codes.
   *
   * @param {Array<string|number>} trackingCodes the IDs of the orders to be unarchived
   *
   * @returns {Promise<boolean>} true if the orders were unarchived, false otherwise
   */
  async unarchiveOrders(trackingCodes) {
    const params = await getParams();
    const request = {
      tracking_codes: trackingCodes,
    };
    const response = await delivoAxios.post(UNARCHIVE_ORDERS, request, params);
    if (response.status !== 200) {
      console.error(`Failed to unarchive order.`, trackingCodes, response);
      return false;
    }
    console.debug("Orders unarchived.", trackingCodes);
    return true;
  }
}

const ordersApi = new DelivoOrdersApi();
export { ordersApi };
