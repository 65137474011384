import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Close } from "@mui/icons-material";
import { CitySelectField } from "../selectFields/CitySelectField";
import { getValidationSchema } from "./addressValidation";
import {
  editAddressesInitialValues,
  emptyAddressesInitialValues,
} from "./addressesInitialStates";

function AddressModal({
  isOpen,
  onClose,
  onAddAddress,
  onUpdateAddress,
  initialData,
  isUpdating,
}) {
  const { t } = useTranslation();

  const fieldStyles = { width: "95%", m: 1 };
  const validationSchema = getValidationSchema(t);

  const initialValues =
    isUpdating && initialData
      ? editAddressesInitialValues(initialData)
      : emptyAddressesInitialValues;

  const handleSubmit = async (values, { resetForm }) => {
    if (isUpdating) {
      await onUpdateAddress(values);
    } else {
      await onAddAddress(values);
    }
    resetForm();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Button
        onClick={onClose}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "end",
          mt: 1,
          color: "#707070",
        }}
      >
        <Close />
      </Button>
      <DialogTitle sx={{ textAlign: "center", mt: 1 }}>
        {isUpdating ? t("accountPage.editAddressTitle") : t("accountPage.addAddressTitle")}
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, touched, errors, values }) => (
          <Form>
            <DialogContent sx={{ minWidth: { xs: "240px", sm: "500px" } }}>
              <Field
                as={TextField}
                name="name"
                error={Boolean(errors.name) && Boolean(touched.name)}
                helperText={Boolean(touched.name) && errors.name}
                label={t("accountPage.fieldName")}
                type="text"
                sx={fieldStyles}
                placeholder={t("placeholders.addressName")}
                variant="outlined"
                inputProps={{ maxLength: 80 }}
              />
              <CitySelectField
                fieldName={"cityId"}
                label={t("accountPage.fieldCity")}
                textFieldStyles={fieldStyles}
              />
              <Field
                as={TextField}
                name="address"
                error={Boolean(errors.address) && Boolean(touched.address)}
                helperText={Boolean(touched.address) && errors.address}
                label={t("accountPage.fieldAddress")}
                type="text"
                sx={fieldStyles}
                placeholder={t("placeholders.address")}
                variant="outlined"
                inputProps={{ maxLength: 255 }}
              />
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  mb: 1,
                  bgcolor: "#FFD600",
                  color: "#000",
                  width: "50%",
                  "&:hover": {
                    bgcolor: "#b29500",
                  },
                }}
                disabled={isSubmitting}
                type="submit"
              >
                {isUpdating ? t("accountPage.saveAddressBtn") : t("accountPage.addAddressBtn")}
              </Button>
              <Button
                sx={{
                  mb: 1,
                  color: "#000",
                  width: "30%",
                }}
                onClick={onClose}
              >
                {t("accountPage.cancelAddressBtn")}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default AddressModal;
