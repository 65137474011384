import { OrderListItem } from "./orderTypes";

/**
 * @typedef {object} RegisterOrderMapping a single order view in the registry
 * @property {string} note The note of the order
 * @property {string} tracking_code The tracking code of the order
 * @property {string} shipment_status The shipment status of the order
 *
 */

/**
 * @typedef {object} RegisterListItemMapping A single RegisterListItem object mapping
 * @property {number} id The id of the register
 * @property {string} registry_tracking_code The tracking code of the register
 * @property {string} status The register status
 * @property {string} departure_city_id the ID of the departure city
 * @property {string} departure_address the departure address
 * @property {string} orders_count the order count
 * @property {number} ordering_number  the ordering number
 * @property {PostOfficeMapping} post_office - The date of delivery the order
 * @property {RegisterOrderMapping[]} orders Array of the orders
 */

export class RegisterListItem {
  /**
   * Creates a RegisterListItem instance.
   *
   * @param {RegisterListItemMapping} registerData - The register data to be created.
   * @param {number} registerData.id The id of the register
   * @param {string} registerData.registry_tracking_code - The tracking code of the register
   * @param {string} registerData.status - The register status
   * @param {string} registerData.departure_city_id - The ID of the departure city
   * @param {string} registerData.departure_address - The departure address
   * @param {string} registerData.orders_count - The order count
   * @param {number} registerData.ordering_number - The ordering number
   * @param {string} registerData.creation_date - The creation date or register
   * @param {PostOfficeMapping} registerData.post_office - The post office
   * @param {RegisterOrderMapping[]} registerData.orders - Array of the orders
   */
  constructor({
    id,
    registry_tracking_code,
    status,
    departure_city_id,
    departure_address,
    orders_count,
    ordering_number,
    post_office,
    orders,
    creation_date,
  }) {
    /**
     * The unique ID of the register.
     *
     * This value is unique across organizations.
     * @type {number}
     */
    this.id = id;
    /**
     * The serial always-increasing number of the register in a particular organization.
     *
     * This value is not unique across organizations.
     * @type {number}
     */
    this.serialNumber = ordering_number;
    this.trackingCode = registry_tracking_code;
    this.status = status;
    this.departureCityId = departure_city_id;
    this.departureAddress = departure_address;
    this.ordersCount = orders_count;
    this.postOffice = post_office;
    this.orders = orders;
    this.creationDate = creation_date;
  }
}

/**
 * @typedef {object} RegisterDetailsMapping A single RegisterListItem object mapping
 * @extends RegisterListItemMapping
 * @property {OrderListItemMapping[]} orders_detailed_data detailed info about every order
 * in the registry
 */

export class RegisterDetails extends RegisterListItem {
  /**
   * Creates a RegisterDetails instance.
   *
   * @param {RegisterDetailsMapping} registerData - The register data to be created.
   * @param {number} registerData.id The id of the register
   * @param {string} registerData.registry_tracking_code - The tracking code of the register
   * @param {string} registerData.status - The register status
   * @param {string} registerData.departure_city_id - The ID of the departure city
   * @param {string} registerData.departure_address - The departure address
   * @param {string} registerData.orders_count - The order count
   * @param {number} registerData.ordering_number - The ordering number
   * @param {string} registerData.creation_date - The creation date or register
   * @param {PostOfficeMapping} registerData.post_office - The post office
   * @param {RegisterOrderMapping[]} registerData.orders - Array of the orders
   * @param {OrderListItemMapping[]} registerData.orders_detailed_data - Array of the order details
   */
  constructor({
    id,
    registry_tracking_code,
    status,
    departure_city_id,
    departure_address,
    orders_count,
    ordering_number,
    post_office,
    orders,
    creation_date,
    orders_detailed_data,
  }) {
    super({
      id,
      registry_tracking_code,
      status,
      departure_city_id,
      departure_address,
      orders_count,
      ordering_number,
      post_office,
      orders,
      creation_date,
    });
    this.ordersDetails = (orders_detailed_data || []).map(
      (orderDetails) => new OrderListItem(orderDetails),
    );
  }
}

/**
 * @typedef {object} GetAvailableOrdersByAddress
 * @property {string} departure_city_id the city of the sender
 * @property {string} departure_address the address of the sender within the city
 */

/**
 * @typedef {object} GetAvailableOrdersByPostOffice
 * @property {string} post_office_sender_guid the post office GUID
 */

/**
 * @typedef {object} CreateOrUpdateRegistryRequest
 * @property {string[]} order_tracking_codes the order tracking codes that are attached to the registry
 * @property {string} departure_address the address from which the registry is gonna be picked up from
 * @property {string} departure_city_id the ID of the city where the departure address is located at
 * @property {string} departure_post_office_guid the GUID of the post office from which the registry is gonna be picked up from
 * @property {string} departure_post_office_name the name of the departure post office
 * @property {string} departure_post_office_address the address of the departure post office
 */
