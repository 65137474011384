/**
 * Retrieves the helper text for a form field based on specific conditions. It checks whether a request
 * has been made. If no items are found, a specified 'no items' message is returned. Otherwise, it returns any error messages for the field if
 * the field has been touched.
 *
 * @param {Object} params - The parameters for getting the form field helper text.
 * @param {boolean} params.requestMade - Indicates whether a request for items has been made.
 * @param {string} params.identifier - The identifier used in the request, to specify the subject of the items request.
 * @param {Array} params.items - The array of items retrieved from the request.
 * @param {string} params.field - The name of the form field for which the helper text is being retrieved.
 * @param {Object} params.touched - An object containing fields that have been touched by the user.
 * @param {Object} params.errors - An object containing error messages for the form fields.
 * @param {string} params.noItemsText - The text to return when no items are found for the given identifier.
 * @returns {string|null} The helper text for the form field, or null if no specific text is needed.
 */
export const getFormFieldHelperText = ({
  requestMade,
  identifier,
  items,
  field,
  touched,
  errors,
  noItemsText,
}) => {
  const isIdentifierHasNoItems = requestMade && identifier && items.length === 0;
  if (isIdentifierHasNoItems) {
    return noItemsText;
  } else {
    return (touched[field] && errors[field]) || null;
  }
};
