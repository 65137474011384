import { getLocalizedText } from "../components/getLocalizedText";

/**
 * Localizes the news item by translating its properties to the specified language.
 *
 * @param {NewsItem} newsItem - The news item object.
 * @param {string} language - The language code.
 * @returns {Object} - The localized news item.
 */
export function localizeNewsItem(newsItem, language) {
  if (!newsItem) return null;

  return {
    ...newsItem,
    title: getLocalizedText(newsItem.titleEn, newsItem.titleKa, language),
    previewMessage: getLocalizedText(
      newsItem.previewMessageEn,
      newsItem.previewMessageKa,
      language,
    ),
    fullMessage: getLocalizedText(newsItem.fullMessageEn, newsItem.fullMessageKa, language),
  };
}
