import { Breadcrumbs, Link, Typography } from "@mui/material";
import React from "react";
import { TooltipWrapper } from "../tooltipWrapper/TooltipWrapper";

/**
 * Renders a breadcrumb navigation with a single link followed by static text.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.href - The URL the breadcrumb link points to.
 * @param {string} props.label - The label for the breadcrumb link.
 * @param {string} props.text - The static text displayed after the link.
 * @returns {JSX.Element} The breadcrumb component.
 */
export const Breadcrumb = ({ href, label, text }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" className={"px-2"}>
      <Link underline="hover" color="inherit" href={href}>
        <Typography className="m-3 mr-0">{label}</Typography>
      </Link>
      <TooltipWrapper title={text} placement="top">
        <Typography color="text.primary" className="truncate" style={{ maxWidth: 200 }}>
          {text}
        </Typography>
      </TooltipWrapper>
    </Breadcrumbs>
  );
};
