import * as Sentry from "@sentry/react";
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

function escapeRegex(string) {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&");
}

/**
 * Configures Sentry.io integration.
 */
export function configureSentry() {
  const env = process.env.REACT_APP_ENVIRONMENT || "NOT_SET";
  const replaysSessionSampleRate = env === "prod" ? 0.1 : 0;
  const release = `delivo-business-portal@${process.env.REACT_APP_VERSION}.${process.env.REACT_APP_GIT_COMMIT}`;
  const apiUrl = `${process.env.REACT_APP_DELIVO_API_BASE_URL}/v1/`;
  const themeColor = "rgba(255,214,0,1)";
  const outlineColor = "rgba(255,214,0,0.85)";
  let dsn =
    "https://4b772a04a88e531ec533cf394f069212@o4506348746964992.ingest.sentry.io/4506348752732160";
  if (env === "local" || env === "NOT_SET") {
    dsn = "";
  }
  Sentry.init({
    dsn: dsn,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", new RegExp(escapeRegex(apiUrl))],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
      new Sentry.Feedback({
        // Additional SDK configuration goes in here, for example:
        autoInject: false,
        colorScheme: "light",
        submitButtonLabel: "Send Feedback",
        formTitle: "Share Feedback",
        messagePlaceholder: "Something wrong? What did you expect?",
        successMessageText: "Thank you for your feedback!",
        themeLight: {
          submitBackground: themeColor,
          submitBackgroundHover: outlineColor,
          submitBorder: outlineColor,
          submitOutlineFocus: outlineColor,
          inputOutlineFocus: outlineColor,
        },
      }),
    ],
    environment: env,
    release: release,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
