import { Typography } from "@mui/material";
import DOMPurify from "dompurify";

/**
 * A component that renders content as HTML. Sanitizes HTML content to prevent XSS attacks.
 *
 * @param {object} props - Component props.
 * @param {string} props.content - The content to be rendered, which may contain HTML.
 * @returns {JSX.Element} A JSX element that renders the content appropriately.
 */
export const HtmlContentDisplay = ({ content }) => {
  const sanitizedContent = DOMPurify.sanitize(content);

  return (
    <div style={{ overflowX: "auto" }}>
      <style>
        {`
          .html-content figure {
            width: 90% !important;
            height: auto;
          }
          .html-content img {
            max-width: 100%;
            height: auto;
          }
        `}
      </style>
      <Typography
        className="html-content"
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    </div>
  );
};
