import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "./providers/AuthProvider/AuthProvider";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import NewPassword from "./pages/resetPassword/NewPassword";
import { useFlags } from "./providers/FlagsProvider/FlagsProvider";
import { PageNotFound } from "./pages/404/PageNotFound";
import GuestLayout from "./components/layouts/guestLayouts/GuestLayout";
import PrivateLayout from "./components/layouts/privateLayout/PrivateLayout";
import NewOrders from "./components/newOrdersList/NewOrders";
import MyOrders from "./components/myOrdersList/MyOrders";
import FeedbackForm from "./components/feedbackForm/FeedbackForm";
import OrderCreationWizard from "./components/orderCreationWizard/OrderCreationWizard";
import Registers from "./components/registers/Registers";
import RegisterCreation from "./components/registers/RegisterCreation";
import MyAddresses from "./components/myAddresses/MyAddresses";
import AccountTabs from "./pages/accountPage/AccountTabs";
import AccountInfo from "./components/accoutnInfo/AccountInfo";
import Employees from "./components/employees/Employees";
import LoginForm from "./components/loginForm/LoginForm";
import RegistrationForm from "./components/registrationForm/RegistrationForm";
import LoginTabs from "./pages/loginPage/LoginTabs";
import * as Sentry from "@sentry/react";
import OrdersImport from "./components/ordersImport/OrdersImport";
import RegisterDetails from "./components/registers/RegisterDetails";
import Changelog from "./pages/сhangelog/Changelog";
import OrderDetails from "./components/orderTrackingForm/OrderDetails";
import OrderDetailsTabs from "./components/orderTrackingForm/OrderDetailsTabs";
import OrderStatuses from "./components/orderTrackingForm/OrderStatuses";
import ArchivedOrders from "./components/archivedOrders/ArchivedOrders";
import LoadingBackdrop from "./components/loadingBackdrop/LoadingBackdrop";
import PasswordLayout from "./components/layouts/guestLayouts/PasswordLayout";
import ImportSuccessful from "./components/ordersImport/ImportSuccessful";
import ImportFailed from "./components/ordersImport/ImportFailed";
import FAQList from "./components/faq/FAQList";
import FAQItem from "./components/faq/FAQItem";
import NewsList from "./components/news/NewsList";
import NewsItem from "./components/news/NewsItem";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
const ProtectedRoutes = ({ nonAuthenticatedRedirect = "/login" }) => {
  const { user, noUserExpected } = useAuth();

  if (user) {
    console.debug("Protected route. User available. OK");
    return <PrivateLayout />;
  } else if (noUserExpected) {
    console.debug("Protected route, but user available? o_O WTF?");
    return <Navigate to="/login" replace />;
  } else {
    console.debug("Protected route, but no user available? o_O WTF?");
    return <LoadingBackdrop />;
  }
};

const Router = () => {
  const { user } = useAuth();
  const {
    accountPageEnable,
    employeesPageEnable,
    loginPageEnable,
    myAddressesPageEnable,
    myOrdersPageEnable,
    newOrdersPageEnable,
    resetPasswordPageEnable,
    setPasswordPageEnable,
    registersPageEnable,
    registerDetailsPageEnable,
    registerCreationPageEnable,
    registrationPageEnable,
    ordersTrackingPageEnable,
    orderCreationPageEnable,
    faqPageEnable,
    feedbackPageEnable,
    importExcelFilePageEnable,
    orderUpdatingPageEnable,
    archivedOrdersPageEnable,
    newsPageEnable,
  } = useFlags();
  const rootUrlRedirect = user ? "/orders/my" : "/login";

  return (
    <SentryRoutes>
      <Route path="/" element={<Navigate to={rootUrlRedirect} replace />} />
      <Route path="/changelog" element={<Changelog />} />
      <Route element={<GuestLayout />}>
        <Route path="/" element={<LoginTabs />}>
          {loginPageEnable && <Route path={"/login"} element={<LoginForm />} />}
          {registrationPageEnable && (
            <Route path={"/registration"} element={<RegistrationForm />} />
          )}
        </Route>
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Route>
      <Route element={<PasswordLayout />}>
        {setPasswordPageEnable && <Route path={"/setpassword"} element={<NewPassword />} />}
        {resetPasswordPageEnable && <Route path={"/resetpassword"} element={<ResetPassword />} />}
      </Route>
      <Route element={<ProtectedRoutes nonAuthenticatedRedirect={"/login"} />}>
        {feedbackPageEnable && <Route path={"/feedback"} element={<FeedbackForm />} />}
        {faqPageEnable && <Route path="/faq/:slug" element={<FAQItem />} />}
        {faqPageEnable && <Route path="/faq" element={<FAQList />} />}
        <Route path="/account" element={<AccountTabs />}>
          {accountPageEnable && <Route index element={<AccountInfo />} />}
          {myAddressesPageEnable && <Route path={"addresses"} element={<MyAddresses />} />}
          {employeesPageEnable && <Route path={"employees"} element={<Employees />} />}
        </Route>
        <Route path="/registers">
          {registersPageEnable && <Route index element={<Registers />} />}
          {registerDetailsPageEnable && (
            <Route path={":registerId"} element={<RegisterDetails />} />
          )}
          {registerCreationPageEnable && <Route path={"new"} element={<RegisterCreation />} />}
        </Route>
        <Route path="/orders">
          {myOrdersPageEnable && <Route path={"my"} element={<MyOrders />} />}
          {newOrdersPageEnable && <Route path={"new"} element={<NewOrders />} />}
          {archivedOrdersPageEnable && <Route path={"archive"} element={<ArchivedOrders />} />}
          {ordersTrackingPageEnable && (
            <Route path={":trackingCode"} element={<OrderDetailsTabs />}>
              <Route index element={<OrderDetails />} />
              <Route path="status" element={<OrderStatuses />} />
            </Route>
          )}
          {orderCreationPageEnable && (
            <Route path={"create"} element={<OrderCreationWizard isUpdating={false} />} />
          )}
          {orderUpdatingPageEnable && (
            <Route
              path={":trackingCode/edit"}
              element={<OrderCreationWizard isUpdating={true} />}
            />
          )}
          {importExcelFilePageEnable && (
            <>
              <Route path={"import"} element={<OrdersImport />} />
              <Route path={"import/success"} element={<ImportSuccessful />} />
              <Route path={"import/errors"} element={<ImportFailed />} />
            </>
          )}
        </Route>
        {newsPageEnable && <Route path={"news"} element={<NewsList />} />}
        {newsPageEnable && <Route path={"/news/:slug"} element={<NewsItem />} />}
      </Route>
    </SentryRoutes>
  );
};

export default Router;
