import React from "react";
import { Box } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import StatusItems from "./StatusItems";
import LoadingBackdrop from "../loadingBackdrop/LoadingBackdrop";

function OrderStatuses() {
  const { order, loading } = useOutletContext();

  return (
    <Box className={"w-full shadow-lg py-3"}>
      <LoadingBackdrop isVisible={loading} />
      <StatusItems order={order} />
    </Box>
  );
}

export default OrderStatuses;
