import enFlag from "../../images/enFlag.svg";
import geFlag from "../../images/geFlag.svg";
import { useTranslation } from "react-i18next";

/**
 * Flag component to display a flag image based on the language.
 * @param {Object} props - The props object.
 * @param {string} props.language - The language code ('en' for English or 'ka' for Georgian).
 * @returns {JSX.Element} Flag component.
 */
export function Flag({ language }) {
  const { t } = useTranslation();
  const { src, alt } = language.includes("en")
    ? { src: enFlag, alt: t("alts.enFlag") }
    : { src: geFlag, alt: t("alts.geFlag") };

  return <img src={src} alt={alt} style={{ width: "28px", height: "20px" }} />;
}
