import React from "react";
import { Button, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Feedback, getClient } from "@sentry/react";
import { FeedbackIcon } from "../icons/FeedbackIcon";

const feedbackBtnSx = {
  textTransform: "none",
  borderRadius: "0px",
  "&:hover": {
    borderBottom: "1.5px solid black",
  },
  paddingRight: "0px",
  height: "30px",
};

export const FeedbackBtn = () => {
  const { t } = useTranslation();
  const client = getClient();
  const feedback = client?.getIntegration(Feedback);
  const openFeedbackForm = () => {
    feedback?.openDialog();
  };

  if (!feedback) {
    return null;
  }

  return (
    <Button
      variant="text"
      sx={feedbackBtnSx}
      className={"text-black pl-3 py-2"}
      onClick={openFeedbackForm}
      startIcon={<FeedbackIcon className="hidden lg:block" />}
    >
      <ListItemText primaryTypographyProps={{ fontWeight: "500" }}>
        {t("feedbackForm.feedbackTitle")}
      </ListItemText>
    </Button>
  );
};
