import { useCallback, useMemo } from "react";

/**
 * Custom hook to generate localized text for DataGrid components.
 *
 * @param {function} t - Translation function.
 * @returns {object} Localized text for DataGrid components.
 */
export const useLocaleText = (t) => {
  /**
   * Generates a localized string for the number of selected rows.
   *
   * @param {number} count - Number of selected rows.
   * @returns {string} Localized string for the number of selected rows.
   */
  const translateFooterRowSelected = useCallback(
    (count) => {
      return count !== 1
        ? `${count.toLocaleString()} ${t("dataGridTable.rowsSelected")}`
        : `${count.toLocaleString()} ${t("dataGridTable.rowSelected")}`;
    },
    [t],
  );

  /**
   * Memoized object containing localized text for DataGrid components.
   *
   * @returns {object} Localized text for DataGrid components.
   */
  const localeText = useMemo(
    () => ({
      toolbarColumns: t("dataGridTable.columns"),
      toolbarFilters: t("dataGridTable.filters"),
      toolbarExport: t("dataGridTable.export"),
      toolbarQuickFilterPlaceholder: t("placeholders.searchPlaceholder"),
      columnsPanelTextFieldPlaceholder: t("dataGridTable.columnsTitle"),
      columnsPanelHideAllButton: t("dataGridTable.hide"),
      columnsPanelShowAllButton: t("dataGridTable.show"),
      columnsPanelTextFieldLabel: t("dataGridTable.findColumns"),
      filterPanelInputPlaceholder: t("dataGridTable.filterValue"),
      filterPanelColumns: t("dataGridTable.columns"),
      filterPanelOperator: t("dataGridTable.operator"),
      filterPanelInputLabel: t("dataGridTable.value"),
      toolbarExportPrint: t("dataGridTable.print"),
      toolbarExportCSV: t("dataGridTable.exportCSV"),
      toolbarExportExcel: t("dataGridTable.exportExcel"),
      filterPanelAddFilter: t("dataGridTable.addFilter"),
      filterPanelRemoveAll: t("dataGridTable.remoteFilter"),
      checkboxSelectionHeaderName: t("dataGridTable.checkboxHeader"),
      filterOperatorContains: t("dataGridTable.contains"),
      filterOperatorEquals: t("dataGridTable.equals"),
      filterOperatorEndsWith: t("dataGridTable.endsWith"),
      filterOperatorStartsWith: t("dataGridTable.startsWith"),
      filterOperatorIsAnyOf: t("dataGridTable.isAnyOf"),
      filterOperatorIsEmpty: t("dataGridTable.isEmpty"),
      filterOperatorIsNotEmpty: t("dataGridTable.isNotEmpty"),
      filterPanelOperatorAnd: t("dataGridTable.and"),
      filterPanelOperatorOr: t("dataGridTable.or"),
      filterOperatorIs: t("dataGridTable.is"),
      filterOperatorNot: t("dataGridTable.not"),
      filterValueAny: t("dataGridTable.any"),
      filterValueTrue: t("dataGridTable.true"),
      filterValueFalse: t("dataGridTable.false"),
      filterOperatorAfter: t("dataGridTable.isAfter"),
      filterOperatorOnOrAfter: t("dataGridTable.isOnOfAfter"),
      filterOperatorBefore: t("dataGridTable.isBefore"),
      filterOperatorOnOrBefore: t("dataGridTable.isOnOrBefore"),
      columnMenuSortAsc: t("dataGridTable.sortAsc"),
      columnMenuSortDesc: t("dataGridTable.sortDesc"),
      columnMenuUnsort: t("dataGridTable.unsort"),
      pinToLeft: t("dataGridTable.pitLeft"),
      pinToRight: t("dataGridTable.pitRight"),
      columnMenuFilter: t("dataGridTable.filter"),
      columnMenuHideColumn: t("dataGridTable.hideColumns"),
      columnMenuManageColumns: t("dataGridTable.manageColumns"),
      aggregationMenuItemHeader: t("dataGridTable.aggregation"),
      aggregationFunctionLabelSize: t("dataGridTable.size"),
      aggregationFunctionLabelMin: t("dataGridTable.min"),
      aggregationFunctionLabelMax: t("dataGridTable.max"),
      aggregationFunctionLabelSum: t("dataGridTable.sum"),
      aggregationFunctionLabelAvg: t("dataGridTable.avg"),
      footerRowSelected: translateFooterRowSelected,
      MuiTablePagination: {
        labelRowsPerPage: t("dataGridTable.rowsPerPage"),
      },
      toolbarDensity: t("dataGridTable.density"),
      toolbarDensityComfortable: t("dataGridTable.densityComfortable"),
      toolbarDensityCompact: t("dataGridTable.densityCompact"),
      toolbarDensityStandard: t("dataGridTable.densityStandard"),
      toolbarDensityLabel: t("dataGridTable.density"),
      columnHeaderSortIconLabel: t("dataGridTable.sortIconLabel"),
      toolbarFiltersTooltipHide: t("dataGridTable.hideFilters"),
      toolbarFiltersTooltipShow: t("dataGridTable.showFilters"),
      filterPanelDeleteIconLabel: t("dataGridTable.deleteIconLabel"),
      noResultsOverlayLabel: t("dataGridTable.noResults"),
      columnsManagementShowHideAllText: t("dataGridTable.showHideAll"),
      columnsManagementSearchTitle: t("placeholders.searchPlaceholder"),
    }),
    [t, translateFooterRowSelected],
  );

  return localeText;
};
