import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

function LoadingBackdrop({ isVisible = false }) {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backdropFilter: "blur(8px)",
      }}
      open={isVisible}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default LoadingBackdrop;
