import React from "react";
import { Box, Typography } from "@mui/material";

/**
 * A component to display a detail block with a title and text.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title to display in the block.
 * @param {string} props.text - The text to display below the title.
 * @param {string} props.className - Additional CSS classes to apply to the root element.
 * @returns {JSX.Element} The rendered component.
 */
function DetailBlock({ title, text, className }) {
  return (
    <Box className={className}>
      <Typography variant="caption" className={"text-gray-500"}>
        {title}
      </Typography>
      <Typography>{text}</Typography>
    </Box>
  );
}

export default DetailBlock;
