import React from "react";
import { Box, Typography } from "@mui/material";
import whatsApp from "../../images/whatsApp.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import { TooltipWrapper } from "../tooltipWrapper/TooltipWrapper";

function PrivateManager({ managerInfo }) {
  const { t } = useTranslation();

  const formatTime = (time) => {
    const [hours, minutes] = time.split(":");
    return `${hours}:${minutes}`;
  };
  const formattedWorkHourStart = formatTime(managerInfo.workHourStart);
  const formattedWorkHourEnd = formatTime(managerInfo.workHourEnd);
  const formattedWorkingHours = `${formattedWorkHourStart}-${formattedWorkHourEnd}`;
  const personalPhone = managerInfo.personalPhone.includes("+")
    ? managerInfo.personalPhone.replace("+", "")
    : managerInfo.personalPhone;

  return (
    <Box
      className={
        "flex flex-col border-solid border-0.5 border-black rounded-lg my-2 items-center w-full p-2"
      }
    >
      <Box className={"w-full flex items-center justify-center sm:flex-wrap"}>
        <Box className={"flex items-center px-1"}>
          <Link
            to={`https://wa.me/${personalPhone}`}
            target="_blank"
            className={"no-underline text-black"}
            rel="noopener noreferrer"
            title={t("managerInfo.whatsApp")}
            aria-label={t("managerInfo.whatsApp")}
          >
            <TooltipWrapper title={t("managerInfo.whatsApp")}>
              <img alt={t("managerInfo.whatsApp")} src={whatsApp} className={"p-1"} />
            </TooltipWrapper>
          </Link>
          <Link
            to={`tel:${managerInfo.personalPhone}`}
            className={"no-underline text-black"}
            rel="noopener noreferrer"
            title={t("managerInfo.mobilePhone")}
            aria-label={t("managerInfo.mobilePhone")}
          >
            <TooltipWrapper title={t("managerInfo.mobilePhone")}>
              <PhoneIphoneOutlinedIcon className={"h-7 w-5"} />
            </TooltipWrapper>
          </Link>
        </Box>
        <Link
          to={`https://wa.me/${personalPhone}`}
          target="_blank"
          className={"no-underline text-black"}
          rel="noopener noreferrer"
          title={t("managerInfo.whatsApp")}
          aria-label={t("managerInfo.whatsApp")}
        >
          <Typography variant={"body2"} className={"text-xs text-center"}>
            {managerInfo.personalPhone}
          </Typography>
        </Link>
      </Box>
      <Typography className={"uppercase text-xs my-2 text-center"}>
        {t("managerInfo.managerName")} -
      </Typography>
      <Typography className={"uppercase text-xs text-center"}>
        {managerInfo.firstName} {managerInfo.lastName}
      </Typography>
      <Box className={"flex items-center sm:flex-wrap sm:justify-center my-2"}>
        <Typography className={"text-xs pr-1"}>{t("managerInfo.workingHours")}:</Typography>
        <Typography className={"text-xs"}>{formattedWorkingHours}</Typography>
      </Box>
    </Box>
  );
}

export default PrivateManager;
