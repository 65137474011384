import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import ButtonComponent from "../buttonComponent/ButtonComponent";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FailedRowsList from "./FailedRowsList";

/**
 * Component for displaying details of failed imports.
 *
 * This component renders detailed information about failed imports, including a description of the errors encountered during the import process.
 * It utilizes `useLocation` from 'react-router-dom' to access any initial error data passed via route state (`location.state.errorData`).
 *
 * @returns {JSX.Element} A React component that displays information about failed imports and provides
 * a button to navigate back to the import page.
 */
function ImportFailed() {
  const { t } = useTranslation();
  const location = useLocation();
  const { errorData } = location.state || {};

  const totalErrors = errorData.failed_rows.length;

  return (
    <Box
      className={
        "w-full shadow-md rounded-md mb-5 max-h-[80vh] 2xl:max-h-[85vh] flex flex-col overflow-visible"
      }
    >
      <Typography variant="h5" className={"px-1 m-2"}>
        {t("uploadFilePage.title")}
      </Typography>
      <Typography className={"px-1 m-2"}>
        {t("uploadFilePage.descriptionError", { totalErrors })}
      </Typography>
      <Divider orientation={"horizontal"} flexItem />
      <Box className={"overflow-y-hidden"}>
        {errorData.failed_rows && errorData.failed_rows.length > 0 ? (
          <FailedRowsList errorData={errorData} />
        ) : (
          <Typography variant="body1" className="p-4 text-center">
            {t("uploadFilePage.noFailedRows")}
          </Typography>
        )}
      </Box>
      <Box className={"flex flex-col m-3"}>
        <Link to={"/orders/import"} className={"m-1.5 justify-self-end self-end"}>
          <ButtonComponent
            text={
              errorData.failed_rows && errorData.failed_rows.length > 0
                ? t("uploadFilePage.tryAgainBtn")
                : t("uploadFilePage.goBack")
            }
            className={"px-5 py-3"}
          />
        </Link>
      </Box>
    </Box>
  );
}

export default ImportFailed;
