import { useCallback, useEffect, useMemo, useState } from "react";
import { useFetchOrders } from "./useFetchOrders";
import { ordersApi } from "../api/DelivoOrdersApi";

export const OrderStatus = {
  NEW: "New",
  ACTIVE: "Active",
  ARCHIVED: "Archived",
  ALL: "",
};

/**
 * Returns orders for a given order status.
 * @param {string} orderStatus the status of the orders to be fetched
 * @param {Object} orderDates An object containing start and end date for filtering orders
 * @param {Date} orderDates.startDate The start date for filtering orders
 * @param {Date} orderDates.endDate The end date for filtering orders
 * @returns {{fetchOrders: ((function(*): Promise<Order[]>)|*), orders: Order[], loading: boolean}}
 */
export function useOrders(orderStatus = OrderStatus.ALL, { startDate, endDate }) {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchOrdersHandler = useMemo(() => {
    switch (orderStatus) {
      case OrderStatus.NEW:
        return ordersApi.getNewOrders;
      case OrderStatus.ACTIVE:
        return ordersApi.getActiveOrders;
      case OrderStatus.ARCHIVED:
        return ordersApi.getArchivedOrders;
      default:
        return ordersApi.getOrders;
    }
  }, [orderStatus]);

  const { fetchData } = useFetchOrders(fetchOrdersHandler);

  const fetchOrders = useCallback(
    async ({ startDate, endDate }) => {
      setLoading(true);
      try {
        const fetchedOrders = await fetchData({ startDate, endDate });
        setOrders(fetchedOrders);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    },
    [fetchData],
  );

  useEffect(() => {
    fetchOrders({ startDate, endDate });
  }, [fetchOrders, startDate, endDate]);

  const deleteOrder = async (id) => {
    /**
     * Deletes a single order.
     *
     * @param {string} id - The ID of the order to be deleted.
     * @returns {Promise<boolean>} - A promise that resolves to `true` if the deletion is successful, otherwise `false`.
     */
    const orderDeleted = await ordersApi.deleteOrder(id);
    setOrders((prevOrders) => prevOrders.filter((order) => order.id !== id));
    return orderDeleted;
  };
  const bulkDeleteOrders = async (orderIdsToDelete) => {
    /**
     * Deletes multiple orders in bulk.
     *
     * @param {string[]} orderIdsToDelete - An array of order IDs to be deleted.
     * @returns {Promise<boolean>} - A promise that resolves to `true` if the bulk deletion is successful, otherwise `false`.
     */
    const bulkDelete = await ordersApi.deleteOrders(orderIdsToDelete);
    setOrders((prevOrders) => prevOrders.filter((order) => !orderIdsToDelete.includes(order.id)));

    return bulkDelete;
  };

  const archiveOrders = async (orderIdsToArchive) => {
    /**
     * Archive orders.
     *
     * @param {string[]} orderIdsToArchive - An array of order IDs to be archived.
     * @returns {Promise<boolean>} - A promise that resolves to `true` if the order archived,  otherwise `false`.
     */
    const archivedOrders = await ordersApi.archiveOrders(orderIdsToArchive);
    console.info("Order(s) archived successfully");
    return archivedOrders;
  };

  const unarchiveOrders = async (orderIdsToUnarchive) => {
    /**
     * Unarchives orders, making them active again.
     *
     * @param {string[]} orderIdsToUnarchive - An array of order IDs to be unarchived.
     * @returns {Promise<boolean>} - A promise that resolves with the unarchived orders if successful.
     */
    const unarchivedOrders = await ordersApi.unarchiveOrders(orderIdsToUnarchive);
    console.info("Order(s) unarchived successfully");
    return unarchivedOrders;
  };

  return {
    orders,
    loading,
    fetchOrders,
    deleteOrder,
    bulkDeleteOrders,
    archiveOrders,
    unarchiveOrders,
  };
}
