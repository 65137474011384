import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

export function ChangelogItem({ version, gitCommit, whenReleased }) {
  const { t } = useTranslation();
  return (
    <Box>
      <Card className={"w-full"}>
        <CardContent>
          <Box className={"flex"}>
            <Box className={"w-2/12 my-2 flex"}>
              <Typography variant="body2">{t("app.version")}:</Typography>
              <Typography className={"font-bold ml-2"} variant="body2">
                {version}
              </Typography>
            </Box>
            <Box className={"w-3/12 my-2 flex"}>
              <Typography variant="body2">{t("app.whenReleased")}:</Typography>
              <Typography className={"font-bold ml-2"} variant="body2">
                {whenReleased}
              </Typography>
            </Box>
            <Box className={"my-2 flex"}>
              <Typography variant="body2">{t("app.gitCommit")}:</Typography>
              <Typography className={"font-bold ml-2"} variant="body2">
                {gitCommit}
              </Typography>
            </Box>
          </Box>
          <Typography>
            <Trans i18nKey={`app.versions.${version}`} />
          </Typography>
        </CardContent>
      </Card>
      <Divider orientation={"horizontal"} sx={{ p: "0.25rem" }} flexItem />
    </Box>
  );
}
