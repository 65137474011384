import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Tab, Tabs } from "@mui/material";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import HeaderWithLogOutButton from "../../components/headerWithLogOutButton/HeaderWithLogOutButton";

function AccountTabs() {
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname;
  return (
    <Box className={"shadow-md rounded-md"}>
      <HeaderWithLogOutButton />
      <Divider orientation={"horizontal"} flexItem />
      <Tabs value={path} aria-label="account tabs">
        <Tab
          label={t("accountPage.accountInfo")}
          component={NavLink}
          to="/account"
          value="/account"
          className={"smMax:w-1/2"}
        />
        <Tab
          label={t("accountPage.myAddresses")}
          component={NavLink}
          to="/account/addresses"
          value="/account/addresses"
          className={"smMax:w-1/2"}
        />
      </Tabs>
      <Divider orientation={"horizontal"} flexItem />
      <Outlet />
    </Box>
  );
}

export default AccountTabs;
