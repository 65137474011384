import React from "react";
import { Box, Chip, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import tipsIcon from "../../images/tipsIcon.svg";
import LoadingBackdrop from "../loadingBackdrop/LoadingBackdrop";
import { useAccountContext } from "../../providers/AccountProvider/AccountProvider";
import { TooltipWrapper } from "../tooltipWrapper/TooltipWrapper";

function AccountInfo() {
  const { t } = useTranslation();
  const { accountInfo, loading } = useAccountContext();
  const publicName = accountInfo?.publicName || "-";
  const publicPhone = accountInfo?.publicPhone || "-";
  const publicAddress = accountInfo?.publicAddress || "-";

  const smallScreenStyleBox = "flex items-start flex-col pr-5";
  const otherScreenStyleBox =
    "flex-col md:w-2/5 xl:w-1/3 sm:justify-center sm:items-start md:pr-0";

  return (
    <>
      <LoadingBackdrop isVisible={loading} />
      <Box className={"flex flex-col items-start justify-around h-fit w-full box-border"}>
        <Box
          className={
            "flex flex-col md:flex-row md:items-center md:justify-between w-full m-3 md:flex-wrap xl:flex-nowrap"
          }
        >
          <Box className={`${smallScreenStyleBox} ${otherScreenStyleBox} md:ml-2`}>
            <Typography variant="caption" className={"text-gray-500"}>
              {t("registrationForm.companyNameFieldLabel")}
            </Typography>
            <Typography variant="body1" className={"sm:text-start"}>
              {accountInfo.companyName}
            </Typography>
          </Box>
          <Box className={`${smallScreenStyleBox} ${otherScreenStyleBox}`}>
            <Typography variant="caption" className={"text-gray-500"}>
              {t("registrationForm.legalAddressFieldLabel")}
            </Typography>
            <Typography variant="body1" className={"sm:text-start"}>
              {accountInfo.legalAddress}
            </Typography>
          </Box>
          <Box className={`${smallScreenStyleBox} ${otherScreenStyleBox} md:ml-2 xl:ml-0`}>
            <Typography variant="caption" className={"text-gray-500"}>
              {t("registrationForm.emailFieldLabel")}
            </Typography>
            <Typography variant="body1" className={"sm:text-start"}>
              {accountInfo.email}
            </Typography>
          </Box>
        </Box>
        <Box
          className={"flex flex-col md:flex-row md:items-center md:justify-between w-full m-3"}
        >
          <Box className={`${smallScreenStyleBox} ${otherScreenStyleBox} md:ml-2`}>
            <Typography variant="caption" className={"text-gray-500"}>
              {t("registrationForm.companyIDFieldLabel")}
            </Typography>
            <Typography variant="body1" className={"sm:text-start"}>
              {accountInfo.govNumber}
            </Typography>
          </Box>
          <Box className={`${smallScreenStyleBox} ${otherScreenStyleBox}`}>
            <Typography variant="caption" className={"text-gray-500"}>
              {t("registrationForm.companyPhoneNumberFieldLabel")}
            </Typography>
            <Typography variant="body1" className={"sm:text-start"}>
              {accountInfo.companyPhone}
            </Typography>
          </Box>
          <Box className={"w-1/3 flex items-center justify-start md:mx-8 xl:mx-0"}></Box>
        </Box>
        <Divider orientation={"horizontal"} flexItem />
        <Box
          className={
            "flex flex-col md:flex-row md:items-center md:justify-between w-full m-3 md:flex-wrap xl:flex-nowrap"
          }
        >
          <Box className={`${smallScreenStyleBox} ${otherScreenStyleBox} md:ml-2`}>
            <Typography variant="caption" className={"text-gray-500"}>
              {t("registrationForm.nameContactPersonFieldLabel")}
            </Typography>
            <Typography variant="body1" className={"sm:text-start"}>
              {accountInfo.contactName}
            </Typography>
          </Box>
          <Box className={`${smallScreenStyleBox} ${otherScreenStyleBox}`}>
            <Typography variant="caption" className={"text-gray-500"}>
              {t("registrationForm.phoneNumberContactPersonFieldLabel")}
            </Typography>
            <Typography variant="body1" className={"sm:text-start"}>
              {accountInfo.contactPhone}
            </Typography>
          </Box>
          <Box className={`${smallScreenStyleBox} ${otherScreenStyleBox} md:ml-2 xl:ml-0`}>
            <Typography variant="caption" className={"text-gray-500"}>
              {t("registrationForm.emailContactPersonFieldLabel")}
            </Typography>
            <Typography variant="body1" className={"sm:text-start"}>
              {accountInfo.contactEmail}
            </Typography>
          </Box>
        </Box>
        <Divider orientation={"horizontal"} flexItem />
        <Box className={"flex items-center mx-3"}>
          <TooltipWrapper
            title={
              <>
                <p>
                  <strong>{t("tooltips.publicProfileTitle")}</strong> -
                  {t("tooltips.publicProfileText")}
                  <br />
                  <strong>{t("accountPage.enabled")}:</strong> {t("tooltips.enabledText")}
                  <br />
                  <strong>{t("accountPage.disabled")}:</strong> {t("tooltips.disabledText")}
                </p>
              </>
            }
            className={"flex items-center mr-4"}
          >
            <Box>
              <Typography variant={"h6"} className={"my-2 xs:ml-0 md:ml-2 mr-1"}>
                {t("accountPage.publicProfileTitle")}
              </Typography>
              <span role="img">
                <img src={tipsIcon} alt={t("ordersTableHeaders.estimatedTimeHint")} />
              </span>
            </Box>
          </TooltipWrapper>
          <Chip
            className={`${accountInfo.usePublicProfile ? "bg-green-200" : ""}`}
            label={
              accountInfo.usePublicProfile ? t("accountPage.enabled") : t("accountPage.disabled")
            }
          />
        </Box>
        <Box className={"flex items-center justify-between w-full m-3 mt-0"}>
          <Divider orientation={"horizontal"} flexItem />
          <Box
            className={
              "flex flex-col md:flex-row md:items-center md:justify-between w-full md:flex-wrap xl:flex-nowrap"
            }
          >
            <Box className={`${smallScreenStyleBox} ${otherScreenStyleBox} md:ml-2`}>
              <Typography variant="caption" className={"text-gray-500"}>
                {t("registrationForm.publicName")}
              </Typography>
              <Typography variant="body1" className={"sm:text-start"}>
                {publicName}
              </Typography>
            </Box>
            <Box className={`${smallScreenStyleBox} ${otherScreenStyleBox}`}>
              <Typography variant="caption" className={"text-gray-500"}>
                {t("registrationForm.publicPhone")}
              </Typography>
              <Typography variant="body1" className={"sm:text-start"}>
                {publicPhone}
              </Typography>
            </Box>
            <Box
              className={`${smallScreenStyleBox} ${otherScreenStyleBox} md:ml-2 xl:ml-0 md:w-full`}
            >
              <Typography variant="caption" className={"text-gray-500"}>
                {t("registrationForm.publicAddress")}
              </Typography>
              <Typography variant="body1" className={"sm:text-start"}>
                {publicAddress}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default AccountInfo;
