import React from "react";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";

const MemoizedCard = React.memo(({ faqItem }) => {
  return (
    <Card className="mb-4 shadow-lg h-fit">
      <CardActionArea href={`/faq/${faqItem.slug}`}>
        <CardContent>
          <Typography gutterBottom variant="h6" className="text-lg font-bold mb-4">
            {faqItem.question}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
});
export const RelatedFAQ = ({ relatedFAQ }) => {
  return (
    <>
      {relatedFAQ.map((faqItem) => (
        <MemoizedCard faqItem={faqItem} key={faqItem.slug} />
      ))}
    </>
  );
};
