import { Box, IconButton, useMediaQuery } from "@mui/material";
import React, { useCallback } from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import PrintAction from "../actionIcons/PrintAction";
import ArchiveAction from "../actionIcons/ArchiveAction";
import UnarchiveAction from "../actionIcons/UnarchiveAction";
import DeleteAction from "../actionIcons/DeleteAction";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/**
 * Action toolbar component for DataGrid, optimized with memoization to avoid unnecessary re-renders.
 *
 * @component
 * @param {object} props - The component props.
 * @param {boolean} props.showPrintIcon - Whether to show the print icon.
 * @param {function} props.onPrint - Callback function for print action.
 * @param {boolean} props.disableIcons - Whether to disable the icons.
 * @param {boolean} props.showArchiveIcon - Whether to show the archive icon.
 * @param {function} props.onArchive - Callback function for archive action.
 * @param {boolean} props.showUnarchiveIcon - Whether to show the unarchive icon.
 * @param {function} props.onUnarchive - Callback function for unarchive action.
 * @param {boolean} props.showDeleteIcon - Whether to show the delete icon.
 * @param {function} props.onDelete - Callback function for delete action.
 * @returns {JSX.Element} The rendered action toolbar component.
 * @param {boolean} props.showHiddenButtons - Flag to show or hide hidden buttons in mobile view.
 * @param {Function} props.setShowHiddenButtons - Function to toggle the hidden buttons state.
 */
export const ActionsToolBar = React.memo(
  ({
    showPrintIcon,
    onPrint,
    disableIcons,
    showArchiveIcon,
    onArchive,
    showUnarchiveIcon,
    onUnarchive,
    showDeleteIcon,
    onDelete,
    showHiddenButtons,
    setShowHiddenButtons,
  }) => {
    const isMobile = useMediaQuery("(max-width:640px)");

    const handleToggleHiddenButtons = useCallback(() => {
      setShowHiddenButtons((prevState) => !prevState);
    }, [setShowHiddenButtons]);

    const OtherActionsButtons = () => (
      <>
        <GridToolbarDensitySelector />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        {showPrintIcon && <PrintAction onClick={onPrint} disabled={disableIcons} />}
        {showArchiveIcon && <ArchiveAction onClick={onArchive} disabled={disableIcons} />}
        {showUnarchiveIcon && <UnarchiveAction onClick={onUnarchive} disabled={disableIcons} />}
        {showDeleteIcon && <DeleteAction onClick={onDelete} disabled={disableIcons} />}
      </>
    );

    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        {!isMobile ? (
          <OtherActionsButtons />
        ) : (
          <>
            <IconButton onClick={handleToggleHiddenButtons} color={"primary"}>
              {showHiddenButtons ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            {showHiddenButtons && (
              <Box className={"flex w-full flex-wrap"}>
                <OtherActionsButtons />
              </Box>
            )}
          </>
        )}
      </GridToolbarContainer>
    );
  },
);
