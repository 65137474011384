import { Box, Divider, Typography } from "@mui/material";
import RadioGroupField from "../../radioGroupField/RadioGroupField";
import { DELIVERY_METHOD } from "../../constants";
import { RegionSelectField } from "../../selectFields/RegionSelectField";
import { CitySelectField } from "../../selectFields/CitySelectField";
import { AddressSelectField } from "../../selectFields/AddressSelectField";
import React from "react";
import {
  adaptiveBoxWrapper,
  boxWrapper,
  getContentBlockClass,
  headerSectionStyle,
  radioGroupBtnCustomStyle,
} from "../tailwindClasses";
import { useTranslation } from "react-i18next";
import { PostOfficeField } from "../../selectFields/PostOfficeField";

/**
 * NotEditedOrderPart component displays the pickup city and address of an order in a non-editable format.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Object} props.order - The order object containing order details
 * @returns {JSX.Element} The rendered component
 */
export const NonEditableOrderDetails = ({ order }) => {
  const { t } = useTranslation();
  return (
    <Box
      className={
        "md:w-full box-border flex flex-col md:flex-row items-start justify-start p-1.5 mb-5 mx-6"
      }
    >
      <Box className={"w-8/12 md:w-5/12"}>
        <Typography variant="caption" className={"text-gray-500"}>
          {t("orderUpdating.pickupCity")}
        </Typography>
        <Typography>{order.pickupCity}</Typography>
      </Box>
      <Box className={"w-8/12"}>
        <Typography variant="caption" className={"text-gray-500"}>
          {t("orderUpdating.pickupAddress")}
        </Typography>
        <Typography>{order.pickupAddress}</Typography>
      </Box>
      <Box className={"w-1/3"} />
    </Box>
  );
};

/**
 * PickupOrderInfo component displays the pickup information section in an order form.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.pickupCity - The selected pickup city
 * @param {string} props.pickupPostOffice - The selected pickup post office
 * @param {string} props.pickupRegion - The selected pickup region
 * @param {Function} props.handleRegionChange - Handler for region change
 * @param {Function} props.handlePickupCityChange - Handler for pickup city change
 * @param {string} props.pickupMethod - The selected pickup method
 * @param {Function} props.handleChange - General change handler for form fields
 * @param {string} props.pickupAddressId - The selected pickup address ID
 * @param {Object} props.touched - Formik touched object for field validation
 * @param {Object} props.errors - Formik errors object for field validation
 * @returns {JSX.Element} The rendered component
 */
export const PickupOrderInfo = ({
  pickupCity,
  pickupPostOffice,
  pickupRegion,
  handleRegionChange,
  handlePickupCityChange,
  pickupMethod,
  handleChange,
  pickupAddressId,
  touched,
  errors,
}) => {
  const { t } = useTranslation();

  const pickupMethodLabels = [
    {
      value: DELIVERY_METHOD.COURIER,
      label: t("orderCreation.clientPartRadioGroupDeliveryMethodCourier"),
    },
    {
      value: DELIVERY_METHOD.DEPARTMENT,
      label: t("orderCreation.clientPartRadioGroupDeliveryMethodPostOffice"),
    },
  ];

  const courierMethodStyles = getContentBlockClass(pickupMethod === DELIVERY_METHOD.COURIER);
  const departmentMethodStyles = getContentBlockClass(
    pickupMethod === DELIVERY_METHOD.DEPARTMENT,
  );

  return (
    <>
      <Typography variant={"h6"} className={headerSectionStyle}>
        {t("orderCreation.clientPartTitleDeliveryMethod")}
      </Typography>
      <Divider orientation={"horizontal"} flexItem />
      <RadioGroupField
        name="pickupMethod"
        value={pickupMethod}
        onChange={handleChange}
        labels={pickupMethodLabels}
        className={radioGroupBtnCustomStyle}
      />
      <Box className={departmentMethodStyles}>
        <Box className={`${adaptiveBoxWrapper} w-full md:w-5/6`}>
          <RegionSelectField
            fieldName="pickupRegion"
            onCustomChange={() => handleRegionChange("pickupCity", "pickupPostOffice")}
          />
          <CitySelectField
            fieldName="pickupCity"
            label={t("orderCreation.clientPartSelectCity")}
            tooltipMessage={t("tooltips.noPostOffices")}
            customOnChange={handlePickupCityChange}
            checkedField="postOfficeDeliveryEnabled"
            region={pickupRegion}
          />
          <PostOfficeField
            cityId={pickupCity}
            selectedPostOffice={pickupPostOffice}
            fieldName="pickupPostOffice"
            touched={touched}
            errors={errors}
          />
        </Box>
      </Box>
      <Box className={courierMethodStyles}>
        <Box className={boxWrapper}>
          <AddressSelectField
            fieldName="pickupAddress"
            value={pickupAddressId}
            className="w-full md:w-2/3 xl:w-1/3"
          />
        </Box>
      </Box>
    </>
  );
};
