import React, { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Link } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { object, string } from "yup";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../buttonComponent/ButtonComponent";
import { useAuth } from "../../providers/AuthProvider/AuthProvider";
import { useSnackbar } from "notistack";

function LoginForm() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [typePass, setTypePass] = useState("password");
  const [rememberMeCheckbox, setRememberMeCheckbox] = useState(true);
  const { login } = useAuth();

  const validationSchema = object({
    email: string()
      .required(t("errorsMessages.emailRequire"))
      .email(t("errorsMessages.emailValidation"))
      .max(125, t("errorsMessages.maxEmailLength")),
    password: string().required(t("errorsMessages.passwordRequire")),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, formikHelpers) => {
          const success = await login(
            values.email.toLowerCase(),
            values.password,
            rememberMeCheckbox,
            "/account",
          );
          if (!success) {
            enqueueSnackbar(t("alertsMessages.invalidEmailOrPassword"), { variant: "error" });
          } else {
            formikHelpers.resetForm();
          }
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form className={"flex flex-col items-center p-2"}>
            <Typography variant="h4">{t("loginForm.title")}</Typography>
            <Field
              as={TextField}
              error={Boolean(errors.email) && Boolean(touched.email)}
              helperText={Boolean(touched.email) && errors.email}
              label={t("loginForm.emailFieldLabel")}
              name="email"
              placeholder="example@mail.com"
              type="email"
              className={"w-full md:w-1/2 my-4"}
              inputProps={{ maxLength: 125 }}
            />
            <Field
              as={TextField}
              label={t("loginForm.passwordFieldLabel")}
              type={typePass}
              placeholder={t("placeholders.password")}
              className={"w-full md:w-1/2 my-4"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setTypePass(typePass === "password" ? "text" : "password");
                      }}
                    >
                      <VisibilityOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              name="password"
              error={Boolean(errors.password) && Boolean(touched.password)}
              helperText={Boolean(touched.password) && errors.password}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMeCheckbox}
                  onChange={() => setRememberMeCheckbox(!rememberMeCheckbox)}
                />
              }
              label={t("loginForm.rememberCheck")}
            />
            <Box
              className={
                "flex flex-col md:flex-row justify-around items-center m-2 w-full md:w-4/6"
              }
            >
              <ButtonComponent
                text={t("loginButton")}
                disabled={isSubmitting}
                type="submit"
                className={"w-[200px] mb-5 md:w-auto md:mb-0"}
              />
              <Link to={"/resetpassword"} className={"text-gray-400 "}>
                {t("resetPasswordButton")}
              </Link>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}
export default LoginForm;
