import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { formatGeDateTime } from "../dates";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import useStatusTranslations from "../../hooks/useStatusTranslations";
import { ORDER_STATUS } from "../constants";

function StatusItem({ statusItem }) {
  const { formatOrderStatus } = useStatusTranslations();

  const hasPostOffice = (status) =>
    [ORDER_STATUS.READY_TO_ISSUE, ORDER_STATUS.DELIVERED_TO_SORTING_WAREHOUSE].includes(status);
  return (
    <Box className={"flex items-center justify-start smMax:mx-4 mx-5 py-1"}>
      <Box className={"w-1/5 flex items-center justify-around"}>
        <Typography className={"text-xs xl:text-sm"}>
          {formatGeDateTime(new Date(statusItem.stepDate))}
        </Typography>
        <FiberManualRecordIcon className={"text-sm smMax:hidden"} color="primary" />
      </Box>
      <Divider orientation={"vertical"} flexItem className={"mx-1"} />
      <Box className={"ml-2 w-4/5 sm:ml-1"}>
        <Box className={"flex items-center smMax:flex-col smMax:items-start"}>
          <Typography className={"text-pretty"}>
            {formatOrderStatus(statusItem.stepStatus)}
          </Typography>
          {hasPostOffice(statusItem.stepStatus) && (
            <Typography className={"text-gray-500 pl-2 pt-0.5 text-xs"}>
              {statusItem.stepPostOffice}
            </Typography>
          )}
        </Box>
        <Box>
          <Typography variant="caption" className={"text-gray-500"}>
            {statusItem.stepComment}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default StatusItem;
