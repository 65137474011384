import { styled } from "@mui/material";
import { MaterialDesignContent } from "notistack";

/**
 * Snackbar styles object defining common layout properties.
 * @type {Object}
 */
const snackbarStyles = {
  "@media (max-width:600px)": {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "& div": {
      marginLeft: "0",
      paddingLeft: "0",
    },
  },
};

const snackbarSuccessStyles = {
  "@media (max-width:600px)": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "& div": {
      marginLeft: "0",
      paddingLeft: "0",
      "& button": {
        paddingLeft: "0",
      },
    },
    "& #notistack-snackbar": {
      maxWidth: "90%",
    },
  },
};

/**
 * StyledMaterialDesignContent is a styled component that applies common styles
 * to MaterialDesignContent for success and error notistack notifications.
 *
 * The class names follow the notistack convention: notistack-MuiContent and
 * notistack-MuiContent-<variant_name>.
 *
 * @type {import("@mui/system").StyledComponent<import("@mui/material").BoxProps>}
 */
export const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": snackbarSuccessStyles,
  "&.notistack-MuiContent-error": snackbarStyles,
  "&.notistack-MuiContent-warning": snackbarStyles,
}));
