import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAvailableOrders, useRegister } from "../../hooks/useRegister";
import { registerCreationColumns, registerDetailsColumns } from "./registerColumns";
import { useSnackbar } from "notistack";
import { Box, Divider, Typography } from "@mui/material";
import ButtonComponent from "../buttonComponent/ButtonComponent";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DataGridTable from "../dataGrid/DataGridTable";
import { useRegisters } from "../../hooks/useRegisters";
import { REGISTER_STATUS_FROM_API } from "../constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirm } from "material-ui-confirm";
import { handleConfirmation } from "../handleConfirmation";
import { bulkPrintStickers } from "../handlePrint";
import showApiErrorNotification from "../showApiErrorNotification";
import { TooltipWrapper } from "../tooltipWrapper/TooltipWrapper";

function RegisterDetails() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getAvailableOrders } = useGetAvailableOrders();
  const { registerId } = useParams();
  const { deleteRegister } = useRegisters();
  const {
    register,
    loading: loadingRegister,
    readyForDeliveryRegister,
    updateRegister,
    fetchRegister,
  } = useRegister(registerId);
  const [ordersToSelect, setOrdersToSelect] = useState([]);
  const [selectedOrderTrackingCodes, setSelectedOrderTrackingCodes] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const isRegisterDraft = register.registryStatus === REGISTER_STATUS_FROM_API.DRAFT;
  const columns = isRegisterDraft ? registerCreationColumns(t) : registerDetailsColumns(t);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const selectedCity = register?.registryPickupCity;

  let selectedAddress = "";
  let registerNumber = "";
  if (!loadingRegister && register) {
    if (register.registryPickupPostOffice === "") {
      selectedAddress = register.registryPickupAddress;
    } else {
      selectedAddress = `${register.registryPickupPostOffice}, ${register.registryPickupPostOfficeAddress}`;
    }

    if (register.registryTrackingNumber === null) {
      registerNumber = register.registrySerialNumber;
    } else {
      registerNumber = register.registryTrackingNumber;
    }
  }
  const allOrders = useMemo(() => {
    return [...(register?.ordersDetails || []), ...ordersToSelect];
  }, [register, ordersToSelect]);

  const updateAvailableOrders = useCallback(async () => {
    if (!register) {
      return;
    }
    const pickUpCityId = register?.registryPickupCityId.id;
    const filters = {
      pickupCity: pickUpCityId,
      pickupAddress: register?.registryPickupAddress,
      pickupPostOffice: register?.registryPickupPostOfficeId,
    };
    try {
      const availableOrders = await getAvailableOrders(filters);
      if (availableOrders.length === 0) {
        enqueueSnackbar(t("alertsMessages.noOrdersMoreFind"), {
          variant: "warning",
        });
      } else {
        setOrdersToSelect(availableOrders);
      }
    } catch (error) {
      console.error("Error fetching available orders:", error);
      showApiErrorNotification(t("alertsMessages.smthGoesWrong"), error, enqueueSnackbar);
    }
  }, [getAvailableOrders, register, t, enqueueSnackbar]);

  const handleRegisterUpdate = async () => {
    const updatedRegisterData = {
      orders_tracking_codes: selectedOrderTrackingCodes,
      departure_address: register?.registryPickupAddress || null,
      departure_city_id: register?.registryPickupCity || null,
      departure_post_office_guid: register?.registryPickupPostOfficeId || null,
      departure_post_office_name: register?.registryPickupPostOffice || null,
      departure_post_office_address: register?.registryPickupPostOfficeAddress || null,
    };
    const updatedRegister = await updateRegister(updatedRegisterData);
    console.info(`Register ${updatedRegister.registrySerialNumber} updated.`, updatedRegister);
    enqueueSnackbar(t("alertsMessages.registerUpdated"), {
      variant: "success",
    });
    setOrdersToSelect([]);
    setHasChanges(false);
  };

  useEffect(() => {
    const trackingCodes = (register?.ordersDetails || []).map((order) => order.trackingCode);
    setSelectedOrderTrackingCodes(trackingCodes);
    setHasChanges(false);
  }, [register]);

  const handleRegisterReadyDelivery = async () => {
    let newStatus = "";

    if (register.registryPickupPostOffice === "") {
      newStatus = REGISTER_STATUS_FROM_API.READY_FOR_PICKUP;
    } else if (register.registryPickupAddress === "") {
      newStatus = REGISTER_STATUS_FROM_API.READY_FOR_SHIPMENT;
    } else {
      console.warn(
        `Register ${registerId} is in invalid status. Can't proceed with the delivery.`,
        register,
      );
      return;
    }
    try {
      if (newStatus) {
        await readyForDeliveryRegister(newStatus);
        enqueueSnackbar(t("alertsMessages.registerStatusUpdated"), {
          variant: "success",
        });
        navigate("/registers/");
        console.log("Register status updated successfully.");
      } else {
        enqueueSnackbar(t("alertsMessages.registerFailedStatusUpdate"), {
          variant: "error",
        });
        console.log("No valid status found to update.");
      }
    } catch (error) {
      showApiErrorNotification(
        t("alertsMessages.registerFailedStatusUpdate"),
        error,
        enqueueSnackbar,
      );
      console.error("Failed to update register status", error);
    }
  };
  const handleDeleteRegister = async (id) => {
    try {
      const registerDeleted = await deleteRegister(id);
      if (registerDeleted) {
        enqueueSnackbar(t("alertsMessages.registerDeleted"), {
          variant: "success",
        });
        console.info("Register deleted");
        navigate("/registers/");
      } else {
        enqueueSnackbar(t("alertsMessages.registerDeleteFailed"), {
          variant: "error",
        });
        console.error(`Failed to delete the register ${id}`);
      }
    } catch (e) {
      showApiErrorNotification(t("alertsMessages.registerDeleteFailed"), e, enqueueSnackbar);
      console.error("Failed to delete the register", e);
    }
  };
  const handleDeleteConfirmation = async () => {
    const confirmationDetails = {
      description: t("confirmation.confirmDelete", { registerNumber }),
    };
    await handleConfirmation(
      confirm,
      confirmationDetails,
      () => handleDeleteRegister(registerId),
      t,
    );
  };

  const handleReadyForDeliveryConfirmation = async () => {
    const confirmationDetails = {
      description: t("confirmation.confirmReadyDelivery", { registerNumber }),
    };
    await handleConfirmation(confirm, confirmationDetails, handleRegisterReadyDelivery, t);
  };

  const handlerBulkPrintStickersHandler = async () => {
    try {
      await bulkPrintStickers(selectedOrderTrackingCodes);
    } catch (error) {
      showApiErrorNotification(t("alertsMessages.failedPrintStickers"), error, enqueueSnackbar);
      console.error(`Error printing stickers: ${selectedOrderTrackingCodes}`, error);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleRefresh = () => {
    fetchRegister(registerId);
    setOrdersToSelect([]);
  };

  return (
    <Box className={"w-full shadow-lg rounded-md"}>
      <Box className={"flex items-center w-full"}>
        <Box className={"w-1/12"}>
          <ButtonComponent startIcon={<ArrowBackIosNewIcon />} onClick={handleGoBack} />
        </Box>
        <Box className={"flex items-end w-5/12 flex-nowrap"}>
          {!isRegisterDraft ? (
            <Typography variant={"h4"}>
              {t("pagesTitles.register")}: {registerNumber}
            </Typography>
          ) : (
            <Typography variant={"h4"}>
              {t("register.registerEditingTitle")}: {registerNumber}
            </Typography>
          )}
        </Box>
        <Box className={"flex items-start justify-around w-6/12"}>
          <Box className={"my-4 w-1/3"}>
            <Typography variant="caption" className={"text-gray-500"}>
              {t("accountPage.fieldCity")}
            </Typography>
            <Typography>{selectedCity}</Typography>
          </Box>
          <Box className={"my-4 w-1/2"}>
            <Typography variant="caption" className={"text-gray-500"}>
              {t("accountPage.fieldAddress")}
            </Typography>
            <Typography>{selectedAddress}</Typography>
          </Box>
        </Box>
        <Divider orientation={"horizontal"} flexItem />
      </Box>
      {!isRegisterDraft ? (
        <Box></Box>
      ) : (
        <Box className={"w-full flex justify-end p-.5"}>
          <Box className={"w-1/2"}></Box>
          <Box className={"w-1/2 flex items-center justify-between"}>
            <TooltipWrapper title={t("tooltips.findMoreOrders")}>
              <span>
                <ButtonComponent
                  text={t("register.findMoreBtn")}
                  onClick={updateAvailableOrders}
                />
              </span>
            </TooltipWrapper>
            <TooltipWrapper title={hasChanges ? t("tooltips.readyDeliveryBtnRegister") : ""}>
              <span>
                <ButtonComponent
                  text={t("register.readyDelivery")}
                  disabled={hasChanges || selectedOrderTrackingCodes.length === 0}
                  onClick={handleReadyForDeliveryConfirmation}
                />
              </span>
            </TooltipWrapper>
            <ButtonComponent
              text={<DeleteIcon />}
              className={"bg-red-500 text-black p-3 mr-2"}
              onClick={handleDeleteConfirmation}
            />
          </Box>
        </Box>
      )}
      <DataGridTable
        rows={allOrders}
        columns={columns}
        showPrintIcon={true}
        onPrint={handlerBulkPrintStickersHandler}
        checkboxSelection={isRegisterDraft}
        hideFooterSelectedRowCount={!isRegisterDraft}
        rowSelectionModel={isRegisterDraft ? selectedOrderTrackingCodes : ""}
        onRowSelectionModelChange={(newSelectedOrders) => {
          setSelectedOrderTrackingCodes(newSelectedOrders);
          setHasChanges(true);
        }}
        loading={loadingRegister}
        onRefresh={handleRefresh}
        isRefreshBtnVisible={isRegisterDraft}
        emptyTableText={
          <Typography className={"text-center pt-16"}>
            {t("ordersTableHeaders.noOrdersInRegister")}
          </Typography>
        }
      />
      {isRegisterDraft ? (
        <Box className={"flex justify-end"}>
          <TooltipWrapper title={t("tooltips.saveBtnEditRegister")}>
            <span>
              <ButtonComponent
                text={t("register.saveBtn")}
                onClick={handleRegisterUpdate}
                disabled={!hasChanges || selectedOrderTrackingCodes.length === 0}
              />
            </span>
          </TooltipWrapper>
        </Box>
      ) : (
        <Box></Box>
      )}
    </Box>
  );
}

export default RegisterDetails;
