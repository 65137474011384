import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import logoBlack from "../images/logoBlack.svg";
import checked from "../images/checked.svg";

function ContentSideForm() {
  const { t, i18n } = useTranslation();
  return (
    <div className={"flex flex-col items-center h-full m-5"}>
      <Link to={"/"}>
        <img src={logoBlack} alt={t("alts.logoBlack")} className={"md:w-36 m-8"} />
      </Link>
      <Typography variant={"h5"} className={"m-5 2xl:text-2xl"}>
        {t("contentSideBar.title")}
      </Typography>
      <div className={"flex items-center self-start m-0 2xl:m-5"}>
        <span className={"m-2"}>
          <img src={checked} className={"md:w-6 w-10 h-10"} alt={t("alts.checkedIcon")} />
        </span>
        <Typography variant={"body1"} className={"my-2"}>
          {t("contentSideBar.contentSideBarTextLine1")}
        </Typography>
      </div>
      <div className={"flex items-center self-start m-0 2xl:m-5"}>
        <span className={"m-2"}>
          <img src={checked} className={"md:w-6 w-10 h-10"} alt={t("alts.checkedIcon")} />
        </span>
        <Typography variant={"body1"} className={"my-2"}>
          {t("contentSideBar.contentSideBarTextLine2")}
        </Typography>
      </div>
      <div className={"flex items-center self-start m-0 2xl:m-5"}>
        <span className={"m-2"}>
          <img src={checked} className={"md:w-6 w-10 h-10"} alt={t("alts.checkedIcon")} />
        </span>
        <Typography variant={"body1"} className={"my-2"}>
          {t("contentSideBar.contentSideBarTextLine3")}
        </Typography>
      </div>
    </div>
  );
}

export default ContentSideForm;
