import axios from "axios";
import {
  AVAILABLE_ORDERS_REGISTERS_URL,
  REGISTERS_URL,
  REGISTERS_URL_PREFIX,
} from "./delivo-api-config";
import { getParams } from "./DelivoApi";
import { RegisterDetails, RegisterListItem } from "./registersTypes";
import { OrderListItem } from "./orderTypes";
import delivoAxios from "./delivoAxios";

/**
 * Delivo API for working with registers.
 */
class DelivoRegistersApi {
  /**
   * Get all registers.
   *
   * @returns {Promise<RegisterListItem[]>} A list of registers data objects.
   */

  async getRegisters() {
    try {
      console.debug(`Retrieving available registers.`);
      const params = await getParams();
      const response = await delivoAxios.get(REGISTERS_URL, params);
      if (response.status !== 200) {
        console.error("Failed to get registers.", response);
        throw Error("Failed to retrieve registers.");
      }
      const userRegisters = response.data;
      console.debug("userRegisters", userRegisters);
      return userRegisters.map((userRegister) => new RegisterListItem(userRegister));
    } catch (e) {
      console.error("Failed to get registers.", e);
      throw e;
    }
  }

  /**
   * Get register detail info.
   *
   * @param {string|number} registerId the ID of the register
   * @returns {Promise<RegisterDetails>}  An object of register data.
   */
  async getRegister(registerId) {
    try {
      console.debug(`Retrieving available orders register by ID.`, registerId);
      const params = await getParams();
      const url = `${REGISTERS_URL_PREFIX}/${registerId}/`;
      const response = await delivoAxios.get(url, params);
      if (response.status !== 200) {
        console.error("Failed to get register.", response);
        throw Error(`Failed to get a register for ID ${registerId}`);
      }
      const result = new RegisterDetails(response.data);
      console.debug("Register retrieved", registerId, result);
      return result;
    } catch (e) {
      console.error("Failed to get register.", e);
      throw e;
    }
  }

  /**
   * Returns information about available orders for register
   *
   * @param {GetAvailableOrdersByAddress | GetAvailableOrdersByPostOffice} queryParams
   * the available orders request params
   * @returns {Promise<OrderListItem[]>} A list of order data objects
   */
  async getAvailableOrders(queryParams) {
    try {
      console.debug(`Retrieving available orders.`, queryParams);
      const params = await getParams({ queryParams });
      const response = await delivoAxios.get(AVAILABLE_ORDERS_REGISTERS_URL, params);
      if (response.status !== 200) {
        console.error("Failed to get available orders.", response);
        throw new Error("Failed to get available orders.");
      }
      const userAvailableOrders = response.data;
      console.debug("register available orders", userAvailableOrders);
      return userAvailableOrders.map((order) => new OrderListItem(order));
    } catch (e) {
      console.error("Failed to get available orders.", e);
      throw e;
    }
  }

  /**
   * Creates a new register from the given request params.
   *
   * @param {CreateOrUpdateRegistryRequest} registerCreationRequest the register creation request body
   * @returns {Promise<RegisterListItem>}
   */
  async createRegister(registerCreationRequest) {
    console.debug("Creating a new register.", registerCreationRequest);
    const params = await getParams();
    const response = await delivoAxios.post(REGISTERS_URL, registerCreationRequest, params);
    if (response.status !== 201) {
      console.error("Failed to create a register.", registerCreationRequest, response);
      throw Error("Failed to create a register.");
    }
    const registerCreated = new RegisterListItem(response.data);
    console.debug("A new register created.", registerCreated);
    return registerCreated;
  }

  /**
   * Returns a list of the available registers.
   *
   * @param {string|number} registerId the ID of the register to be updated
   * @param {CreateOrUpdateRegistryRequest} register the register to be updated
   *
   * @returns {Promise<RegisterDetails>}
   */
  async updateRegister(registerId, register) {
    console.debug("Updating register.", registerId, register);
    const params = await getParams();
    const response = await delivoAxios.put(
      `${REGISTERS_URL_PREFIX}/${registerId}/`,
      register,
      params,
    );
    if (response.status !== 200) {
      console.error(`Failed to update register with ID ${registerId}.`, register, response);
      throw Error(`Failed to update register with ID ${registerId}.`);
    }
    const updatedRegister = new RegisterDetails(response.data);
    console.debug("Register updated.", registerId, updatedRegister);
    return updatedRegister;
  }

  /**
   * Modifies the registry status and sets it to ready for delivery.
   *
   * @param {string|number} registerId the ID of the register to be updated
   * @param {object} register the fields of register to be updated
   * @param {REGISTER_STATUS_FROM_API.READY_FOR_PICKUP | REGISTER_STATUS_FROM_API.READY_FOR_SHIPMENT} register.status the status of register
   *
   * @returns {Promise<RegisterListItem>}
   */
  async readyForDeliveryRegister(registerId, { status }) {
    console.debug(`Changing register ${registerId} status to ${status}`, registerId, status);
    const params = await getParams();

    const data = { status };
    const response = await delivoAxios.patch(
      `${REGISTERS_URL_PREFIX}/${registerId}/`,
      data,
      params,
    );
    if (response.status !== 200) {
      console.error(`Failed to modify registry ${registerId} status to ${status}.`, response);
      throw new Error(`Failed to modify registry ${registerId} status to ${status}.`);
    }
    const result = new RegisterListItem(response.data);
    console.debug("Register status updated.", registerId, result);
    return result;
  }

  /**
   * Deletes register with the given ID.
   *
   * @param {string|number} registerId the ID of the register to be deleted
   *
   * @returns {Promise<boolean>}
   */
  async deleteRegister(registerId) {
    console.debug("Deleting register", registerId);
    const params = await getParams();
    const response = await delivoAxios.delete(`${REGISTERS_URL_PREFIX}/${registerId}/`, params);
    if (response.status !== 204) {
      console.error(`Failed to delete register ${registerId}.`, response);
      return false;
    }
    console.debug("Register deleted.", registerId);
    return true;
  }
  /**
   * Get register sticker with the given ID.
   *
   * @param {string|number} registerId the ID of the register
   * *
   * @returns {Promise<Blob>} A Promise that resolves to a PDF Blob representing the register sticker
   */
  async getRegisterSticker(registerId) {
    const params = await getParams();
    params.responseType = "blob";
    const url = `${REGISTERS_URL}${registerId}/pdf-report/`;
    const response = await delivoAxios.get(url, params);
    if (response.status !== 200) {
      console.error(
        `Failed to get register sticker for register with ID ${registerId}.`,
        response,
      );
      throw new Error(`Failed to get register sticker for register with ID ${registerId}.`);
    }
    return response.data;
  }
}

const registersApi = new DelivoRegistersApi();
export { registersApi };
